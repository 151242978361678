import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AdActionsView from "@/views/AdActionsView.vue";
import AdStructureView from "@/views/AdStructureView.vue";
import AdServingView from "@/views/AdServingView.vue";
import AdStrategy from "@/components/AdStrategy.vue";
import AdCompositionView from "@/views/AdCompositionView.vue";
import AdErrorView from "@/views/AdErrorView.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/home',
        name: 'history',
        component: HomeView
    },
    {
        path: '/ad-actions',
        name: 'ad-actions',
        component: AdActionsView
    },
    {
        path: '/ad-structure',
        name: 'ad-structure',
        component: AdStructureView
    },

    {
        path: '/ad-serving',
        name: 'ad-serving',
        component: AdServingView
    },
    {
        path: '/ad-strategy',
        name: 'ad-strategy',
        component: AdStrategy
    },
    {
        path: '/ad-composition',
        name: 'ad-composition',
        component: AdCompositionView
    },
       {
        path: '/404',
        name: 'ad-error',
        component: AdErrorView
    },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
