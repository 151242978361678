<template>
<el-container>
  <el-header>
    <Header></Header>
  </el-header>
  <el-main>
    <AdServing></AdServing>
  </el-main>
  <el-footer>
    <Footer></Footer>
  </el-footer>
</el-container>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import AdServing from "@/components/AdServing.vue";
export default {
  name: "AdServingView",
  components: {
    Header,
    Footer,
    AdServing
    // Ad
  }
}
</script>

<style scoped>
.el-header  {
    background-color: #545c64;
    color: #333;
    text-align: center;
    /*line-height: 230px;*/
    height: 80px;
  }
   .el-footer {
    width: 100%;
    background-color: #545c64;
    color: #333;
    text-align: center;
    /*line-height: 80px;*/
     height: 100px !important;
  }
</style>