<template>
  <div :style="styles">
    <el-col :span="24">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">广告操作</el-breadcrumb-item>
        <el-breadcrumb-item>SKU选择</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider></el-divider>
    </el-col>
    <el-row :gutter="24">
      <el-col :span="6" style="padding-left: 40px">
        <el-input v-model="filterValue" placeholder="请输入ASIN或SKU"
                  @keydown.enter="search" @blur="search" @clear="refresh" clearable></el-input>
      </el-col>
      <el-col :span="6" :offset="12">
        <el-button type="primary" @click="BatchModifyAdCombination">批量组合选择</el-button>
        <el-button type="primary" @click="BatchModifyAd">批量拆分选择</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24" style="margin: 10px 30px;">
      <div class="my-table-container">
        <div class="empty-div"></div>
        <el-tabs type="border-card" v-model="activeTab">
          <el-tab-pane label="SKU选择" name="first">
            <el-table
                class="my-table"
                v-loading="loading"
                border
                :max-height="getMaxHeight()"
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%;"
                :header-row-style="{height: '50px'}"
                @selection-change="handleSelectionChange"
                @sort-change="handleSortChange"
            >
              <el-table-column
                  type="selection"
                  width="40">
              </el-table-column>
              <el-table-column
                  sortable
                  type="index"
                  label="序号"
                  width="60">
              </el-table-column>
              <el-table-column
                  prop="image"
                  label="图片"
                  width="100">
                <template slot-scope="scope">
                  <img :src="scope.row.image" alt="图片"/>
                </template>
              </el-table-column>
              <el-table-column
                  prop="asin"
                  label="ASIN"
                  width="150">
              </el-table-column>
              <el-table-column
                  prop="sku"
                  label="SKU"
                  width="160">
              </el-table-column>
              <el-table-column
                  prop="product_name"
                  label="产品名称"
                  width="400">
              </el-table-column>
              <el-table-column
                  prop="currency"
                  label="国家"
                  width="60">
              </el-table-column>
              <el-table-column
                  sortable
                  prop="price"
                  label="价格"
                  width="80"
              >
              </el-table-column>
              <el-table-column
                  sortable
                  prop="quantity"
                  label="库存量"
                  width="90">
              </el-table-column>
              <el-table-column
                  sortable
                  prop="star"
                  label="星级"
                  width="80">
              </el-table-column>

              <el-table-column
                  sortable
                  prop="sales"
                  label="销量/月"
                  width="100">
              </el-table-column>
              <el-table-column
                  fixed="right"
                  label="操作"
                  width="100">
                <template slot-scope="scope">
                  <el-button
                      @click.native.prevent="CreateRow(scope.$index, tableData)"
                      type="text"
                      size="small">
                    选择
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="手动输入" name="second">
            <el-input
                type="textarea"
                :rows="10"
                placeholder="请输入或粘贴需要投放的关键词,一行一个"
                v-model="skuInput">
            </el-input>
          </el-tab-pane>
        </el-tabs>
        <el-dialog title="产品名称" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
          <el-form :model="form">
            <el-row>
              <div>已选商品</div>
              <div class="product-container">
                <div v-for="(product, index) in selection" :key="index">
                  <img :src="product.image" alt="商品图片">
                  <p class="sku">SKU: {{ product.sku }}</p>
                  <p class="asin">ASIN: {{ product.asin }}</p>
                </div>
              </div>
            </el-row>
            <el-form-item label="产品名称" :label-width="formLabelWidth">
              <el-input v-model="form.name" autocomplete="off"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogForm">下一步</el-button>
          </div>
        </el-dialog>
      </div>
    </el-row>
    <el-pagination
        class="pagination-wrapper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="parseInt(pagination.page)"
        :page-sizes="pagination.pageSizeOption"
        :page-size="parseInt(pagination.page_size)"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="pagination.total_records"
    >
    </el-pagination>
  </div>
</template>

<script>

export default {
  name: "AdActions",
  data() {
    return {
      maxHeight: 500,
      activeTab: 'first',
      skuInput: '',
      form: {name: ''},
      pagination: {
        page: 0,
        page_size: 20,
        total_records: 1,
        total_pages: 1,
        pageSizeOption: [20, 50, 100, 200]
      },
      src: '',
      filterValue: '',
      dialogFormVisible: false,
      formLabelWidth: '120px',
      baseFontSize: 18,
      baseLineHeight: 1.8,
      tableData: [
        {asin: "B0CGV59GP4", sku: "B0CGV59GP4"},
        {asin: "B0CGV7SDT9", sku: "B0CGV7SDT9"},
        {asin: "B0CGV5M24S", sku: "B0CGV5M24S"},
      ],
      selection: [],
      dialogVisible: false,
      dialogInput: '',
      loading: true,

    }
  },
  methods: {
    getMaxHeight() {
      // 在这里根据屏幕大小或其他条件计算最大高度
      // 这里简单地根据窗口高度调整最大高度，你可以根据需要进行更复杂的计算
      return Math.max(window.innerHeight - 100, this.maxHeight);
    },
    handleSortChange(sort) {
      const {prop, order} = sort;
      // 示例中按照prop指定的属性在order指定的顺序进行排序
      this.$axios.get('https://ad.yaelongtime.com/api/sp/sku',
          {
            params: {
              sort: sort.prop + ',' + (sort.order == 'ascending' ? 'desc' : 'asc')
            }
          }
      ).then(res => {
        if (res.data.content) {
          this.loading = false
          let tableData = []
          res.data.content.forEach(function (ad) {
            let skuData = {
              image: ad.imageUrl,
              sku: ad.sku,
              product_name: ad.title,
              price: '$' + ad.price,
              asin: ad.asin,
              product_state: '',
              availability: ad.availability,
              quantity: ad.quantity,
              star: ad.star + '星',
              sales: ad.sales,
              currency: ad.priceToPay.currency
            }
            tableData.push(skuData)
          })

          this.tableData = tableData
          this.pagination.total_pages = res.data.totalPages
          this.pagination.total_records = res.data.totalElements
        } else {
          this.tableData = []
        }
      }).catch(error => {
        this.$message({
          message: error,
          type: 'error'
        })
      })
    },
    handleSizeChange(val) {
      this.pagination.page_size = val
      this.handleCurrentChange(0)
      console.log(`每页 ${val} 条`);
    },
    handleChange() {
    },
    search() {
      const searchAsin = () => {
        this.searchAsin()
      };
      if (this.filterValue) {
        this.$axios.get('https://ad.yaelongtime.com/api/sp/sku',
            {
              params: {
                sku: this.filterValue,
              }
            }
        ).then(res => {
          if (res.data.content) {
            this.loading = false
            let tableData = []
            res.data.content.forEach(function (ad) {
              let skuData = {
                image: ad.imageUrl,
                sku: ad.sku,
                product_name: ad.title,
                price: '$' + ad.price,
                asin: ad.asin,
                product_state: '',
                quantity: ad.quantity,
                star: ad.star + '星',
                sales: ad.sales,
                currency: ad.priceToPay.currency
              }
              tableData.push(skuData)
            })
            this.tableData = tableData
            this.pagination.total_pages = res.data.totalPages
            this.pagination.total_records = res.data.totalElements
          } else {
            this.tableData = []
            searchAsin()
          }
        }).catch(error => {
          this.$message({
            message: error,
            type: 'error'
          })
        })

      } else {
        this.handleCurrentChange()
      }
    },
    searchAsin() {
      this.$axios.get('https://ad.yaelongtime.com/api/sp/sku',
          {
            params: {
              asin: this.filterValue,
            }
          }
      ).then(res => {
        if (res.data.content) {
          this.loading = false
          let tableData = []
          res.data.content.forEach(function (ad) {
            let skuData = {
              image: ad.imageUrl,
              sku: ad.sku,
              product_name: ad.title,
              price: '$' + ad.price,
              asin: ad.asin,
              product_state: '',
              quantity: ad.quantity,
              star: ad.star + '星',
              sales: ad.sales,
              currency: ad.priceToPay.currency
            }
            tableData.push(skuData)
          })
          this.tableData = tableData
          this.pagination.total_pages = res.data.totalPages
          this.pagination.total_records = res.data.totalElements
        } else {
          this.tableData = []
        }
      }).catch(error => {
        this.$message({
          message: error,
          type: 'error'
        })
      })
    },
    refresh() {
      this.filterValue = '';
      this.search();
    },
    handleSelectionChange(selection) {
      this.selection = selection;
    },
    CreateRow(scope) {
      let selected_product_information = []
      selected_product_information.push(this.tableData[scope])
      if (this.selection.length < 1) {
        if (this.tableData[scope].quantity < 1) {
          this.$message({
            message: '产品不符合投放标准',
            type: 'error'
          })
        } else {
          this.$router.push({
            path: '/ad-structure', query: {
              selected_product_information: selected_product_information,
              upPath: this.$route.path
            }
          });
        }
      } else if (this.selection.length === 1) {
        if (this.selection[0].quantity < 1) {
          this.$message({
            message: '产品不符合投放标准',
            type: 'error'
          })
          this.selection = []
        } else {
          this.$router.push({
            path: '/ad-structure', query: {
              selected_product_information: this.selection,
              upPath: this.$route.path
            }
          });
        }
      } else {
        this.$message({
          message: '数量超范围',
          type: 'error'
        })
      }
    },
    //多条拆分组合投放
    BatchModifyAd() {
      if (this.activeTab === 'first') {
        let quantity = ''
        if (this.selection.length > 1) {
          this.selection.forEach(function (product) {
            product.product_state = 1
            if (product.quantity < 1) {
              quantity = 0
            }
          })
          if (quantity === 0) {
            this.$message({
              message: '产品不符合投放标准',
              type: 'error'
            })
          } else {
            this.$router.push({
              path: '/ad-structure', query: {
                selected_product_information: this.selection,
                upPath: this.$route.path
              }
            });
          }
        } else if (this.selection.length === 1) {
          this.CreateRow()
        } else {
          this.$message({
            message: '未选择',
            type: 'error'
          })
        }
      } else if (this.activeTab === 'second') {
        const asinList = this.skuInput.trim(); // 去除首尾空白字符
        if (asinList) {
          const asinLists = asinList.split('\n'); // 将输入的关键词按行拆分成数组
          //获取推荐ASIN的详细信息
          if (asinLists.length > 1) {
            this.$axios.post('https://ad.yaelongtime.com/api/proxy/product/metadata',
                {
                  "asins": asinLists,
                  "checkItemDetails": "true",
                  "pageSize": 300,
                  "locale": "en_US"
                },
                {
                  headers: {
                    'Content-Type': 'application/vnd.productmetadataresponse.v1+json'
                  },
                }
            ).then(res => {
              let tableRecommendationData = []
              if (res.data.ProductMetadataList.length > 0) {
                res.data.ProductMetadataList.forEach(function (product) {
                  let tableRecommendation = {
                    image: product.imageUrl,
                    sku: product.sku,
                    product_name: product.title,
                    price: '$' + product.priceToPay.amount,
                    asin: product.asin,
                    product_state: 1,
                    quantity: product.quantity,
                    star: product.star + '星',
                    sales: product.sales,
                    currency: product.priceToPay.currency,
                  }
                  tableRecommendationData.push(tableRecommendation)
                })
                this.selection = tableRecommendationData
                this.$router.push({
                  path: '/ad-structure', query: {
                    selected_product_information: this.selection,
                    upPath: this.$route.path
                  }
                });
              } else {
                asinList.forEach(function (product) {
                  let tableRecommendation = {
                    image: '',
                    sku: '',
                    product_name: '',
                    price: '$' + '',
                    asin: product.asin,
                    product_state: '',
                    quantity: '',
                    star: '' + '星',
                    sales: '',
                    currency: ''
                  }
                  tableRecommendationData.push(tableRecommendation)
                })
                this.tableRecommendationData = tableRecommendationData
              }
            }).catch(error => {
              this.$message({
                message: error,
                type: 'error'
              })
            })
          } else {
            this.$message({
              message: 'ASIN数量不符合要求',
              type: 'error'
            })
          }
        }

      }
    },
    //多条组合投放
    BatchModifyAdCombination() {
      if (this.activeTab === 'first') {
        if (this.selection.length > 1) {
          this.selection.forEach(function (product) {
            product.product_state = 2
          })
          this.dialogFormVisible = true;
          let productDate = []
          productDate.push(this.selection)
        } else if (this.selection.length === 1) {
          this.CreateRow()
        } else {
          this.$message({
            message: '未选择',
            type: 'error'
          })
        }
      } else if (this.activeTab === 'second') {
        const asinList = this.skuInput.trim(); // 去除首尾空白字符
        if (asinList) {
          const asinLists = asinList.split('\n'); // 将输入的关键词按行拆分成数组
          //获取推荐ASIN的详细信息
          if (asinLists.length > 1) {
            this.$axios.post('https://ad.yaelongtime.com/api/proxy/product/metadata',
                {
                  "asins": asinLists,
                  "checkItemDetails": "true",
                  "pageSize": 300,
                  "locale": "en_US"
                },
                {
                  headers: {
                    'Content-Type': 'application/vnd.productmetadataresponse.v1+json'
                  },
                }
            ).then(res => {
              let tableRecommendationData = []
              if (res.data.ProductMetadataList.length > 0) {
                res.data.ProductMetadataList.forEach(function (product) {
                  let tableRecommendation = {
                    image: product.imageUrl,
                    sku: product.sku,
                    product_name: product.title,
                    price: '$' + product.priceToPay.amount,
                    asin: product.asin,
                    product_state: 2,
                    quantity: product.quantity,
                    star: product.star + '星',
                    sales: product.sales,
                    currency: product.priceToPay.currency,
                  }
                  tableRecommendationData.push(tableRecommendation)
                })
                this.dialogFormVisible = true;
                this.selection = tableRecommendationData
              } else {
                asinList.forEach(function (product) {
                  let tableRecommendation = {
                    image: '',
                    sku: '',
                    product_name: '',
                    price: '$' + '',
                    asin: product.asin,
                    product_state: '',
                    quantity: '',
                    star: '' + '星',
                    sales: '',
                    currency: ''
                  }
                  tableRecommendationData.push(tableRecommendation)
                })
                this.tableRecommendationData = tableRecommendationData
              }
            }).catch(error => {
              this.$message({
                message: error,
                type: 'error'
              })
            })
          } else {
            this.$message({
              message: 'ASIN数量不符合要求',
              type: 'error'
            })
          }
        }
      }
    },
    dialogForm() {
      let product_name = this.form.name
      if (product_name === "") {
        this.$message({
          message: '产品名称不能为空',
          type: 'error'
        })
      } else {
        this.selection.forEach(function (ad) {
          ad.name = product_name
        })
        this.$router.push({
          path: '/ad-structure', query: {
            selected_product_information: this.selection,
            upPath: this.$route.path
          }
        });
      }
    },
    handleCurrentChange(currentPage) {
      if (currentPage === 0) {
        currentPage = this.pagination.page
      } else {
        currentPage = currentPage - 1
      }
      this.$axios.get('https://ad.yaelongtime.com/api/sp/sku',
          {
            params: {
              page: currentPage,
              size: this.pagination.page_size,
              sort: "quantity" + ',' + "desc"
            }
          }
      ).then(res => {
        console.log(8787, res.data)
        this.loading = false
        let tableData = []
        if (res.data.content) {
          res.data.content.forEach(function (ad) {
            let skuData = {
              image: ad.imageUrl,
              sku: ad.sku,
              product_name: ad.title,
              price: '$' + ad.price,
              asin: ad.asin,
              availability: ad.availability,
              product_state: '',
              quantity: ad.quantity,
              star: ad.star + '星',
              sales: ad.sales,
              currency: ad.priceToPay.currency
            }
            tableData.push(skuData)
          })
          this.tableData = tableData
          this.pagination.total_pages = res.data.totalPages
          this.pagination.total_records = res.data.totalElements
        }
      }).catch(error => {
        this.$message({
          message: error,
          type: 'error'
        })
      })
    },
  },
  computed: {
    styles() {
      // 根据基准字号和行高计算字体大小和行距
      const fontSize = `${this.baseFontSize}px`;
      const lineHeight = `${this.baseLineHeight * this.baseFontSize}px`;

      // 响应式布局
      return {
        'font-size': fontSize,
        'line-height': lineHeight,
        'padding-top': '-10px',
        'padding-left': '-10px',
        'padding-right': '1px',
      };
    },
  },
  created() {
    this.handleCurrentChange(0)
  },
  beforeRouteLeave(to, from, next) {
    // 当前路由即将离开
    if (to.path === '/ad-structure' && from.path === '/ad-actions') {
      // 确认离开，继续导航到目标路由
      next();
    } else {
      // 其他情况，继续导航
      next();
    }
  },
  mounted() {
    // 监听窗口大小变化，实时更新表格最大高度
    window.addEventListener('resize', () => {
      this.maxHeight = this.getMaxHeight();
    });
  },
}
</script>

<style scoped>
.product-container {
  display: flex;
  flex-wrap: wrap;
}

.product-item {
  display: flex;
  align-items: center;
  margin: 20px;
}

.product-item img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}

.product-info {
  display: flex;
  flex-direction: column;
}

.el-row {
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.search {
  margin: 0;
  padding: 0;
  background-color: #f9fafc;
  padding-right: 100%;
  font-size: 1px;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 6px;
}

.my-table-container {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.my-table {
  border-collapse: collapse;
}

@media screen and (max-width: 600px) {
  .my-table {
    font-size: 12px;
    line-height: 1.5;
  }
}

.my-table-container {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.my-table {
  border-collapse: collapse;
}

@media screen and (max-width: 600px) {
  .my-table {
    font-size: 12px;
    line-height: 1.5;
  }
}

.sku,
.asin {
  margin: 10px 10px 1px 10px;
}
</style>