<template>
  <div :style="styles">
    <el-col :span="24">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">广告结构</el-breadcrumb-item>
        <el-breadcrumb-item>基础数据</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider></el-divider>
    </el-col>
    <el-col :span="24" style="padding: 10px 50px">
      <el-row>
        <div>已选商品</div>
        <div class="product-container">
          <div v-for="(product, index) in selectionProduct" :key="index">
            <img :src="product.image" alt="商品图片">
            <p class="sku">SKU: {{ product.sku }}</p>
            <p class="asin">ASIN: {{ product.asin }}</p>
          </div>
        </div>
      </el-row>
    </el-col>
    <el-col :span="24" style="padding: 40px 60px 200px 500px">
      <el-form :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm"
               style="width: 100%;">
        <el-form-item label="预算" prop="name" style="width: 33%;">
          <el-input v-model="budget">
            <template slot="append">$</template>
          </el-input>
        </el-form-item>
        <el-form-item label="组合" style="width: 50%">
          <el-select
              v-model="selectedPortfoliosValue"
              collapse-tags
              clearable
              filterable
              placeholder="请选择组合"
          >
            <el-option
                v-for="item in ad_portfolios_options"
                :key="item.id"
                :label="item.name"
                :value="item.portfolios">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="广告组" prop="name">
          <el-select
              v-model="selectedGroupsValue"
              allow-create
              collapse-tags
              filterable
              placeholder="请选择广告组"
          >
            <el-option
                v-for="item in ad_group_options"
                :key="item.id"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="日期" style="width: 100%" required>
          <el-col :span="5">
            <el-form-item prop="startDate">
              <el-date-picker type="date" placeholder="选择开始日期" v-model="startDate" style="width: 90%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="1" style="padding-left: 10px">-</el-col>
          <el-col :span="5">
            <el-form-item prop="endDate">
              <el-date-picker type="date" placeholder="选择结束日期" v-model="endDate" style="width: 90%"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="广告组默认竞价" style="width: 33%">
          <template slot="label">
            <span class="red-asterisk-label">
            </span>广告组默认竞价
          </template>
          <el-input v-model="defaultBidding">
            <template slot="append">$</template>
          </el-input>
        </el-form-item>
        <el-form-item label="广告位竞价调整" style="width: 71%">
          <template slot="label">
            <span></span>广告位竞价调整
          </template>
          <el-col :span="24">
            搜索结果顶部(首页)
            <el-input v-model="adHomeSlotsValue" style="width: 40%;">
              <template slot="append">%</template>
            </el-input>
          </el-col>
          <el-col :span="22" style="padding: 20px" :offset="2">
            商品页面
            <el-input v-model="adSlotsValue" style="width: 48%;">
              <template slot="append">%</template>
            </el-input>
          </el-col>
          <el-col :span="24" style="padding: 10px">
            搜索结果其余位置
            <el-input v-model="adOtherPagesValue" style="width: 41%;">
              <template slot="append">%</template>
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleUpClick">上一步</el-button>
          <el-button type="primary" @click="onSubmit">下一步</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </div>
</template>

<script>
import {Message} from 'element-ui'

export default {
  name: "AdComposition",
  data() {
    return {
      selectionInfo: [],
      tableRecommendData: [],
      tableInfoData: [],
      selectionProduct: [],
      ad_portfolios_options: [],
      budget: '',
      region: '',
      defaultBidding: '',
      adSlotsValue: 0,
      adHomeSlotsValue: 0,
      adOtherPagesValue:0,
      name: '',
      selectedPortfoliosValue: '',
      startDate: '',
      endDate: '',
      delivery: false,
      type: [],
      resource: '',
      desc: '',
      rules: {
        name: [
          {required: true, message: '请输入预算', trigger: 'blur'},
          {message: '预算必填', trigger: 'blur'}
        ],
        region: [
          {required: true, message: '请选择广告活动', trigger: 'change'}
        ],
        date1: [
          {type: 'date', required: true, message: '请选择开始日期', trigger: 'change'}
        ],
        date2: [
          {type: 'date', required: true, message: '请选择结束时间', trigger: 'change'}
        ],
        type: [
          {type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change'}
        ],
        resource: [
          {required: true, message: '请选择活动资源', trigger: 'change'}
        ],
        desc: [
          {required: true, message: '请填写活动形式', trigger: 'blur'}
        ]
      },
      selectedGroupsValue: '',
      ad_group_options: [],
    }
  },
  methods: {
    //上一步
    handleUpClick() {
      this.$router.push({
        path: '/ad-strategy', query: {
          selectionProductStrategyData: this.tableRecommendData,
          selectionProductData: this.selectionProduct,
          tableInfoData: this.tableInfoData,
          selectionInfo: this.selectionInfo,
          upPath: this.$route.path
        }
      });
    },
    //下一步
    onSubmit() {
      let startDate = this.startDate
      let endDate = this.endDate
      const budget = this.budget
      const selectedPortfoliosValue = this.selectedPortfoliosValue
      const defaultBidding = this.defaultBidding
      let adSlotsValue = ''
      let adHomeSlotsValue = ''
      let adSpSlotsValue = this.adSlotsValue
      let adSpHomeSlotsValue = this.adHomeSlotsValue
      const selectionProduct = this.selectionProduct
      const selectionInfo = this.selectionInfo
      const selectedGroupsValue = this.selectedGroupsValue
      let adOtherPagesValue = this.adOtherPagesValue
      if (startDate && endDate) {
        startDate.setDate(startDate.getDate() + 1)
        startDate = startDate.toISOString().split('T')[0]
        endDate.setDate(endDate.getDate() + 1)
        endDate = endDate.toISOString().split('T')[0]
      } else if (startDate) {
        startDate.setDate(startDate.getDate() + 1)
        startDate = startDate.toISOString().split('T')[0]
      }
      this.selectionProduct.forEach(function (product) {
        if (product.product_state === 1) {
          if (product.strategy[0].keyword) {
            product.strategy[0].keyword.forEach(function (keyword) {
              if (product.strategy[0].advertising_strategy.includes('SP')) {
                if (product.strategy[0].advertising_strategy === 'SPP-C') {
                  product.strategy[0].typeText = '类目'
                } else if (product.strategy[0].advertising_strategy === 'SPP-A1' || product.strategy[0].advertising_strategy === 'SPP-A2') {
                  product.strategy[0].typeText = 'ASIN'
                } else {
                  product.strategy[0].typeText = keyword.selectedKeywordType
                }
                if (keyword.rangeEnd) {
                  keyword.bidding = (product.strategy[0].selectedType / 10 * keyword.rangeEnd).toFixed(2)
                } else {
                  keyword.rangeStart = '~'
                  keyword.rangeMedian = '~'
                  keyword.rangeEnd = '~'
                  keyword.bidding = defaultBidding
                }
              } else if (product.strategy[0].advertising_strategy.includes('SB')) {
                if (product.strategy[0].advertising_strategy.includes('C')) {
                  product.strategy[0].typeText = '类目'
                } else if (product.strategy[0].advertising_strategy.includes('A')) {
                  product.strategy[0].typeText = 'ASIN'
                } else {
                  product.strategy[0].typeText = keyword.selectedKeywordType
                }
                if (keyword.rangeEnd) {
                  keyword.bidding = (product.strategy[0].selectedType / 10 * keyword.rangeEnd).toFixed(2)
                } else {
                  keyword.rangeStart = '~'
                  keyword.rangeMedian = '~'
                  keyword.rangeEnd = '~'
                  keyword.bidding = defaultBidding
                }
              } else if (product.strategy[0].advertising_strategy.includes('SD')) {
              }
            })
          } else if (product.strategy[0].autoData) {
            product.strategy[0].autoData[0].rangeStart = '~'
            product.strategy[0].autoData[0].rangeMedian = '~'
            product.strategy[0].autoData[0].rangeEnd = '~'
            product.strategy[0].autoData[0].bidding = defaultBidding
            product.strategy[0].typeText = product.strategy[0].autoData[0].typeInfo
          }
        }
      })
      this.tableRecommendData.forEach(function (ad) {
        if (ad.advertising_strategy.includes('SP')) {
          adSlotsValue = adSpSlotsValue
          adHomeSlotsValue = adSpHomeSlotsValue
          adOtherPagesValue = adOtherPagesValue
           ad.adPlacementPosition = 'PLACEMENT_REST_OF_SEARCH'
            ad.adPlacementPositionName = '搜索结果其余位置'
          if (ad.advertising_strategy.includes('SPW1') || ad.advertising_strategy.includes('SPW2') || ad.advertising_strategy.includes('SPW3') || ad.advertising_strategy.includes('SPA1') || ad.advertising_strategy.includes('SPA2')) {
            ad.adPlacementPosition = 'PLACEMENT_TOP'
            ad.adPlacementPositionName = '搜索结果顶部(首页)'
          } else if (ad.advertising_strategy.includes('SPA3') || ad.advertising_strategy.includes('SPA4') || ad.advertising_strategy.includes('SPP')) {
            ad.adPlacementPosition = 'PLACEMENT_PRODUCT_PAGE'
            ad.adPlacementPositionName = '商品页面'
          }

        }
        else if (ad.advertising_strategy.includes('SB')) {
          if (ad.advertising_strategy.includes('SBI-P') || ad.advertising_strategy.includes('SBV-P')) {
            ad.adPlacementPosition = 'DETAIL_PAGE'
            ad.adPlacementPositionName = '推广的产品界面'
            adSlotsValue = '产品界面'
            adHomeSlotsValue = '/'
            adOtherPagesValue = '/'
          } else {
            ad.adPlacementPosition = 'HOME'
            ad.adPlacementPositionName = '品牌旗舰店(包括子页面)'
            adSlotsValue = '/'
            adHomeSlotsValue = '品牌旗舰店(包括子页面)'
            adOtherPagesValue = ''
          }
        }
        else if (ad.advertising_strategy.includes('SD')) {
          adSlotsValue = '/'
          adHomeSlotsValue = '/'
          adOtherPagesValue = '/'
        }
        ad.startDate = startDate
        ad.endDate = endDate
        ad.budget = budget
        ad.defaultBidding = defaultBidding
        ad.adSlotsValue = adSlotsValue
        ad.adHomeSlotsValue = adHomeSlotsValue
        ad.selectedPortfoliosValue = selectedPortfoliosValue
        ad.selectedGroupsValue = selectedGroupsValue
        ad.adOtherPagesValue = adOtherPagesValue
        if (ad.selectedType === '2' || ad.selectedType === '4' || ad.selectedType === 2) {
          ad.biddingStrategy = '动态竞价-提高或降低'
          ad.types = 'AUTO_FOR_SALES'
        }
        else if (ad.selectedType === '6' || ad.selectedType === '8') {
          ad.biddingStrategy = '固定竞价'
          ad.types = 'MANUAL'
        }
        else if (ad.selectedType === '10' || ad.selectedType === '12') {
          ad.biddingStrategy = '动态竞价-只降低'
          ad.types = 'LEGACY_FOR_SALES'
        }
        if (ad.autoData) {
          ad.autoData.forEach(function (auto) {
            ad.typeText = auto.typeInfo
            if (!auto.rangeEnd) {
              auto.rangeStart = '~'
              auto.rangeMedian = '~'
              auto.rangeEnd = '~'
              auto.bidding = ad.defaultBidding
            } else {
              auto.bidding = (ad.selectedType / 10 * auto.rangeEnd).toFixed(2)
            }
          })
        }
        if (ad.keyword) {
          ad.keyword.forEach(function (keywords) {
            if (ad.advertising_strategy.substring(0, 2).includes('SP')) {
              if (ad.advertising_strategy.includes('SPP-C')) {
                ad.typeText = '类目'
              } else if (ad.advertising_strategy.includes('SPP-A1')) {
                ad.typeText = 'ASIN'
              } else if (ad.advertising_strategy.includes('SPP-A2')) {
                ad.typeText = 'ASIN'
              } else {
                ad.typeText = keywords.selectedKeywordType
              }
              console.log(898989, ad)
            } else if (ad.advertising_strategy.substring(0, 2).includes('SB')) {
              if (ad.advertising_strategy.includes('C')) {
                ad.typeText = '类目'
              } else if (ad.advertising_strategy.includes('A')) {
                ad.typeText = 'ASIN'
              } else {
                ad.typeText = keywords.selectedKeywordType
              }
            } else if (ad.advertising_strategy.substring(0, 2).includes('SD')) {

            }
            if (!keywords.rangeEnd) {
              keywords.rangeStart = '~'
              keywords.rangeMedian = '~'
              keywords.rangeEnd = '~'
              keywords.bidding = ad.defaultBidding
            } else {
              keywords.bidding = (ad.selectedType / 10 * keywords.rangeEnd).toFixed(2)
            }
          })
          ad.keywordLength = ad.keyword.length
          ad.strategy = selectionProduct
        } else if (ad.autoData) {
          ad.keywordLength = ad.autoData.length
          ad.strategy = selectionProduct
        } else if (ad.keyword && ad.autoData) {
          ad.keyword.forEach(function (keyword) {
            keyword.bidding = (ad.selectedType / 10 * keyword.rangeEnd).toFixed(2)
          })
          if (ad.keyword.length > 0) {
            ad.keywordLength = ad.keyword.length
          } else if (ad.autoData.length > 0) {
            ad.keywordLength = ad.autoData.length
          }
          ad.strategy = selectionProduct
        } else if (startDate !== '' && budget !== '' && defaultBidding !== '') {
          ad.strategy = selectionProduct
        }
      })
      // }

      if (startDate && budget && defaultBidding && selectedGroupsValue) {
        this.$router.push({
          path: '/ad-serving', query: {
            selectionProductData: this.selectionProduct,
            selectionProductStrategyData: this.tableRecommendData,
            tableInfoData: this.tableInfoData,
            upPath: this.$route.path,
            selectionInfo: this.selectionInfo
          }
        });

      } else {
        Message({
          message: '缺少必填项',
          type: 'error',
        });
        return false
      }
    },
    //获取组合
    handlePortfoliosData() {
      this.$axios.get('https://ad.yaelongtime.com/api/proxy/v2/portfolios', {
        params: {
          'portfolioStateFilter': 'enabled'
        }
      }, {
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(res => {
        const adPortfolios = []
        res.data.forEach(item => {
          const Portfolios = {
            name: item.name,
            portfolios: item.portfolioId
          }
          adPortfolios.push(Portfolios);
        })
        this.ad_portfolios_options = adPortfolios
      })
    }
  },
  computed: {
    styles() {
      // 根据基准字号和行高计算字体大小和行距
      const fontSize = `${this.baseFontSize}px`;
      const lineHeight = `${this.baseLineHeight * this.baseFontSize}px`;
      // 响应式布局
      return {
        'font-size': fontSize,
        'line-height': lineHeight,
        'padding-top': '-10px',
        'padding-left': '-10px',
        'padding-right': '1px',
      };
    }
    ,
  },
  created() {
    if (this.$route.query.upPath === '/ad-strategy') {
      this.tableRecommendData = this.$route.query.selectionProductStrategyData
      this.selectionProduct = this.$route.query.selectionProductData
      this.tableInfoData = this.$route.query.tableInfoData
      this.selectionInfo = this.$route.query.selectionInfo
    } else if (this.$route.query.upPath === '/ad-serving') {
      this.tableRecommendData = this.$route.query.selectionProductStrategyData
      this.selectionProduct = this.$route.query.selectionProductData
      this.tableInfoData = this.$route.query.tableInfoData
      this.selectionInfo = this.$route.query.selectionInfo
      this.budget = this.$route.query.selectionProductStrategyData[0].budget
      this.startDate = ''
      this.endDate = ''
      this.selectedGroupsValue = this.$route.query.selectionProductStrategyData[0].selectedGroupsValue
      this.selectedPortfoliosValue = this.$route.query.selectionProductStrategyData[0].selectedPortfoliosValue
      this.defaultBidding = this.$route.query.selectionProductStrategyData[0].defaultBidding
      this.homeValue = this.$route.query.selectionProductStrategyData[0].homeValue
      this.productValue = this.$route.query.selectionProductStrategyData[0].productValue
    }
  },
  mounted() {
    this.handlePortfoliosData()
    setTimeout(this.handlePortfoliosData, 30 * 60 * 1000); //
    // this.handleGroupsData()
    // setTimeout(this.handleGroupsData, 30 * 60 * 1000); //
  },
}
</script>

<style scoped>
.red-asterisk-label::before {
  content: '*';
  color: red;
}

.product-container {
  display: flex;
  flex-wrap: wrap;
}

.product-item {
  display: flex;
  align-items: center;
  margin: 20px;
}

.product-item img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}

.sku,
.asin {
  margin: 20px 10px 1px 10px;
}

.el-form-item__content {
  width: 40%;
}
</style>