<template>
  <div :style="styles">
    <el-col :span="24">
      <el-menu
          :router='true'
          :default-active="$route.path"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b">
        <el-menu-item index="1">
          <div class="demo-image">
            <el-image
                style="width: 40px; height: 40px"
                :src="require('../assets/img/yayeguoji_logo.jpg')"></el-image>
          </div>
        </el-menu-item>
        <el-menu-item index="/home">全部活动</el-menu-item>
        <el-menu-item index="/ad-serving">广告投放</el-menu-item>
      </el-menu>
    </el-col>
    <el-col :span="24" style="padding: 10px">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">广告策略</el-breadcrumb-item>
        <el-breadcrumb-item>策略分类</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider></el-divider>
    </el-col>
    <el-col :span="24" style="padding: 10px 50px">
      <el-row>
        <div>已选商品</div>
        <div class="product-container">
          <div v-for="(product, index) in selectionProduct" :key="index">
            <img :src="product.image" alt="商品图片">
            <p class="sku">SKU: {{ product.sku }}</p>
            <p class="asin">ASIN: {{ product.asin }}</p>
          </div>
        </div>
      </el-row>
    </el-col>
    <el-col :span="24" style="padding: 5px 50px">
      <el-tabs type="border-card">
        <el-tab-pane label="SP">
          <el-input
              prefix-icon="el-icon-search"
              placeholder="按策略搜索"
              v-model="search_sp"
              clearable>
          </el-input>
          <el-table
              border
              style="width: 100%"
              :data="filteredTableSpData"
              max-height="350"
          >
            <el-table-column
                prop="advertising_strategy"
                label="策略"
                width="120">
            </el-table-column>
            <el-table-column
                prop="characteristic"
                label="特点"
                width="1500">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="120">
              <template slot-scope="scope">
                <el-button
                    :disabled="scope.row.disabled"
                    @click="addToTarget(scope.row)"
                    type="text"
                    size="small"
                >
                  {{ scope.row.disabled ? '已添加' : '添加' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="SB">
          <el-input
              prefix-icon="el-icon-search"
              placeholder="按策略搜索"
              v-model="search_sb"
              clearable>
          </el-input>
          <el-table
              :data="filteredTableSbData"
              style="width: 100%"
              max-height="350">
            <el-table-column
                prop="advertising_strategy"
                label="策略"
                width="120">
            </el-table-column>
            <el-table-column
                prop="characteristic"
                label="特点"
                width="1500">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="120">
              <template slot-scope="scope">
                <el-button
                    :disabled="scope.row.disabled"
                    @click="addToTarget(scope.row)"
                    type="text"
                    size="small"
                >
                  {{ scope.row.disabled ? '已添加' : '添加' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="SD">
          <el-input
              prefix-icon="el-icon-search"
              placeholder="按策略搜索"
              v-model="search_sd"
              clearable>
          </el-input>
          <el-table
              :data="filteredTableSdData"
              style="width: 100%"
              max-height="350"
          >
            <el-table-column
                prop="advertising_strategy"
                label="策略"
                width="200">
            </el-table-column>
            <el-table-column
                prop="characteristic"
                label="特点"
                width="1500">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="120">
              <template slot-scope="scope">
                <el-button
                    :disabled="scope.row.disabled"
                    @click="addToTarget(scope.row)"
                    type="text"
                    size="small"
                >
                  {{ scope.row.disabled ? '已添加' : '添加' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-table
            :data="tableRecommendData"
            style="width: 100%"
            max-height="500"
            @sort-change="handleSortChange"
        >
          <el-table-column
              fixed
              prop="advertising_strategy"
              label="策略"
              width="150">
          </el-table-column>
          <el-table-column
              label="竞价水准"
              width="120">
            <template slot-scope="scope">
              <el-select
                  clearable
                  v-model="scope.row.selectedType"
                  placeholder="2"
                  @change="handleSelection(scope.row.label)">
                <el-option
                    v-for="option in typeOptions"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
              label="操作"
              width="120">
            <template slot-scope="scope">
              <el-button
                  @click.native.prevent="deleteRow(scope.$index, tableRecommendData)"
                  type="text"
                  size="small">
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tabs>
      <el-dialog title="图片格式" :visible.sync="dialogImageFormVisible" style="font-weight: bold;"
                 :close-on-click-modal="false">
        <div class="required-field">
          品牌
        </div>
        <el-select
            v-model="selectedBrandIdValue"
            allow-create
            collapse-tags
            filterable
            placeholder="请选择品牌"
            @change="selectedBrandClick"
        >
          <el-option
              v-for="item in brandIdList"
              :key="item.id"
              :label="item.brandName"
              :value="item.id">
          </el-option>
        </el-select>
        <div style="padding-top: 20px; font-weight: bold;" class="required-field">品牌LOGO及徽标</div>
        <div style="padding: 20px">
          <el-image :src="currentLogoUrl"></el-image>
        </div>
        <div>
          <el-button type="text" @click="selectedBrandLogoClick">切换LOGO</el-button>
          <el-button type="text" @click="handleBrandLogoClick">添加LOGO</el-button>
        </div>
        <div class="required-field" style="padding-bottom: 20px; font-weight: bold;">
          标题:
          <el-input v-model="inputBrandName" placeholder="请输入标题"></el-input>
        </div>
        <div style="padding-bottom: 20px">
          <div style="width: 100px; height: 100px; text-align: center; background-color: #eef1f5">
            <el-button type="text" @click="triggerFileInput" style="width: 100%; height: 100%;">
              <img :src="imageFile" v-if="imageFile" alt="Selected Image" style="width: 100%; height: 100%;"/>
              <i class="el-icon-picture" v-else style="font-size: 2em;"></i>
            </el-button>
          </div>
          <input type="file" @change="handleFileUpload" ref="fileInput" style="display: none" accept="image/*"/>
          <div style="padding-top: 20px; font-weight: bold;">自定义图片(可选)
            <el-button type="primary" @click="uploadImage" :disabled="disabledImageButton">上传图片</el-button>
          </div>
        </div>
        <div style="font-weight: bold;" class="required-field">着陆页
          <div style="padding-left: 50px">
            <el-radio v-model="radio" label="1">
              品牌旗舰店(包括子页面)
              <div style="padding: 20px; background-color: #eef1f5" v-if="radio === '1'">
                品牌旗舰店
                <el-select
                    v-model="selectedStoresIdValue"
                    allow-create
                    collapse-tags
                    filterable
                    placeholder="请选择品牌旗舰店"
                    @change="handleBrandClick"
                >
                  <el-option
                      v-for="item in brandStoreList"
                      :key="item.id"
                      :label="item.brandName"
                      :value="item.id">
                  </el-option>
                </el-select>
                页面
                <el-select
                    v-model="selectedStorePageUrlValue"
                    allow-create
                    collapse-tags
                    filterable
                    placeholder="请选择页面"
                    @change="selectedStorePageClick"
                >
                  <el-option
                      v-for="item in storePageList"
                      :key="item.id"
                      :label="item.storePageName"
                      :value="item.storePageUrl">
                  </el-option>
                </el-select>
              </div>
            </el-radio>
          </div>
          <div style="padding-left: 50px">
            <el-radio v-model="radio" label="2">产品详情页</el-radio>
          </div>
        </div>
        <div v-loading="loading">
          <el-tabs v-model="activeTab">
            <el-tab-pane v-for="(product, index) in tabFirstProductsList" label="1" name="1"
                         :key="product.id">
              <div class="product-item">
                <img :src="product.image" :alt="product.product_name"/>
                <div class="product-info">
                  <h3>{{ product.product_name }}</h3>
                  <p>asin: {{ product.asin }}</p>
                </div>
                <el-button @click="selectedProduct(product.asin)">更换产品</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane v-for="(product, index) in tabSecondProductsList" label="2" name="2"
                         :key="product.id">
              <div class="product-item">
                <img :src="product.image" :alt="product.product_name"/>
                <div class="product-info">
                  <h3>{{ product.product_name }}</h3>
                  <p>asin: {{ product.asin }}</p>
                </div>
                <el-button @click="selectedProduct(product.asin)">更换产品</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane v-for="(product, index) in tabThirdProductsList" label="3" name="3"
                         :key="product.id">
              <div class="product-item">
                <img :src="product.image" :alt="product.product_name"/>
                <div class="product-info">
                  <h3>{{ product.product_name }}</h3>
                  <p>asin: {{ product.asin }}</p>
                </div>
                <el-button @click="selectedProduct(product.asin)">更换产品</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogImageFormVisible = false">取消</el-button>
          <el-button type="primary" @click="handleBrandInfoClick">创建资源</el-button>
        </div>
      </el-dialog>
      <el-dialog title="图片格式" :visible.sync="dialogStoresFormVisible" style="font-weight: bold;"
                 :close-on-click-modal="false">
        <div class="required-field">
          品牌
        </div>
        <el-select
            v-model="selectedBrandIdValue"
            allow-create
            collapse-tags
            filterable
            placeholder="请选择品牌"
            @change="selectedBrandClick"
        >
          <el-option
              v-for="item in brandIdList"
              :key="item.id"
              :label="item.brandName"
              :value="item.id">
          </el-option>
        </el-select>
        <div style="padding-top: 20px; font-weight: bold;" class="required-field">品牌LOGO及徽标</div>
        <div style="padding: 20px">
          <el-image :src="currentLogoUrl"></el-image>
        </div>
        <div>
          <el-button type="text" @click="selectedBrandLogoClick">切换LOGO</el-button>
          <el-button type="text" @click="handleBrandLogoClick">添加LOGO</el-button>
        </div>
        <div class="required-field" style="padding-bottom: 20px; font-weight: bold;">
          标题:
          <el-input v-model="inputBrandName" placeholder="请输入标题"></el-input>
        </div>
        <div style="font-weight: bold;" class="required-field">着陆页
          <div style="padding-left: 50px">
            <el-radio v-model="radio" label="1">
              品牌旗舰店(包括子页面)
              <div style="padding: 20px; background-color: #eef1f5" v-if="radio === '1'">
                品牌旗舰店
                <el-select
                    v-model="selectedStoresIdValue"
                    allow-create
                    collapse-tags
                    filterable
                    placeholder="请选择品牌旗舰店"
                    @change="handleBrandClick"
                >
                  <el-option
                      v-for="item in brandStoreList"
                      :key="item.id"
                      :label="item.brandName"
                      :value="item.id">
                  </el-option>
                </el-select>
              </div>
            </el-radio>
          </div>
        </div>
        <div v-if="firstProductsList.length>0">
          <el-tabs v-model="activeTab">
            <el-tab-pane label="1" name="1">
              <div class="product-item" v-for="(product) in firstProductsList">
                <span>{{ product.storePageName }}</span>
                <img :src="product.image" :alt="product.product_name"/>
                <div class="product-info">
                  <h3>{{ product.product_name }}</h3>
                  <p>asin: {{ product.asin }}</p>
                </div>
                <el-button @click="selectedPageProduct(product.asin)">更换产品</el-button>
                <el-button @click="selectedPage(product.asin)">更换页面</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="2" name="2">
              <div class="product-item" v-for="(product) in secondProductsList">
                <span>{{ product.storePageName }}</span>
                <img :src="product.image" :alt="product.product_name"/>
                <div class="product-info">
                  <h3>{{ product.product_name }}</h3>
                  <p>asin: {{ product.asin }}</p>
                </div>
                <el-button @click="selectedPageProduct(product.asin)">更换产品</el-button>
                <el-button @click="selectedPage(product.asin)">更换页面</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="3" name="3">
              <div class="product-item" v-for="(product) in thirdProductsList">
                <span>{{ product.storePageName }}</span>
                <img :src="product.image" :alt="product.product_name"/>
                <div class="product-info">
                  <h3>{{ product.product_name }}</h3>
                  <p>asin: {{ product.asin }}</p>
                </div>
                <el-button @click="selectedPageProduct(product.asin)">更换产品</el-button>
                <el-button @click="selectedPage(product.asin)">更换页面</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogStoresFormVisible = false">取消</el-button>
          <el-button type="primary" @click="handleBrandInfoClick">创建资源</el-button>
        </div>
      </el-dialog>
      <el-dialog title="视频格式" :visible.sync="dialogVideoFormVisible" style="font-weight: bold;"
                 :close-on-click-modal="false">
        <div class="required-field">
          品牌
        </div>
        <el-select
            v-model="selectedBrandIdValue"
            allow-create
            collapse-tags
            filterable
            placeholder="请选择品牌"
            @change="selectedBrandClick"
        >
          <el-option
              v-for="item in brandIdList"
              :key="item.id"
              :label="item.brandName"
              :value="item.id">
          </el-option>
        </el-select>
        <div style="padding-top: 20px; font-weight: bold;" class="required-field">品牌LOGO及徽标</div>
        <div style="padding: 20px">
          <el-image :src="currentLogoUrl"></el-image>
        </div>
        <div>
          <el-button type="text" @click="selectedBrandLogoClick">切换LOGO</el-button>
          <el-button type="text" @click="handleBrandLogoClick">添加LOGO</el-button>
        </div>
        <div style="padding-bottom: 20px; font-weight: bold;" class="required-field">
          标题:
          <el-input v-model="inputBrandName" placeholder="请输入标题"></el-input>
        </div>
        <div>
          <div style="width: 100px; height: 100px; text-align: center; background-color: #eef1f5">
            <el-button type="text" @click="triggerFileVideoInput" style="width: 100%; height: 100%;">
              <video v-if="VideoFile" controls style="width: 100%; height: 100%; ">
                <source :src="VideoFile" type="video/mp4">
                Your browser does not support the video tag.
              </video>
              <i class="el-icon-video-camera-solid" v-else style="font-size: 2em;"></i>
            </el-button>
          </div>
          <input type="file" ref="fileVideoInput" style="display: none" @change="handleVideoUpload" accept="image/*"/>
          <div style="padding-top: 20px; font-weight: bold;" class="required-field">视频
            <button @click="uploadVideo">上传视频</button>
          </div>
        </div>
        <div style="font-weight: bold;" class="required-field">着陆页
          <div style="padding-left: 50px">
            <el-radio v-model="radio" label="1">
              品牌旗舰店(包括子页面)
              <div style="padding: 20px; background-color: #eef1f5" v-if="radio === '1'">
                品牌旗舰店
                <el-select
                    v-model="selectedStoresIdValue"
                    allow-create
                    collapse-tags
                    filterable
                    placeholder="请选择品牌旗舰店"
                    @change="handleBrandClick"
                >
                  <el-option
                      v-for="item in brandStoreList"
                      :key="item.id"
                      :label="item.brandName"
                      :value="item.id">
                  </el-option>
                </el-select>
                页面
                <el-select
                    v-model="selectedStorePageUrlValue"
                    allow-create
                    collapse-tags
                    filterable
                    placeholder="请选择页面"
                    @change="selectedStorePageClick"
                >
                  <el-option
                      v-for="item in storePageList"
                      :key="item.id"
                      :label="item.storePageName"
                      :value="item.storePageUrl">
                  </el-option>
                </el-select>
              </div>
            </el-radio>
          </div>
          <div style="padding-left: 50px">
            <el-radio v-model="radio" label="2">产品详情页</el-radio>
          </div>
        </div>
        <div v-if="videoProductsList.length>0">
          <el-tabs v-model="activeTab">
            <!-- 使用 v-for 循环渲染不同的 el-tab-pane -->
            <el-tab-pane v-for="(product, index) in videoProductsList" :label="String(index + 1)" :name="product.id"
                         :key="product.id">
              <div class="product-item">
                <img :src="product.image" :alt="product.product_name"/>
                <div class="product-info">
                  <h3>{{ product.product_name }}</h3>
                  <p>asin: {{ product.asin }}</p>
                </div>
                <el-button @click="selectedProduct(product.asin)">更换产品</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVideoFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleBrandInfoClick">创建资源</el-button>
        </div>
      </el-dialog>
      <el-dialog title="视频格式" :visible.sync="dialogStoreVideoFormVisible" style="font-weight: bold;"
                 :close-on-click-modal="false">
        <div>
          <div style="width: 100px; height: 100px; text-align: center; background-color: #eef1f5">
            <el-button type="text" @click="triggerFileVideoInput" style="width: 100%; height: 100%;">
              <video v-if="VideoFile" controls style="width: 100%; height: 100%; ">
                <source :src="VideoFile" type="video/mp4">
                Your browser does not support the video tag.
              </video>
              <i class="el-icon-video-camera-solid" v-else style="font-size: 2em;"></i>
            </el-button>
          </div>
          <input type="file" ref="fileVideoInput" style="display: none" @change="handleVideoUpload" accept="image/*"/>
          <div style="padding-top: 20px; font-weight: bold;" class="required-field">视频
            <button @click="uploadVideo">上传视频</button>
          </div>
        </div>
        <div style="font-weight: bold;" class="required-field">着陆页
          <div style="padding-left: 50px">
            <el-radio v-model="radio" label="1">
              品牌旗舰店(包括子页面)
              <div style="padding: 20px; background-color: #eef1f5" v-if="radio === '1'">
                品牌旗舰店
                <el-select
                    v-model="selectedStoresIdValue"
                    allow-create
                    collapse-tags
                    filterable
                    placeholder="请选择品牌旗舰店"
                    @change="handleBrandClick"
                >
                  <el-option
                      v-for="item in brandStoreList"
                      :key="item.id"
                      :label="item.brandName"
                      :value="item.id">
                  </el-option>
                </el-select>
                页面
                <el-select
                    v-model="selectedStorePageUrlValue"
                    allow-create
                    collapse-tags
                    filterable
                    placeholder="请选择页面"
                    @change="selectedStorePageClick"
                >
                  <el-option
                      v-for="item in storePageList"
                      :key="item.id"
                      :label="item.storePageName"
                      :value="item.storePageUrl">
                  </el-option>
                </el-select>
              </div>
            </el-radio>
          </div>
          <div style="padding-left: 50px">
            <el-radio v-model="radio" label="2">产品详情页</el-radio>
          </div>
        </div>
        <div v-if="videoProductsList.length>0">
          <el-tabs v-model="activeTab">
            <!-- 使用 v-for 循环渲染不同的 el-tab-pane -->
            <el-tab-pane v-for="(product, index) in videoProductsList" :label="String(index + 1)" :name="product.id"
                         :key="product.id">
              <div class="product-item">
                <img :src="product.image" :alt="product.product_name"/>
                <div class="product-info">
                  <h3>{{ product.product_name }}</h3>
                  <p>asin: {{ product.asin }}</p>
                </div>
                <el-button @click="selectedProduct(product.asin)">更换产品</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogStoreVideoFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleBrandInfoClick">创建资源</el-button>
        </div>
      </el-dialog>
      <el-dialog title="切换品牌LOGO" :visible.sync="dialogLogoFormVisible" :close-on-click-modal="false">
        <div class="image-container">
          <div v-for="(item, index) in brandUrlList" :key="index">
            <img :src="item.url" :alt="'Image ' + item.assetId"
                 :class="{ selected: item.isSelected }"
                 @click="toggleSelection(index)"
            />
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogLogoFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogFormVisibleClick">应用</el-button>
        </div>
      </el-dialog>
      <el-dialog title="切换品牌LOGO" :visible.sync="dialogSdLogoFormVisible" :close-on-click-modal="false">
        <div class="image-container">
          <div v-for="(item, index) in brandSdUrlList" :key="index">
            <img :src="item.url" :alt="'Image ' + item.assetId"
                 :class="{ selected: item.isSelected }"
                 @click="toggleSelection(index)"
            />
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogSdLogoFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogFormVisibleClick">应用</el-button>
        </div>
      </el-dialog>
      <el-dialog title="新增品牌LOGO" :visible.sync="dialogCreateLogoFormVisible" :close-on-click-modal="false">
        <div>
          <input type="file" placeholder="上传LOGO" @change="handleBrandFileUpload" accept="image/*"/>
          <p>图片规格</p>
          <p>图片大小： 400x400 像素或更大</p>
          <p>文件大小： 1MB 或更小</p>
          <p>文件格式： PNG 或 JPG</p>
          <p>内容： 徽标必须填满图片或置于白色或透明背景上</p>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogCreateLogoFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="uploadBrandImage">应用</el-button>
        </div>
      </el-dialog>
      <el-dialog title="选择商品" :visible.sync="dialogSwitchProductsFormVisible" :close-on-click-modal="false">
        <el-input
            prefix-icon="el-icon-search"
            placeholder="按ASIN搜索"
            v-model="search_product"
            clearable>
        </el-input>
        <div v-for="(product, index) in filteredTableProductData"
             :key="product.id">
          <div class="product-item">
            <img :src="product.image" :alt="product.product_name"/>
            <div class="product-info">
              <h3>{{ product.product_name }}</h3>
              <p>asin: {{ product.asin }}</p>
            </div>
            <el-button @click="addProductClick(product)" :disabled="product.added">替换</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog title="选择页面" :visible.sync="dialogSwitchPageFormVisible" :close-on-click-modal="false">
        <el-input
            prefix-icon="el-icon-search"
            placeholder="按页面搜索"
            v-model="search_page"
            clearable>
        </el-input>
        <div v-for="(page, index) in filterStorePageList"
             :key="page.id">
          <div class="product-item">
            <div class="product-info">
              <p>{{ page.storePageName }}</p>
            </div>
            <el-button @click="addPageClick(page)" :disabled="page.added">替换</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog title="选择页面对应商品" :visible.sync="dialogSwitchPageProductsFormVisible"
                 :close-on-click-modal="false">
        <el-input
            prefix-icon="el-icon-search"
            placeholder="按ASIN搜索"
            v-model="search_page_product"
            clearable>
        </el-input>
        <div v-for="(product, index) in filterTablePageProductData"
             :key="product.id">
          <div class="product-item">
            <img :src="product.image" :alt="product.product_name"/>
            <div class="product-info">
              <h3>{{ product.product_name }}</h3>
              <p>asin: {{ product.asin }}</p>
            </div>
            <el-button @click="addPageProductClick(product)" :disabled="product.added">添加</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog title="图片格式" :visible.sync="dialogSdImageFormVisible" style="font-weight: bold;"
                 :close-on-click-modal="false">
        <div>
          <div class="required-field">
            优化方案
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="1">针对页面访问次数进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的点击率。通过向更有可能点击广告的顾客展示您的广告来提高商品购买意向。
          </div>
          <div style="padding-left: 20px">
            付费类型：按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="2">针对转化量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的转化率。通过向更有可能购买商品的顾客展示您的广告来提高销量。
          </div>
          <div style="padding-left: 20px">
            付费类型： 按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="3">针对可见展示量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px; tab-size: unset">
            我们将优化您的竞价以获得更高的可见展示次数。通过在亚马逊上向尽可能多的顾客展示您的广告来提高商品知名度。
          </div>
          <div style="padding-left: 20px">
            成本类型： 每千次可见展示成本 (VCPM) | 归因于可见展示量和点击次数的转化
          </div>
        </div>
<!--        <div v-for="item in items" :key="item.name" class="panel-container">-->
<!--          <div v-if="item.name==='1'">-->
<!--            <el-switch v-model="item.isActive" @change="handleSwitchChange(item.name)" active-color="#13ce66"-->
<!--                     inactive-color="#ff4949"/>-->
<!--          </div>-->
          <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="徽标" name="1" style="font-size: 20px">
              <div style="padding: 20px">
                <el-image :src="currentSdLogoUrl"></el-image>
              </div>
              <div>
                <el-button type="text" @click="selectedSdBrandLogoClick">切换LOGO</el-button>
                <el-button type="text" @click="handleBrandLogoClick">添加LOGO</el-button>
              </div>
            </el-collapse-item>
            <el-collapse-item title="标题" name="2" style="font-size: 16px">
              <el-input v-model="inputBrandName" placeholder="请输入标题"></el-input>
            </el-collapse-item>
            <el-collapse-item title="图片" name="3" style="font-size: 16px">
              <div style="width: 100px; height: 100px; text-align: center; background-color: #eef1f5">
                <el-button type="text" @click="triggerFileInput" style="width: 100%; height: 100%;">
                  <img :src="imageFile" v-if="imageFile" alt="Selected Image" style="width: 100%; height: 100%;"/>
                  <i class="el-icon-picture" v-else style="font-size: 2em;"></i>
                </el-button>
              </div>
              <input type="file" @change="handleFileUpload" ref="fileInput" style="display: none" accept="image/*"/>
              <div style="padding-top: 20px; font-weight: bold;">自定义图片(可选)
                <el-button type="primary" @click="uploadImage" :disabled="disabledImageButton">上传图片</el-button>
              </div>
            </el-collapse-item>
          </el-collapse>
<!--        </div>-->

        <div slot="footer" class="dialog-footer; text-center" style="padding-top: 20px">
          <el-button @click="dialogSdImageFormVisible = false">取消</el-button>
          <el-button type="primary" plain @click="handleSdInfoClick">创建资源</el-button>
        </div>
      </el-dialog>
      <el-dialog title="图片格式" :visible.sync="dialogSdImageAaFormVisible" style="font-weight: bold;"
                 :close-on-click-modal="false">
        <div>
          <div class="required-field">
            优化方案
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="1">针对页面访问次数进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的点击率。通过向更有可能点击广告的顾客展示您的广告来提高商品购买意向。
          </div>
          <div style="padding-left: 20px">
            付费类型：按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="2">针对转化量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的转化率。通过向更有可能购买商品的顾客展示您的广告来提高销量。
          </div>
          <div style="padding-left: 20px">
            付费类型： 按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="3">针对可见展示量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px; tab-size: unset">
            我们将优化您的竞价以获得更高的可见展示次数。通过在亚马逊上向尽可能多的顾客展示您的广告来提高商品知名度。
          </div>
          <div style="padding-left: 20px">
            成本类型： 每千次可见展示成本 (VCPM) | 归因于可见展示量和点击次数的转化
          </div>
        </div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="徽标" name="1" style="font-size: 16px">
            <div style="padding: 20px">
              <el-image :src="currentSdLogoUrl"></el-image>
            </div>
            <div>
              <el-button type="text" @click="selectedSdBrandLogoClick">切换LOGO</el-button>
              <el-button type="text" @click="handleBrandLogoClick">添加LOGO</el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item title="标题" name="2" style="font-size: 16px">
            <el-input v-model="inputBrandName" placeholder="请输入标题"></el-input>
          </el-collapse-item>
          <el-collapse-item title="图片" name="3" style="font-size: 16px">
            <div style="width: 100px; height: 100px; text-align: center; background-color: #eef1f5">
              <el-button type="text" @click="triggerFileInput" style="width: 100%; height: 100%;">
                <img :src="imageFile" v-if="imageFile" alt="Selected Image" style="width: 100%; height: 100%;"/>
                <i class="el-icon-picture" v-else style="font-size: 2em;"></i>
              </el-button>
            </div>
            <input type="file" @change="handleFileUpload" ref="fileInput" style="display: none" accept="image/*"/>
            <div style="padding-top: 20px; font-weight: bold;">自定义图片(可选)
              <el-button type="primary" @click="uploadImage" :disabled="disabledImageButton">上传图片</el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div slot="footer" class="dialog-footer; text-center">
          <el-button @click="dialogSdImageAaFormVisible = false">取消</el-button>
          <el-button type="primary" plain @click="handleSdInfoClick">创建资源</el-button>
        </div>
      </el-dialog>
      <el-dialog title="图片格式" :visible.sync="dialogSdImageVrFormVisible" style="font-weight: bold;"
                 :close-on-click-modal="false">
        <div>
          <div class="required-field">
            优化方案
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="1">针对页面访问次数进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的点击率。通过向更有可能点击广告的顾客展示您的广告来提高商品购买意向。
          </div>
          <div style="padding-left: 20px">
            付费类型：按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="2">针对转化量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的转化率。通过向更有可能购买商品的顾客展示您的广告来提高销量。
          </div>
          <div style="padding-left: 20px">
            付费类型： 按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="3">针对可见展示量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px; tab-size: unset">
            我们将优化您的竞价以获得更高的可见展示次数。通过在亚马逊上向尽可能多的顾客展示您的广告来提高商品知名度。
          </div>
          <div style="padding-left: 20px">
            成本类型： 每千次可见展示成本 (VCPM) | 归因于可见展示量和点击次数的转化
          </div>
        </div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="徽标" name="1" style="font-size: 16px">
            <div style="padding: 20px">
              <el-image :src="currentSdLogoUrl"></el-image>
            </div>
            <div>
              <el-button type="text" @click="selectedSdBrandLogoClick">切换LOGO</el-button>
              <el-button type="text" @click="handleBrandLogoClick">添加LOGO</el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item title="标题" name="2" style="font-size: 16px">
            <el-input v-model="inputBrandName" placeholder="请输入标题"></el-input>
          </el-collapse-item>
          <el-collapse-item title="图片" name="3" style="font-size: 16px">
            <div style="width: 100px; height: 100px; text-align: center; background-color: #eef1f5">
              <el-button type="text" @click="triggerFileInput" style="width: 100%; height: 100%;">
                <img :src="imageFile" v-if="imageFile" alt="Selected Image" style="width: 100%; height: 100%;"/>
                <i class="el-icon-picture" v-else style="font-size: 2em;"></i>
              </el-button>
            </div>
            <input type="file" @change="handleFileUpload" ref="fileInput" style="display: none" accept="image/*"/>
            <div style="padding-top: 20px; font-weight: bold;">自定义图片(可选)
              <el-button type="primary" @click="uploadImage" :disabled="disabledImageButton">上传图片</el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div>
          <div>受众-再营销浏览定向</div>
          <div>回溯期
            <el-select v-model="BacktrackingDaysValue" placeholder="回溯天数" style="padding-bottom: 20px"
                       @change="handleBacktrackingChange">
              <el-option
                  v-for="item in BacktrackingData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-table
              :data="tableSdAudienceData"
              border
              style="width: 100%">
            <el-table-column
                fixed
                prop="audienceName"
                label="动态细分"
                width="250">
            </el-table-column>
            <el-table-column
                label="添加"
                width="100">
              <template slot-scope="scope">
                <el-button
                    :disabled="scope.row.disabled"
                    @click="addToSdAudienceTarget(scope.row)"
                    type="text"
                    size="small"
                >
                  {{ scope.row.disabled ? '已添加' : '添加' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-table
              :data="selectedTableAudienceData"
              style="width: 100%"
              max-height="500"
              @sort-change="handleSortChange"
          >
            <el-table-column
                fixed
                prop="category"
                label="匹配方式"
                width="200">
            </el-table-column>
            <el-table-column
                fixed
                prop="audienceName"
                label="受众"
                width="200">
            </el-table-column>
            <el-table-column
                fixed
                prop="backtrackingDaysValue"
                label="回溯期"
                width="600">
            </el-table-column>
            <el-table-column
                label="操作"
                width="120">
              <template slot-scope="scope">
                <el-button
                    @click.native.prevent="deleteSdAudienceTarget(scope.$index, tableRecommendData)"
                    type="text"
                    size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="dialog-footer; text-center">
          <el-button @click="dialogSdImageVrFormVisible = false">取消</el-button>
          <el-button type="primary" plain @click="handleSdInfoClick">创建资源</el-button>
        </div>
      </el-dialog>
      <el-dialog title="图片格式" :visible.sync="dialogSdImagePrFormVisible" style="font-weight: bold;"
                 :close-on-click-modal="false">
        <div>
          <div class="required-field">
            优化方案
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="1">针对页面访问次数进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的点击率。通过向更有可能点击广告的顾客展示您的广告来提高商品购买意向。
          </div>
          <div style="padding-left: 20px">
            付费类型：按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="2">针对转化量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的转化率。通过向更有可能购买商品的顾客展示您的广告来提高销量。
          </div>
          <div style="padding-left: 20px">
            付费类型： 按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="3">针对可见展示量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px; tab-size: unset">
            我们将优化您的竞价以获得更高的可见展示次数。通过在亚马逊上向尽可能多的顾客展示您的广告来提高商品知名度。
          </div>
          <div style="padding-left: 20px">
            成本类型： 每千次可见展示成本 (VCPM) | 归因于可见展示量和点击次数的转化
          </div>
        </div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="徽标" name="1" style="font-size: 16px">
            <div style="padding: 20px">
              <el-image :src="currentLogoUrl"></el-image>
            </div>
            <div>
              <el-button type="text" @click="selectedSdBrandLogoClick">切换LOGO</el-button>
              <el-button type="text" @click="handleBrandLogoClick">添加LOGO</el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item title="标题" name="2" style="font-size: 16px">
            <el-input v-model="currentSdLogoUrl" placeholder="请输入标题"></el-input>
          </el-collapse-item>
          <el-collapse-item title="图片" name="3" style="font-size: 16px">
            <div style="width: 100px; height: 100px; text-align: center; background-color: #eef1f5">
              <el-button type="text" @click="triggerFileInput" style="width: 100%; height: 100%;">
                <img :src="imageFile" v-if="imageFile" alt="Selected Image" style="width: 100%; height: 100%;"/>
                <i class="el-icon-picture" v-else style="font-size: 2em;"></i>
              </el-button>
            </div>
            <input type="file" @change="handleFileUpload" ref="fileInput" style="display: none" accept="image/*"/>
            <div style="padding-top: 20px; font-weight: bold;">自定义图片(可选)
              <el-button type="primary" @click="uploadImage" :disabled="disabledImageButton">上传图片</el-button>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div>
          <div>受众-营销再购买</div>
          <div>回溯期
            <el-select v-model="BacktrackingDaysValue" placeholder="回溯天数" style="padding-bottom: 20px"
                       @change="handleBacktrackingChange">
              <el-option
                  v-for="item in BacktrackingData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-table
              :data="tableSdAudienceData"
              border
              style="width: 100%">
            <el-table-column
                fixed
                prop="audienceName"
                label="动态细分"
                width="250">
            </el-table-column>
            <el-table-column
                label="添加"
                width="100">
              <template slot-scope="scope">
                <el-button
                    :disabled="scope.row.disabled"
                    @click="addToSdAudienceTarget(scope.row)"
                    type="text"
                    size="small"
                >
                  {{ scope.row.disabled ? '已添加' : '添加' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-table
              :data="selectedTableAudienceData"
              style="width: 100%"
              max-height="500"
              @sort-change="handleSortChange"
          >
            <el-table-column
                fixed
                prop="category"
                label="匹配方式"
                width="200">
            </el-table-column>
            <el-table-column
                fixed
                prop="audienceName"
                label="受众"
                width="200">
            </el-table-column>
            <el-table-column
                fixed
                prop="backtrackingDaysValue"
                label="回溯期"
                width="600">
            </el-table-column>
            <el-table-column
                label="操作"
                width="120">
              <template slot-scope="scope">
                <el-button
                    @click.native.prevent="deleteSdAudienceTarget(scope.$index, tableRecommendData)"
                    type="text"
                    size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="dialog-footer; text-center">
          <el-button @click="dialogSdImagePrFormVisible = false">取消</el-button>
          <el-button type="primary" plain @click="handleSdInfoClick">创建资源</el-button>
        </div>
      </el-dialog>
      <el-dialog title="视频格式" :visible.sync="dialogSdVideoFormVisible" style="font-weight: bold;"
                 :close-on-click-modal="false">
        <div>
          <div class="required-field">
            优化方案
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="1">针对页面访问次数进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的点击率。通过向更有可能点击广告的顾客展示您的广告来提高商品购买意向。
          </div>
          <div style="padding-left: 20px">
            付费类型：按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="2">针对转化量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的转化率。通过向更有可能购买商品的顾客展示您的广告来提高销量。
          </div>
          <div style="padding-left: 20px">
            付费类型： 按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="3">针对可见展示量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px; tab-size: unset">
            我们将优化您的竞价以获得更高的可见展示次数。通过在亚马逊上向尽可能多的顾客展示您的广告来提高商品知名度。
          </div>
          <div style="padding-left: 20px">
            成本类型： 每千次可见展示成本 (VCPM) | 归因于可见展示量和点击次数的转化
          </div>
        </div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="徽标" name="1">
            <div style="padding: 20px">
              <el-image :src="currentSdLogoUrl"></el-image>
            </div>
            <div>
              <el-button type="text" @click="selectedSdBrandLogoClick">切换LOGO</el-button>
              <el-button type="text" @click="handleBrandLogoClick">添加LOGO</el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item title="标题" name="2">
            <el-input v-model="inputBrandName" placeholder="请输入标题"></el-input>
          </el-collapse-item>
          <el-collapse-item title="视频" name="3" class="required-field">
            <div>
              <div style="width: 100px; height: 100px; text-align: center; background-color: #eef1f5">
                <el-button type="text" @click="triggerFileVideoInput" style="width: 100%; height: 100%;">
                  <video v-if="VideoFile" controls style="width: 100%; height: 100%; ">
                    <source :src="VideoFile" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                  <i class="el-icon-video-camera-solid" v-else style="font-size: 2em;"></i>
                </el-button>
              </div>
              <input type="file" ref="fileVideoInput" style="display: none" @change="handleVideoUpload"
                     accept="image/*"/>
              <div style="padding-top: 20px; font-weight: bold;" class="required-field">视频
                <button @click="uploadVideo">上传视频</button>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogSdVideoFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleSdInfoClick">创建资源</el-button>
        </div>
      </el-dialog>
      <el-dialog title="视频格式" :visible.sync="dialogSdVideoAaFormVisible" style="font-weight: bold;"
                 :close-on-click-modal="false">
        <div>
          <div class="required-field">
            优化方案
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="1">针对页面访问次数进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的点击率。通过向更有可能点击广告的顾客展示您的广告来提高商品购买意向。
          </div>
          <div style="padding-left: 20px">
            付费类型：按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="2">针对转化量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的转化率。通过向更有可能购买商品的顾客展示您的广告来提高销量。
          </div>
          <div style="padding-left: 20px">
            付费类型： 按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="3">针对可见展示量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px; tab-size: unset">
            我们将优化您的竞价以获得更高的可见展示次数。通过在亚马逊上向尽可能多的顾客展示您的广告来提高商品知名度。
          </div>
          <div style="padding-left: 20px">
            成本类型： 每千次可见展示成本 (VCPM) | 归因于可见展示量和点击次数的转化
          </div>
        </div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="徽标" name="1">
            <div style="padding: 20px">
              <el-image :src="currentSdLogoUrl"></el-image>
            </div>
            <div>
              <el-button type="text" @click="selectedSdBrandLogoClick">切换LOGO</el-button>
              <el-button type="text" @click="handleBrandLogoClick">添加LOGO</el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item title="标题" name="2">
            <el-input v-model="inputBrandName" placeholder="请输入标题"></el-input>
          </el-collapse-item>
          <el-collapse-item title="视频" name="3" class="required-field">
            <div>
              <div style="width: 100px; height: 100px; text-align: center; background-color: #eef1f5">
                <el-button type="text" @click="triggerFileVideoInput" style="width: 100%; height: 100%;">
                  <video v-if="VideoFile" controls style="width: 100%; height: 100%; ">
                    <source :src="VideoFile" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                  <i class="el-icon-video-camera-solid" v-else style="font-size: 2em;"></i>
                </el-button>
              </div>
              <input type="file" ref="fileVideoInput" style="display: none" @change="handleVideoUpload"
                     accept="image/*"/>
              <div style="padding-top: 20px; font-weight: bold;" class="required-field">视频
                <button @click="uploadVideo">上传视频</button>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogSdVideoAaFormVisible = false">取消</el-button>
          <el-button type="primary" @click="handleSdInfoClick">创建资源</el-button>
        </div>
      </el-dialog>
      <el-dialog title="视频格式" :visible.sync="dialogSdVideoVrFormVisible" style="font-weight: bold;"
                 :close-on-click-modal="false">
        <div>
          <div class="required-field">
            优化方案
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="1">针对页面访问次数进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的点击率。通过向更有可能点击广告的顾客展示您的广告来提高商品购买意向。
          </div>
          <div style="padding-left: 20px">
            付费类型：按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="2">针对转化量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的转化率。通过向更有可能购买商品的顾客展示您的广告来提高销量。
          </div>
          <div style="padding-left: 20px">
            付费类型： 按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="3">针对可见展示量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px; tab-size: unset">
            我们将优化您的竞价以获得更高的可见展示次数。通过在亚马逊上向尽可能多的顾客展示您的广告来提高商品知名度。
          </div>
          <div style="padding-left: 20px">
            成本类型： 每千次可见展示成本 (VCPM) | 归因于可见展示量和点击次数的转化
          </div>
        </div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="徽标" name="1">
            <div style="padding: 20px">
              <el-image :src="currentSdLogoUrl"></el-image>
            </div>
            <div>
              <el-button type="text" @click="selectedSdBrandLogoClick">切换LOGO</el-button>
              <el-button type="text" @click="handleBrandLogoClick">添加LOGO</el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item title="标题" name="2">
            <el-input v-model="inputBrandName" placeholder="请输入标题"></el-input>
          </el-collapse-item>
          <el-collapse-item title="视频" name="3" class="required-field">
            <div>
              <div style="width: 100px; height: 100px; text-align: center; background-color: #eef1f5">
                <el-button type="text" @click="triggerFileVideoInput" style="width: 100%; height: 100%;">
                  <video v-if="VideoFile" controls style="width: 100%; height: 100%; ">
                    <source :src="VideoFile" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                  <i class="el-icon-video-camera-solid" v-else style="font-size: 2em;"></i>
                </el-button>
              </div>
              <input type="file" ref="fileVideoInput" style="display: none" @change="handleVideoUpload"
                     accept="image/*"/>
              <div style="padding-top: 20px; font-weight: bold;" class="required-field">视频
                <button @click="uploadVideo">上传视频</button>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div>
          <div>受众-再营销浏览定向</div>
          <div>回溯期
            <el-select v-model="BacktrackingDaysValue" placeholder="回溯天数" style="padding-bottom: 20px"
                       @change="handleBacktrackingChange">
              <el-option
                  v-for="item in BacktrackingData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-table
              :data="tableSdAudienceData"
              border
              style="width: 100%">
            <el-table-column
                fixed
                prop="audienceName"
                label="动态细分"
                width="250">
            </el-table-column>
            <el-table-column
                label="添加"
                width="100">
              <template slot-scope="scope">
                <el-button
                    :disabled="scope.row.disabled"
                    @click="addToSdAudienceTarget(scope.row)"
                    type="text"
                    size="small"
                >
                  {{ scope.row.disabled ? '已添加' : '添加' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-table
              :data="selectedTableAudienceData"
              style="width: 100%"
              max-height="500"
              @sort-change="handleSortChange"
          >
            <el-table-column
                fixed
                prop="category"
                label="匹配方式"
                width="200">
            </el-table-column>
            <el-table-column
                fixed
                prop="audienceName"
                label="受众"
                width="200">
            </el-table-column>
            <el-table-column
                fixed
                prop="backtrackingDaysValue"
                label="回溯期"
                width="600">
            </el-table-column>
            <el-table-column
                label="操作"
                width="120">
              <template slot-scope="scope">
                <el-button
                    @click.native.prevent="deleteSdAudienceTarget(scope.$index, tableRecommendData)"
                    type="text"
                    size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogSdVideoVrFormVisible = false">取消</el-button>
          <el-button type="primary" @click="handleSdInfoClick">创建资源</el-button>
        </div>
      </el-dialog>
      <el-dialog title="视频格式" :visible.sync="dialogSdVideoPrFormVisible" style="font-weight: bold;"
                 :close-on-click-modal="false">
        <div>
          <div class="required-field">
            优化方案
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="1">针对页面访问次数进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的点击率。通过向更有可能点击广告的顾客展示您的广告来提高商品购买意向。
          </div>
          <div style="padding-left: 20px">
            付费类型：按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="2">针对转化量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px">
            我们将优化您的竞价以获得更高的转化率。通过向更有可能购买商品的顾客展示您的广告来提高销量。
          </div>
          <div style="padding-left: 20px">
            付费类型： 按点击付费 (CPC) | 归因于点击次数的转化
          </div>
          <div>
            <el-radio v-model="radioOptimizationPlan" label="3">针对可见展示量进行优化</el-radio>
          </div>
          <div style="padding-left: 20px; tab-size: unset">
            我们将优化您的竞价以获得更高的可见展示次数。通过在亚马逊上向尽可能多的顾客展示您的广告来提高商品知名度。
          </div>
          <div style="padding-left: 20px">
            成本类型： 每千次可见展示成本 (VCPM) | 归因于可见展示量和点击次数的转化
          </div>
        </div>
        <el-collapse v-model="activeName" accordion>
          <el-collapse-item title="徽标" name="1" style="font-size: 16px">
            <div style="padding: 20px">
              <el-image :src="currentSdLogoUrl"></el-image>
            </div>
            <div>
              <el-button type="text" @click="selectedSdBrandLogoClick">切换LOGO</el-button>
              <el-button type="text" @click="handleBrandLogoClick">添加LOGO</el-button>
            </div>
          </el-collapse-item>
          <el-collapse-item title="标题" name="2" style="font-size: 16px">
            <el-input v-model="inputBrandName" placeholder="请输入标题"></el-input>
          </el-collapse-item>
          <el-collapse-item title="视频" name="3" class="required-field" style="font-size: 16px">
            <div>
              <div style="width: 100px; height: 100px; text-align: center; background-color: #eef1f5">
                <el-button type="text" @click="triggerFileVideoInput" style="width: 100%; height: 100%;">
                  <video v-if="VideoFile" controls style="width: 100%; height: 100%; ">
                    <source :src="VideoFile" type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                  <i class="el-icon-video-camera-solid" v-else style="font-size: 2em;"></i>
                </el-button>
              </div>
              <input type="file" ref="fileVideoInput" style="display: none" @change="handleVideoUpload"
                     accept="image/*"/>
              <div style="padding-top: 20px; font-weight: bold;" class="required-field">视频
                <button @click="uploadVideo">上传视频</button>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div>
          <div>受众-营销再购买</div>
          <div>回溯期
            <el-select v-model="BacktrackingDaysValue" placeholder="回溯天数" style="padding-bottom: 20px"
                       @change="handleBacktrackingChange">
              <el-option
                  v-for="item in BacktrackingData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-table
              :data="tableSdAudienceData"
              border
              style="width: 100%">
            <el-table-column
                fixed
                prop="audienceName"
                label="动态细分"
                width="250">
            </el-table-column>
            <el-table-column
                label="添加"
                width="100">
              <template slot-scope="scope">
                <el-button
                    :disabled="scope.row.disabled"
                    @click="addToSdAudienceTarget(scope.row)"
                    type="text"
                    size="small"
                >
                  {{ scope.row.disabled ? '已添加' : '添加' }}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-table
              :data="selectedTableAudienceData"
              style="width: 100%"
              max-height="500"
              @sort-change="handleSortChange"
          >
            <el-table-column
                fixed
                prop="category"
                label="匹配方式"
                width="200">
            </el-table-column>
            <el-table-column
                fixed
                prop="audienceName"
                label="受众"
                width="200">
            </el-table-column>
            <el-table-column
                fixed
                prop="backtrackingDaysValue"
                label="回溯期"
                width="600">
            </el-table-column>
            <el-table-column
                label="操作"
                width="120">
              <template slot-scope="scope">
                <el-button
                    @click.native.prevent="deleteSdAudienceTarget(scope.$index, tableRecommendData)"
                    type="text"
                    size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogSdVideoPrFormVisible = false">取消</el-button>
          <el-button type="primary" @click="handleSdInfoClick">创建资源</el-button>
        </div>
      </el-dialog>
    </el-col>
    <el-col :span="24" style="padding: 20px 600px">
      <el-button @click="handleUpClick">上一步</el-button>
      <el-button type="primary" plain @click="handleNextClick">下一步</el-button>
    </el-col>
    <el-col :span="24">
      <div class="footer" style="background-color:#545c64">
        <ul>
          <li>帮助中心</li>
          <li>意见反馈</li>
          <li>新手指南</li>
        </ul>
        <p>Copyright © admin-web.com版权所有</p>
      </div>
    </el-col>
  </div>
</template>

<script>
import {Message} from 'element-ui'
import axios from "axios";

export default {
  name: "AdStrategy",
  data() {
    return {
      dialogSdLogoFormVisible: false,
      selectedTableAudienceData: [],
      tableAudienceData: [],
      pagination: {
        page: 0,
        page_size: 20,
        total_records: 1,
        total_pages: 1,
        pageSizeOption: [20, 50, 100, 200]
      },
      isAccordionEnabled: true,
      isSwitchOn: true, // Switch 的状态
      value: false,
      activeName: '',
      items: [
        {name: '1', content: '徽标', isActive: true}
      ],
      dialogSdVideoPrFormVisible: false,
      dialogSdVideoVrFormVisible: false,
      dialogSdVideoAaFormVisible: false,
      tableSdAudienceData: [
        {
          audienceName: '您推广的商品',
          disabled: false
        },
        {
          audienceName: '与您推广商品类似的商品',
          disabled: false
        },
        {
          audienceName: '类别',
          disabled: false
        },
      ],
      BacktrackingData: [
        {
          value: '7',
          label: 7
        }, {
          value: '14',
          label: 14
        }, {
          value: '30',
          label: 30
        }
      ],
      BacktrackingDaysValue: 30,
      activeNameSd: 'first',
      dialogSdImagePrFormVisible: false,
      dialogSdImageVrFormVisible: false,
      dialogSdImageAaFormVisible: false,
      radioOptimizationPlan: '1',
      radio: '1',
      activeTab: "1",
      loading: true,
      brandList: [],
      ProductsList: [],
      storePageList: [],
      sbStoreProduct: [],
      brandStoreList: [],
      brandIdList: [],
      selectionInfo: {},
      tableRecommendData: [],
      imageFile: null,
      VideoFile: null,
      selectedFile: null,
      selectedImageFile: null,
      src: '',
      imageUrl: '',
      search_sp: '',
      search_sb: '',
      search_sd: '',
      search_product: '',
      search_page: '',
      inputBrandName: '',
      selectedBrandIdValue: '',
      selectedStoresIdValue: '',
      customImageAssetId: '',
      customVideoAssetId: '',
      search_page_product: '',
      selectedBrandLogoName: '',
      selectedStorePageUrlValue: '',
      disabledImageButton: false,
      dialogStoresFormVisible: false,
      dialogSdVideoFormVisible: false,
      dialogSdImageFormVisible: false,
      dialogSwitchPageFormVisible: false,
      dialogStoreVideoFormVisible: false,
      dialogSwitchPageProductsFormVisible: false,
      typeOptions: [
        {label: '2', value: '2'},
        {label: '4', value: '4'},
        {label: '6', value: '6'},
        {label: '8', value: '8'},
        {label: '10', value: '10'},
        {label: '12', value: '12'},
      ],
      selectionProduct: [],
      firstProductsList: [],
      secondProductsList: [],
      thirdProductsList: [],
      filterValue: '',
      dialogCreateVideoFormVisible: false,
      dialogImageFormVisible: false,
      dialogVideoFormVisible: false,
      dialogLogoFormVisible: false,
      dialogCreateLogoFormVisible: false,
      fileListCommoditySets: [],
      fileListStores: [],
      fileListVideo: [],
      videoProductsList: [],
      formLabelWidth: '120px',
      baseFontSize: 18,
      baseLineHeight: 1.8,
      url_path: sessionStorage.url_path || '/',
      activeIndex: '/ad-structure',
      tableSpData: [
        {
          "advertising_strategy": "SPA1",
          "characteristic": "自动投放 匹配与产品紧密相关的搜索词 流量相对小且精准-展示在搜索页-帮助识别检查文案及拓词，适用产品各个阶段，特别是新品期",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        {
          "advertising_strategy": "SPA2",
          "characteristic": "自动投放 匹配与产品并不密切相关的搜索词，流量相对大但不精准-展示在搜索页-广撒网获取流量，适用产品稳定后，想要拓展流量范围",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        {
          "advertising_strategy": "SPA3",
          "characteristic": "自动投放 匹配同类产品，流量相对小但精准-展示在商品页-适用产品各个阶段，特别是新品期",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        {
          "advertising_strategy": "SPA4",
          "characteristic": "自动投放 匹配互补商品，流量相对大但不精准-展示在商品页-适用有搭配使用需求的产品",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        {
          "advertising_strategy": "SPW1-KW",
          "characteristic": "手动投放 广泛匹配大词，没有特定顺序要求，支持同义词，中间可插入其他词，支持常见拼写错误，支持单复数，流量很大且不精准-展示在搜索页-服务于曝光拓词",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        {
          "advertising_strategy": "SPW2-KW",
          "characteristic": "手动投放 词组匹配大词，有特定顺序，支持前后添加其他关键词，不支持中间插入，支持单复数，流量较大-展示在搜索页-适用于有歧义的关键词",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        {
          "advertising_strategy": "SPW3-KW",
          "characteristic": "手动投放 精准匹配大词，搜索词必须与关键词匹配，仅支持单复数，流量相对较大-展示在搜索页-主要服务于曝光和点击",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        {
          "advertising_strategy": "SPW1-LW",
          "characteristic": "手动投放 广泛匹配长尾词，没有特定顺序要求，支持同义词，中间可插入其他词，支持常见拼写错误，支持单复数-展示在搜索页-流量大，可以持续拓词",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        {
          "advertising_strategy": "SPW2-LW",
          "characteristic": "手动投放 词组匹配长尾词，有特定顺序，支持前后添加其他关键词，不支持中间插入，支持单复数-展示在搜索页-流量小也相对精准",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        {
          "advertising_strategy": "SPW3-LW",
          "characteristic": "手动投放 精准匹配长尾词，搜索词必须与关键词匹配，仅支持单复数，流量小且精准-展示在搜索页-主要服务于转化",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        {
          "advertising_strategy": "SPW1-SW",
          "characteristic": "手动投放 广泛匹配拓展词，没有特定顺序要求，支持同义词，中间可插入其他词，支持常见拼写错误，支持单复数-展示在搜索页-流量大，可以持续拓词",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        {
          "advertising_strategy": "SPW2-SW",
          "characteristic": "手动投放 词组匹配拓展词，有特定顺序，支持前后添加其他关键词，不支持中间插入，支持单复数-展示在搜索页-流量小，服务于点击转化",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        {
          "advertising_strategy": "SPW3-SW",
          "characteristic": "手动投放 精准匹配拓展词，搜索词必须与关键词匹配，仅支持单复数，流量小且精准-展示在搜索页-服务于转化，如果拓展词比较长，且匹配方式严格，流量会很小",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        {
          "advertising_strategy": "SPP-C",
          "characteristic": "手动投放 投放品类，流量大，流量的精细程度可以按照品类条件进行细化-展示在商品页-适用于各个阶段",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        {
          "advertising_strategy": "SPP-A1",
          "characteristic": "手动投放 精准匹配本品牌类似产品，流量小且精准-展示在本品牌商品页-占领自身商品页的坑位，流量内部转化，起防御作用",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        },
        // {
        //   "advertising_strategy": "SPP-A1",
        //   "characteristic": "手动投放 精准匹配其它品牌产品，流量小且精准-展示在竞品商品页，目的抢夺竞品流量，提高转化",
        //   "disabled": false,
        //   "selectedType": 2,
        //   "startDate": "",
        //   "endDate": ""
        // },
        {
          "advertising_strategy": "SPP-A2",
          "characteristic": "手动投放 宽泛匹配其它品牌产品，流量相对大一些-展示在竞品商品页，目的抢夺竞品流量，提高转化",
          "disabled": false,
          "selectedType": '2',
          "startDate": "",
          "endDate": ""
        }
      ],
      tableSbData: [
        {
          "advertising_strategy": "SBI-P-KW1",
          "characteristic": "商品集，着陆到推广的产品页面，广泛匹配大词，图片形式展示-流量很大，但不精准-提高曝光",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-P-KW2",
          "characteristic": "商品集，着陆到推广的产品页面，词组匹配大词，图片形式展示-流量很大，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-P-KW3",
          "characteristic": "商品集，着陆到推广的产品页面，精准匹配大词，图片形式展示-流量大且精准",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-P-LW1",
          "characteristic": "商品集，着陆到推广的产品页面，广泛匹配长尾词，图片形式展示-流量大",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-P-LW2",
          "characteristic": "商品集，着陆到推广的产品页面，词组匹配长尾词，图片形式展示-流量小，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-P-LW3",
          "characteristic": "商品集，着陆到推广的产品页面，精准匹配长尾词，图片形式展示-流量小且精准，提高转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-P-SW1",
          "characteristic": "商品集，着陆到推广的产品页面，广泛匹配拓展词，图片形式展示-流量大",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-P-SW2",
          "characteristic": "商品集，着陆到推广的产品页面，词组匹配拓展词，图片形式展示-流量小，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-P-SW3",
          "characteristic": "商品集，着陆到推广的产品页面，精准匹配拓展词，图片形式展示-流量小且精准，提高",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-P-C",
          "characteristic": "商品集，着陆到推广的产品页面，匹配类目，图片形式展示-流量大，流量精准程度可以按照类目细分",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-P-A1",
          "characteristic": "商品集，着陆到推广的产品页面，匹配本品牌类似产品，图片形式展示-流量小且精准，防御作用，实现流量内部转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-P-A2",
          "characteristic": "商品集，着陆到推广的产品页面，匹配其他品牌类似产品，图片形式展示-流量小且精准，抢夺竞品流量",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-S-KW1",
          "characteristic": "品牌旗舰店焦点模式，着陆到品牌旗舰店（包括子页面），广泛匹配大词，图片形式展示，让顾客有机会找到其他感兴趣的品牌商品，流量大，但不精准",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-S-KW2",
          "characteristic": "品牌旗舰店焦点模式，着陆到品牌旗舰店（包括子页面），词组匹配大词，图片形式展示，让顾客有机会找到其他感兴趣的品牌商品，流量大，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-S-KW3",
          "characteristic": "品牌旗舰店焦点模式，着陆到品牌旗舰店（包括子页面），精准匹配大词，图片形式展示，让顾客有机会找到其他感兴趣的品牌商品，流量大，精准",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-S-LW1",
          "characteristic": "品牌旗舰店焦点模式，着陆到品牌旗舰店（包括子页面），广泛匹配长尾词，图片形式展示，让顾客有机会找到其他感兴趣的品牌商品，流量大",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-S-LW2",
          "characteristic": "品牌旗舰店焦点模式，着陆到品牌旗舰店（包括子页面），词组匹配长尾词，图片形式展示，让顾客有机会找到其他感兴趣的品牌商品，流量小，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-S-LW3",
          "characteristic": "品牌旗舰店焦点模式，着陆到品牌旗舰店（包括子页面），精准匹配长尾词，图片形式展示，让顾客有机会找到其他感兴趣的品牌商品，流量小，精准",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-S-SW1",
          "characteristic": "品牌旗舰店焦点模式，着陆到品牌旗舰店（包括子页面），广泛匹配拓展词，图片形式展示，让顾客有机会找到其他感兴趣的品牌商品，流量大",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-S-SW2",
          "characteristic": "品牌旗舰店焦点模式，着陆到品牌旗舰店（包括子页面），词组匹配拓展词，图片形式展示，让顾客有机会找到其他感兴趣的品牌商品，流量小，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-S-SW3",
          "characteristic": "品牌旗舰店焦点模式，着陆到品牌旗舰店（包括子页面），精准匹配拓展词，图片形式展示，让顾客有机会找到其他感兴趣的品牌商品，流量小，精准",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-S-C",
          "characteristic": "品牌旗舰店焦点模式，着陆到品牌旗舰店（包括子页面），匹配类目，图片形式展示，让顾客有机会找到其他感兴趣的品牌商品，流量大，流量精准程度可以按照类目细分",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-S-A",
          "characteristic": "品牌旗舰店焦点模式，着陆到品牌旗舰店（包括子页面），匹配本品牌类似产品或类似竞品，图片形式展示，让顾客有机会找到其他感兴趣的品牌商品，流量小且精准，防御或抢占竞品流量",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-SP-KW1",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），广泛匹配大词，图片形式展示-流量大，但不精准",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-SP-KW2",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），词组匹配大词，图片形式展示-流量大，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-SP-KW3",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），精准匹配大词，图片形式展示-流量大，精准",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-SP-LW1",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），广泛匹配长尾词，图片形式展示-流量大",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-SP-LW2",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），词组匹配长尾词，图片形式展示-流量小，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-SP-LW3",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），精准匹配长尾词，图片形式展示-流量小，精准",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-SP-SW1",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），广泛匹配拓展词，图片形式展示-流量大",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-SP-SW2",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），词组匹配拓展词，图片形式展示-流量小，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-SP-SW3",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），精准匹配拓展词，图片形式展示-流量小，精准",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-SP-C",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），匹配类目，图片形式展示-流量大，流量精准程度可以按照类目细分",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBI-SP-A",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），匹配本品牌类似产品或类似竞品，图片形式展示-流量小且精准，防御或抢占竞品流量",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-P-KW1",
          "characteristic": "商品集，着陆到推广的产品页面，广泛匹配大词，视频形式展示-流量很大，但不精准-提高曝光",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-P-KW2",
          "characteristic": "商品集，着陆到推广的产品页面，词组匹配大词，视频形式展示-流量很大，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-P-KW3",
          "characteristic": "商品集，着陆到推广的产品页面，精准匹配大词，视频形式展示-流量大且精准",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-P-LW1",
          "characteristic": "商品集，着陆到推广的产品页面，广泛匹配长尾词，视频形式展示-流量大",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-P-LW2",
          "characteristic": "商品集，着陆到推广的产品页面，词组匹配长尾词，视频形式展示-流量小，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-P-LW3",
          "characteristic": "商品集，着陆到推广的产品页面，精准匹配长尾词，视频形式展示-流量小且精准，提高转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-P-SW1",
          "characteristic": "商品集，着陆到推广的产品页面，广泛匹配拓展词，视频形式展示-流量大",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-P-SW2",
          "characteristic": "商品集，着陆到推广的产品页面，词组匹配拓展词，视频形式展示-流量小，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-P-SW3",
          "characteristic": "商品集，着陆到推广的产品页面，精准匹配拓展词，视频形式展示-流量小且精准，提高",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-P-C",
          "characteristic": "商品集，着陆到推广的产品页面，匹配类目，视频形式展示-流量大，流量精准程度可以按照类目细分",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-P-A1",
          "characteristic": "商品集，着陆到推广的产品页面，匹配本品牌类似产品，视频形式展示-流量小且精准，防御作用，实现流量内部转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-P-A2",
          "characteristic": "商品集，着陆到推广的产品页面，匹配其他品牌类似产品，视频形式展示-流量小且精准，抢夺竞品流量",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-SP-KW1",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），广泛匹配大词，视频形式展示-流量大，但不精准",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-SP-KW2",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），词组匹配大词，视频形式展示-流量大，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-SP-KW3",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），精准匹配大词，视频形式展示-流量大，精准",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-SP-LW1",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），广泛匹配长尾词，视频形式展示-流量大",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-SP-LW2",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），词组匹配长尾词，视频形式展示-流量小，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-SP-LW3",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），精准匹配长尾词，视频形式展示-流量小，精准",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-SP-SW1",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），广泛匹配拓展词，视频形式展示-流量大",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-SP-SW2",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），词组匹配拓展词，视频形式展示-流量小，适合有歧义的关键词",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-SP-SW3",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），精准匹配拓展词，视频形式展示-流量小，精准",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-SP-C",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），匹配类目，视频形式展示-流量大，流量精准程度可以按照类目细分",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SBV-SP-A",
          "characteristic": "商品集，着陆到品牌旗舰店（包括子页面），匹配本品牌类似产品或类似竞品，视频形式展示-流量小且精准，防御或抢占竞品流量",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        }
      ],
      tableSdData: [
        {
          "advertising_strategy": "SDI-C-VCPM",
          "characteristic": "品类投放-按千次可见展示收费，图片形式展示-流量大，流量的精准程度可以按照条件进行细化-提高曝光和点击，扩大品牌认知",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-C-CPC1",
          "characteristic": "品类投放-点击收费，并按点击量优化竞价，图片形式展示-流量大，流量的精准程度可以按照条件进行细化-提高点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-C-CPC2",
          "characteristic": "品类投放-点击收费，并按转化率优化竞价，图片形式展示-流量大，流量的精准程度可以按照条件进行细化-提高转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-AA1-VCPM",
          "characteristic": "按照生活方式匹配相关受众-按千次可见展示收费，图片形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高曝光和点击，扩大品牌认知",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-AA1-CPC1",
          "characteristic": "按照生活方式匹配相关受众-点击收费，并按点击量优化竞价，图片形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-AA1-CPC2",
          "characteristic": "按照生活方式匹配相关受众-点击收费，并按转化率优化竞价，图片形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-AA2-VCPM",
          "characteristic": "按照兴趣匹配相关受众-按千次可见展示收费，图片形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高曝光和点击，扩大品牌认知",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-AA2-CPC1",
          "characteristic": "按照兴趣匹配相关受众-点击收费，并按点击量优化竞价，图片形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-AA2-CPC2",
          "characteristic": "按照兴趣匹配相关受众-点击收费，并按转化率优化竞价，图片形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-AA3-VCPM",
          "characteristic": "按照生活事件匹配相关受众-按千次可见展示收费，图片形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高曝光和点击，扩大品牌认知",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-AA3-CPC1",
          "characteristic": "按照生活事件匹配相关受众-点击收费，并按点击量优化竞价，图片形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-AA3-CPC2",
          "characteristic": "按照生活事件匹配相关受众-点击收费，并按转化率优化竞价，图片形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-AA4-VCPM",
          "characteristic": "按照场内容群（最近活动）匹配相关受众-按千次可见展示收费，图片形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高曝光和点击，扩大品牌认知",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-AA4-CPC1",
          "characteristic": "按照场内容群（最近活动）匹配相关受众-点击收费，并按点击量优化竞价，图片形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-AA4-CPC2",
          "characteristic": "按照场内容群（最近活动）匹配相关受众-点击收费，并按转化率优化竞价，图片形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-VR7-VCPM",
          "characteristic": "再营销浏览定向,按千次可见展示收费，图片形式对7天内浏览过已推广/类似/特定类目的受众展示-提升曝光,扩大品牌认知",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-VR7-CPC1",
          "characteristic": "再营销浏览定向,点击收费，并按点击量优化竞价，图片形式对7天内浏览过已推广/类似/特定类目的受众展示-提升点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-VR7-CPC2",
          "characteristic": "再营销浏览定向,点击收费，并按转化率优化竞价，图片形式对7天内浏览过已推广/类似/特定类目的受众展示-提升转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-VR14-VCPM",
          "characteristic": "再营销浏览定向,按千次可见展示收费，图片形式对14天内浏览过已推广/类似/特定类目的受众展示-提升曝光，适合单价高一点的产品，客户犹豫时间较长",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-VR14-CPC1",
          "characteristic": "再营销浏览定向,点击收费，并按点击量优化竞价，图片形式对14天内浏览过已推广/类似/特定类目的受众展示-提升点击，适合单价高一点的产品，客户犹豫时间较长",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-VR14-CPC2",
          "characteristic": "再营销浏览定向,点击收费，并按转化率优化竞价，图片形式对14天内浏览过已推广/类似/特定类目的受众展示-提升转化，适合单价高一点的产品，客户犹豫时间较长",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-VR30-VCPM",
          "characteristic": "再营销浏览定向,按千次可见展示收费，图片形式对30天内浏览过已推广/类似/特定类目的受众展示-提升曝光",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-VR30-CPC1",
          "characteristic": "再营销浏览定向,点击收费，并按点击量优化竞价，图片形式对30天内浏览过已推广/类似/特定类目的受众展示-提升点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-VR30-CPC2",
          "characteristic": "再营销浏览定向,点击收费，并按转化率优化竞价，图片形式对30天内浏览过已推广/类似/特定类目的受众展示-提升转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-PR7-VCPM",
          "characteristic": "购买再营销，按千次可见展示收费，图片形式对7天内购买过已推广/类似/特定类目的受众展示",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-PR7-CPC1",
          "characteristic": "购买再营销，点击收费，并按点击量优化竞价，图片形式对7天内购买过已推广/类似/特定类目的受众展示",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-PR7-CPC2",
          "characteristic": "购买再营销，点击收费，并按转化率优化竞价，图片形式对7天内购买过已推广/类似/特定类目的受众展示",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-PR14-VCPM",
          "characteristic": "购买再营销，按千次可见展示收费，图片形式对14天内购买过已推广/类似/特定类目的受众展示-适用于快消品",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-PR14-CPC1",
          "characteristic": "购买再营销，点击收费，并按点击量优化竞价，图片形式对14天内购买过已推广/类似/特定类目的受众展示-适用于快消品",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-PR14-CPC2",
          "characteristic": "购买再营销，点击收费，并按转化率优化竞价，图片形式对14天内购买过已推广/类似/特定类目的受众展示-适用于快消品",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-PR30-VCPM",
          "characteristic": "购买再营销，按千次可见展示收费，图片形式对30天内购买过已推广/类似/特定类目的受众展示",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-PR30-CPC1",
          "characteristic": "购买再营销，点击收费，并按点击量优化竞价，图片形式对30天内购买过已推广/类似/特定类目的受众展示",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-PR30-CPC2",
          "characteristic": "购买再营销，点击收费，并按转化率优化竞价，图片形式对30天内购买过已推广/类似/特定类目的受众展示",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-P-VCPM",
          "characteristic": "竞品产品投放-按千次可见展示收费，图片形式展示-提高客户对产品的认知，抢占竞品流量-提高曝光和点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-P-CPC1",
          "characteristic": "竞品产品投放-点击收费，并按点击量优化竞价，图片形式展示-提高客户对产品的认知，抢占竞品流量-提高点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDI-P-CPC2",
          "characteristic": "竞品产品投放-点击收费，并按转化率优化竞价，图片形式展示-提高客户对产品的认知，抢占竞品流量-提高转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-C-VCPM",
          "characteristic": "品类投放-按千次可见展示收费，视频形式展示-流量大，流量的精准程度可以按照条件进行细化-提高曝光和点击，扩大品牌认知",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-C-CPC1",
          "characteristic": "品类投放-点击收费，并按点击量优化竞价，视频形式展示-流量大，流量的精准程度可以按照条件进行细化-提高点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-C-CPC2",
          "characteristic": "品类投放-点击收费，并按转化率优化竞价，视频形式展示-流量大，流量的精准程度可以按照条件进行细化-提高转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-AA1-VCPM",
          "characteristic": "按照生活方式匹配相关受众-按千次可见展示收费，视频形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高曝光和点击，扩大品牌认知",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-AA1-CPC1",
          "characteristic": "按照生活方式匹配相关受众-点击收费，并按点击量优化竞价，视频形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-AA1-CPC2",
          "characteristic": "按照生活方式匹配相关受众-点击收费，并按转化率优化竞价，视频形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-AA2-VCPM",
          "characteristic": "按照兴趣匹配相关受众-按千次可见展示收费，视频形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高曝光和点击，扩大品牌认知",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-AA2-CPC1",
          "characteristic": "按照兴趣匹配相关受众-点击收费，并按点击量优化竞价，视频形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-AA2-CPC2",
          "characteristic": "按照兴趣匹配相关受众-点击收费，并按转化率优化竞价，视频形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-AA3-VCPM",
          "characteristic": "按照生活事件匹配相关受众-按千次可见展示收费，视频形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高曝光和点击，扩大品牌认知",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-AA3-CPC1",
          "characteristic": "按照生活事件匹配相关受众-点击收费，并按点击量优化竞价，视频形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-AA3-CPC2",
          "characteristic": "按照生活事件匹配相关受众-点击收费，并按转化率优化竞价，视频形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-AA4-VCPM",
          "characteristic": "按照场内容群（最近活动）匹配相关受众-按千次可见展示收费，视频形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高曝光和点击，扩大品牌认知",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-AA4-CPC1",
          "characteristic": "按照场内容群（最近活动）匹配相关受众-点击收费，并按点击量优化竞价，视频形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-AA4-CPC2",
          "characteristic": "按照场内容群（最近活动）匹配相关受众-点击收费，并按转化率优化竞价，视频形式展示-流量较大，流量的精准程度可以筛选受众群体进行细化-提高转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-VR7-VCPM",
          "characteristic": "再营销浏览定向,按千次可见展示收费，视频形式对7天内浏览过已推广/类似/特定类目的受众展示-提升曝光,扩大品牌认知",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-VR7-CPC1",
          "characteristic": "再营销浏览定向,点击收费，并按点击量优化竞价，视频形式对7天内浏览过已推广/类似/特定类目的受众展示-提升点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-VR7-CPC2",
          "characteristic": "再营销浏览定向,点击收费，并按转化率优化竞价，视频形式对7天内浏览过已推广/类似/特定类目的受众展示-提升转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-VR14-VCPM",
          "characteristic": "再营销浏览定向,按千次可见展示收费，视频形式对14天内浏览过已推广/类似/特定类目的受众展示-提升曝光，适合单价高一点的产品，客户犹豫时间较长",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-VR14-CPC1",
          "characteristic": "再营销浏览定向,点击收费，并按点击量优化竞价，视频形式对14天内浏览过已推广/类似/特定类目的受众展示-提升点击，适合单价高一点的产品，客户犹豫时间较长",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-VR14-CPC2",
          "characteristic": "再营销浏览定向,点击收费，并按转化率优化竞价，视频形式对14天内浏览过已推广/类似/特定类目的受众展示-提升转化，适合单价高一点的产品，客户犹豫时间较长",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-VR30-VCPM",
          "characteristic": "再营销浏览定向,按千次可见展示收费，视频形式对30天内浏览过已推广/类似/特定类目的受众展示-提升曝光",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-VR30-CPC1",
          "characteristic": "再营销浏览定向,点击收费，并按点击量优化竞价，视频形式对30天内浏览过已推广/类似/特定类目的受众展示-提升点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-VR30-CPC2",
          "characteristic": "再营销浏览定向,点击收费，并按转化率优化竞价，视频形式对30天内浏览过已推广/类似/特定类目的受众展示-提升转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-PR7-VCPM",
          "characteristic": "购买再营销，按千次可见展示收费，视频形式对7天内购买过已推广/类似/特定类目的受众展示",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-PR7-CPC1",
          "characteristic": "购买再营销，点击收费，并按点击量优化竞价，视频形式对7天内购买过已推广/类似/特定类目的受众展示",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-PR7-CPC2",
          "characteristic": "购买再营销，点击收费，并按转化率优化竞价，视频形式对7天内购买过已推广/类似/特定类目的受众展示",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-PR14-VCPM",
          "characteristic": "购买再营销，按千次可见展示收费，视频形式对14天内购买过已推广/类似/特定类目的受众展示-适用于快消品",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-PR14-CPC1",
          "characteristic": "购买再营销，点击收费，并按点击量优化竞价，视频形式对14天内购买过已推广/类似/特定类目的受众展示-适用于快消品",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-PR14-CPC2",
          "characteristic": "购买再营销，点击收费，并按转化率优化竞价，视频形式对14天内购买过已推广/类似/特定类目的受众展示-适用于快消品",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-PR30-VCPM",
          "characteristic": "购买再营销，按千次可见展示收费，视频形式对30天内购买过已推广/类似/特定类目的受众展示",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-PR30-CPC1",
          "characteristic": "购买再营销，点击收费，并按点击量优化竞价，视频形式对30天内购买过已推广/类似/特定类目的受众展示",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-PR30-CPC2",
          "characteristic": "购买再营销，点击收费，并按转化率优化竞价，视频形式对30天内购买过已推广/类似/特定类目的受众展示",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-P-VCPM",
          "characteristic": "竞品产品投放-按千次可见展示收费，视频形式展示-提高客户对产品的认知，抢占竞品流量-提高曝光和点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-P-CPC1",
          "characteristic": "竞品产品投放-点击收费，并按点击量优化竞价，视频形式展示-提高客户对产品的认知，抢占竞品流量-提高点击",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        },
        {
          "advertising_strategy": "SDV-P-CPC2",
          "characteristic": "竞品产品投放-点击收费，并按转化率优化竞价，视频形式展示-提高客户对产品的认知，抢占竞品流量-提高转化",
          "disabled": false,
          "selectedType": 2,
          "startDate": "",
          "endDate": "",
          "brand": 0
        }
      ],
      tableInfoData: [],
      autoData: [],
      brandUrlList: [],
      brandSdUrlList: [],
      sbDialogDict: {},
      videoAssetIds: [],
      currentLogoIndex: 0,
      tableProductData: [],
      dialogSwitchProductsFormVisible: false,
      tablePageProductData: [],
      tabFirstProductsList: [],
      tabSecondProductsList: [],
      tabThirdProductsList: []
    }
  },
  methods: {
    generateTitle(panel) {
      return (
          <div style="display: flex; align-items: center;">
            <el-button
                type="text"
                icon={this.activeName === panel.name ? 'el-icon-arrow-down' : 'el-icon-arrow-right'}
                onClick={() => this.toggleCollapse(panel.name)}
            ></el-button>
            <span style="margin-left: 10px;">{panel.name}</span>
          </div>
      );
    },
    toggleCollapse(panelName) {
      this.activeName = this.activeName === panelName ? '' : panelName;
    },
    //SD移除受众投放数据
    deleteSdAudienceTarget(index) {
      const deletedRow = this.selectedTableAudienceData[index];
      this.selectedTableAudienceData.splice(index, 1);
      const sourceRow = this.tableSdAudienceData.find(item => item.audienceName === deletedRow.audienceName);
      if (sourceRow) {
        sourceRow.disabled = false;
      }
    },
    //SD受众投放数据
    handleBacktrackingChange() {
      // 当 BacktrackingDaysValue 发生变化时的处理逻辑
      // 在这里更新表格数据的 disabled 参数
      this.tableSdAudienceData.forEach((item) => {
        // 这里可以根据你的实际数据结构进行调整
        item.disabled = false;
      });
    },
    //SD广告数据
    handleSdInfoClick() {
      this.tableSdAudienceData.forEach(function (sd) {
        sd.disabled = false
      })
      let selectionInfo = this.selectionInfo
      this.brandList.currentLogoUrl = this.currentLogoUrl
      this.brandList.brandName = this.inputBrandName
      if (this.radioOptimizationPlan === '1') {
        this.brandList.costType = 'cpc'
        this.brandList.bidOptimization = 'clicks'
      } else if (this.radioOptimizationPlan === '2') {
        this.brandList.costType = 'cpc'
        this.brandList.bidOptimization = 'conversions'
      } else if (this.radioOptimizationPlan === '3') {
        this.brandList.costType = 'vcpm'
        this.brandList.bidOptimization = 'reach'
      }
      if (this.brandList.advertising_strategy.includes('SDI')) {
        if (this.customImageAssetId) {
          this.brandList.customImageAssetId = this.customImageAssetId
        } else if (this.brandSdUrlList.length > 0) {
          this.brandList.brandName = this.brandSdUrlList[this.currentLogoIndex].name
          this.brandList.brandLogoAssetID = this.brandSdUrlList[this.currentLogoIndex].assetId
        } else if (this.inputBrandName !== '') {
          this.brandList.headline = this.inputBrandName
        }
        if (this.brandList.advertising_strategy.includes('SDI-P-') || this.brandList.advertising_strategy.includes('SDI-C')) {
          this.brandList.tactic = 'T00020'
          this.brandList.imageUrl = this.imageUrl
          if (this.brandList.advertising_strategy.includes('SDI-P-')) {
            this.brandList.typeText = 'ASIN'
            this.brandList.matching_method = '品类投放 — ASIN'
            this.brandList.matching_method_type = 'manual'
            this.brandList.keyword = selectionInfo.tableRecommendationAsinData
            this.brandList.keywordLength = selectionInfo.tableRecommendationAsinData.length
            this.brandList.disabled = true;
            this.tableRecommendData.push(this.brandList);
            this.dialogSdImageFormVisible = false
          } else if (this.brandList.advertising_strategy.includes('SDI-C')) {
            this.brandList.typeText = '类目'
            this.brandList.matching_method = '品类投放 — 类目'
            this.brandList.matching_method_type = 'manual'
            this.brandList.keyword = selectionInfo.tableCategoriesData
            this.brandList.keywordLength = selectionInfo.tableCategoriesData.length
            this.brandList.disabled = true;
            this.tableRecommendData.push(this.brandList);
            this.dialogSdImageFormVisible = false
          }
        }
        if (this.brandList.advertising_strategy.includes('SDI-AA')) {
          this.brandList.matching_method = '受众'
          this.brandList.typeText = '亚马逊消费者'
          this.brandList.tactic = 'T00030'
          this.brandList.disabled = true;
          this.tableRecommendData.push(this.brandList);
          this.dialogSdImageAaFormVisible = false
        } else if (this.brandList.advertising_strategy.includes('SDI-VR')) {
          if (this.selectedTableAudienceData.length > 0) {
            let selectedTableAudienceData = []
            if (selectionInfo.tableCategoriesData.length > 0) {
              this.selectedTableAudienceData.forEach(function (audience) {
                let categoriesDict = {}
                if (audience.audienceName === '类别') {
                  selectionInfo.tableCategoriesData.forEach(function (categories) {
                    categoriesDict = {
                      na: categories.na,
                      id: categories.id,
                      audienceName: audience.audienceName,
                      backtrackingDaysValue: audience.backtrackingDaysValue,
                      category: audience.category,
                      disabled: audience.disabled,
                      disabledRefinement: categories.disabledRefinement,
                      positioning: categories.positioning
                    }
                    selectedTableAudienceData.push(categoriesDict)
                  })
                } else {
                  selectedTableAudienceData.push(audience)
                }
              })
            } else {
              this.selectedTableAudienceData.forEach(function (audience) {
                selectedTableAudienceData.push(audience)
              })
            }
            this.brandList.keyword = selectedTableAudienceData
            this.brandList.matching_method = '受众'
            this.brandList.typeText = '再营销浏览定向'
            this.brandList.tactic = 'T00030'
            this.brandList.disabled = true;
            this.tableRecommendData.push(this.brandList);
            this.dialogSdImageVrFormVisible = false
            this.selectedTableAudienceData = []
          } else {
            this.$message.error('缺少受众')
          }
        } else if (this.brandList.advertising_strategy.includes('SDI-PR')) {
          if (this.selectedTableAudienceData.length > 0) {
            let selectedTableAudienceData = []
            if (selectionInfo.tableCategoriesData.length > 0) {
              this.selectedTableAudienceData.forEach(function (audience) {
                let categoriesDict = {}
                if (audience.audienceName === '类别') {
                  selectionInfo.tableCategoriesData.forEach(function (categories) {
                    categoriesDict = {
                      na: categories.na,
                      id: categories.id,
                      audienceName: audience.audienceName,
                      backtrackingDaysValue: audience.backtrackingDaysValue,
                      category: audience.category,
                      disabled: audience.disabled,
                      disabledRefinement: categories.disabledRefinement,
                      positioning: categories.positioning
                    }
                    selectedTableAudienceData.push(categoriesDict)
                  })
                } else {
                  categoriesDict = audience
                  selectedTableAudienceData.push(categoriesDict)
                }
              })
            } else {
              this.selectedTableAudienceData.forEach(function (audience) {
                selectedTableAudienceData.push(audience)
              })
            }
            this.brandList.keyword = selectedTableAudienceData
            this.brandList.matching_method = '受众'
            this.brandList.typeText = '购买再营销'
            this.brandList.tactic = 'T00030'
            this.brandList.disabled = true;
            this.tableRecommendData.push(this.brandList);
            this.dialogSdImagePrFormVisible = false
            this.selectedTableAudienceData = []
          } else {
            this.$message.error('缺少受众')
          }
        }
      } else if (this.brandList.advertising_strategy.includes('SDV')) {
        if (this.customVideoAssetId) {
          this.brandList.customVideoAssetId = this.customVideoAssetId
          if (this.brandUrlList.length > 0) {
            this.brandList.brandName = this.brandUrlList[this.currentLogoIndex].name
            this.brandList.brandLogoAssetID = this.brandUrlList[this.currentLogoIndex].assetId
          } else if (this.inputBrandName !== '') {
            this.brandList.headline = this.inputBrandName
          }
          if (this.brandList.advertising_strategy.includes('SDV-P-') || this.brandList.advertising_strategy.includes('SDV-C')) {
            this.brandList.tactic = 'T00020'
            this.brandList.videoProductsList = this.videoProductsList
            if (this.brandList.advertising_strategy.includes('SDV-P-')) {
              this.brandList.typeText = 'ASIN'
              this.brandList.matching_method = '品类投放 — ASIN'
              this.brandList.matching_method_type = 'manual'
              this.brandList.keyword = selectionInfo.tableRecommendationAsinData
              this.brandList.keywordLength = selectionInfo.tableRecommendationAsinData.length
              this.brandList.disabled = true;
              this.tableRecommendData.push(this.brandList);
              this.dialogSdVideoFormVisible = false
            } else if (this.brandList.advertising_strategy.includes('SDV-C')) {
              this.brandList.typeText = '类目'
              this.brandList.matching_method = '品类投放 — 类目'
              this.brandList.matching_method_type = 'manual'
              this.brandList.keyword = selectionInfo.tableCategoriesData
              this.brandList.keywordLength = selectionInfo.tableCategoriesData.length
              this.brandList.disabled = true;
              this.tableRecommendData.push(this.brandList);
              this.dialogSdVideoFormVisible = false
            }
          } else if (this.brandList.advertising_strategy.includes('SDV-AA')) {
            this.brandList.matching_method = '受众'
            this.brandList.typeText = '亚马逊消费者'
            this.brandList.tactic = 'T00030'
            this.brandList.disabled = true;
            this.tableRecommendData.push(this.brandList);
            this.dialogSdVideoAaFormVisible = false
          } else if (this.brandList.advertising_strategy.includes('SDV-VR')) {
            if (this.selectedTableAudienceData.length > 0) {
              let selectedTableAudienceData = []
              if (selectionInfo.tableCategoriesData.length > 0) {
                this.selectedTableAudienceData.forEach(function (audience) {
                  let categoriesDict = {}
                  if (audience.audienceName === '类别') {
                    selectionInfo.tableCategoriesData.forEach(function (categories) {
                      categoriesDict = {
                        na: categories.na,
                        id: categories.id,
                        audienceName: audience.audienceName,
                        backtrackingDaysValue: audience.backtrackingDaysValue,
                        category: audience.category,
                        disabled: audience.disabled,
                        disabledRefinement: categories.disabledRefinement,
                        positioning: categories.positioning
                      }
                      selectedTableAudienceData.push(categoriesDict)
                    })
                  } else {
                    categoriesDict = audience
                    selectedTableAudienceData.push(categoriesDict)
                  }
                  audience.disabled = false
                })
              } else {
                this.selectedTableAudienceData.forEach(function (audience) {
                  selectedTableAudienceData.push(audience)
                })
              }
              this.brandList.keyword = selectedTableAudienceData
              this.brandList.matching_method = '受众'
              this.brandList.typeText = '再营销浏览定向'
              this.brandList.tactic = 'T00030'
              this.brandList.disabled = true;
              this.tableRecommendData.push(this.brandList);
              this.dialogSdVideoVrFormVisible = false
              this.selectedTableAudienceData = []
            } else {
              this.$message.error('缺少受众')
            }

          } else if (this.brandList.advertising_strategy.includes('SDV-PR')) {
            if (this.selectedTableAudienceData.length > 0) {
              let selectedTableAudienceData = []
              if (selectionInfo.tableCategoriesData.length > 0) {
                this.selectedTableAudienceData.forEach(function (audience) {
                  let categoriesDict = {}
                  if (audience.audienceName === '类别') {
                    selectionInfo.tableCategoriesData.forEach(function (categories) {
                      categoriesDict = {
                        na: categories.na,
                        id: categories.id,
                        audienceName: audience.audienceName,
                        backtrackingDaysValue: audience.backtrackingDaysValue,
                        category: audience.category,
                        disabled: audience.disabled,
                        disabledRefinement: categories.disabledRefinement,
                        positioning: categories.positioning
                      }
                      selectedTableAudienceData.push(categoriesDict)
                    })
                  } else {
                    categoriesDict = audience
                    selectedTableAudienceData.push(categoriesDict)
                  }
                  audience.disabled = false
                })
              } else {
                this.selectedTableAudienceData.forEach(function (audience) {
                  selectedTableAudienceData.push(audience)
                })
              }
              this.brandList.keyword = selectedTableAudienceData
              this.brandList.matching_method = '受众'
              this.brandList.typeText = '购买再营销'
              this.brandList.tactic = 'T00030'
              this.brandList.disabled = true;
              this.tableRecommendData.push(this.brandList);
              this.dialogSdVideoPrFormVisible = false
              this.selectedTableAudienceData = []
            } else {
              this.$message.error('缺少受众')
            }
          }
        } else {
          this.$message.error('缺少视频资源')
        }
      }
      this.customImageAssetId = ''
      this.brandSdUrlList = []
      this.inputBrandName = ''

    },
    //添加SD再营销浏览定向与购买再营销受众选择
    addToSdAudienceTarget(row) {
      const clonedRow = {...row};
      // 禁用按钮
      row.disabled = true;
      // 存储 BacktrackingDaysValue 到独立属性
      clonedRow.backtrackingDaysValue = this.BacktrackingDaysValue;
      clonedRow.category = this.brandList.typeText
      // 添加到 selectedTableAudienceData 中
      this.selectedTableAudienceData.push(clonedRow);
    },
    //替换页面
    addPageClick(page) {
      page.addStore = true
      if (this.activeTab === "1") {
        this.firstProductsList[0].storePageName = page.storePageName
        page.addStore = true
        this.handleFirstPageProductClick(page)
      } else if (this.activeTab === "2") {
        this.secondProductsList[0].storePageName = page.storePageName
        this.handleSecondPageProductClick(page.storePageUrl)
      } else if (this.activeTab === "3") {
        this.thirdProductsList[0].storePageName = page.storePageName
        this.handleThirdPageProductClick(page.storePageUrl)
      }
      this.dialogSwitchPageFormVisible = false
    },
    //更换页面对应的产品
    selectedPageProduct() {
      this.dialogSwitchPageProductsFormVisible = true
    },
    //更换页面
    selectedPage() {
      this.dialogSwitchPageFormVisible = true
    },
    //切换产品
    addProductClick(selectedProduct) {
      selectedProduct.added = true
      if (this.activeTab === "1") {
        this.tabFirstProductsList[0].added = false
        this.tabFirstProductsList[0] = selectedProduct
      } else if (this.activeTab === "2") {
        this.tabSecondProductsList[0].added = false
        this.tabSecondProductsList[0] = selectedProduct
      } else if (this.activeTab === "3") {
        this.tabThirdProductsList[0].added = false
        this.tabThirdProductsList[0] = selectedProduct
      }
      this.dialogSwitchProductsFormVisible = false
    },
    //切换页面对应的产品
    addPageProductClick(selectedProduct) {
      selectedProduct.added = true
      if (this.activeTab === "1") {
        this.firstProductsList[0].added = false
        this.firstProductsList[0] = selectedProduct
      } else if (this.activeTab === "2") {
        this.secondProductsList[0].added = false
        this.secondProductsList[0] = selectedProduct
      } else if (this.activeTab === "3") {
        this.thirdProductsList[0].added = false
        this.thirdProductsList[0] = selectedProduct
      }
      this.dialogSwitchPageProductsFormVisible = false

    },
    //产品集合产品信息切换
    selectedProduct() {
      this.dialogSwitchProductsFormVisible = true
    },
    //上传视频
    triggerFileVideoInput() {
      // 通过ref找到文件输入框并模拟点击
      this.$refs.fileVideoInput.click();
    },
    //上传图片
    triggerFileInput() {
      // 通过ref找到文件输入框并模拟点击
      this.$refs.fileInput.click();
    },
    //品牌选择，LOGO,图片
    handleBrandInfoClick() {
      let brandList = this.brandList
      if (this.sbStoreProduct.length > 0) {
        Message({
          message: '产品数量不符合该广告类型',
          type: 'error',
        });
        if (this.brandList.advertising_strategy.includes('SBI-P') || this.brandList.advertising_strategy.includes('SBI-SP')) {
          this.dialogImageFormVisible = true
        } else if (this.brandList.advertising_strategy.includes('SBI-S')) {
          this.dialogStoresFormVisible = true
        } else if (this.brandList.advertising_strategy.includes('SBV-SP')) {
          this.dialogVideoFormVisible = true
        }
        this.sbStoreProduct = []
      } else {
        this.disabledImageButton = false
        let selectionInfo = this.selectionInfo
        this.dialogImageFormVisible = false
        this.dialogStoresFormVisible = false
        this.imageFile = null
        if (this.brandList.advertising_strategy.includes('SBV-P')) {
          if (this.selectedStoresIdValue !== '' && this.selectedStorePageUrlValue !== '') {
            this.dialogStoreVideoFormVisible = false
            this.brandList.selectedProductList = this.ProductsList
            if (this.customVideoAssetId) {
              this.brandList.customVideoAssetId = this.customVideoAssetId
              this.brandList.storePageList = this.storePageList
              this.brandList.ProductsList = this.ProductsList
              let brandList = this.brandList
              this.brandList.pageRadio = this.radio
              this.selectedStoresIdValue = ''
              this.selectedStorePageUrlValue = ''
              if (this.brandList.advertising_strategy.includes('C')) {
                this.brandList.matching_method = '商品投放 — 类目定位'
                this.brandList.matching_method_type = 'MANUAL'
                this.brandList.keyword = selectionInfo.tableCategoriesData
                this.brandList.keywordLength = selectionInfo.tableCategoriesData.length
                this.brandList.disabled = true;
                this.tableRecommendData.push(this.brandList);
              } else if (this.brandList.advertising_strategy.includes('A1')) {
                let asinType1List = []
                selectionInfo.tableRecommendationAsinData.forEach(function (asin) {
                  if (asin.selectedAsinType === '精准') {
                    let asinType1 = {
                      positioning: asin.positioning,
                      recommendedAsin: asin.recommendedAsin,
                      selectedAsinType: asin.selectedAsinType,
                      disabled: asin.disabled
                    }
                    asinType1List.push(asinType1)
                  }
                })
                if (asinType1List.length !== 0) {
                  this.brandList.keyword = asinType1List
                  this.brandList.keywordLength = asinType1List.length
                  this.brandList.keywordLength = selectionInfo.tableCategoriesData.length
                  this.brandList.disabled = true;
                  this.tableRecommendData.push(this.brandList);
                }
                this.brandList.matching_method = '商品投放 — ASIN定位'
                this.brandList.matching_method_type = 'MANUAL'
                this.brandList.disabled = true;
                this.tableRecommendData.push(this.brandList);
              } else if (this.brandList.advertising_strategy.includes('A2')) {
                let asinType2List = []
                selectionInfo.tableRecommendationAsinData.forEach(function (asin) {
                  if (asin.selectedAsinType === '扩展') {
                    let asinType2 = {
                      positioning: asin.positioning,
                      recommendedAsin: asin.recommendedAsin,
                      selectedAsinType: asin.selectedAsinType,
                      disabled: asin.disabled
                    }
                    asinType2List.push(asinType2)
                  }
                })
                if (asinType2List.length !== 0) {
                  this.brandList.keyword = asinType2List
                  this.brandList.keywordLength = asinType2List.length
                }
                this.brandList.matching_method = '商品投放 — ASIN定位'
                this.brandList.matching_method_type = 'MANUAL'
                this.brandList.disabled = true;
                this.tableRecommendData.push(this.brandList);
              } else if (this.brandList.advertising_strategy.includes('KW')) {
                let keywordBroadList = []
                let keywordPhraseList = []
                let keywordExactList = []
                this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                  if (brandList.advertising_strategy.includes('1')) {
                    if (keyword.matchType === 'BROAD') {
                      keywordBroadList.push(keyword)
                    }
                    brandList.keywordLength = keywordBroadList.length
                  } else if (brandList.advertising_strategy.includes('2')) {
                    if (keyword.matchType === 'PHRASE') {
                      keywordPhraseList.push(keyword)
                    }
                    brandList.keywordLength = keywordPhraseList.length
                  } else if (brandList.advertising_strategy.includes('3')) {
                    if (keyword.matchType === 'EXACT') {
                      keywordExactList.push(keyword)
                    }
                    brandList.keywordLength = keywordExactList.length
                  }
                })
                if (keywordBroadList.length > 0) {
                  this.brandList.keyword = keywordBroadList
                } else if (keywordPhraseList.length > 0) {
                  this.brandList.keyword = keywordPhraseList
                } else if (keywordExactList > 0) {
                  this.brandList.keyword = keywordExactList
                }
                this.brandList.matching_method = '关键词'
                this.brandList.matching_method_type = 'MANUAL'
                this.brandList.disabled = true;
                this.tableRecommendData.push(this.brandList);
              } else if (this.brandList.advertising_strategy.includes('LW')) {
                let keywordBroadList = []
                let keywordPhraseList = []
                let keywordExactList = []
                this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                  if (brandList.advertising_strategy.includes('1')) {
                    if (keyword.matchType === 'BROAD') {
                      keywordBroadList.push(keyword)
                    }
                    brandList.keywordLength = keywordBroadList.length
                  } else if (brandList.advertising_strategy.includes('2')) {
                    if (keyword.matchType === 'PHRASE') {
                      keywordPhraseList.push(keyword)
                    }
                    brandList.keywordLength = keywordPhraseList.length
                  } else if (brandList.advertising_strategy.includes('3')) {
                    if (keyword.matchType === 'EXACT') {
                      keywordExactList.push(keyword)
                    }
                    brandList.keywordLength = keywordExactList.length
                  }
                })
                if (keywordBroadList.length > 0) {
                  this.brandList.keyword = keywordBroadList
                } else if (keywordPhraseList.length > 0) {
                  this.brandList.keyword = keywordPhraseList
                } else if (keywordExactList > 0) {
                  this.brandList.keyword = keywordExactList
                }
                this.brandList.matching_method = '关键词'
                this.brandList.matching_method_type = 'MANUAL'
                this.brandList.disabled = true;
                this.tableRecommendData.push(this.brandList);
              } else if (this.brandList.advertising_strategy.includes('SW')) {
                let keywordBroadList = []
                let keywordPhraseList = []
                let keywordExactList = []
                this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                  if (brandList.advertising_strategy.includes('1')) {
                    if (keyword.matchType === 'BROAD') {
                      keywordBroadList.push(keyword)
                    }
                    brandList.keywordLength = keywordBroadList.length
                  } else if (brandList.advertising_strategy.includes('2')) {
                    if (keyword.matchType === 'PHRASE') {
                      keywordPhraseList.push(keyword)
                    }
                    brandList.keywordLength = keywordPhraseList.length
                  } else if (this.brandList.advertising_strategy.includes('3')) {
                    if (keyword.matchType === 'EXACT') {
                      keywordExactList.push(keyword)
                    }
                    brandList.keywordLength = keywordExactList.length
                  }
                })
                if (keywordBroadList.length > 0) {
                  this.brandList.keyword = keywordBroadList
                } else if (keywordPhraseList.length > 0) {
                  this.brandList.keyword = keywordPhraseList
                } else if (keywordExactList > 0) {
                  this.brandList.keyword = keywordExactList
                }
                this.brandList.matching_method = '关键词'
                this.brandList.matching_method_type = 'MANUAL'
                this.brandList.disabled = true;
                this.tableRecommendData.push(this.brandList);
              }
            } else {
              this.$message({
                message: '缺失视频资源',
                type: 'error'
              })
              this.dialogStoreVideoFormVisible = true
            }
          } else {
            Message({
              message: '缺少必填项',
              type: 'error',
            });
            this.dialogStoreVideoFormVisible = true
          }
        } else {
          if (this.selectedBrandIdValue !== '' && this.brandUrlList.length > 0 && this.inputBrandName !== '') {
            this.brandList.selectedProductList = this.ProductsList
            if (this.customImageAssetId) {
              this.brandList.customImageAssetId = this.customImageAssetId
            } else if (this.customVideoAssetId) {
              this.brandList.customVideoAssetId = this.customVideoAssetId
            }
            this.brandList.brandName = this.brandUrlList[this.currentLogoIndex].name
            this.brandList.brandLogoAssetID = this.brandUrlList[this.currentLogoIndex].assetId
            this.brandList.headline = this.inputBrandName
            this.brandList.pageRadio = this.radio
            this.brandList.brandEntityId = this.selectedBrandIdValue
            this.brandList.landingPageUrl = this.selectedStorePageUrlValue
            this.brandList.storePageList = this.storePageList
            this.brandList.ProductsList = this.ProductsList
            let brandList = this.brandList
            if (this.brandList.advertising_strategy && this.brandList.advertising_strategy.substring(0, 3).includes("I")) { // 将选中行数据添加到目标表格
              if (this.brandList.advertising_strategy.includes('SBI-P')) {
                this.inputBrandName = ''
                this.brandList.adType = '产品集合'
                if (this.brandList.advertising_strategy.includes('C')) {
                  this.brandList.matching_method = '商品投放 — 类目定位'
                  this.brandList.matching_method_type = 'MANUAL'
                  this.brandList.keyword = selectionInfo.tableCategoriesData
                  this.brandList.keywordLength = selectionInfo.tableCategoriesData.length
                  this.brandList.disabled = true;
                  this.tableRecommendData.push(this.brandList);
                } else if (this.brandList.advertising_strategy.includes('A1')) {
                  let asinType1List = []
                  selectionInfo.tableRecommendationAsinData.forEach(function (asin) {
                    if (asin.selectedAsinType === '精准') {
                      let asinType1 = {
                        positioning: asin.positioning,
                        recommendedAsin: asin.recommendedAsin,
                        selectedAsinType: asin.selectedAsinType,
                        disabled: asin.disabled
                      }
                      asinType1List.push(asinType1)
                    }
                  })
                  if (asinType1List.length !== 0) {
                    this.brandList.keyword = asinType1List
                    this.brandList.keywordLength = asinType1List.length
                  }
                  this.brandList.matching_method = '商品投放 — ASIN定位'
                  this.brandList.matching_method_type = 'MANUAL'
                  this.brandList.disabled = true;
                  this.tableRecommendData.push(this.brandList);
                } else if (this.brandList.advertising_strategy.includes('A2')) {
                  let asinType2List = []
                  selectionInfo.tableRecommendationAsinData.forEach(function (asin) {
                    if (asin.selectedAsinType === '扩展') {
                      let asinType2 = {
                        positioning: asin.positioning,
                        recommendedAsin: asin.recommendedAsin,
                        selectedAsinType: asin.selectedAsinType,
                        disabled: asin.disabled
                      }
                      asinType2List.push(asinType2)
                    }
                  })
                  if (asinType2List.length !== 0) {
                    this.brandList.keyword = asinType2List
                    this.brandList.keywordLength = asinType2List.length
                  }
                  this.brandList.matching_method = '商品投放 — ASIN定位'
                  this.brandList.matching_method_type = 'MANUAL'
                  this.brandList.disabled = true;
                  this.tableRecommendData.push(this.brandList);
                } else if (this.brandList.advertising_strategy.includes('KW')) {
                  let keywordBroadList = []
                  let keywordPhraseList = []
                  let keywordExactList = []
                  this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                    if (brandList.advertising_strategy.includes('1')) {
                      if (keyword.matchType === 'BROAD') {
                        keywordBroadList.push(keyword)
                      }
                      brandList.keywordLength = keywordBroadList.length
                    } else if (brandList.advertising_strategy.includes('2')) {
                      if (keyword.matchType === 'PHRASE') {
                        keywordPhraseList.push(keyword)
                      }
                      brandList.keywordLength = keywordPhraseList.length
                    } else if (brandList.advertising_strategy.includes('3')) {
                      if (keyword.matchType === 'EXACT') {
                        keywordExactList.push(keyword)
                      }
                      brandList.keywordLength = keywordExactList.length
                    }
                  })
                  if (keywordBroadList.length > 0) {
                    this.brandList.keyword = keywordBroadList
                  } else if (keywordPhraseList.length > 0) {
                    this.brandList.keyword = keywordPhraseList
                  } else if (keywordExactList > 0) {
                    this.brandList.keyword = keywordExactList
                  }
                  this.brandList.matching_method = '关键词'
                  this.brandList.matching_method_type = 'MANUAL'
                  this.brandList.disabled = true;
                  this.tableRecommendData.push(this.brandList);
                } else if (this.brandList.advertising_strategy.includes('LW')) {
                  let keywordBroadList = []
                  let keywordPhraseList = []
                  let keywordExactList = []
                  this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                    if (brandList.advertising_strategy.includes('1')) {
                      if (keyword.matchType === 'BROAD') {
                        keywordBroadList.push(keyword)
                      }
                      brandList.keywordLength = keywordBroadList.length
                    } else if (brandList.advertising_strategy.includes('2')) {
                      if (keyword.matchType === 'PHRASE') {
                        keywordPhraseList.push(keyword)
                      }
                      brandList.keywordLength = keywordPhraseList.length
                    } else if (brandList.advertising_strategy.includes('3')) {
                      if (keyword.matchType === 'EXACT') {
                        keywordExactList.push(keyword)
                      }
                      brandList.keywordLength = keywordExactList.length
                    }
                  })
                  this.brandList.matching_method = '关键词'
                  this.brandList.matching_method_type = 'MANUAL'
                  this.brandList.disabled = true;
                  this.tableRecommendData.push(this.brandList);
                } else if (this.brandList.advertising_strategy.includes('SW')) {
                  let keywordBroadList = []
                  let keywordPhraseList = []
                  let keywordExactList = []
                  this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                    if (brandList.advertising_strategy.includes('1')) {
                      if (keyword.matchType === 'BROAD') {
                        keywordBroadList.push(keyword)
                      }
                      brandList.keywordLength = keywordBroadList.length
                    } else if (brandList.advertising_strategy.includes('2')) {
                      if (keyword.matchType === 'PHRASE') {
                        keywordPhraseList.push(keyword)
                      }
                      brandList.keywordLength = keywordPhraseList.length
                    } else if (brandList.advertising_strategy.includes('3')) {
                      if (keyword.matchType === 'EXACT') {
                        keywordExactList.push(keyword)
                      }
                      brandList.keywordLength = keywordExactList.length
                    }
                  })
                  this.brandList.matching_method = '关键词'
                  this.brandList.matching_method_type = 'MANUAL'
                  this.brandList.disabled = true;
                  this.tableRecommendData.push(this.brandList);
                }
              } else if (this.brandList.advertising_strategy.includes('SBI-SP')) {
                if (this.selectedStoresIdValue !== '' && this.selectedStorePageUrlValue !== '') {
                  this.brandList.adType = '产品集合'
                  this.inputBrandName = ''
                  this.selectedStoresIdValue = ''
                  this.selectedStorePageUrlValue = ''
                  this.currentLogoUrl = ''
                  if (this.brandList.advertising_strategy.includes('C')) {
                    this.brandList.matching_method = '商品投放 — 类目定位'
                    this.brandList.matching_method_type = 'MANUAL'
                    this.brandList.keyword = selectionInfo.tableCategoriesData
                    this.brandList.keywordLength = selectionInfo.tableCategoriesData.length
                    this.brandList.disabled = true;
                    this.tableRecommendData.push(this.brandList);
                  } else if (this.brandList.advertising_strategy.includes('A')) {
                    let asinType1List = []
                    selectionInfo.tableRecommendationAsinData.forEach(function (asin) {
                      if (asin.selectedAsinType === '精准') {
                        let asinType1 = {
                          positioning: asin.positioning,
                          recommendedAsin: asin.recommendedAsin,
                          selectedAsinType: asin.selectedAsinType,
                          disabled: asin.disabled
                        }
                        asinType1List.push(asinType1)
                      }
                    })
                    if (asinType1List.length !== 0) {
                      this.brandList.keyword = asinType1List
                      this.brandList.keywordLength = asinType1List.length
                    }
                    this.brandList.matching_method = '商品投放 — ASIN定位'
                    this.brandList.matching_method_type = 'MANUAL'
                    this.brandList.disabled = true;
                    this.tableRecommendData.push(this.brandList);
                  } else if (this.brandList.advertising_strategy.includes('KW')) {
                    let keywordBroadList = []
                    let keywordPhraseList = []
                    let keywordExactList = []
                    this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                      if (brandList.advertising_strategy.includes('1')) {
                        if (keyword.matchType === 'BROAD') {
                          keywordBroadList.push(keyword)
                        }
                        brandList.keywordLength = keywordBroadList.length
                      } else if (brandList.advertising_strategy.includes('2')) {
                        if (keyword.matchType === 'PHRASE') {
                          keywordPhraseList.push(keyword)
                        }
                        brandList.keywordLength = keywordPhraseList.length
                      } else if (brandList.advertising_strategy.includes('3')) {
                        if (keyword.matchType === 'EXACT') {
                          keywordExactList.push(keyword)
                        }
                        brandList.keywordLength = keywordExactList.length
                      }
                    })
                    if (keywordBroadList.length > 0) {
                      this.brandList.keyword = keywordBroadList
                    } else if (keywordPhraseList.length > 0) {
                      this.brandList.keyword = keywordPhraseList
                    } else if (keywordExactList > 0) {
                      this.brandList.keyword = keywordExactList
                    }
                    this.brandList.matching_method = '关键词'
                    this.brandList.matching_method_type = 'MANUAL'
                    this.brandList.disabled = true;
                    this.tableRecommendData.push(this.brandList);
                  } else if (this.brandList.advertising_strategy.includes('LW')) {
                    let keywordBroadList = []
                    let keywordPhraseList = []
                    let keywordExactList = []
                    this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                      if (brandList.advertising_strategy.includes('1')) {
                        if (keyword.matchType === 'BROAD') {
                          keywordBroadList.push(keyword)
                        }
                        brandList.keywordLength = keywordBroadList.length
                      } else if (brandList.advertising_strategy.includes('2')) {
                        if (keyword.matchType === 'PHRASE') {
                          keywordPhraseList.push(keyword)
                        }
                        brandList.keywordLength = keywordPhraseList.length
                      } else if (brandList.advertising_strategy.includes('3')) {
                        if (keyword.matchType === 'EXACT') {
                          keywordExactList.push(keyword)
                        }
                        brandList.keywordLength = keywordExactList.length
                      }
                    })
                    if (keywordBroadList.length > 0) {
                      this.brandList.keyword = keywordBroadList
                    } else if (keywordPhraseList.length > 0) {
                      this.brandList.keyword = keywordPhraseList
                    } else if (keywordExactList > 0) {
                      this.brandList.keyword = keywordExactList
                    }
                    this.brandList.matching_method = '关键词'
                    this.brandList.matching_method_type = 'MANUAL'
                    this.brandList.disabled = true;
                    this.tableRecommendData.push(this.brandList);
                  } else if (this.brandList.advertising_strategy.includes('SW')) {
                    let keywordBroadList = []
                    let keywordPhraseList = []
                    let keywordExactList = []
                    this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                      if (brandList.advertising_strategy.includes('1')) {
                        if (keyword.matchType === 'BROAD') {
                          keywordBroadList.push(keyword)
                        }
                        brandList.keywordLength = keywordBroadList.length
                      } else if (brandList.advertising_strategy.includes('2')) {
                        if (keyword.matchType === 'PHRASE') {
                          keywordPhraseList.push(keyword)
                        }
                        brandList.keywordLength = keywordPhraseList.length
                      } else if (brandList.advertising_strategy.includes('3')) {
                        if (keyword.matchType === 'EXACT') {
                          keywordExactList.push(keyword)
                        }
                        brandList.keywordLength = keywordExactList.length
                      }
                    })
                    if (keywordBroadList.length > 0) {
                      this.brandList.keyword = keywordBroadList
                    } else if (keywordPhraseList.length > 0) {
                      this.brandList.keyword = keywordPhraseList
                    } else if (keywordExactList > 0) {
                      this.brandList.keyword = keywordExactList
                    }
                    this.brandList.matching_method = '关键词'
                    this.brandList.matching_method_type = 'MANUAL'
                    this.brandList.disabled = true;
                    this.tableRecommendData.push(this.brandList);
                  }
                } else {
                  Message({
                    message: '缺少必填项',
                    type: 'error',
                  });
                  this.dialogImageFormVisible = true
                }
              } else if (this.brandList.advertising_strategy.includes('SBI-S')) {
                this.loading = true
                if (this.selectedStoresIdValue !== '') {
                  if (this.firstProductsList.length > 0) {
                    this.brandList.adType = '商店聚焦'
                    this.brandList.firstProductsList = this.firstProductsList
                    this.brandList.secondProductsList = this.secondProductsList
                    this.brandList.thirdProductsList = this.thirdProductsList
                    this.inputBrandName = ''
                    this.selectedStoresIdValue = ''
                    this.selectedStorePageUrlValue = ''
                    this.currentLogoUrl = ''
                    if (this.brandList.advertising_strategy.includes('C')) {
                      this.brandList.matching_method = '商品投放 — 类目定位'
                      this.brandList.matching_method_type = 'MANUAL'
                      this.brandList.keyword = selectionInfo.tableCategoriesData
                      this.brandList.keywordLength = selectionInfo.tableCategoriesData.length
                      this.brandList.disabled = true;
                      this.tableRecommendData.push(this.brandList);
                    } else if (this.brandList.advertising_strategy.includes('A')) {
                      let asinType1List = []
                      selectionInfo.tableRecommendationAsinData.forEach(function (asin) {
                        if (asin.selectedAsinType === '精准') {
                          let asinType1 = {
                            positioning: asin.positioning,
                            recommendedAsin: asin.recommendedAsin,
                            selectedAsinType: asin.selectedAsinType,
                            disabled: asin.disabled
                          }
                          asinType1List.push(asinType1)
                        }
                      })
                      if (asinType1List.length !== 0) {
                        this.brandList.keyword = asinType1List
                        this.brandList.keywordLength = asinType1List.length
                      }
                      this.brandList.matching_method = '商品投放 — ASIN定位'
                      this.brandList.matching_method_type = 'MANUAL'
                      this.brandList.disabled = true;
                      this.tableRecommendData.push(this.brandList);
                    } else if (this.brandList.advertising_strategy.includes('KW')) {
                      let keywordBroadList = []
                      let keywordPhraseList = []
                      let keywordExactList = []
                      this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                        if (brandList.advertising_strategy.includes('1')) {
                          if (keyword.matchType === 'BROAD') {
                            keywordBroadList.push(keyword)
                          }
                          brandList.keywordLength = keywordBroadList.length
                        } else if (brandList.advertising_strategy.includes('2')) {
                          if (keyword.matchType === 'PHRASE') {
                            keywordPhraseList.push(keyword)
                          }
                          brandList.keywordLength = keywordPhraseList.length
                        } else if (brandList.advertising_strategy.includes('3')) {
                          if (keyword.matchType === 'EXACT') {
                            keywordExactList.push(keyword)
                          }
                          brandList.keywordLength = keywordExactList.length
                        }
                      })
                      if (keywordBroadList.length > 0) {
                        this.brandList.keyword = keywordBroadList
                      } else if (keywordPhraseList.length > 0) {
                        this.brandList.keyword = keywordPhraseList
                      } else if (keywordExactList > 0) {
                        this.brandList.keyword = keywordExactList
                      }
                      this.brandList.matching_method = '关键词'
                      this.brandList.matching_method_type = 'MANUAL'
                      this.brandList.disabled = true;
                      this.tableRecommendData.push(this.brandList);
                    } else if (this.brandList.advertising_strategy.includes('LW')) {
                      let keywordBroadList = []
                      let keywordPhraseList = []
                      let keywordExactList = []
                      this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                        if (brandList.advertising_strategy.includes('1')) {
                          if (keyword.matchType === 'BROAD') {
                            keywordBroadList.push(keyword)
                          }
                          brandList.keywordLength = keywordBroadList.length
                        } else if (brandList.advertising_strategy.includes('2')) {
                          if (keyword.matchType === 'PHRASE') {
                            keywordPhraseList.push(keyword)
                          }
                          brandList.keywordLength = keywordPhraseList.length
                        } else if (brandList.advertising_strategy.includes('3')) {
                          if (keyword.matchType === 'EXACT') {
                            keywordExactList.push(keyword)
                          }
                          brandList.keywordLength = keywordExactList.length
                        }
                      })
                      if (keywordBroadList.length > 0) {
                        this.brandList.keyword = keywordBroadList
                      } else if (keywordPhraseList.length > 0) {
                        this.brandList.keyword = keywordPhraseList
                      } else if (keywordExactList > 0) {
                        this.brandList.keyword = keywordExactList
                      }
                      this.brandList.matching_method = '关键词'
                      this.brandList.matching_method_type = 'MANUAL'
                      this.brandList.disabled = true;
                      this.tableRecommendData.push(this.brandList);
                    } else if (this.brandList.advertising_strategy.includes('SW')) {
                      let keywordBroadList = []
                      let keywordPhraseList = []
                      let keywordExactList = []
                      this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                        if (brandList.advertising_strategy.includes('1')) {
                          if (keyword.matchType === 'BROAD') {
                            keywordBroadList.push(keyword)
                          }
                          brandList.keywordLength = keywordBroadList.length
                        } else if (brandList.advertising_strategy.includes('2')) {
                          if (keyword.matchType === 'PHRASE') {
                            keywordPhraseList.push(keyword)
                          }
                          brandList.keywordLength = keywordPhraseList.length
                        } else if (brandList.advertising_strategy.includes('3')) {
                          if (keyword.matchType === 'EXACT') {
                            keywordExactList.push(keyword)
                          }
                          brandList.keywordLength = keywordExactList.length
                        }
                      })
                      if (keywordBroadList.length > 0) {
                        this.brandList.keyword = keywordBroadList
                      } else if (keywordPhraseList.length > 0) {
                        this.brandList.keyword = keywordPhraseList
                      } else if (keywordExactList > 0) {
                        this.brandList.keyword = keywordExactList
                      }
                      this.brandList.matching_method = '关键词'
                      this.brandList.matching_method_type = 'MANUAL'
                      this.brandList.disabled = true;
                      this.tableRecommendData.push(this.brandList);
                    }
                  } else {
                    Message({
                      message: '产品数量不符合要求',
                      type: 'error',
                    });
                    this.dialogStoresFormVisible = true
                  }

                } else {
                  Message({
                    message: '缺少必填项',
                    type: 'error',
                  });
                  this.dialogStoresFormVisible = true
                }
              }
            } else if (this.brandList.advertising_strategy.substring(0, 3).includes("V") && this.brandList.advertising_strategy.substring(0, 3).includes("SBV-SP")) {
              if (this.customVideoAssetId === '') {
                this.$message({
                  message: '缺失视频资源',
                  type: 'error'
                })
                this.dialogVideoFormVisible = true
              } else {
                this.dialogVideoFormVisible = false
                this.brandList.adType = '视频'
                this.brandList.videoProductsList = this.videoProductsList
                this.brandList.videoAssetIds = this.videoAssetIds
                if (this.brandList.advertising_strategy.includes('SBV-SP')) {
                  if (this.selectedStoresIdValue !== '' && this.selectedStorePageUrlValue !== '') {
                    this.inputBrandName = ''
                    this.selectedStoresIdValue = ''
                    this.selectedStorePageUrlValue = ''
                    this.currentLogoUrl = ''
                    if (this.brandList.advertising_strategy.includes('C')) {
                      this.brandList.matching_method = '商品投放 — 类目定位'
                      this.brandList.matching_method_type = 'MANUAL'
                      this.brandList.keyword = selectionInfo.tableCategoriesData
                      this.brandList.keywordLength = selectionInfo.tableCategoriesData.length
                      this.brandList.disabled = true;
                      this.tableRecommendData.push(this.brandList);
                    }
                    if (this.brandList.advertising_strategy.includes('A')) {
                      let asinType1List = []
                      selectionInfo.tableRecommendationAsinData.forEach(function (asin) {
                        if (asin.selectedAsinType === '精准') {
                          let asinType1 = {
                            positioning: asin.positioning,
                            recommendedAsin: asin.recommendedAsin,
                            selectedAsinType: asin.selectedAsinType,
                            disabled: asin.disabled
                          }
                          asinType1List.push(asinType1)
                        }
                      })
                      if (asinType1List.length !== 0) {
                        this.brandList.keyword = asinType1List
                        this.brandList.keywordLength = asinType1List.length
                      }
                      this.brandList.matching_method = '商品投放 — ASIN定位'
                      this.brandList.matching_method_type = 'MANUAL'
                      this.brandList.disabled = true;
                      this.tableRecommendData.push(this.brandList);
                    } else if (this.brandList.advertising_strategy.includes('KW')) {
                      let keywordBroadList = []
                      let keywordPhraseList = []
                      let keywordExactList = []
                      this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                        if (brandList.advertising_strategy.includes('1')) {
                          if (keyword.matchType === 'BROAD') {
                            keywordBroadList.push(keyword)
                          }
                          brandList.keywordLength = keywordBroadList.length
                        } else if (brandList.advertising_strategy.includes('2')) {
                          if (keyword.matchType === 'PHRASE') {
                            keywordPhraseList.push(keyword)
                          }
                          brandList.keywordLength = keywordPhraseList.length
                        } else if (brandList.advertising_strategy.includes('3')) {
                          if (keyword.matchType === 'EXACT') {
                            keywordExactList.push(keyword)
                          }
                          brandList.keywordLength = keywordExactList.length
                        }
                      })
                      if (keywordBroadList.length > 0) {
                        this.brandList.keyword = keywordBroadList
                      } else if (keywordPhraseList.length > 0) {
                        this.brandList.keyword = keywordPhraseList
                      } else if (keywordExactList > 0) {
                        this.brandList.keyword = keywordExactList
                      }
                      this.brandList.matching_method = '关键词'
                      this.brandList.matching_method_type = 'MANUAL'
                      this.brandList.disabled = true;
                      this.tableRecommendData.push(this.brandList);
                    } else if (this.brandList.advertising_strategy.includes('LW')) {
                      let keywordBroadList = []
                      let keywordPhraseList = []
                      let keywordExactList = []
                      this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                        if (brandList.advertising_strategy.includes('1')) {
                          if (keyword.matchType === 'BROAD') {
                            keywordBroadList.push(keyword)
                          }
                          brandList.keywordLength = keywordBroadList.length
                        } else if (brandList.advertising_strategy.includes('2')) {
                          if (keyword.matchType === 'PHRASE') {
                            keywordPhraseList.push(keyword)
                          }
                          brandList.keywordLength = keywordPhraseList.length
                        } else if (brandList.advertising_strategy.includes('3')) {
                          if (keyword.matchType === 'EXACT') {
                            keywordExactList.push(keyword)
                          }
                          brandList.keywordLength = keywordExactList.length
                        }
                      })
                      if (keywordBroadList.length > 0) {
                        this.brandList.keyword = keywordBroadList
                      } else if (keywordPhraseList.length > 0) {
                        this.brandList.keyword = keywordPhraseList
                      } else if (keywordExactList > 0) {
                        this.brandList.keyword = keywordExactList
                      }
                      this.brandList.matching_method = '关键词'
                      this.brandList.matching_method_type = 'MANUAL'
                      this.brandList.disabled = true;
                      this.tableRecommendData.push(this.brandList);
                    } else if (this.brandList.advertising_strategy.includes('SW')) {
                      let keywordBroadList = []
                      let keywordPhraseList = []
                      let keywordExactList = []
                      this.selectionInfo.tableKeywordData.forEach(function (keyword) {
                        if (brandList.advertising_strategy.includes('1')) {
                          if (keyword.matchType === 'BROAD') {
                            keywordBroadList.push(keyword)
                          }
                          brandList.keywordLength = keywordBroadList.length
                        } else if (brandList.advertising_strategy.includes('2')) {
                          if (keyword.matchType === 'PHRASE') {
                            keywordPhraseList.push(keyword)
                          }
                          brandList.keywordLength = keywordPhraseList.length
                        } else if (brandList.advertising_strategy.includes('3')) {
                          if (keyword.matchType === 'EXACT') {
                            keywordExactList.push(keyword)
                          }
                          brandList.keywordLength = keywordExactList.length
                        }
                      })
                      if (keywordBroadList.length > 0) {
                        this.brandList.keyword = keywordBroadList
                      } else if (keywordPhraseList.length > 0) {
                        this.brandList.keyword = keywordPhraseList
                      } else if (keywordExactList > 0) {
                        this.brandList.keyword = keywordExactList
                      }
                      this.brandList.matching_method = '关键词'
                      this.brandList.matching_method_type = 'MANUAL'
                      this.brandList.disabled = true;
                      this.tableRecommendData.push(this.brandList);
                    }
                  } else {
                    Message({
                      message: '缺少必填项',
                      type: 'error',
                    });
                    this.dialogVideoFormVisible = true
                  }

                }
              }
            }
          } else {
            Message({
              message: '缺少必填项',
              type: 'error',
            });
            if (this.brandList.advertising_strategy.includes('SBI-P') || this.brandList.advertising_strategy.includes('SBI-SP')) {
              this.dialogImageFormVisible = true
            } else if (this.brandList.advertising_strategy.includes('SBI-S')) {
              this.dialogStoresFormVisible = true
            } else if (this.brandList.advertising_strategy.includes('SBV-SP')) {
              this.dialogVideoFormVisible = true
            }
          }
        }
      }
    },
    //获取着陆页ASIN
    //徽标切换
    toggleSelection(index) {
      this.currentLogoIndex = index;
    },
    //切换品牌logo
    selectedBrandLogoClick() {
      this.dialogLogoFormVisible = true
    },
    //切换SD品牌logo
    selectedSdBrandLogoClick() {
      this.$axios.post('https://ad.yaelongtime.com/api/proxy/assets/search', {
            "filterCriteria": {
              "valueFilters": [
                {
                  "values": ["LOGO"],
                  "valueField": "ASSET_TYPE"
                }
              ]
            }
          },
          {
            headers: {
              'Content-Type': 'text/plain'
            }
          }
      ).then(res => {
        let urlLict = []
        let brandName = ''
        if (res.data.assetList.length > 0) {
          res.data.assetList.forEach(function (logo) {
            if (logo.assetSubTypeList[0] === "LOGO" && logo.fileMetadata.height >= 400 && logo.fileMetadata.width >= 400) {
              let urlDict = {
                url: logo.storageLocationUrls.processedUrls.IMAGE_THUMBNAIL_500,
                assetId: logo.assetId,
                isSelected: false,
                name: brandName
              }
              urlLict.push(urlDict)
            }
          })
        } else {
          urlLict = []
        }
        this.brandSdUrlList = urlLict
      }).catch(error => {
        Message({
          message: error,
          type: 'error',
        });
      })
      this.dialogSdLogoFormVisible = true
    },
    //上传图片
    handleFileUpload(event) {
      const imageFile = event.target.files[0];
      this.selectedImageFile = event.target.files[0];
      if (imageFile) {
        // 创建一个URL对象以显示所选图片
        this.imageFile = URL.createObjectURL(imageFile);
      } else {
        this.imageFile = null; // 清空已选择的图片
      }
    },
    uploadImage() {
      this.disabledImageButton = true
      if (!this.selectedImageFile) {
        alert("请选择一个文件");
        return;
      }
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryData = event.target.result;
        this.handleImageClick(binaryData);
      };
      reader.readAsArrayBuffer(this.selectedImageFile);
    },
    //上传图片
    handleImageClick(binaryData) {
      let url = ''
      this.$axios.post('https://ad.yaelongtime.com/api/proxy/assets/upload', {
            "filename": this.selectedImageFile.name
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
      ).then(res => {
        url = res.data.url
        return this.$axios.put(res.data.url, binaryData, {
          headers: {
            'Content-Type': ' image/png'
          }
        })
      }).then(res => {
        const parts = url.split("?");
        const imageUrl = parts[0];
        return this.$axios.post('https://ad.yaelongtime.com/api/proxy/assets/register', {
              "url": imageUrl,
              "name": this.selectedImageFile.name,
              "assetType": "IMAGE",
              "assetSubTypeList": [
                "PRODUCT_IMAGE"
              ],
              "associatedSubEntityList": [
                {
                  "brandEntityId": this.selectedBrandIdValue
                }
              ]
            }, {
              headers: {
                'Content-Type': 'application/json'
              }
            }
        )
      }).then(res => {
        if (res.data.assetId) {
          Message({
            message: '图片上传成功',
            type: 'success',
          });
          this.customImageAssetId = res.data.assetId
        }
        this.selectedImageFile = null
      })
    },
    //上传品牌LOGO
    handleBrandFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    uploadBrandImage() {
      if (!this.selectedFile) {
        alert("请选择一个文件");
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        const binaryData = event.target.result;
        this.handleCreateBrandLogoClick(binaryData);
      };
      reader.readAsArrayBuffer(this.selectedFile);
    },
    //点击新增LOGO弹框
    handleBrandLogoClick() {
      this.dialogCreateLogoFormVisible = true
    },
    //新增品牌logo
    handleCreateBrandLogoClick(binaryData) {
      this.dialogCreateLogoFormVisible = true
      let url = ''
      let brandLogoAssetId = ''
      let brandUrlList = this.brandUrlList
      this.$axios.post('https://ad.yaelongtime.com/api/proxy/assets/upload', {
            "filename": this.selectedFile.name
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
      ).then(res => {
        url = res.data.url
        return this.$axios.put(res.data.url, binaryData, {
          headers: {
            'Content-Type': ' image/png'
          }
        })
      }).then(res => {
        const parts = url.split("?");
        const brandUrl = parts[0];
        return this.$axios.post('https://ad.yaelongtime.com/api/proxy/assets/register', {
              "url": brandUrl,
              "name": this.selectedFile.name,
              "assetType": "IMAGE",
              "assetSubTypeList": [
                "LOGO"
              ],
              "associatedSubEntityList": [
                {
                  "brandEntityId": this.selectedBrandIdValue
                }
              ]
            }, {
              headers: {
                'Content-Type': 'application/json'
              }
            }
        )
      }).then(res => {
        if (res.data.assetId) {
          Message({
            message: '品牌LOGO上传成功',
            type: 'success',
          });
          brandLogoAssetId = res.data.assetId
        }
        this.selectedFile = null
        this.dialogCreateLogoFormVisible = false
        return this.$axios.post('https://ad.yaelongtime.com/api/proxy/assets/search', {
              "filterCriteria": {
                "valueFilters": [
                  {
                    "values": ["LOGO"],
                    "valueField": "ASSET_TYPE"
                  }
                ]
              }
            },
            {
              headers: {
                'Content-Type': 'text/plain'
              }
            }
        )
      }).then(res => {
        let urlLict = []
        let currentLogoUrl = this.currentLogoUrl
        let brandName = ''
        if (res.data.assetList.length > 0) {
          res.data.assetList.forEach(function (logo) {
            if (logo.assetSubTypeList[0] === "LOGO" && logo.fileMetadata.height > 400 && logo.fileMetadata.width > 400) {
              if (logo.assetId === brandLogoAssetId) {
                currentLogoUrl = logo.storageLocationUrls.processedUrls.IMAGE_THUMBNAIL_500
                let brandUrlDict = {
                  url: logo.storageLocationUrls.processedUrls.IMAGE_THUMBNAIL_500,
                  assetId: logo.assetId,
                  isSelected: false,
                  name: brandName
                }
                brandUrlList.push(brandUrlDict)
              }
            }
          })
        }
      }).catch(error => {
        Message({
          message: error,
          type: 'error',
        });
      })
    },
    //上传视频
    handleVideoUpload(event) {
      this.selectedFile = event.target.files[0];
      const VideoFile = event.target.files[0];
      if (VideoFile) {
        // 创建一个URL对象以显示所选图片
        this.VideoFile = URL.createObjectURL(VideoFile);
      } else {
        this.VideoFile = null; // 清空已选择的视频
      }
    },
    uploadVideo() {
      if (!this.selectedFile) {
        alert("请选择一个文件");
        return;
      }
      const reader = new FileReader();

      reader.onload = (event) => {
        const binaryData = event.target.result;
        this.handleVideoClick(binaryData);
      };
      reader.readAsArrayBuffer(this.selectedFile);
    },
    //上传视频
    handleVideoClick(binaryData) {
      let url = ''
      this.$axios.post('https://ad.yaelongtime.com/api/proxy/assets/upload', {
            "filename": this.selectedFile.name
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
      ).then(res => {
        url = res.data.url
        return this.$axios.put(res.data.url, binaryData, {
          headers: {
            'Content-Type': 'video/quicktime'
          }
        })
      }).then(res => {
        const parts = url.split("?");
        const videoUrl = parts[0];
        return this.$axios.post('https://ad.yaelongtime.com/api/proxy/assets/register', {
              "url": videoUrl,
              "name": this.selectedFile.name,
              "assetType": "VIDEO",
              "assetSubTypeList": [
                "BACKGROUND_VIDEO"
              ],
              "associatedSubEntityList": [
                {
                  "brandEntityId": this.selectedBrandIdValue
                }
              ]
            }, {
              headers: {
                'Content-Type': 'application/json'
              }
            }
        )
      }).then(res => {
        this.VideoFile = null
        this.selectedFile = null
        if (res.data) {
          this.customVideoAssetId = res.data.assetId
        }
      })
    },
    //获取对应品牌对应的徽标
    selectedBrandClick() {
      const handleStoresName = () => {
        this.handleStoresName()
      };
      this.$axios.post('https://ad.yaelongtime.com/api/proxy/assets/search', {
            "filterCriteria": {
              "valueFilters": [
                {
                  "values": ["LOGO"],
                  "valueField": "ASSET_TYPE"
                }
              ]
            }
          },
          {
            headers: {
              'Content-Type': 'text/plain'
            }
          }
      ).then(res => {
        let urlLict = []
        let selectedBrandIdValue = this.selectedBrandIdValue
        let brandName = ''
        this.brandIdList.forEach(function (brand) {
          if (brand.id === selectedBrandIdValue) {
            brandName = brand.brandName
          }
        })
        if (res.data.assetList.length > 0) {
          res.data.assetList.forEach(function (logo) {
            if (logo.assetSubTypeList[0] === "LOGO" && logo.fileMetadata.height >= 400 && logo.fileMetadata.width >= 400) {
              let urlDict = {
                url: logo.storageLocationUrls.processedUrls.IMAGE_THUMBNAIL_500,
                assetId: logo.assetId,
                isSelected: false,
                name: brandName
              }
              urlLict.push(urlDict)
            }
          })
        } else {
          urlLict = []
        }
        this.brandUrlList = urlLict
        if (this.radio === 1) {
          handleStoresName()
        }
      }).catch(error => {
        Message({
          message: error,
          type: 'error',
        });
      })
    },
    //获取品牌旗舰店及页面
    handleStoresName() {
      this.$axios.get('https://ad.yaelongtime.com/api/proxy/v2/stores',
      ).then(res => {
        let storePageList = []
        let brandStoreList = []
        if (res.data) {
          res.data.forEach(function (store) {
            let brandStoreDict = {
              brandName: store.storeName
            }
            brandStoreList.push(brandStoreDict)
            store.storePageInfo.forEach(function (storeInfo) {
              let storePageDict = {
                storePageId: storeInfo.storePageId,
                storePageUrl: storeInfo.storePageUrl,
                storePageName: storeInfo.storePageName,
                addStore: false
              }
              storePageList.push(storePageDict)
            })
          })
        }
        this.brandStoreList = brandStoreList
        this.storePageList = storePageList
      })
    },
    handleBrandClick() {
      this.handleFirstPageProductClick()
      this.handleSecondPageProductClick()
      this.handleThirdPageProductClick()
    },
    //获取第一页对应的产品信息
    handleFirstPageProductClick(url) {
      let pageUrl = ''
      if (!url) {
        pageUrl = this.storePageList[0]
      } else {
        pageUrl = url
      }
      this.$axios.get('https://ad.yaelongtime.com/api/proxy/pageAsins', {
        params: {
          pageUrl: pageUrl.storePageUrl
        }
      }).then(res => {
        if (res.data.asinList.length > 0) {
          this.handleFirstPageProductDetails(res.data.asinList, pageUrl)
        }
      })
    },
    //第一个页面的asin详细信息
    handleFirstPageProductDetails(asinList, store) {
      if (asinList.length > 0) {
        this.$axios.post('https://ad.yaelongtime.com/api/proxy/product/metadata',
            {
              "asins": asinList,
              "checkItemDetails": "true",
              "pageSize": 300,
              "locale": "en_US"
            },
            {
              headers: {
                'Content-Type': 'application/vnd.productmetadataresponse.v1+json'
              },
            }
        ).then(res => {
          let tableProductData = []
          if (res.data.ProductMetadataList.length > 0) {
            res.data.ProductMetadataList.forEach(function (product, index) {
              let tableProduct = {
                storePageName: store.storePageName,
                image: product.imageUrl,
                sku: product.sku,
                product_name: product.title,
                positioning: 'ASIN' + ':' + product.asin,
                recommendedAsin: product.asin,
                disabled: false,
                asin: product.asin,
                id: (index + 1).toString(),
                added: false,
              }
              tableProductData.push(tableProduct)
            })
            this.tablePageProductData = tableProductData
            this.firstProductsList = this.tablePageProductData.slice(0, 1)
            this.firstProductsList.forEach(function (product) {
              product.added = true
            })
          } else {
            this.sbStoreProduct.push(res.data.ProductMetadataList)
          }
        }).catch(error => {
          this.$message({
            message: error,
            type: 'error'
          })
        })
      }
    },
    //获取第二页对应的产品信息
    handleSecondPageProductClick(url) {
      let pageUrl = ''
      if (!url) {
        pageUrl = this.storePageList[1]
      } else {
        pageUrl = url
      }
      this.$axios.get('https://ad.yaelongtime.com/api/proxy/pageAsins', {
        params: {
          pageUrl: pageUrl.storePageUrl
        }
      }).then(res => {
        if (res.data.asinList.length > 0) {
          this.handleSecondPageProductDetails(res.data.asinList, pageUrl)
        }
      })
    },
    //第二个页面的asin详细信息
    handleSecondPageProductDetails(asinList, store) {
      if (asinList.length > 0) {
        this.$axios.post('https://ad.yaelongtime.com/api/proxy/product/metadata',
            {
              "asins": asinList,
              "checkItemDetails": "true",
              "pageSize": 300,
              "locale": "en_US"
            },
            {
              headers: {
                'Content-Type': 'application/vnd.productmetadataresponse.v1+json'
              },
            }
        ).then(res => {
          let tableProductData = []
          if (res.data.ProductMetadataList.length > 0) {
            res.data.ProductMetadataList.forEach(function (product, index) {
              let tableProduct = {
                storePageName: store.storePageName,
                image: product.imageUrl,
                sku: product.sku,
                product_name: product.title,
                positioning: 'ASIN' + ':' + product.asin,
                recommendedAsin: product.asin,
                disabled: false,
                asin: product.asin,
                id: (index + 1).toString(),
                added: false,
              }
              tableProductData.push(tableProduct)
            })
            this.tablePageProductData = tableProductData
            this.secondProductsList = this.tablePageProductData.slice(0, 1)
            this.secondProductsList.forEach(function (product) {
              product.added = true
            })
          }
        }).catch(error => {
          this.$message({
            message: error,
            type: 'error'
          })
        })
      }
    },
    //获取第三页对应的产品信息
    handleThirdPageProductClick(url) {
      let pageUrl = ''
      if (!url) {
        pageUrl = this.storePageList[2]
      } else {
        pageUrl = url
      }
      this.$axios.get('https://ad.yaelongtime.com/api/proxy/pageAsins', {
        params: {
          pageUrl: pageUrl.storePageUrl
        }
      }).then(res => {
        if (res.data.asinList.length > 0) {
          this.handleThirdPageProductDetails(res.data.asinList, pageUrl)
        }
      })
    },
    //第三个页面的asin详细信息
    handleThirdPageProductDetails(asinList, store) {
      if (asinList.length > 0) {
        this.$axios.post('https://ad.yaelongtime.com/api/proxy/product/metadata',
            {
              "asins": asinList,
              "checkItemDetails": "true",
              "pageSize": 300,
              "locale": "en_US"
            },
            {
              headers: {
                'Content-Type': 'application/vnd.productmetadataresponse.v1+json'
              },
            }
        ).then(res => {
          let tableProductData = []
          if (res.data.ProductMetadataList.length > 0) {
            res.data.ProductMetadataList.forEach(function (product, index) {
              let tableProduct = {
                storePageName: store.storePageName,
                image: product.imageUrl,
                sku: product.sku,
                product_name: product.title,
                positioning: 'ASIN' + ':' + product.asin,
                recommendedAsin: product.asin,
                disabled: false,
                asin: product.asin,
                id: (index + 1).toString(),
                added: false,
              }
              tableProductData.push(tableProduct)
            })
            this.tablePageProductData = tableProductData
            this.thirdProductsList = this.tablePageProductData.slice(0, 1)
            this.thirdProductsList.forEach(function (product) {
              product.added = true
            })
          }
          // else {
          //    this.sbStoreProduct.push(res.data.ProductMetadataList)
          // }
        }).catch(error => {
          this.$message({
            message: error,
            type: 'error'
          })
        })
      }
    },
    //获取对应页面的asin
    selectedStorePageClick() {
      this.$axios.get('https://ad.yaelongtime.com/api/proxy/pageAsins', {
        params: {
          pageUrl: this.selectedStorePageUrlValue
        }
      }).then(res => {
        if (res.data.asinList.length > 3) {
          this.productDetails(res.data.asinList)
        }
      })
    },
    //获取产品的详细信息
    productDetails(asinList) {
      if (asinList.length > 3) {
        this.$axios.post('https://ad.yaelongtime.com/api/proxy/product/metadata',
            {
              "asins": asinList.slice(0, 300),
              "checkItemDetails": "true",
              "pageSize": 300,
              "locale": "en_US"
            },
            {
              headers: {
                'Content-Type': 'application/vnd.productmetadataresponse.v1+json'
              },
            }
        ).then(res => {
          let tableProductData = []
          if (res.data.ProductMetadataList.length > 3) {
            res.data.ProductMetadataList.forEach(function (product, index) {
              let tableProduct = {
                image: product.imageUrl,
                sku: product.sku,
                product_name: product.title,
                positioning: 'ASIN' + ':' + product.asin,
                recommendedAsin: product.asin,
                disabled: false,
                asin: product.asin,
                id: (index + 1).toString(),
                added: false,
              }
              tableProductData.push(tableProduct)
            })
            this.tableProductData = tableProductData
            this.loading = false
            this.videoProductsList = this.tableProductData.slice(0, 1)
            this.videoProductsList.forEach(function (product) {
              product.added = true
            })
            this.tabFirstProductsList = this.tableProductData.slice(0, 1)
            this.tabFirstProductsList.forEach(function (product) {
              product.added = true
            })
            this.tabSecondProductsList = this.tableProductData.slice(1, 2)
            this.tabSecondProductsList.forEach(function (product) {
              product.added = true
            })
            this.tabThirdProductsList = this.tableProductData.slice(2, 3)
            this.tabThirdProductsList.forEach(function (product) {
              product.added = true
            })
            this.ProductsList = [this.tabFirstProductsList[0], this.tabSecondProductsList[0], this.tabThirdProductsList[0]]
          } else {
            this.sbStoreProduct.push(res.data.ProductMetadataList)
            // this.$message({
            //   message: '页面ASIN不符合要求',
            //   type: 'error'
            // })
            // this.loading = false
          }
        }).catch(error => {
          this.$message({
            message: error,
            type: 'error'
          })
        })
      } else {
        this.$message({
          message: '页面ASIN不符合要求',
          type: 'error'
        })
      }
    },
    dialogFormVisibleClick() {
      this.dialogLogoFormVisible = false
    },
    handleSelection() {
    },
    handleSortChange(column) {
      console.log(55555, column)
    },
    //添加策略
    addToTarget(row) {
      this.sbDialogDict = row
      this.brandList = row
      const handleBrandId = () => {
        this.handleBrandId()
      };
      let selectionInfo = this.selectionInfo
      let asinList = []
      this.selectionProduct.forEach(item => {
        asinList.push(item.asin)
      });
      if (row.advertising_strategy.substring(0, 2) === 'SP') {
        if (row.advertising_strategy === 'SPA1' || row.advertising_strategy === 'SPA2' || row.advertising_strategy === 'SPA3' || row.advertising_strategy === 'SPA4') {
          if (row.advertising_strategy === 'SPA1') {
            let autoSPA1Data = []
            const closeAutoData = {
              typeInfo: '紧密匹配',
              types: true
            }
            autoSPA1Data.push(closeAutoData)
            row.autoData = autoSPA1Data
          } else if (row.advertising_strategy === 'SPA2') {
            let autoSPA2Data = []
            const looseAutoData = {
              typeInfo: '宽泛匹配',
              types: true
            }
            autoSPA2Data.push(looseAutoData)
            row.autoData = autoSPA2Data
          } else if (row.advertising_strategy === 'SPA3') {
            let autoSPA3Data = []
            const substitutesAutoDate = {
              typeInfo: '同类商品',
              types: true
            }
            autoSPA3Data.push(substitutesAutoDate)
            row.autoData = autoSPA3Data
          } else if (row.advertising_strategy === 'SPA4') {
            let autoSPA4Data = []
            const complementsAutoDate = {
              typeInfo: '关联商品',
              types: true
            }
            autoSPA4Data.push(complementsAutoDate)
            row.autoData = autoSPA4Data
          }
          row.matching_method = '自动'
          row.matching_method_type = 'AUTO'
          this.tableRecommendData.push(row);
          // 禁用按钮
          row.disabled = true;
        } else if (row.advertising_strategy.includes('SPW1')) {
          let keywordSPW1KWList = []
          let keywordSPW1LWList = []
          let keywordSPW1SWList = []
          if (row.advertising_strategy.includes('KW')) {
            this.selectionInfo.tableKeywordData.forEach(function (keyword) {
              if (keyword.matchType === 'BROAD') {
                if (keyword.selectedKeywordType === 'KW') {
                  keywordSPW1KWList.push(keyword)
                }
              }
            })
          } else if (row.advertising_strategy.includes('LW')) {
            this.selectionInfo.tableKeywordData.forEach(function (keyword) {
              if (keyword.matchType === 'BROAD') {
                if (keyword.selectedKeywordType === 'LW') {
                  keywordSPW1LWList.push(keyword)
                }
              }
            })
          } else if (row.advertising_strategy.includes('SW')) {
            this.selectionInfo.tableKeywordData.forEach(function (keyword) {
              if (keyword.matchType === 'BROAD') {
                if (keyword.selectedKeywordType === 'SW') {
                  keywordSPW1SWList.push(keyword)
                }
              }
            })
          }
          if (keywordSPW1LWList.length !== 0) {
            row.keyword = keywordSPW1LWList
          } else if (keywordSPW1SWList.length !== 0) {
            row.keyword = keywordSPW1SWList
          } else if (keywordSPW1KWList.length !== 0) {
            row.keyword = keywordSPW1KWList
          }
          row.matching_method = '关键词'
          row.matching_method_type = 'MANUAL'
          row.disabled = true;
          this.tableRecommendData.push(row);
        } else if (row.advertising_strategy.includes('SPW2')) {
          let keywordSPW2KWList = []
          let keywordSPW2LWList = []
          let keywordSPW2SWList = []
          if (row.advertising_strategy.includes('KW')) {
            this.selectionInfo.tableKeywordData.forEach(function (keyword) {
              if (keyword.matchType === 'PHRASE') {
                if (keyword.selectedKeywordType === 'KW') {
                  keywordSPW2KWList.push(keyword)
                }
              }
            })
          } else if (row.advertising_strategy.includes('LW')) {
            this.selectionInfo.tableKeywordData.forEach(function (keyword) {
              if (keyword.matchType === 'PHRASE') {
                if (keyword.selectedKeywordType === 'LW') {
                  keywordSPW2LWList.push(keyword)
                }
              }
            })
          } else if (row.advertising_strategy.includes('SW')) {
            this.selectionInfo.tableKeywordData.forEach(function (keyword) {
              if (keyword.matchType === 'PHRASE') {
                if (keyword.selectedKeywordType === 'SW') {
                  keywordSPW2SWList.push(keyword)
                }
              }
            })
          }
          if (keywordSPW2LWList.length !== 0) {
            row.keyword = keywordSPW2LWList
          } else if (keywordSPW2SWList.length !== 0) {
            row.keyword = keywordSPW2SWList
          } else if (keywordSPW2KWList.length !== 0) {
            row.keyword = keywordSPW2KWList
          }
          row.matching_method = '关键词'
          row.matching_method_type = 'MANUAL'
          row.disabled = true;
          this.tableRecommendData.push(row);
        } else if (row.advertising_strategy.includes('SPW3')) {
          let keywordSPW3KWList = []
          let keywordSPW3LWList = []
          let keywordSPW3SWList = []
          if (row.advertising_strategy.includes('KW')) {
            this.selectionInfo.tableKeywordData.forEach(function (keyword) {
              if (keyword.matchType === 'EXACT') {
                if (keyword.selectedKeywordType === 'KW') {
                  keywordSPW3KWList.push(keyword)
                }
              }
            })
          } else if (row.advertising_strategy.includes('LW')) {
            this.selectionInfo.tableKeywordData.forEach(function (keyword) {
              if (keyword.matchType === 'EXACT') {
                if (keyword.selectedKeywordType === 'LW') {
                  keywordSPW3LWList.push(keyword)
                }
              }
            })
          } else if (row.advertising_strategy.includes('SW')) {
            this.selectionInfo.tableKeywordData.forEach(function (keyword) {
              if (keyword.matchType === 'EXACT') {
                if (keyword.selectedKeywordType === 'SW') {
                  keywordSPW3SWList.push(keyword)
                }
              }
            })
          }
          if (keywordSPW3LWList.length !== 0) {
            row.keyword = keywordSPW3LWList
          } else if (keywordSPW3SWList.length !== 0) {
            row.keyword = keywordSPW3SWList
          } else if (keywordSPW3KWList.length !== 0) {
            row.keyword = keywordSPW3KWList
          }
          row.matching_method = '关键词'
          row.matching_method_type = 'MANUAL'
          row.disabled = true;
          this.tableRecommendData.push(row);
        } else if (row.advertising_strategy.includes('SPP-C')) {
          row.matching_method = '商品投放 — 类目定位'
          row.matching_method_type = 'MANUAL'
          row.keyword = selectionInfo.tableCategoriesData
          row.keywordLength = selectionInfo.tableCategoriesData.length
          row.disabled = true;
          this.tableRecommendData.push(row);
        } else if (row.advertising_strategy === 'SPP-A1') {
          let asinType1List = []
          selectionInfo.tableRecommendationAsinData.forEach(function (asin) {
            if (asin.selectedAsinType === '精准') {
              let asinType1 = {
                positioning: asin.positioning,
                recommendedAsin: asin.recommendedAsin,
                selectedAsinType: asin.selectedAsinType,
                disabled: asin.disabled
              }
              asinType1List.push(asinType1)
            }
          })
          if (asinType1List.length !== 0) {
            row.keyword = asinType1List
            row.keywordLength = asinType1List.length
          }
          row.matching_method = '商品投放 — ASIN定位'
          row.matching_method_type = 'MANUAL'
          row.disabled = true;
          this.tableRecommendData.push(row);
        } else if (row.advertising_strategy === 'SPP-A2') {
          let asinType2List = []
          selectionInfo.tableRecommendationAsinData.forEach(function (asin) {
            if (asin.selectedAsinType === '扩展') {
              let asinType2 = {
                positioning: asin.positioning,
                recommendedAsin: asin.recommendedAsin,
                selectedAsinType: asin.selectedAsinType,
                disabled: asin.disabled
              }
              asinType2List.push(asinType2)
            }
          })
          if (asinType2List.length !== 0) {
            row.keyword = asinType2List
            row.keywordLength = asinType2List.length
          }
          row.matching_method = '商品投放 — ASIN定位'
          row.matching_method_type = 'MANUAL'
          row.disabled = true;
          this.tableRecommendData.push(row);
        }
      } else if (row.advertising_strategy.substring(0, 2) === 'SB') {
        handleBrandId()
        if (row.advertising_strategy && row.advertising_strategy.substring(0, 3).includes("I")) { // 将选中行数据添加到目标表格
          if (row.advertising_strategy.includes('SBI-P')) {
            this.radio = '2'
            this.loading = false
            let productList = []
            this.selectionProduct.forEach(function (product) {
              productList.push(product.asin)
            })
            if (productList.length < 3) {
              Message({
                message: '至少3件商品',
                type: 'error',
              });
              this.dialogImageFormVisible = false
            } else {
              this.dialogImageFormVisible = true
            }
          } else if (row.advertising_strategy.includes('SBI-SP')) {
            this.radio = '1'
            this.dialogImageFormVisible = true
          } else if (row.advertising_strategy.includes('SBI-S')) {
            this.radio = '1'
            this.dialogStoresFormVisible = true
          }
        } else if (row.advertising_strategy.substring(0, 3).includes("V")) {
          row.adType = '视频'
          if (row.advertising_strategy.includes('SBV-P')) {
            let videoProductsList = this.videoProductsList
            let radio = this.radio
            let dialogStoreVideoFormVisible = this.dialogStoreVideoFormVisible
            this.selectionProduct.forEach(function (product) {
              if (product.product_state === 2) {
                if (asinList.length > 1) {
                  Message({
                    message: '产品数量超范围',
                    type: 'error'
                  })
                  dialogStoreVideoFormVisible = false
                } else {
                  videoProductsList = []
                  radio = '2'
                  this.loading = false
                  dialogStoreVideoFormVisible = true
                }
              } else {
                videoProductsList = []
                radio = '2'
                dialogStoreVideoFormVisible = true
              }
            })
            this.dialogStoreVideoFormVisible = dialogStoreVideoFormVisible
          } else if (row.advertising_strategy.includes('SBV-SP')) {
            this.radio = '1'
            this.dialogVideoFormVisible = true
          }
        }
      } else if (row.advertising_strategy.substring(0, 2) === 'SD') {
        // this.$axios.post('https://ad.yaelongtime.com/api/proxy/assets/search', {
        //       "filterCriteria": {
        //         "valueFilters": [
        //           {
        //             "values": ["LOGO"],
        //             "valueField": "ASSET_TYPE"
        //           }
        //         ]
        //       }
        //     },
        //     {
        //       headers: {
        //         'Content-Type': 'text/plain'
        //       }
        //     }
        // ).then(res => {
        //   let urlLict = []
        //   let brandName = ''
        //   if (res.data.assetList.length > 0) {
        //     res.data.assetList.forEach(function (logo) {
        //       if (logo.assetSubTypeList[0] === "LOGO" && logo.fileMetadata.height >= 400 && logo.fileMetadata.width >= 400) {
        //         let urlDict = {
        //           url: logo.storageLocationUrls.processedUrls.IMAGE_THUMBNAIL_500,
        //           assetId: logo.assetId,
        //           isSelected: false,
        //           name: brandName
        //         }
        //         urlLict.push(urlDict)
        //       }
        //     })
        //   } else {
        //     urlLict = []
        //   }
        //   this.brandUrlList = urlLict
        // }).catch(error => {
        //   Message({
        //     message: error,
        //     type: 'error',
        //   });
        // })
        if (selectionInfo.tableAudienceData.length > 0) {
          if (row.advertising_strategy.includes('AA1')) {
            const LifestyleList = []
            selectionInfo.tableAudienceData.forEach(function (audience) {
              if (audience.category === 'Lifestyle') {
                LifestyleList.push(audience)
              }
            })
            row.keyword = LifestyleList
            row.keywordLength = LifestyleList.length
          } else if (row.advertising_strategy.includes('AA2')) {
            const InterestList = []
            selectionInfo.tableAudienceData.forEach(function (audience) {
              if (audience.category === 'Interest') {
                InterestList.push(audience)
              }
            })
            row.keyword = InterestList
            row.keywordLength = InterestList.length
          } else if (row.advertising_strategy.includes('AA3')) {
            const LifeEventList = []
            selectionInfo.tableAudienceData.forEach(function (audience) {
              if (audience.category === 'Life event') {
                LifeEventList.push(audience)
              }
            })
            row.keyword = LifeEventList
            row.keywordLength = LifeEventList.length
          } else if (row.advertising_strategy.includes('AA4')) {
            const InMarketList = []
            selectionInfo.tableAudienceData.forEach(function (audience) {
              if (audience.category === 'In-market') {
                InMarketList.push(audience)
              }
            })
            row.keyword = InMarketList
            row.keywordLength = InMarketList.length
          }
        } else if (selectionInfo.tableCategoriesData.length > 0) {
          if (row.advertising_strategy.includes('SDI-C') || row.advertising_strategy.includes('SDV-C')) {
            row.matching_method = '品类投放 — 类目'
            row.matching_method_type = 'manual'
            row.keyword = selectionInfo.tableCategoriesData
            row.keywordLength = selectionInfo.tableCategoriesData.length
          }
        } else if (selectionInfo.tableRecommendationAsinData.length > 0) {
          if (row.advertising_strategy.includes('SDI-P-') || row.advertising_strategy.includes('SDV-P-')) {
            row.matching_method = '品类投放 — ASIN'
            row.matching_method_type = 'manual'
            row.keyword = selectionInfo.tableRecommendationAsinData
            row.keywordLength = selectionInfo.tableRecommendationAsinData.length
          }
        }
        if (row.advertising_strategy.substring(0, 3).includes("I")) {
          if (row.advertising_strategy.includes('SDI-P-') || row.advertising_strategy.includes('SDI-C')) {
            this.dialogSdImageFormVisible = true
          } else if (row.advertising_strategy.includes('SDI-AA')) {
            row.matching_method = '受众投放 — 亚马逊消费者'
            row.matching_method_type = 'manual'
            this.dialogSdImageAaFormVisible = true
          } else if (row.advertising_strategy.includes('SDI-VR')) {
            row.typeText = '购买再营销'
            this.dialogSdImageVrFormVisible = true
          } else if (row.advertising_strategy.includes('SDI-PR')) {
            row.typeText = '购买再营销'
            this.dialogSdImagePrFormVisible = true
          }
        } else if (row.advertising_strategy.substring(0, 3).includes("V")) {
          if (row.advertising_strategy.includes('SDV-P-') || row.advertising_strategy.includes('SDV-C')) {
            this.dialogSdVideoFormVisible = true
          } else if (row.advertising_strategy.includes('SDV-AA')) {
            this.dialogSdVideoAaFormVisible = true
          } else if (row.advertising_strategy.includes('SDV-VR')) {
            row.typeText = '购买再营销'
            this.dialogSdVideoVrFormVisible = true
          } else if (row.advertising_strategy.includes('SDV-PR')) {
            row.typeText = '购买再营销'
            this.dialogSdVideoPrFormVisible = true
          }
        }
      }
    },
    //获取品牌ID和徽标
    handleBrandId() {
      const handleStoresName = () => {
        this.handleStoresName()
      };
      this.$axios.get('https://ad.yaelongtime.com/api/proxy/brands', {},
          {
            headers: {
              'Content-Type': 'application/vnd.sbtargeting.v4+json'
            }
          }
      ).then(res => {
        let brandIdList = []
        if (res.data) {
          res.data.forEach(function (brand) {
            const brandIdDict = {
              brandName: brand.brandRegistryName,
              id: brand.brandEntityId
            }
            brandIdList.push(brandIdDict)
          })
          this.brandIdList = brandIdList
          if (this.radio === '1') {
            handleStoresName()
          }
        } else {
          this.brandIdList = []
        }
      })
    },
    //清除选择的策略
    deleteRow(index) {
      const deletedRow = this.tableRecommendData[index];
      this.tableRecommendData.splice(index, 1);
      if (this.tableSpData.find(item => item === deletedRow)) {
        const sourceRow = this.tableSpData.find(item => item === deletedRow);
        if (sourceRow) {
          sourceRow.disabled = false;
        }
      } else if (this.tableSbData.find(item => item === deletedRow)) {
        const sourceRow = this.tableSbData.find(item => item === deletedRow);
        if (sourceRow) {
          sourceRow.disabled = false;
        }
      } else {
        const sourceRow = this.tableSdData.find(item => item === deletedRow);
        if (sourceRow) {
          sourceRow.disabled = false;
        }
      }
    },
    handleSelect(key, keyPath) {
      this.activeIndex = key
    },
    goPage(url_path) {
      // 已经是当前路由就没有必要重新跳转
      if (this.url_path !== url_path) {
        // 传入的参数，如果不等于当前路径，就跳转
        this.$router.push(url_path)
      }
      sessionStorage.url_path = url_path;
    },
    handleNextClick() {
      let strategy = this.tableRecommendData
      let shouldRedirect = true;
      let tableInfoData = [this.tableSpData, this.tableSbData, this.tableSdData]
      this.selectionProduct.forEach(function (product) {
        if (strategy.length < 1) {
          Message({
            message: '缺少策略',
            type: 'error',
          });
          shouldRedirect = false;
        } else if (product.product_state === 1) {
          if (strategy.length > 1) {
            let spStrategyList = []
            strategy.forEach(function (adStrategy) {
              if (adStrategy.advertising_strategy.substring(0, 2).includes('SP')) {
                spStrategyList.push(adStrategy)
                if (spStrategyList.length > 1) {
                  Message({
                    message: '策略数量超范围',
                    type: 'error',
                  });
                  shouldRedirect = false;
                }
              } else if (adStrategy.advertising_strategy.substring(0, 2).includes('SB')) {
                product.strategy = strategy
              } else if (adStrategy.advertising_strategy.substring(0, 2).includes('SD')) {
                product.strategy = strategy
              }
            })
          } else {
            if (strategy[0].autoData) {
              strategy[0].keywordLength = strategy[0].autoData.length
            } else {
              strategy[0].keywordLength = strategy[0].keyword.length
            }
            strategy[0].product_state = 1
            product.strategy = strategy
          }
        }

      })
      if (!shouldRedirect) {
        return false; // 阻止默认的页面跳转行为
      } else {
        this.$router.push({
          path: '/ad-composition', query: {
            selectionProductData: this.selectionProduct,
            selectionProductStrategyData: this.tableRecommendData,
            tableInfoData: tableInfoData,
            upPath: this.$route.path,
            selectionInfo: this.selectionInfo,
          }
        });
      }
    },
    handleUpClick() {
      this.$router.push({
        path: '/ad-structure', query: {
          selected_product_information: this.selectionProduct,
          selected_product_info: this.selectionInfo,
          upPath: this.$route.path
        }
      });
    },
  },
  computed: {
    //筛选页面对应的产品
    filterTablePageProductData() {
      if (!this.search_page_product) {
        return this.tablePageProductData
      }
      return this.tablePageProductData.filter(data => {
        return data.asin.includes(this.search_page_product);
        // 根据搜索输入框的值进行筛选，这里使用 includes() 方法进行简单的包含关系筛选
      });
    },
    //筛选页面
    filterStorePageList() {
      if (!this.search_page) {
        return this.storePageList
      }
      return this.storePageList.filter(data => {
        return data.storePageName.includes(this.search_page);
        // 根据搜索输入框的值进行筛选，这里使用 includes() 方法进行简单的包含关系筛选
      });
    },
    //筛选产品信息
    filteredTableProductData() {
      if (!this.search_product) {
        return this.tableProductData; // 如果搜索输入框无值，返回原始数据
      }
      // 使用 Array.filter() 方法筛选数据
      return this.tableProductData.filter(data => {
        return data.asin.includes(this.search_product) || data.product_name.includes(this.search_product);
        // 根据搜索输入框的值进行筛选，这里使用 includes() 方法进行简单的包含关系筛选
      });
    },
    //切换品牌LOGO
    currentLogoUrl() {
      if (this.brandUrlList[this.currentLogoIndex]) {
        return this.brandUrlList[this.currentLogoIndex].url;
      }
    },
    //切换SD品牌LOGO
    currentSdLogoUrl() {
      if (this.brandSdUrlList[this.currentLogoIndex]) {
        return this.brandSdUrlList[this.currentLogoIndex].url;
      }
    },
    filteredTableSpData() {
      if (!this.search_sp) {
        return this.tableSpData; // 如果搜索输入框无值，返回原始数据
      }
      // 使用 Array.filter() 方法筛选数据
      return this.tableSpData.filter(data => {
        return data.advertising_strategy.includes(this.search_sp);
        // 根据搜索输入框的值进行筛选，这里使用 includes() 方法进行简单的包含关系筛选
      });
    },
    filteredTableSbData() {
      if (!this.search_sb) {
        return this.tableSbData; // 如果搜索输入框无值，返回原始数据
      }
      // 使用 Array.filter() 方法筛选数据
      return this.tableSbData.filter(data => {
        return data.advertising_strategy.includes(this.search_sb);
        // 根据搜索输入框的值进行筛选，这里使用 includes() 方法进行简单的包含关系筛选
      });
    },
    filteredTableSdData() {
      if (!this.search_sd) {
        return this.tableSdData; // 如果搜索输入框无值，返回原始数据
      }
      // 使用 Array.filter() 方法筛选数据
      return this.tableSdData.filter(data => {
        return data.advertising_strategy.includes(this.search_sd);
        // 根据搜索输入框的值进行筛选，这里使用 includes() 方法进行简单的包含关系筛选
      });
    },
    styles() {
      // 根据基准字号和行高计算字体大小和行距
      const fontSize = `${this.baseFontSize}px`;
      const lineHeight = `${this.baseLineHeight * this.baseFontSize}px`;

      // 响应式布局
      return {
        'font-size': fontSize,
        'line-height': lineHeight,
        'padding-top': '-10px',
        'padding-left': '-10px',
        'padding-right': '1px',
      };
    },
  },
  created() {
    if (this.$route.query.upPath === '/ad-composition') {
      this.tableRecommendData = this.$route.query.selectionProductStrategyData
      this.selectionProduct = this.$route.query.selectionProductData
      this.tableSpData = this.$route.query.tableInfoData[0]
      this.tableSbData = this.$route.query.tableInfoData[1]
      this.tableSdData = this.$route.query.tableInfoData[2]
      this.selectionInfo = this.$route.query.selectionInfo
      this.tableAudienceData = this.$route.query.tableAudienceData
    } else if (this.$route.query.upPath === '/ad-structure') {
      this.selectionProduct = this.$route.query.selectionProductData
      this.selectionInfo = this.$route.query.selectionData
      this.tableAudienceData = this.$route.query.selectionData.tableAudienceData
    } else if (this.$route.path === 'ad-strategy') {
      this.selectionInfo = this.$route.query.selectionProductStrategyData
    }
  },
  beforeRouteLeave(to, from, next) {
    // 当前路由即将离开
    if (to.path === '/ad-structure' && from.path === '/ad-strategy') {
      // 确认离开，继续导航到目标路由
      next();
    } else {
      // 其他情况，继续导航
      next();
    }
  }
  ,
}
</script>

<style scoped>
.product-container {
  display: flex;
  flex-wrap: wrap;
}

.product-item {
  display: flex;
  align-items: center;
  margin: 20px;
}

.product-item img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}

.product-info {
  display: flex;
  flex-direction: column;
}

.footer ul {
  margin: 10px auto 16px;
  padding-top: 20px;
  width: 810px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
}

.footer ul li {
  float: left;
  width: 100%;
  margin: 10px;
  text-align: center;
  font-size: 14px;
  display: inline-block;
}


.footer p {
  text-align: center;
  font-size: 10px;
  padding-bottom: 3px;
}

.footer ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style: none;
  padding: 0;
}

.footer li {
  margin-right: 20px;
}

.sku,
.asin {
  margin: 10px 10px 1px 10px;
}

.demo-image__placeholder {
  /* 设置外部容器的样式，可以根据需要调整 */
}

.block {
  display: flex;
  justify-content: space-around; /* 让子元素在水平方向上平均分布 */
  align-items: center; /* 垂直居中对齐 */
}

.item {
  text-align: center; /* 文本居中显示 */
  margin-top: 20px; /* 文本与图片的间距，可以根据需要调整 */
}

.demonstration {
  /* 设置标题样式，可以根据需要调整 */
}

.el-image {
  width: 250px; /* 图片宽度，可以根据需要调整 */
  height: 200px; /* 图片高度，可以根据需要调整 */
}

.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-item {
  overflow: hidden;
  cursor: pointer;
  transition: border 0.3s;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-item.selected {
  border: 2px solid blue;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.product-item {
  display: flex;
  margin: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.product-item img {
  max-width: 100%;
  height: auto;
}

.product-info {
  text-align: left;
  margin-top: 10px;
}

.product-info h3 {
  margin: 0;
}

.product-info p {
  font-weight: bold;
}

.required-field::before {
  content: '*';
  color: red;
  margin-right: 4px; /* 可以调整星号与文本之间的距离 */
}

.panel-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* 调整间距 */
}

.panel-header {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ebebeb;
  border-radius: 4px;
  margin-bottom: 5px; /* 调整间距 */
}

.panel-title {
  margin-left: 10px; /* 调整开关和标题之间的间距 */
}
</style>