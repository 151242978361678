<template>
<div class="not-found">
    <h1>未授权亚马逊店铺信息</h1>
    <p>Oops! The page you are looking for might be under construction or does not exist.</p>
   <p>请联系IT</p>
  </div>
</template>

<script>

export default {
  name: "AdErrorView",

}
</script>

<style scoped>
.not-found {
  text-align: center;
  padding: 20px;
  margin-top: 50px;
}

h1 {
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

p {
  font-size: 1.2em;
  color: #666;
}

</style>