import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
// 1 axios的配置 ,这个配置完，以后再任意组件中直接使用  this.$axios
import axios from 'axios'
Vue.prototype.$axios=axios
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = '/api/'
// 2 elementui的配置
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 3 vue-cookies的使用
import cookies from 'vue-cookies'
Vue.prototype.$cookies=cookies


// 4 bootstrap配置
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'


//5 去掉所有标签默认样式
import '@/assets/css/global.css'

// 6 全局配置
import settings from "@/assets/js/settings";
Vue.prototype.$settings=settings
//7 表格设置
import XLSX from 'xlsx'
Vue.prototype.$xlsx=XLSX


Vue.config.productionTip = false

// 注册 Vue Devtools 扩展程序
if (process.env.NODE_ENV === 'development') {
  Vue.config.devtools = true
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
