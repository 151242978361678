<template>
  <div :style="styles">
    <el-col :span="24">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">广告结构</el-breadcrumb-item>
        <el-breadcrumb-item>基础数据</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider></el-divider>
    </el-col>
    <el-col :span="24" style="padding: 10px 50px">
      <el-row>
        <div>已选商品</div>
        <div class="product-container">
          <div v-for="(product, index) in selectionProduct" :key="index">
            <img :src="product.image" alt="商品图片">
            <p class="sku">SKU: {{ product.sku }}</p>
            <p class="asin">ASIN: {{ product.asin }}</p>
          </div>
        </div>
      </el-row>
    </el-col>
    <el-col :span="24" style="padding: 10px 50px">
      <el-tabs type="border-card">
        <el-tabs type="card">
          <el-tab-pane label="关键词">
            <el-tabs v-model="activeKeyName" type="card">
              <el-radio-group v-model="radio" @change="handleRadioChange">
                <el-radio :label="1">宽泛匹配</el-radio>
                <el-radio :label="2">词组匹配</el-radio>
                <el-radio :label="3">精准匹配</el-radio>
              </el-radio-group>
              <el-tab-pane label="推荐关键词" name="first">
                <el-input
                    prefix-icon="el-icon-search"
                    placeholder="按关键词搜索"
                    v-model="search_keywords"
                    clearable
                    style="max-width: 600px;"
                >
                </el-input>
                <el-table
                    :data="filterTableRecommendedKeywordsData"
                    style="width: 100%"
                    max-height="500"
                    v-loading="loadingKeywords"
                >
                  <el-table-column
                      prop="keyword"
                      label="推荐"
                      width="300">
                  </el-table-column>
                  <el-table-column
                      prop="typeInfo"
                      label="匹配方式"
                      width="300">
                  </el-table-column>
                  <el-table-column
                      label="操作"
                      width="120">
                    <template slot-scope="scope">
                      <el-button
                          :disabled="scope.row.disabled"
                          @click="addToTarget(scope.row)"
                          type="text"
                          size="small"
                      >
                        {{ scope.row.disabled ? '已添加' : '添加' }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="手动输入" name="second">
                <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入或粘贴需要投放的关键词,一行一个,最多1000个"
                    v-model="keywordInput">
                </el-input>
                <el-col :span="24" style="padding-top: 10px">
                  <el-button style="display: flex" @click="AddKeywordsManually">添加关键词</el-button>
                </el-col>
              </el-tab-pane>
            </el-tabs>
            <el-table
                :data="tableKeywordData"
                style="width: 100%"
                max-height="250">
              <el-table-column
                  fixed
                  prop="keyword"
                  label="关键词"
                  width="300">
              </el-table-column>
              <el-table-column
                  prop="typeInfo"
                  label="匹配方式"
                  width="300">
              </el-table-column>
              <el-table-column
                  label="类型"
                  width="120">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.selectedKeywordType" placeholder="KW">
                    <el-option
                        v-for="option in typeOptions"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                  label="操作"
                  width="120">
                <template slot-scope="scope">
                  <el-button
                      @click.native.prevent="deleteRow(scope.$index, tableKeywordData)"
                      type="text"
                      size="small">
                    移除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="商品投放-类目定位">
            <el-tabs v-model="activeCategoriesName" type="card" @tab-click="handleTargetsCategoriesClick">
              <el-tab-pane label="建议类目" name="first">
                <el-input
                    prefix-icon="el-icon-search"
                    placeholder="按品类名称搜索"
                    v-model="search_category"
                    clearable
                    style="max-width: 600px;"
                >
                </el-input>
                <el-table
                    :data="filterTableTargetsCategoriesData"
                    border
                    style="width: 100%"
                    max-height="500"
                    v-loading="loadingCategories"
                >
                  <el-table-column
                      prop="na"
                      label="建议品类"
                      width="270"
                  >
                  </el-table-column>
                  <el-table-column
                      label="操作"
                      width="100">
                    <template slot-scope="scope">
                      <el-button
                          :disabled="scope.row.disabled"
                          @click="addToCategories(scope.row)"
                          type="text"
                          size="small">
                        {{ scope.row.disabled ? '已添加' : '添加' }}
                      </el-button>
                      <el-button type="text" size="small" @click="handleCategoryRefinementClick(scope.row)">细化
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="全部类目" name="second" @click="handleAllCategoryClick">
                <div class="search-container">
                  <el-input
                      prefix-icon="el-icon-search"
                      placeholder="按品类搜索"
                      v-model="search_allCategory"
                      @clear="refreshAllCategory"
                      clearable
                      class="search-input"
                      style="max-width: 600px;"
                  >
                  </el-input>
                  <el-button type="primary" plain @click="searchAllCategory">查询</el-button>
                </div>

                <el-table
                    :data="tableAllCategoriesData"
                    border
                    style="width: 100%"
                    max-height="500"
                    v-loading="loadingAllCategories"
                >
                  <el-table-column
                      prop="na"
                      label="全部分类"
                      width="500">
                  </el-table-column>
                  <el-table-column
                      label="操作"
                      width="100">
                    <template slot-scope="scope">
                      <el-button
                          @click="handleAllCategoriesClick(scope.row)"
                          type="text"
                          size="small"
                          :disabled="scope.row.disabled"
                      > {{ scope.row.disabled ? '已添加' : '添加' }}
                      </el-button>
                      <el-button type="text" size="small" @click="handleCategoryRefinementClick(scope.row)">细化
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-pagination
                    class="pagination-wrapper"
                    @size-change="handleSizeChange"
                    @current-change="handleAllCategoryClick"
                    :current-page="parseInt(pagination.page)"
                    :page-sizes="pagination.pageSizeOption"
                    :page-size="parseInt(pagination.page_size)"
                    background
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagination.total_records"
                >
                </el-pagination>
              </el-tab-pane>
              <el-tab-pane label="动态细化" name="third">
                <el-table
                    v-loading="loadingAsin"
                    :data="RecommendationData"
                    border
                    style="width: 100%"
                    max-height="500"
                >
                  <el-table-column
                      prop="na"
                      label="动态细分"
                      width="300">
                  </el-table-column>
                  <el-table-column
                      label="操作"
                      width="100">
                    <template slot-scope="scope">
                      <el-button
                          @click="handleRecommendationClick(scope.row)"
                          type="text"
                          size="small"
                          :disabled="scope.row.disabled"
                      >{{ scope.row.disabled ? '已添加' : '添加' }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
            </el-tabs>
            <el-table
                :data="tableCategoriesData"
                style="width: 100%"
                max-height="250">
              <el-table-column
                  fixed
                  prop="na"
                  label="分类"
                  width="300">
              </el-table-column>
              <el-table-column
                  prop="positioning"
                  label="定位"
                  width="300">
              </el-table-column>
              <el-table-column
                  label="操作"
                  width="120">
                <template slot-scope="scope">
                  <el-button
                      @click.native.prevent="deleteCategoriesRow(scope.$index, tableCategoriesData)"
                      type="text"
                      size="small">
                    移除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="商品投放-ASIN定位">
            <el-tabs v-model="activeAsinName" type="card" @tab-click="handleAsinClick">
              <el-tab-pane label="商品" name="first">
                <el-input
                    prefix-icon="el-icon-search"
                    placeholder="按ASIN搜索"
                    v-model="search_asin"
                    clearable
                    style="max-width: 600px;"
                >
                </el-input>
                <el-table
                    v-loading="loadingAsin"
                    :data="filteredTableRecommendationData"
                    border
                    style="width: 100%"
                    max-height="500"
                >
                  <el-table-column
                      prop="image"
                      label="图片"
                      width="100">
                    <template slot-scope="scope">
                      <img :src="scope.row.image" alt="图片"/>
                    </template>
                  </el-table-column>
                  <el-table-column
                      prop="product_name"
                      label="标题"
                      width="500">
                  </el-table-column>
                  <el-table-column
                      prop="recommendedAsin"
                      label="ASIN"
                      width="200">
                  </el-table-column>
                  <el-table-column
                      prop="positioning"
                      label="定位"
                      width="200">
                  </el-table-column>
                  <el-table-column
                      label="操作"
                      width="100">
                    <template slot-scope="scope">
                      <el-button
                          @click="handleRecommendationAsinClick(scope.row)"
                          type="text"
                          size="small"
                          :disabled="scope.row.disabled"
                      >{{ scope.row.disabled ? '已添加' : '添加' }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="手动输入ASIN" name="second">
                <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="请输入ASIN,一行一个"
                    v-model="asinInput">
                </el-input>
                <el-col :span="24" style="padding-top: 10px">
                  <el-button style="display: flex" @click="addAsinManually">添加ASIN</el-button>
                </el-col>
              </el-tab-pane>
            </el-tabs>
            <el-table
                :data="tableRecommendationAsinData"
                style="width: 100%"
                max-height="250">
              <el-table-column
                  fixed
                  prop="recommendedAsin"
                  label="分类"
                  width="300">
              </el-table-column>
              <el-table-column
                  prop="positioning"
                  label="定位"
                  width="300">
              </el-table-column>
              <el-table-column
                  label="类型"
                  width="120">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.selectedAsinType" placeholder="精准">
                    <el-option
                        v-for="option in typeAsinOptions"
                        :key="option.value"
                        :label="option.label"
                        :value="option.value">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                  label="操作"
                  width="120">
                <template slot-scope="scope">
                  <el-button
                      @click.native.prevent="deleteRecommendedAsinRow(scope.$index, tableRecommendationAsinData)"
                      type="text"
                      size="small">
                    移除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="SD受众-亚马逊消费者">
            <el-tabs v-model="activeNameSd" @tab-click="handleSdAudienceClick">
              <div class="search-container">
                <el-input
                    prefix-icon="el-icon-search"
                    placeholder="按受众搜索"
                    v-model="search_audience"
                    clearable
                    class="search-input"
                    @clear="refreshSdAudienceClick"
                    style="max-width: 600px;"

                >
                </el-input>
                <el-button type="primary" plain @click="selectedSdAudienceClick">查询</el-button>
              </div>
              <el-tab-pane label="生活方式" name="first">
                <el-table
                    :data="tableLifeStyleData"
                    border
                    max-height="250"
                    style="width: 100%">
                  <el-table-column
                      fixed
                      prop="audienceName"
                      label="受众"
                      width="600">
                  </el-table-column>
                  <el-table-column
                      label="操作"
                      width="120">
                    <template slot-scope="scope">
                      <el-button
                          :disabled="scope.row.disabled"
                          @click="addCategory(scope.row)"
                          type="text"
                          size="small"
                      >
                        {{ scope.row.disabled ? '已添加' : '添加' }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="兴趣" name="second">
                <el-table
                    :data="tableInterestData"
                    border
                    max-height="250"
                    style="width: 100%">
                  <el-table-column
                      fixed
                      prop="audienceName"
                      label="受众"
                      width="600">
                  </el-table-column>
                  <el-table-column
                      label="操作"
                      width="120">
                    <template slot-scope="scope">
                      <el-button
                          :disabled="scope.row.disabled"
                          @click="addCategory(scope.row)"
                          type="text"
                          size="small"
                      >
                        {{ scope.row.disabled ? '已添加' : '添加' }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="生活事件" name="third">
                <el-table
                    :data="tableLifeEventData"
                    border
                    max-height="250"
                    style="width: 100%">
                  <el-table-column
                      fixed
                      prop="audienceName"
                      label="受众"
                      width="600">
                  </el-table-column>
                  <el-table-column
                      label="操作"
                      width="120">
                    <template slot-scope="scope">
                      <el-button
                          :disabled="scope.row.disabled"
                          @click="addCategory(scope.row)"
                          type="text"
                          size="small"
                      >
                        {{ scope.row.disabled ? '已添加' : '添加' }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-tab-pane label="场内客群" name="fourth">
                <el-table
                    :data="tableInMarketData"
                    border
                    max-height="250"
                    style="width: 100%">
                  <el-table-column
                      fixed
                      prop="audienceName"
                      label="受众"
                      width="600">
                  </el-table-column>
                  <el-table-column
                      label="操作"
                      width="120">
                    <template slot-scope="scope">
                      <el-button
                          :disabled="scope.row.disabled"
                          @click="addCategory(scope.row)"
                          type="text"
                          size="small"
                      >
                        {{ scope.row.disabled ? '已添加' : '添加' }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </el-tab-pane>
              <el-pagination
                  class="pagination-wrapper"
                  @size-change="handleSdSizeChange"
                  @current-change="handleSdAudienceClick"
                  :current-page="parseInt(paginations.page)"
                  :page-sizes="paginations.pageSizeOption"
                  :page-size="parseInt(paginations.page_size)"
                  background
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="paginations.total_records"
              >
              </el-pagination>
            </el-tabs>
            <el-table
                :data="tableAudienceData"
                style="width: 100%"
                max-height="500"
                @sort-change="handleSortChange"
            >
              <el-table-column
                  fixed
                  prop="category"
                  label="匹配方式"
                  width="200">
              </el-table-column>
              <el-table-column
                  fixed
                  prop="audienceName"
                  label="受众"
                  width="600">
              </el-table-column>
              <el-table-column
                  label="操作"
                  width="120">
                <template slot-scope="scope">
                  <el-button
                      @click.native.prevent="deleteCategoryRow(scope.$index, tableRecommendData)"
                      type="text"
                      size="small">
                    移除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </el-tabs>
      <el-dialog title="类目细化" :visible.sync="dialogCategoriesRefinementFormVisible" :close-on-click-modal="false">
        <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="类别">{{ category }}
          </el-form-item>
          <el-form-item label="品牌" prop="region">
            <el-select
                v-model="selectedCategoryRefinementValue"
                collapse-tags
                clearable
                filterable
                placeholder="请选择品牌"
            >
              <el-option
                  v-for="item in ad_category_refinement_options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品价格范围">
            <el-col :span="6">
              <el-input v-model="startPrice">-</el-input>
            </el-col>
            <el-col class="line" :span="1" style="padding-left: 15px">-</el-col>
            <el-col :span="6">
              <el-input v-model="endPrice"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="星级评定">
            <div class="block">
              <el-slider
                  v-model="value"
                  range
                  :max="max"
                  :min="min"
                  @change="handleVariationChange"
              >
              </el-slider>
            </div>
          </el-form-item>
          <el-form-item label="配送">
            <el-radio-group v-model="prime">
              <el-radio label="具备Prime资格"></el-radio>
              <el-radio label="不具备Prime资格"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogSbCategoriesRefinementFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogCategoriesForm">确定</el-button>
        </div>
      </el-dialog>
    </el-col>
    <el-col :span="24" style="padding: 10px 600px">
      <el-button @click="handleUpClick">上一步</el-button>
      <el-button type="primary" plain @click="handlePageJump">下一步</el-button>
    </el-col>
  </div>
</template>
<script>

export default {
  name: "AdStructure",
  data() {
    return {
      search_audience: '',
      tableAudienceData: [],
      tableInMarketData: [],
      tableLifeEventData: [],
      tableLifeStyleData: [],
      tableInterestData: [],
      activeNameSd: 'first',
      RecommendationData: [{
        na: '类似于推广的产品',
        disabled: false,
        positioning: 'SD品类投放'
      }],
      id: '',
      category: '',
      startPrice: '',
      endPrice: '',
      max: 5,
      value: ["0", "5"],
      min: 0,
      prime: '',
      selectedCategoryRefinementValue: '',
      ad_category_refinement_options: [],
      dialogCategoriesRefinementFormVisible: false,
      dialogSbCategoriesRefinementFormVisible: false,
      activeAsinName: 'first',
      selectedAsinType: '',
      asinInput: '',
      asinSbInput: '',
      radio: 1,
      radioSb: 1,
      keywordInput: '',
      keywordSbInput: '',
      keywordList: [],
      pagination: {
        page: 0,
        page_size: 20,
        total_records: 1,
        total_pages: 1,
        pageSizeOption: [20, 50, 100, 200]
      },
      paginations: {
        page: 0,
        page_size: 20,
        total_records: 1,
        total_pages: 1,
        pageSizeOption: [20, 50, 100, 200]
      },
      loadingAllCategories: true,
      loadingCategories: true,
      loadingKeywords: true,
      loadingAsin: true,
      search_allCategory: '',
      search_category: '',
      search_asin: '',
      tableRecommendationAsinData: [],
      tableCategoriesData: [],
      tableRecommendationData: [],
      tableAllCategoriesData: [],
      tableTargetsCategoriesData: [],
      tableKeywordData: [],
      tableAllKeywordDate: [],
      input: '',
      selectionProduct: [],
      src: '',
      tableCategoryData: [],
      tableProductData: [],
      tableRecommendData: [],
      activeAllCategoriesName: 'first',
      activeKeyName: 'first',
      activeCategoriesName: 'first',
      tableRecommendedKeywordsData: [],
      typeOptions: [
        {label: 'KW', value: 'KW'},
        {label: 'LW', value: 'LW'},
        {label: 'SW', value: 'SW'},
      ],
      typeAsinOptions: [
        {label: '精准', value: '精准'},
        {label: '扩展', value: '扩展'},
      ],
      asinsList: [],
      keyword: '',
      region: '',
      baseFontSize: 18,
      baseLineHeight: 1.8,
      search_keywords: '',
      categoryRefinement: {}
    };
  },
  methods: {
    handleSortChange(column) {
      console.log(55555, column)
    },
    //SD受众删除
    deleteCategoryRow(index) {
      const deletedRow = this.tableAudienceData[index];
      this.tableAudienceData.splice(index, 1);
      if (this.tableLifeStyleData.find(item => item === deletedRow)) {
        const sourceRow = this.tableLifeStyleData.find(item => item === deletedRow);
        if (sourceRow) {
          sourceRow.disabled = false;
        }
      } else if (this.tableInterestData.find(item => item === deletedRow)) {
        const sourceRow = this.tableInterestData.find(item => item === deletedRow);
        if (sourceRow) {
          sourceRow.disabled = false;
        }
      } else if (this.tableInMarketData.find(item => item === deletedRow)) {
        const sourceRow = this.tableInMarketData.find(item => item === deletedRow);
        if (sourceRow) {
          sourceRow.disabled = false;
        }
      } else if (this.tableLifeEventData.find(item => item === deletedRow)) {
        const sourceRow = this.tableLifeEventData.find(item => item === deletedRow);
        if (sourceRow) {
          sourceRow.disabled = false;
        }
      }
    },
    //SD受众添加
    addCategory(row) {
      this.tableAudienceData.push(row)
      row.disabled = true
    },
    //搜索SD受众
    selectedSdAudienceClick() {
      if (this.search_audience) {
        this.$axios.get('https://ad.yaelongtime.com/api/sd/audience', {
          params: {
            name: this.search_audience,
          }
        }).then(res => {
          let tableLifeStyleData = []
          let tableInterestData = []
          let tableLifeEventData = []
          let tableInMarketData = []
          if (res.data.content) {
            if (res.data.content[0].category === "Lifestyle") {
              let LifestyleDict = {
                category: res.data.content[0].category,
                audienceName: res.data.content[0].name,
                id: res.data.content[0].id,
                status: res.data.content[0].status,
                disabled: false
              }
              tableLifeStyleData.push(LifestyleDict)
              this.activeNameSd = 'first'
            } else if (res.data.content[0].category === 'Interest') {
              let InterestDict = {
                category: res.data.content[0].category,
                audienceName: res.data.content[0].name,
                id: res.data.content[0].id,
                status: res.data.content[0].status,
                disabled: false
              }
              tableInterestData.push(InterestDict)
              this.activeNameSd = 'second'
            } else if (res.data.content[0].category === 'In-market') {
              let InMarketDict = {
                category: res.data.content[0].category,
                audienceName: res.data.content[0].name,
                id: res.data.content[0].id,
                status: res.data.content[0].status,
                disabled: false
              }
              tableInMarketData.push(InMarketDict)
              this.activeNameSd = 'fourth'
            } else if (res.data.content[0].category === 'Life event') {
              let LifeEventDict = {
                category: res.data.content[0].category,
                audienceName: res.data.content[0].name,
                id: res.data.content[0].id,
                status: res.data.content[0].status,
                disabled: false
              }
              tableLifeEventData.push(LifeEventDict)
              this.activeNameSd = 'third'
            }
            this.tableLifeEventData = tableLifeEventData
            this.tableInMarketData = tableInMarketData
            this.tableInterestData = tableInterestData
            this.tableLifeStyleData = tableLifeStyleData
            this.paginations.total_pages = res.data.totalPages
            this.paginations.total_records = res.data.totalElements
          } else {
            this.tableLifeEventData = []
            this.tableInMarketData = []
            this.tableInterestData = []
            this.tableLifeStyleData = []
            this.paginations.total_pages = res.data.totalPages
            this.paginations.total_records = res.data.totalElements
          }
        })
      }
    },
    // 清除搜索
    refreshSdAudienceClick() {
      this.handleSdAudienceClick(0)
    },
    //获取SD受众数据
    handleSdAudienceClick(currentPage) {
      let tableLifeStyleData = []
      let tableInterestData = []
      let tableLifeEventData = []
      let tableInMarketData = []
      if (currentPage === 0) {
        currentPage = this.paginations.page
      } else {
        currentPage = currentPage - 1
      }
      this.$axios.get('https://ad.yaelongtime.com/api/sd/audience', {
        params: {
          page: currentPage,
          size: this.paginations.page_size,
        }
      }).then(res => {
        if (res.data.content.length > 0) {
          res.data.content.forEach(function (audience) {
            if (audience.category === 'Lifestyle') {
              let LifestyleDict = {
                category: audience.category,
                audienceName: audience.name,
                id: audience.id,
                status: audience.status,
                disabled: false
              }
              tableLifeStyleData.push(LifestyleDict)
            } else if (audience.category === 'Interest') {
              let InterestDict = {
                category: audience.category,
                audienceName: audience.name,
                id: audience.id,
                status: audience.status,
                disabled: false
              }
              tableInterestData.push(InterestDict)
            } else if (audience.category === 'In-market') {
              let InMarketDict = {
                category: audience.category,
                audienceName: audience.name,
                id: audience.id,
                status: audience.status,
                disabled: false
              }
              tableInMarketData.push(InMarketDict)
            } else if (audience.category === 'Life event') {
              let LifeEventDict = {
                category: audience.category,
                audienceName: audience.name,
                id: audience.id,
                status: audience.status,
                disabled: false
              }
              tableLifeEventData.push(LifeEventDict)
            }
          })
          this.tableLifeEventData = tableLifeEventData
          this.tableInMarketData = tableInMarketData
          this.tableInterestData = tableInterestData
          this.tableLifeStyleData = tableLifeStyleData
          this.paginations.total_pages = res.data.totalPages
          this.paginations.total_records = res.data.totalElements
        }
      })
    },
    //受众页数发生变化
    handleSdSizeChange(val) {
      this.paginations.page_size = val
      this.handleSdAudienceClick(0)
      console.log(`每页 ${val} 条`);
    },
    //SD广告
    handleRecommendationClick(row) {
      this.tableCategoriesData.push(row);
      row.disabled = true
    },
    //星级变化
    handleVariationChange() {
      this.max = this.value[1]
      this.min = this.value[0]
    },
    //类目细化关闭
    dialogCategoriesForm() {
      this.dialogCategoriesRefinementFormVisible = false
      if (this.selectedCategoryRefinementValue || this.startPrice || this.endPrice || this.min || this.max || this.prime) {
        let categoryRefinement = {
          na: this.category,
          brand: this.selectedCategoryRefinementValue,
          startPrice: this.startPrice,
          endPrice: this.endPrice,
          startStar: this.min,
          endStart: this.max,
          prime: this.prime,
          positioning: '分类细化:' + this.category,
          id: this.id
        }
        this.tableCategoriesData.push(categoryRefinement)
        this.selectedCategoryRefinementValue = ''
        this.startPrice = ''
        this.endPrice = ''
        this.min = 0
        this.max = 5
        this.value = [0, 5]
        this.prime = ''
      }
    },
    //获取细化品牌
    handleCategoryRefinementClick(row) {
      console.log(8888, row)
      this.$axios.get(`https://ad.yaelongtime.com/api/proxy/sp/targets/category/${row.id}/refinements`,
          {
            headers: {
              'Content-Type': 'application/vnd.spproducttargeting.v3+json'
            },
          }
      ).then(res => {
        let ad_category_refinement_options = []
        if (res.data.brands) {
          res.data.brands.forEach(function (brand) {
            let adCategoryRefinementDict = {
              name: brand.name,
              id: brand.id
            }
            ad_category_refinement_options.push(adCategoryRefinementDict)
          })
          this.ad_category_refinement_options = ad_category_refinement_options
        } else {
          this.ad_category_refinement_options = []
        }
      })
      this.category = row.na
      this.id = row.id
      this.dialogCategoriesRefinementFormVisible = true
    },
    //SP手动添加asin
    addAsinManually() {
      let tableRecommendationAsinData = this.tableRecommendationAsinData
      const asins = this.asinInput.trim(); // 去除首尾空白字符
      if (asins) {
        const asinArray = asins.split('\n'); // 将输入的关键词按行拆分成数组
        let asinList = []
        asinArray.forEach(function (asins) {
          let asin = asins.trim(); // 去除每行的首尾空白字符
          let asinDict = {
            positioning: 'ASIN' + ':' + asin,
            recommendedAsin: asin,
            disabled: false,
            selectedAsinType: '精准'
          }
          tableRecommendationAsinData.push(asinDict)
          asinList.push(asinDict)
        })
        this.asinInput = ''
      } else {
        this.$message({
          message: '输入为空',
          type: 'error'
        })
      }
    },
    //关键字默认匹配选项
    handleRadioChange() {
      let tableRecommendedKeywordsData = []
      if (this.radio === 1) {
        tableRecommendedKeywordsData = this.tableAllKeywordDate[0]
      } else if (this.radio === 3) {
        tableRecommendedKeywordsData = this.tableAllKeywordDate[1]
      } else if (this.radio === 2) {
        tableRecommendedKeywordsData = this.tableAllKeywordDate[2]
      }
      this.tableRecommendedKeywordsData = tableRecommendedKeywordsData
    },
    //SP添加全部类目
    handleAllCategoriesClick(row) {
      // 将选中行数据添加到目标表格
      this.tableCategoriesData.push(row);
      // 禁用按钮
      row.disabled = true;
    },
    handleSizeChange(val) {
      this.pagination.page_size = val
      this.handleAllCategoryClick(0)
      console.log(`每页 ${val} 条`);
    },
    //移除推荐ASIN
    deleteRecommendedAsinRow(index) {
      const deletedRow = this.tableRecommendationAsinData[index];
      this.tableRecommendationAsinData.splice(index, 1);
      const sourceRow = this.tableRecommendationData.find(item => item === deletedRow);
      if (sourceRow) {
        sourceRow.disabled = false;
      }
    },
    //添加推荐ASIN
    handleRecommendationAsinClick(row) {
      // 将选中行数据添加到目标表格
      this.tableRecommendationAsinData.push(row);
      // 禁用按钮
      row.disabled = true;
    },
    //SP清除类目
    deleteCategoriesRow(index) {
      const deletedRow = this.tableCategoriesData[index];
      this.tableCategoriesData.splice(index, 1);
      if (this.tableTargetsCategoriesData.find(item => item === deletedRow)) {
        const sourceRow = this.tableTargetsCategoriesData.find(item => item === deletedRow);
        if (sourceRow) {
          sourceRow.disabled = false;
        }
      } else if (this.tableAllCategoriesData.find(item => item === deletedRow)) {
        const sourceRow = this.tableAllCategoriesData.find(item => item === deletedRow);
        if (sourceRow) {
          sourceRow.disabled = false;
        }
      } else {
        const sourceRow = this.RecommendationData.find(item => item === deletedRow);
        if (sourceRow) {
          sourceRow.disabled = false;
        }
      }
    },
    //添加SP类目
    addToCategories(row) {
      // 将选中行数据添加到目标表格
      this.tableCategoriesData.push(row);
      // 禁用按钮
      row.disabled = true;
    },
    //SP获取ASIN
    handleAsinClick() {
      const sendAsinDetails = (asinList) => {
        this.sendAsinDetails(asinList)
      };
      let asinList = []
      this.selectionProduct.forEach(item => {
        asinList.push(item.asin)
      });
      this.$axios.post('https://ad.yaelongtime.com/api/proxy/sp/targets/products/recommendations',
          {
            "adAsins": asinList,
            "count": 300
          },
          {
            headers: {
              'Content-Type': 'application/vnd.spproductrecommendation.v3+json'
            },
          }
      ).then(res => {
            this.loadingAsin = false
            if (res.data.recommendations !== null) {
              let asinsList = []
              res.data.recommendations.forEach(function (recommendations) {
                asinsList.push(recommendations.recommendedAsin)
              })
              //获取推荐asin详细信息
              sendAsinDetails(asinsList)
            } else {
              this.tableRecommendationData = []
              this.$message({
                message: '无推荐ASIN',
                type: 'error'
              })
            }
          }
      ).catch(error => {
        this.$message({
          message: error,
          type: 'error'
        })
      })
    },
    // //获取asin竞品信息
    // sendAsinDetails(asinList){
    //   this.$axios.get('https://ad.yaelongtime.com/api/sp/competing-product',{
    //     params:{
    //       asin:asinList[8]
    //     }
    //   }).then(res=>{
    //     console.log(88889999, res.data)
    //   })
    // },
    //获取SP推荐ASIN的详细信息
    sendAsinDetails(asinList) {
      if (asinList.length > 0) {
        this.$axios.post('https://ad.yaelongtime.com/api/proxy/product/metadata',
            {
              "asins": asinList,
              "checkItemDetails": "true",
              "pageSize": 300,
              "locale": "en_US"
            },
            {
              headers: {
                'Content-Type': 'application/vnd.productmetadataresponse.v1+json'
              },
            }
        ).then(res => {
          let tableRecommendationData = []
          if (res.data.ProductMetadataList.length > 0) {
            res.data.ProductMetadataList.forEach(function (product) {
              let tableRecommendation = {
                image: product.imageUrl,
                sku: product.sku,
                product_name: product.title,
                positioning: 'ASIN' + ':' + product.asin,
                recommendedAsin: product.asin,
                disabled: false,
                selectedAsinType: '精准'
              }
              tableRecommendationData.push(tableRecommendation)
            })
            asinList.forEach(function (product) {
              let tableRecommendation = {
                image: '',
                sku: '',
                product_name: '',
                positioning: 'ASIN' + ':' + product,
                recommendedAsin: product,
                disabled: false,
                selectedAsinType: '精准'
              }
              tableRecommendationData.push(tableRecommendation)
            })
            this.tableRecommendationData = tableRecommendationData
          } else {
            asinList.forEach(function (product) {
              let tableRecommendation = {
                image: '',
                sku: '',
                product_name: '',
                positioning: 'ASIN' + ':' + product,
                recommendedAsin: product,
                disabled: false,
                selectedAsinType: '精准'
              }
              tableRecommendationData.push(tableRecommendation)
            })
            this.tableRecommendationData = tableRecommendationData
          }
        }).catch(error => {
          this.$message({
            message: error,
            type: 'error'
          })
        })
      } else {
        this.$message({
          message: '无推荐ASIN',
          type: 'error'
        })
      }
    },
    //获取SP全部类目
    handleAllCategoryClick(currentPage) {
      if (currentPage === 0) {
        currentPage = this.pagination.page
      } else {
        currentPage = currentPage - 1
      }
      this.$axios.get('https://ad.yaelongtime.com/api/sp/category',
          {
            headers: {
              'Content-Type': 'application/vnd.spproducttargeting.v3+json'
            },
            params: {
              page: currentPage,
              size: this.pagination.page_size,
            }
          }
      ).then(res => {
        this.loadingAllCategories = false
        let tableAllCategoriesData = []
        res.data.content.forEach(function (categories) {
          let allCategories = {
            na: categories.na,
            positioning: '分类' + ':' + categories.na,
            disabled: false,
            id: categories.id,
            disabledRefinement: false
          }
          tableAllCategoriesData.push(allCategories)
        })
        this.tableAllCategoriesData = tableAllCategoriesData;
        this.pagination.total_pages = res.data.totalPages
        this.pagination.total_records = res.data.totalElements
      }).catch(error => {
        this.$message({
          message: '无类目',
          type: 'error'
        })
      })
    },
    //SP类目筛选
    searchAllCategory() {
      if (this.search_allCategory) {
        this.$axios.get('https://ad.yaelongtime.com/api/sp/category',
            {
              params: {
                na: this.search_allCategory,
              }
            }
        ).then(res => {
          let tableAllCategoriesData = []
          res.data.content.forEach(function (categories) {
            let allCategoriesData = {
              na: categories.na,
              disabled: false,
              selectedType: '',
              id: categories.id
            }
            tableAllCategoriesData.push(allCategoriesData);
          })
          this.tableAllCategoriesData = tableAllCategoriesData
          return this.tableAllCategoriesData
        }).catch(error => {
          this.$message({
            message: '无类目',
            type: 'error'
          })
        })
      } else {
        this.handleAllCategoryClick(0)
      }
    },
    //SP清空删选
    refreshAllCategory() {
      this.search_category = ''
      this.handleAllCategoryClick()
    },
    // 获取SP推荐类目
    handleTargetsCategoriesClick() {
      let asinList = []
      this.selectionProduct.forEach(item => {
        asinList.push(item.asin)
      });
      this.$axios.post('https://ad.yaelongtime.com/api/proxy/sp/targets/categories/recommendations',
          {
            "includeAncestor": true,
            "asins": asinList,
          },
          {
            headers: {
              'Content-Type': 'application/vnd.spproducttargeting.v3+json'
            },
          }
      ).then(res => {
        if (res.data.categories !== null) {
          this.loadingCategories = false
          let tableTargetsCategoriesData = []
          res.data.categories.forEach(function (categories) {
            let categoriesData = {
              na: categories.name,
              disabled: false,
              positioning: '分类' + ':' + categories.name,
              id: categories.id,
              disabledRefinement: false
            }
            tableTargetsCategoriesData.push(categoriesData)
          })
          this.tableTargetsCategoriesData = tableTargetsCategoriesData
        } else {
          this.loadingCategories = false
          this.tableTargetsCategoriesData = []
          this.$message({
            message: '无推荐类目',
            type: 'error'
          })
        }
      }).catch(error => {
        this.$message({
          message: error,
          type: 'error'
        })
      })
    },
    //获取SP推荐关键字
    handleCurrent(tab, event) {
      let asinList = []
      this.selectionProduct.forEach(item => {
        asinList.push(item.asin)
      });
      this.$axios.post('https://ad.yaelongtime.com/api/proxy/sp/targets/keywords/recommendations',
          {
            "recommendationType": "KEYWORDS_FOR_ASINS",
            "sortDimension": "CLICKS",
            "asins": asinList,
            "locale": "en_US",
          },
          {
            headers: {
              'Content-Type': 'application/vnd.spkeywordsrecommendation.v4+json'
            },
          }
      ).then(res => {
        if (res.data.keywordTargetList.length > 0) {
          this.loadingKeywords = false
          let tableRecommendedKeywordsBroadData = []
          let tableRecommendedKeywordsPhraseData = []
          let tableRecommendedKeywordsExactData = []
          res.data.keywordTargetList.forEach(function (key) {
            key.bidInfo.forEach(function (type) {
              if (type.suggestedBid) {
                if (type.matchType === 'BROAD') {
                  let BroadKeywordData = {
                    keyword: key.keyword,     //关键词
                    searchTermImpressionRank: key.searchTermImpressionRank,   //排行
                    userSelectedKeyword: key.userSelectedKeyword,   //用户是否选择
                    disabled: false,        // 是否选择
                    selectedKeywordType: 'KW',        // 类型
                    matchType: type.matchType,
                    rangeStart: type.suggestedBid.rangeStart / 100,  //开始价
                    rangeMedian: type.suggestedBid.rangeMedian / 100, //中间价
                    rangeEnd: type.suggestedBid.rangeEnd / 100, //最高价
                    typeInfo: '宽泛匹配'
                  }
                  tableRecommendedKeywordsBroadData.push(BroadKeywordData)
                } else if (type.matchType === 'PHRASE') {
                  let phraseKeywordData = {
                    keyword: key.keyword,     //关键词
                    searchTermImpressionRank: key.searchTermImpressionRank,   //排行
                    userSelectedKeyword: key.userSelectedKeyword,   //用户是否选择
                    disabled: false,        // 是否选择
                    selectedKeywordType: 'KW',        // 类型
                    matchType: type.matchType,
                    rangeStart: type.suggestedBid.rangeStart / 100,  //开始价
                    rangeMedian: type.suggestedBid.rangeMedian / 100, //中间价
                    rangeEnd: type.suggestedBid.rangeEnd / 100, //最高价
                    typeInfo: '词组匹配'
                  }
                  tableRecommendedKeywordsPhraseData.push(phraseKeywordData)
                } else if (type.matchType === 'EXACT') {
                  let exactKeywordDate = {
                    keyword: key.keyword,     //关键词
                    searchTermImpressionRank: key.searchTermImpressionRank,   //排行
                    userSelectedKeyword: key.userSelectedKeyword,   //用户是否选择
                    disabled: false,        // 是否选择
                    selectedKeywordType: 'KW',        // 类型
                    matchType: type.matchType,
                    rangeStart: type.suggestedBid.rangeStart / 100,  //开始价
                    rangeMedian: type.suggestedBid.rangeMedian / 100, //中间价
                    rangeEnd: type.suggestedBid.rangeEnd / 100, //最高价
                    typeInfo: '精准匹配'
                  }
                  tableRecommendedKeywordsExactData.push(exactKeywordDate)
                }
              } else {
                if (type.matchType === 'BROAD') {
                  let BroadKeywordData = {
                    keyword: key.keyword,     //关键词
                    searchTermImpressionRank: key.searchTermImpressionRank,   //排行
                    userSelectedKeyword: key.userSelectedKeyword,   //用户是否选择
                    disabled: false,        // 是否选择
                    selectedKeywordType: 'KW',        // 类型
                    matchType: type.matchType,
                    rangeStart: "~",  //开始价
                    rangeMedian: "~", //中间价
                    rangeEnd: "~", //最高价
                    typeInfo: '宽泛匹配'
                  }
                  tableRecommendedKeywordsBroadData.push(BroadKeywordData)
                } else if (type.matchType === 'PHRASE') {
                  let phraseKeywordData = {
                    keyword: key.keyword,     //关键词
                    searchTermImpressionRank: key.searchTermImpressionRank,   //排行
                    userSelectedKeyword: key.userSelectedKeyword,   //用户是否选择
                    disabled: false,        // 是否选择
                    selectedKeywordType: 'KW',        // 类型
                    matchType: type.matchType,
                    rangeStart: "~",  //开始价
                    rangeMedian: "~", //中间价
                    rangeEnd: "~", //最高价
                    typeInfo: '词组匹配'
                  }
                  tableRecommendedKeywordsPhraseData.push(phraseKeywordData)
                } else if (type.matchType === 'EXACT') {
                  let exactKeywordDate = {
                    keyword: key.keyword,     //关键词
                    searchTermImpressionRank: key.searchTermImpressionRank,   //排行
                    userSelectedKeyword: key.userSelectedKeyword,   //用户是否选择
                    disabled: false,        // 是否选择
                    selectedKeywordType: 'KW',        // 类型
                    matchType: type.matchType,
                    rangeStart: "~",  //开始价
                    rangeMedian: "~", //中间价
                    rangeEnd: "~", //最高价
                    typeInfo: '精准匹配'
                  }
                  tableRecommendedKeywordsExactData.push(exactKeywordDate)
                }
              }
            })
          })
          this.tableAllKeywordDate = [tableRecommendedKeywordsBroadData, tableRecommendedKeywordsExactData, tableRecommendedKeywordsPhraseData]
          this.tableRecommendedKeywordsData = tableRecommendedKeywordsBroadData
        } else {
          this.tableRecommendedKeywordsData = []
        }
      }).catch(error => {
        this.$message({
          message: '无推荐关键字55',
          type: 'error'
        })
      })
    },
    onSubmit() {
      console.log('submit!');
    },
    //新增手动添加SP关键字
    AddKeywordsManually() {
      let radio = this.radio
      let tableKeywordData = this.tableKeywordData
      const keywords = this.keywordInput.trim(); // 去除首尾空白字符
      if (keywords) {
        const keywordArray = keywords.split('\n'); // 将输入的关键词按行拆分成数组
        let keywordList = []
        keywordArray.forEach(function (keyword) {
          if (radio === 1) {
            let keywordDict = {
              keyword: keyword,
              typeInfo: '宽泛匹配',
              matchType: 'BROAD',
              selectedKeywordType: 'KW',
            }
            tableKeywordData.push(keywordDict)
            keywordList.push(keywordDict)
          } else if (radio === 2) {
            let keywordDict = {
              keyword: keyword,
              typeInfo: '词组匹配',
              matchType: 'PHRASE',
              selectedKeywordType: 'KW',
            }
            tableKeywordData.push(keywordDict)
            keywordList.push(keywordDict)
          } else if (radio === 3) {
            let keywordDict = {
              keyword: keyword,
              typeInfo: '精准匹配',
              matchType: 'EXACT',
              selectedKeywordType: 'KW',
            }
            tableKeywordData.push(keywordDict)
            keywordList.push(keywordDict)
          }
        })
        this.keywordInput = ''
      } else {
        this.$message({
          message: '输入为空',
          type: 'error'
        })
      }
    },
    addToTarget(row) {
      // 将选中行数据添加到目标表格
      this.tableKeywordData.push(row);
      // 禁用按钮
      row.disabled = true;
    },
    //移除SP关键字
    deleteRow(index) {
      const deletedRow = this.tableKeywordData[index];
      this.tableKeywordData.splice(index, 1);
      const sourceRow = this.tableRecommendedKeywordsData.find(item => item === deletedRow);
      if (sourceRow) {
        sourceRow.disabled = false;
      }
    },
    //下一页
    handlePageJump() {
      const tableDate = {
        tableKeywordData: this.tableKeywordData,
        tableCategoriesData: this.tableCategoriesData,
        tableRecommendationAsinData: this.tableRecommendationAsinData,
        tableRecommendedKeywordsData: this.tableRecommendedKeywordsData,
        tableSbRecommendedKeywordsData: this.tableSbRecommendedKeywordsData,
        tableTargetsCategoriesData: this.tableTargetsCategoriesData,
        tableSbTargetsCategoriesData: this.tableSbTargetsCategoriesData,
        tableAllCategoriesData: this.tableAllCategoriesData,
        tableSbAllCategoriesData: this.tableSbAllCategoriesData,
        tableRecommendationData: this.tableRecommendationData,
        tableSbRecommendationData: this.tableSbRecommendationData,
        tableAudienceData: this.tableAudienceData
      }
      this.$router.push({
        path: '/ad-strategy', query: {
          selectionProductData: this.selectionProduct,
          selectionData: tableDate,
          upPath: this.$route.path
        }
      });
    },
    //上一页
    handleUpClick() {
      this.$router.push('/ad-actions');
    },
  },
  computed: {
    //搜索sp推荐类目
    filterTableTargetsCategoriesData() {
      if (!this.search_category) {
        return this.tableTargetsCategoriesData; // 如果搜索输入框无值，返回原始数据
      }
      // 使用 Array.filter() 方法筛选数据
      return this.tableTargetsCategoriesData.filter(data => {
        return data.na.includes(this.search_category);
        // 根据搜索输入框的值进行筛选，这里使用 includes() 方法进行简单的包含关系筛选
      });
    },
    //搜素SP关键词
    filterTableRecommendedKeywordsData() {
      if (!this.search_keywords) {
        return this.tableRecommendedKeywordsData; // 如果搜索输入框无值，返回原始数据
      }
      // 使用 Array.filter() 方法筛选数据
      return this.tableRecommendedKeywordsData.filter(data => {
        return data.keyword.includes(this.search_keywords);
        // 根据搜索输入框的值进行筛选，这里使用 includes() 方法进行简单的包含关系筛选
      });
    },
    //SP搜索Asin
    filteredTableRecommendationData() {
      if (!this.search_asin) {
        return this.tableRecommendationData; // 如果搜索输入框无值，返回原始数据
      }
      // 使用 Array.filter() 方法筛选数据
      return this.tableRecommendationData.filter(data => {
        return data.recommendedAsin.includes(this.search_asin);
        // 根据搜索输入框的值进行筛选，这里使用 includes() 方法进行简单的包含关系筛选
      });
    },
    styles() {
      // 根据基准字号和行高计算字体大小和行距
      const fontSize = `${this.baseFontSize}px`;
      const lineHeight = `${this.baseLineHeight * this.baseFontSize}px`;

      // 响应式布局
      return {
        'font-size': fontSize,
        'line-height': lineHeight,
        'padding-top': '-10px',
        'padding-left': '-10px',
        'padding-right': '1px',
      };
    },
  },
  beforeMount() {
    if (this.$route.query.upPath === '/ad-actions') {
      this.handleCurrent()
      this.handleAsinClick()
      setTimeout(this.handleAsinClick, 30 * 60 * 1000);
      this.handleTargetsCategoriesClick()
      setTimeout(this.handleTargetsCategoriesClick, 30 * 60 * 1000);
      this.handleAllCategoryClick(0)
      setTimeout(this.handleAllCategoryClick, 30 * 60 * 1000);
      this.handleSdAudienceClick(0)
      setTimeout(this.handleSdAudienceClick, 30 * 60 * 1000);
    } else if (this.$route.query.upPath === '/ad-strategy') {
      this.loadingKeywords = false
      this.loadingCategories = false
      this.loadingAllCategories = false
      this.loadingAsin = false
      this.tableRecommendedKeywordsData = this.$route.query.selected_product_info.tableRecommendedKeywordsData
      this.tableTargetsCategoriesData = this.$route.query.selected_product_info.tableTargetsCategoriesData
      this.tableAllCategoriesData = this.$route.query.selected_product_info.tableAllCategoriesData
      this.tableRecommendationData = this.$route.query.selected_product_info.tableRecommendationData
    }
  },
  created() {
    if (this.$route.query.upPath === '/ad-strategy') {
      this.selectionProduct = this.$route.query.selected_product_information
      this.tableCategoriesData = this.$route.query.selected_product_info.tableCategoriesData
      this.tableKeywordData = this.$route.query.selected_product_info.tableKeywordData
      this.tableRecommendationAsinData = this.$route.query.selected_product_info.tableRecommendationAsinData
    } else if (this.$route.query.upPath === '/ad-actions') {
      this.selectionProduct = this.$route.query.selected_product_information
    }
  },
}
</script>

<style scoped>
.product-container {
  display: flex;
  flex-wrap: wrap;
}

.product-item {
  display: flex;
  align-items: center;
  margin: 20px;
}

.product-item img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}

.sku,
.asin {
  margin: 20px 10px 1px 10px;
}

.search-container {
  display: flex;
  align-items: center; /* 居中对齐 */
  gap: 10px; /* 输入框和按钮之间的间距 */
}

/* 可选样式 */
.search-input {
  flex: 1; /* 输入框占据剩余空间 */
}
</style>