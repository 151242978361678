<template>
  <div :style="styles">
    <el-col :span="24">
      <el-col :span="24">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/home' }">广告投放</el-breadcrumb-item>
          <el-breadcrumb-item>操作</el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
      </el-col>
      <el-row>
        <div style="padding: 10px 50px">已选商品</div>
        <div class="product-container" style="padding: 10px 50px">
          <div v-for="(product, index) in selectionProduct" :key="index">
            <img :src="product.image" alt="商品图片">
            <p class="sku">SKU: {{ product.sku }}</p>
            <p class="asin">ASIN: {{ product.asin }}</p>
          </div>
        </div>
      </el-row>
    </el-col>
    <el-col :span="24" style="padding: 10px 50px">
      <el-table
          ref="multipleTable"
          :data="advertisingStrategyData"
          style="width: 100%"
          @cell-mouse-enter="showButton"
          @cell-mouse-leave="hideButton"
      >
        <el-table-column
            prop="state"
            label="有效"
            width="80"
        >
          <template slot-scope="scope">
            <el-switch
                disabled
                v-model="scope.row.success"
                validate-event
                inactive-color="＃808080"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
            prop="type"
            label="广告类型"
            width="120">
        </el-table-column>
        <el-table-column
            label="广告活动"
            width="400">
          <template slot-scope="scope">
            <el-input v-model="scope.row.ad_activities"
                      placeholder="请输入数量"></el-input>
          </template>
        </el-table-column>
        <el-table-column
            label="预算"
            width="120">
          <template slot-scope="scope">
            <el-input v-model="scope.row.budget"
                      placeholder="请输入数量">
              <template slot="append">$</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
            label="广告组"
            width="300">
          <template slot-scope="scope">
            <el-input v-model="scope.row.selectedGroupsValue"
                      placeholder="请输入数量"></el-input>
          </template>
        </el-table-column>
        <el-table-column
            prop="biddingStrategy"
            label="竞价策略"
            width="250"
        >
          <template slot-scope="scope">
            <el-select
                v-model="scope.row.biddingStrategy"
                @change="handleSelectionBiddingStrategy(scope.row.label)">
              <el-option
                  v-for="option in biddingStrategies"
                  :key="option.value"
                  :label="option.label"
                  :value="option.value">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
            prop="adHomeSlotsValue"
            label="TOP位/品牌旗舰店"
            width="200">
          <template slot-scope="scope">
            <el-input v-model="scope.row.adHomeSlotsValue"
                      placeholder="请输入数量">
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
            prop="adSlotsValue"
            label="商品页面/产品页面"
            width="200">
          <template slot-scope="scope">
            <el-input v-model="scope.row.adSlotsValue"
                      placeholder="请输入数量">
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
            prop="adOtherPagesValue"
            label="搜索结果其余位置"
            width="200">
          <template slot-scope="scope">
            <el-input v-model="scope.row.adOtherPagesValue"
                      placeholder="请输入数量">
              <template slot="append">%</template>
            </el-input>
          </template>
        </el-table-column>
        <el-table-column
            prop="matching_method"
            label="匹配方式"
            width="200">
        </el-table-column>
        <el-table-column
            prop="typeText"
            label="类型"
            width="200">
        </el-table-column>
        <el-table-column
            prop="zip"
            label="投放"
            width="120"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="openDialog(scope.row)" style="padding: 10px">{{
                scope.row.keywordLength ? scope.row.keywordLength : 0
              }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
            label="否定"
            width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="openNegativeDialog(scope.row)" style="padding: 10px">{{
                scope.row.negativeLength ? scope.row.negativeLength : 0
              }}
            </el-button>
          </template>
        </el-table-column>
        <el-table-column
            width="200">
          <template slot-scope="scope">
            <el-button
                icon="el-icon-delete" type="danger" v-show="scope.row.showButton" style="padding: 10px"
                @click.native.prevent="handleDeleteClick(scope.$index, advertisingStrategyData)"
                size="small">
            </el-button>
            <i class="el-icon-circle-plus" type="primary" v-show="scope.row.showButton" style="padding: 10px"></i>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="否定定位" :visible.sync="dialogSpNegativeFormVisible" :close-on-click-modal="false">
        <el-tabs v-model="activeAsinName" type="card" @tab-click="handleNegativeKeywordClick">
          <el-select v-model="negativeHierarchy" placeholder="请选择广告层级">
            <el-option
                v-for="item in negativeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
          <el-tab-pane label="输入否定关键词" name="first">
            <el-radio-group v-model="radioNegative" @change="handleNegativeRadioChange">
              <el-radio :label="1">否定精准</el-radio>
              <el-radio :label="2">否定词组</el-radio>
            </el-radio-group>
            <el-input
                type="textarea"
                :rows="5"
                placeholder="请输入或粘贴需要投放的否定关键词,一行一个"
                v-model="negativeKeywordInput">
            </el-input>
            <el-col :span="24" style="padding-top: 10px">
              <el-button style="display: flex" @click="handleRecommendationNegativeKeywordClick">添加否定关键词
              </el-button>
            </el-col>
          </el-tab-pane>
          <el-tab-pane label="输入否定投放" name="second">
            <el-radio-group v-model="radioNegativeAsin" @change="handleNegativeRadioAsinChange">
              <el-radio :label="1">否定ASIN</el-radio>
              <el-radio :label="2" :disabled="isBrandRadioDisabled">否定品牌</el-radio>
            </el-radio-group>
            <div v-if="radioNegativeAsin===1">
              <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="请输入或粘贴需要投放的否定关键词,一行一个"
                  v-model="negativeKeywordInput">
              </el-input>
              <el-col :span="24" style="padding-top: 10px">
                <el-button style="display: flex" @click="AddNegativeAsinManually">添加否定投放</el-button>
              </el-col>
            </div>
            <div v-else-if="radioNegativeAsin===2">
              <el-input v-model="filterBrandValue" placeholder="请输入品牌"
                        @keydown.enter="searchBrand" @blur="searchBrand" @clear="refreshBrand" clearable></el-input>
              <el-table
                  :data="tableBrandData"
                  height="300"
                  style="width: 100%">
                <el-table-column
                    prop="negativeText"
                    label="品牌"
                    width="180">
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="100">
                  <template slot-scope="scope">
                    <el-button
                        @click="handleRecommendationBrandClick(scope.row)"
                        type="text"
                        size="small"
                        :disabled="scope.row.disabled"
                    >{{ scope.row.disabled ? '已添加' : '添加' }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-table
            :data="tableRecommendationNegativeData"
            style="width: 100%"
            max-height="2350">
          <el-table-column
              fixed
              prop="negativeText"
              label="否定"
              width="100">
          </el-table-column>
          <el-table-column
              prop="matchType"
              label="否定匹配方式"
              width="120">
          </el-table-column>
          <el-table-column
              prop="negativeHierarchy"
              label="否定层级"
              width="200">
          </el-table-column>
          <el-table-column
              label="操作"
              width="120">
            <template slot-scope="scope">
              <el-button
                  @click.native.prevent="deleteRecommendedNegativeRow(scope.$index, tableRecommendationNegativeData)"
                  type="text"
                  size="small">
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogSpNegativeFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogNegativeVisible">下一步</el-button>
        </div>
      </el-dialog>
      <el-dialog title="否定定位" :visible.sync="dialogSbNegativeFormVisible" :close-on-click-modal="false">
        <el-tabs v-model="activeAsinName" type="card" @tab-click="handleNegativeKeywordClick">
          <el-tab-pane label="输入否定关键词" name="first">
            <el-radio-group v-model="radioNegative" @change="handleNegativeRadioChange">
              <el-radio :label="1">否定精准</el-radio>
              <el-radio :label="2">否定词组</el-radio>
            </el-radio-group>
            <el-input
                type="textarea"
                :rows="5"
                placeholder="请输入或粘贴需要投放的否定关键词,一行一个"
                v-model="negativeKeywordInput">
            </el-input>
            <el-col :span="24" style="padding-top: 10px">
              <el-button style="display: flex" @click="handleSbRecommendationNegativeKeywordClick">添加否定关键词
              </el-button>
            </el-col>
          </el-tab-pane>
          <el-tab-pane label="输入否定投放" name="second">
            <el-radio-group v-model="radioNegativeAsin" @change="handleNegativeRadioAsinChange">
              <el-radio :label="1">否定ASIN</el-radio>
              <el-radio :label="2" :disabled="isBrandRadioDisabled">否定品牌</el-radio>
            </el-radio-group>
            <div v-if="radioNegativeAsin===1">
              <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="请输入或粘贴需要投放的否定关键词,一行一个"
                  v-model="negativeKeywordInput">
              </el-input>
              <el-col :span="24" style="padding-top: 10px">
                <el-button style="display: flex" @click="AddNegativeAsinManually">添加否定投放</el-button>
              </el-col>
            </div>
            <div v-else-if="radioNegativeAsin===2">
              <el-input v-model="filterBrandValue" placeholder="请输入品牌"
                        @keydown.enter="searchSbBrand" @blur="searchSbBrand" @clear="refreshSbBrand"
                        clearable></el-input>
              <el-table
                  :data="tableBrandData"
                  height="300"
                  style="width: 100%">
                <el-table-column
                    prop="negativeText"
                    label="品牌"
                    width="180">
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="100">
                  <template slot-scope="scope">
                    <el-button
                        @click="handleRecommendationBrandClick(scope.row)"
                        type="text"
                        size="small"
                        :disabled="scope.row.disabled"
                    >{{ scope.row.disabled ? '已添加' : '添加' }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-table
            :data="tableRecommendationNegativeData"
            style="width: 100%"
            max-height="2350">
          <el-table-column
              fixed
              prop="negativeText"
              label="否定"
              width="100">
          </el-table-column>
          <el-table-column
              prop="matchType"
              label="否定匹配方式"
              width="120">
          </el-table-column>
          <el-table-column
              prop="negativeHierarchy"
              label="否定层级"
              width="200">
          </el-table-column>
          <el-table-column
              label="操作"
              width="120">
            <template slot-scope="scope">
              <el-button
                  @click.native.prevent="deleteRecommendedNegativeRow(scope.$index, tableRecommendationNegativeData)"
                  type="text"
                  size="small">
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogNegativeVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogNegativeVisible">下一步</el-button>
        </div>
      </el-dialog>
      <el-dialog title="否定定位" :visible.sync="dialogSdNegativeFormVisible" :close-on-click-modal="false">
        <el-tabs v-model="activeAsinName" type="card" @tab-click="handleNegativeKeywordClick">
          <el-tab-pane label="输入否定投放" name="first">
            <el-radio-group v-model="radioNegativeAsin" @change="handleNegativeRadioAsinChange">
              <el-radio :label="1">否定ASIN</el-radio>
              <el-radio :label="2" :disabled="isBrandRadioDisabled">否定品牌</el-radio>
            </el-radio-group>
            <div v-if="radioNegativeAsin===1">
              <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="请输入或粘贴需要投放的否定关键词,一行一个"
                  v-model="negativeKeywordInput">
              </el-input>
              <el-col :span="24" style="padding-top: 10px">
                <el-button style="display: flex" @click="AddNegativeAsinManually">添加否定投放</el-button>
              </el-col>
            </div>
            <div v-else-if="radioNegativeAsin===2">
              <el-input v-model="filterBrandValue" placeholder="请输入品牌"
                        @keydown.enter="searchSbBrand" @blur="searchSbBrand" @clear="refreshSbBrand"
                        clearable></el-input>
              <el-table
                  :data="tableBrandData"
                  height="300"
                  style="width: 100%">
                <el-table-column
                    prop="negativeText"
                    label="品牌"
                    width="180">
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="100">
                  <template slot-scope="scope">
                    <el-button
                        @click="handleRecommendationBrandClick(scope.row)"
                        type="text"
                        size="small"
                        :disabled="scope.row.disabled"
                    >{{ scope.row.disabled ? '已添加' : '添加' }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-table
            :data="tableRecommendationNegativeData"
            style="width: 100%"
            max-height="2350">
          <el-table-column
              fixed
              prop="negativeText"
              label="否定"
              width="100">
          </el-table-column>
          <el-table-column
              prop="matchType"
              label="否定匹配方式"
              width="120">
          </el-table-column>
          <el-table-column
              label="操作"
              width="120">
            <template slot-scope="scope">
              <el-button
                  @click.native.prevent="deleteRecommendedNegativeRow(scope.$index, tableRecommendationNegativeData)"
                  type="text"
                  size="small">
                移除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogSdNegativeFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogNegativeVisible">下一步</el-button>
        </div>
      </el-dialog>
      <el-dialog title="自动广告定位" :visible.sync="dialogAutoFormVisible" :close-on-click-modal="false">
        <el-form label-width="80px">
          <el-row>
            <el-form-item label="广告活动">
              <span>{{ dialogData.ad_activities }}</span>
            </el-form-item>
            <el-form-item label="广告组">
              <span>{{ dialogData.ad_activities }}</span>
            </el-form-item>
          </el-row>
          <el-table
              :data="tableAutoData"
              style="width: 100%"
          >
            <el-table-column
                prop="typeInfo"
                label="自动定位组"
                width="100">
            </el-table-column>
            <el-table-column
                prop="suggestedBid1"
                label="应用建议竞价-上限"
                width="150">
            </el-table-column>
            <el-table-column
                prop="suggestedBid2"
                label="应用建议竞价"
                width="130">
            </el-table-column>
            <el-table-column
                prop="suggestedBid3"
                label="应用建议竞价-下限"
                width="150">
            </el-table-column>
            <el-table-column
                label="竞价调整"
                width="200">
              <template slot-scope="scope">
                <el-input v-model="scope.row.bidding"
                          placeholder="请输入竞价">
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogAutoFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogAutoVisible">下一步</el-button>
        </div>
      </el-dialog>
      <el-dialog title="关键词定位" :visible.sync="dialogKeywordFormVisible" :close-on-click-modal="false">
        <el-form label-width="80px">
          <el-row>
            <el-form-item label="广告活动">
              <span>{{ dialogData.ad_activities }}</span>
            </el-form-item>
            <el-form-item label="广告组">
              <span>{{ dialogData.ad_activities }}</span>
            </el-form-item>
            <el-button icon="el-icon-refresh" @click="handleRecommendedBiddingClick" style="padding-bottom: 10px">
              获取推荐竞价
            </el-button>
          </el-row>
          <el-table
              :data="tableKeywordData"
              style="width: 100%"
              max-height="250">
            <el-table-column
                prop="keyword"
                label="关键词"
                width="200">
            </el-table-column>
            <el-table-column
                prop="typeInfo"
                label="匹配方式"
                width="100">
            </el-table-column>
            <el-table-column label="应用建议竞价">
              <el-table-column
                  prop="rangeStart"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="rangeMedian"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="rangeEnd"
                  width="100">
              </el-table-column>
            </el-table-column>
            <el-table-column
                label="竞价调整"
                width="100">
              <template slot-scope="scope">
                <el-input v-model="scope.row.bidding"
                          placeholder="请输入竞价">
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogKeywordFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogKeywordForm">下一步</el-button>
        </div>
      </el-dialog>
      <el-dialog title="类目定位" :visible.sync="dialogCategoriesFormVisible" :close-on-click-modal="false">
        <el-form label-width="80px">
          <el-row>
            <el-form-item label="广告活动">
              <span>{{ dialogData.ad_activities }}</span>
            </el-form-item>
            <el-form-item label="广告组">
              <span>{{ dialogData.ad_activities }}</span>
            </el-form-item>
            <el-button icon="el-icon-refresh" @click="handleRecommendedBiddingClick" style="padding-bottom: 10px">
              获取推荐竞价
            </el-button>
          </el-row>
          <el-table
              :data="tableCategoriesData"
              style="width: 100%"
              max-height="250">
            <el-table-column
                prop="na"
                label="分类"
                width="250">
            </el-table-column>
            <el-table-column
                prop="positioning"
                label="定位"
                width="250">
            </el-table-column>
            <el-table-column label="应用建议竞价">
              <el-table-column
                  prop="rangeStart"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="rangeMedian"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="rangeEnd"
                  width="100">
              </el-table-column>
            </el-table-column>
            <el-table-column
                label="竞价调整"
                width="200">
              <template slot-scope="scope">
                <el-input v-model="scope.row.bidding"
                          placeholder="请输入数量">
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogCategoriesFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogCategoriesForm">下一步</el-button>
        </div>
      </el-dialog>
      <el-dialog title="ASIN定位" :visible.sync="dialogAsinFormVisible" :close-on-click-modal="false">
        <el-form label-width="80px">
          <el-row>
            <el-form-item label="广告活动">
              <span>{{ dialogData.ad_activities }}</span>
            </el-form-item>
            <el-form-item label="广告组">
              <span>{{ dialogData.ad_activities }}</span>
            </el-form-item>
            <el-button icon="el-icon-refresh" @click="handleRecommendedBiddingClick" style="padding-bottom: 10px">
              获取推荐竞价
            </el-button>
          </el-row>
          <el-table
              :data="tableRecommendationAsinData"
              style="width: 100%"
              max-height="250">
            <el-table-column
                prop="recommendedAsin"
                label="商品"
                width="200">
            </el-table-column>
            <el-table-column
                prop="positioning"
                label="定位"
                width="250">
            </el-table-column>
            <el-table-column
                prop="selectedAsinType"
                label="匹配方式"
                width="100">
            </el-table-column>
            <el-table-column label="应用建议竞价">
              <el-table-column
                  prop="rangeStart"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="rangeMedian"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="rangeEnd"
                  width="100">
              </el-table-column>
            </el-table-column>
            <el-table-column
                label="竞价调整"
                width="200">
              <template slot-scope="scope">
                <el-input v-model="scope.row.bidding"
                          placeholder="请输入数量">
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogAsinFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogAsinForm">下一步</el-button>
        </div>
      </el-dialog>
      <el-dialog title="受众定位" :visible.sync="dialogAudienceFormVisible" :close-on-click-modal="false">
        <el-form label-width="80px">
          <el-row>
            <el-form-item label="广告活动">
              <span>{{ dialogData.ad_activities }}</span>
            </el-form-item>
            <el-form-item label="广告组">
              <span>{{ dialogData.ad_activities }}</span>
            </el-form-item>
            <el-button icon="el-icon-refresh" @click="handleRecommendedBiddingClick" style="padding-bottom: 10px"
                       :disabled="disabled">
              获取推荐竞价
            </el-button>
          </el-row>
          <el-table
              :data="tableRecommendationAudienceData"
              style="width: 100%"
              max-height="250">
            <el-table-column
                prop="category"
                label="匹配方式"
                width="140">
            </el-table-column>
            <el-table-column
                prop="audienceName"
                label="受众"
                width="150">
            </el-table-column>
            <el-table-column
                prop="backtrackingDaysValue"
                label="回溯期"
                width="100">
            </el-table-column>
            <el-table-column
                prop="selectedAsinType"
                label="潜在消费者数量"
                width="100">
            </el-table-column>
            <el-table-column label="应用建议竞价">
              <el-table-column
                  prop="rangeStart"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="rangeMedian"
                  width="100">
              </el-table-column>
              <el-table-column
                  prop="rangeEnd"
                  width="100">
              </el-table-column>
            </el-table-column>
            <el-table-column
                label="竞价调整"
                width="200">
              <template slot-scope="scope">
                <el-input v-model="scope.row.bidding"
                          placeholder="请输入数量">
                </el-input>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogAudienceFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogAudienceForm">下一步</el-button>
        </div>
      </el-dialog>
    </el-col>
    <el-col :span="24" style="padding: 20px 600px">
      <el-button @click="handleUpClick">上一步</el-button>
      <el-button type="primary" plain @click="handleAdNextClick" :disabled="disableButton">投放</el-button>
    </el-col>
  </div>
</template>

<script>
import axios from 'axios'
import {Message} from 'element-ui'

export default {
  name: "AdServing",
  data() {
    return {
      dialogSdNegativeFormVisible: false,
      disabled: false,
      tableRecommendationAudienceData: [],
      dialogAudienceFormVisible: false,
      isBrandRadioDisabled: false,
      filterBrandValue: '',
      disableButton: false,
      activeAsinName: 'first',
      tableBrandData: [],
      negativeTargetingData: [],
      negativeHierarchy: '',
      negativeOptions: [
        {label: '在广告活动层级', value: '在广告活动层级'},
        {label: '在广告组层级', value: '在广告组层级'},
      ],
      negativeKeywordData: [],
      negativeKeywordAllData: [],
      negativeKeywordInput: '',
      radioNegativeAsin: 1,
      radioNegative: 1,
      tableRecommendationNegativeData: [],
      biddingStrategies: [
        {label: '动态竞价-只降低', value: 'LEGACY_FOR_SALES'},
        {label: '动态竞价-提高和降低', value: 'AUTO_FOR_SALES'},
        {label: '固定竞价', value: 'MANUAL'},
      ],
      selectedAutoType: '',
      autoColumnLabel: '应用建议竞价',
      typeAutoOptions: [
        {label: '应用建议竞价-上限', value: '应用建议竞价-上限'},
        {label: '应用建议竞价', value: '应用建议竞价'},
        {label: '应用建议竞价-下限', value: '应用建议竞价-下限'},
      ],
      typeOptions: [
        {label: '宽泛匹配', value: 'KW'},
        {label: '精准匹配', value: 'LW'},
        {label: '词组匹配', value: 'SW'},
      ],
      tableAutoData: [],
      selectionInfo: [],
      tableInfoData: [],
      tableCategoriesData: [],
      tableRecommendationAsinData: [],
      tableKeywordData: [],
      dialogData: {},
      dialogSpNegativeFormVisible: false,
      dialogSbNegativeFormVisible: false,
      dialogAutoFormVisible: false,
      dialogAsinFormVisible: false,
      dialogCategoriesFormVisible: false,
      dialogKeywordFormVisible: false,
      selectionProduct: [],
      advertisingStrategyData: [],
      baseFontSize: 18,
      baseLineHeight: 1.8,
      types: '',
      strategy: '',
      src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
      campaignId: '',
      adGroupId: '',
      ad_group_options: [],
      dialogNegativeData: {},
      name: ''
    }
  },
  methods: {
    //创建SD活动
    handleSdCampaignsClick(ad) {
      const sendAdSdGroupRequest = (advertising) => {
        this.sendAdSdGroupRequest(advertising)
      };
      if (ad.startDate !== '' && ad.endDate !== '') {
        let startDate = ad.startDate.replace(/-/g, '');
        let endDate = ad.endDate.replace(/-/g, '');
        let campaigns = []
        if (ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "daily",
            "portfolioId": ad.selectedPortfoliosValue,
            "name": ad.ad_activities,
            "tactic": ad.tactic,
            "state": "enabled",
            "costType": ad.costType,
            "startDate": startDate,
            "endDate": endDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        } else if (!ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "daily",
            "name": ad.ad_activities,
            "tactic": ad.tactic,
            "state": "enabled",
            "costType": ad.costType,
            "startDate": startDate,
            "endDate": endDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        }
        axios.post('https://ad.yaelongtime.com/api/proxy/sd/campaigns',
            campaigns, {
              headers: {
                'Content-Type': 'application/json'
              }
            }
        ).then(res => {
          if (res.data[0].code === 'SUCCESS') {
            ad.campaignId = res.data[0].campaignId
            sendAdSdGroupRequest(ad);
          } else {
            this.$message.success(ad.ad_activities + '已存在')
          }
        })
      } else if (ad.startDate !== '' && ad.endDate === '') {
        let startDate = ad.startDate.replace(/-/g, '');
        let campaigns = []
        if (ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "daily",
            "portfolioId": ad.selectedPortfoliosValue,
            "name": ad.ad_activities,
            "tactic": ad.tactic,
            "state": "enabled",
            "costType": ad.costType,
            "startDate": startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        } else if (!ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "daily",
            "name": ad.ad_activities,
            "tactic": ad.tactic,
            "state": "enabled",
            "costType": ad.costType,
            "startDate": startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        }
        axios.post('https://ad.yaelongtime.com/api/proxy/sd/campaigns',
            campaigns, {
              headers: {
                'Content-Type': 'application/json'
              }
            }
        ).then(res => {
          if (res.data[0].code === 'SUCCESS') {
            ad.campaignId = res.data[0].campaignId
            sendAdSdGroupRequest(ad);
          } else {
            this.$message.error(ad.ad_activities + '已存在')
          }
        }).catch(error => {
          Message({
            message: error,
            type: 'error',
          });
        })
      }
    },
    //创建SD广告组
    sendAdSdGroupRequest(row) {
      const sendAdProductRequest = (row) => {
        this.sendAdProductRequest(row)
      };
      axios.post('https://ad.yaelongtime.com/api/proxy/sd/adGroups',
          [
            {
              "campaignId": row.campaignId,
              "name": row.selectedGroupsValue,
              "bidOptimization": row.bidOptimization,
              "state": "enabled",
              "defaultBid": parseFloat(row.defaultBidding),
            }
          ],
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
      ).then(res => {
        if (res.data[0].code === 'SUCCESS') {
          row.adGroupId = res.data[0].adGroupId
          sendAdProductRequest(row)
        } else if (res.data.adGroups.error) {
          this.$message.error(row.ad_activities + '类目/asin广告组已存在')
        }
      }).catch(error => {
        Message({
          message: error,
          type: 'error',
        });
      })
    },
    //SD产品
    sendAdProductRequest(advertising) {
      const sendSdAdTargetsRequest = (advertising) => {
        this.sendSdAdTargetsRequest(advertising)
      };
      const sendSdAdAudiencesRequest = (advertising) => {
        this.sendSdAdAudiencesRequest(advertising)
      };
      let productAdsList = []
      if (typeof advertising.asin === 'object' && Array.isArray(advertising.asin)) {
        advertising.asin.forEach(function (product) {
          let productAdsDict = {
            "campaignId": advertising.campaignId,
            "state": "enabled",
            "sku": product.sku,
            "adGroupId": advertising.adGroupId
          }
          productAdsList.push(productAdsDict)
        })
      } else {
        let productAds = {
          "campaignId": advertising.campaignId,
          "state": "enabled",
          "sku": advertising.sku,
          "adGroupId": advertising.adGroupId
        }
        productAdsList.push(productAds)
      }
      axios.post('https://ad.yaelongtime.com/api/proxy/sd/productAds',
          productAdsList,
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
      ).then(res => {
        if (res.data[0].code === 'SUCCESS') {
          if (advertising.matching_method.includes('品类投放')) {
            sendSdAdTargetsRequest(advertising)
          } else if (advertising.matching_method === '受众') {
            sendSdAdAudiencesRequest(advertising)
          }
        } else if (res.data.adGroups.error) {
          this.$message.error(advertising.ad_activities + '产品绑定失败')
        }
      }).catch(error => {
        Message({
          message: error,
          type: 'error',
        });
      })
    },
    //SD品类投放
    sendSdAdTargetsRequest(advertising) {
      const sendNegativeRequest = (advertising) => {
        this.sendNegativeRequest(advertising)
      };
      const sendCreativesRequest = (advertising) => {
        this.sendCreativesRequest(advertising)
      };
      let targetsList = []
      let expression = []
      advertising.keyword.forEach(function (targets) {
        if (advertising.matching_method === '品类投放 — 类目') {
          if (targets.positioning.includes('分类细化')) {
            let category = [{
              value: targets.id,
              type: "asinCategorySameAs"
            },]
            if (targets.brand !== '') {
              let refineCategory = {
                type: 'asinBrandSameAs',
                value: targets.brand
              }
              category.push(refineCategory)
            } else if (targets.startPrice !== '' && targets.endPrice !== '') {
              let refineCategory = {
                type: 'asinPriceBetween',
                value: (parseInt(targets.startPrice) - parseInt(targets.endPrice)).toString()
              }
              category.push(refineCategory)
            } else if (targets.startStar !== undefined && targets.endStar !== undefined) {
              let refineCategory = {
                type: 'asinReviewRatingBetween',
                value: (parseInt(targets.startStar) - parseInt(targets.endStar)).toString()
              }
              category.push(refineCategory)
            } else {
              let refineCategory = {
                value: targets.id,
                type: "asinCategorySameAs"
              }
              category.push(refineCategory)
            }
            expression.push(category)
          }
          if (targets.positioning.includes("类目细化")) {
            let targetsDict = {
              "expressions": expression,
              "bid": targets.bidding,
              "adGroupId": advertising.adGroupId,
              "expressionType": "manual",
              "state": "enabled"
            }
            targetsList.push(targetsDict)
          } else if (targets.na !== '类似于推广的产品') {
            let targetsDict = {
              "expression": [
                {
                  "type": "asinCategorySameAs",
                  "value": targets.id
                }
              ],
              "bid": targets.bidding,
              "adGroupId": advertising.adGroupId,
              "expressionType": "manual",
              "state": "enabled"
            }
            targetsList.push(targetsDict)
          } else {
            let targetsDict = {
              "expression": [
                {
                  "type": "similarProduct",
                }
              ],
              "bid": targets.bidding,
              "adGroupId": advertising.adGroupId,
              "expressionType": "manual",
              "state": "enabled"
            }
            targetsList.push(targetsDict)
          }
        } else if (advertising.matching_method === '品类投放 — ASIN') {
          let targetsDict = {
            "expression": [
              {
                "type": "asinSameAs",
                "value": targets.recommendedAsin
              }
            ],
            "bid": targets.bidding,
            "adGroupId": advertising.adGroupId,
            "expressionType": "manual",
            "state": "enabled"
          }
          targetsList.push(targetsDict)
        }
      })
      this.$axios.post('https://ad.yaelongtime.com/api/proxy/sd/targets', targetsList, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        if (res.data[0].code === 'SUCCESS') {
          if (advertising.customImageAssetId || advertising.customVideoAssetId || advertising.brandLogoAssetID || advertising.brandName !== '') {
            sendCreativesRequest(advertising)
          }
          if (advertising.tableRecommendationNegativeData.length > 0) {
            sendNegativeRequest(advertising)
          } else {
            advertising.success = true
            Message({
              message: advertising.ad_activities + '投放成功',
              type: 'success',
            });
          }
        } else {
          Message({
            message: advertising.ad_activities + '投放失败',
            type: 'success',
          });
        }
      })
    },
    //SD受众投放
    sendSdAdAudiencesRequest(advertising) {
      const sendNegativeRequest = (advertising) => {
        this.sendNegativeRequest(advertising)
      };
      const sendCreativesRequest = (advertising) => {
        this.sendCreativesRequest(advertising)
      };
      let audiencesList = []
      advertising.keyword.forEach(function (audiences) {
        if (advertising.typeText === '亚马逊消费者') {
          let audiencesDict = {
            "expression": [
              {
                "type": "audience",
                "value": [
                  {
                    "type": "audienceSameAs",
                    "value": (audiences.id).toString()
                  },
                ]
              }
            ],
            "bid": audiences.bidding,
            "adGroupId": advertising.adGroupId,
            "expressionType": "manual",
            "state": "enabled"
          }
          audiencesList.push(audiencesDict)
        } else if (advertising.typeText === '再营销浏览定向') {
          if (audiences.audienceName === '类别') {
            audiences.categories.forEach(function (categories) {
              let audiencesDict = {
                "expression": [
                  {
                    "type": "views",
                    "value": [
                      {
                        "type": "asinCategorySameAs",
                        "value": categories.id
                      },
                      {
                        "type": "lookback",
                        "value": audiences.backtrackingDaysValue
                      }
                    ]
                  }
                ],
                "bid": audiences.bidding,
                "adGroupId": advertising.adGroupId,
                "expressionType": "manual",
                "state": "enabled"
              }
              audiencesList.push(audiencesDict)
            })
          } else if (audiences.audienceName === '您推广的商品') {
            let audiencesDict = {
              "expression": [
                {
                  "type": "views",
                  "value": [
                    {
                      "type": "exactProduct"
                    },
                    {
                      "type": "lookback",
                      "value": audiences.backtrackingDaysValue
                    }
                  ]
                }
              ],
              "bid": audiences.bidding,
              "adGroupId": advertising.adGroupId,
              "expressionType": "manual",
              "state": "enabled"
            }
            audiencesList.push(audiencesDict)
          } else if (audiences.audienceName === '与您推广商品类似的商品') {
            let audiencesDict = {
              "expression": [
                {
                  "type": "views",
                  "value": [
                    {
                      "type": "similarProduct",
                    },
                    {
                      "type": "lookback",
                      "value": audiences.backtrackingDaysValue
                    }
                  ]
                }
              ],
              "bid": audiences.bidding,
              "adGroupId": advertising.adGroupId,
              "expressionType": "manual",
              "state": "enabled"
            }
            audiencesList.push(audiencesDict)
          }
        } else if (advertising.typeText === '购买再营销') {
          if (audiences.audienceName === '类别') {
            audiences.categories.forEach(function (categories) {
              let audiencesDict = {
                "expression": [
                  {
                    "type": "purchases",
                    "value": [
                      {
                        "type": "asinCategorySameAs",
                        "value": categories.id
                      },
                      {
                        "type": "lookback",
                        "value": audiences.backtrackingDaysValue
                      }
                    ]
                  }
                ],
                "bid": audiences.bidding,
                "adGroupId": advertising.adGroupId,
                "expressionType": "manual",
                "state": "enabled"
              }
              audiencesList.push(audiencesDict)
            })
          } else if (audiences.audienceName === '您推广的商品') {
            let audiencesDict = {
              "expression": [
                {
                  "type": "purchases",
                  "value": [
                    {
                      "type": "exactProduct",
                    },
                    {
                      "type": "lookback",
                      "value": audiences.backtrackingDaysValue
                    }
                  ]
                }
              ],
              "bid": audiences.bidding,
              "adGroupId": advertising.adGroupId,
              "expressionType": "manual",
              "state": "enabled"
            }
            audiencesList.push(audiencesDict)
          } else if (audiences.audienceName === '与您推广商品类似的商品') {
            let audiencesDict = {
              "expression": [
                {
                  "type": "purchases",
                  "value": [
                    {
                      "type": "relatedProduct",
                    },
                    {
                      "type": "lookback",
                      "value": audiences.backtrackingDaysValue
                    }
                  ]
                }
              ],
              "bid": audiences.bidding,
              "adGroupId": advertising.adGroupId,
              "expressionType": "manual",
              "state": "enabled"
            }
            audiencesList.push(audiencesDict)
          }
        }
      })
      this.$axios.post('https://ad.yaelongtime.com/api/proxy/sd/targets', audiencesList, {
        headers: {
          'Content-Type': 'application/json'
        },
      }).then(res => {
        if (res.data[0].code === 'SUCCESS') {
          if (advertising.customImageAssetId || advertising.customVideoAssetId || advertising.brandLogoAssetID || advertising.brandName !== '') {
            sendCreativesRequest(advertising)
          } else if (advertising.tableRecommendationNegativeData.length > 0) {
            sendNegativeRequest(advertising)
          } else {
            advertising.success = true
            Message({
              message: advertising.ad_activities + '投放成功',
              type: 'success',
            });
          }
        } else {
          Message({
            message: advertising.ad_activities + '投放失败',
            type: 'success',
          });
        }
      })
    },
    //SD广告素材
    sendCreativesRequest(advertising) {
      let properties = {}
      if (advertising.brandName !== '') {
        properties = {
          headline: advertising.brandName
        }
      } else if (advertising.brandLogoAssetID) {
        properties = {
          brandLogo: {
            "assetId": advertising.brandLogoAssetID,
            "assetVersion": "version_v1",
            "croppingCoordinates": {
              "top": 0,
              "left": 0,
              "width": 600,
              "height": 100
            }
          }
        }
      } else if (advertising.customImageAssetId) {
        properties = {
          rectCustomImage: {
            "assetId": advertising.customImageAssetId,
            "assetVersion": "version_v1",
            "croppingCoordinates": {
              "top": 0,
              "left": 0,
              "width": 1200,
              "height": 628
            }
          },
          "squareCustomImage": {
            "assetId": advertising.customImageAssetId,
            "assetVersion": "version_v1",
            "croppingCoordinates": {
              "top": 0,
              "left": 0,
              "width": 628,
              "height": 628
            }
          }
        }
      } else if (advertising.customVideoAssetId) {
        properties = {
          video: {
            "assetId": advertising.customVideoAssetId,
            "assetVersion": "version_v1"
          },
          "squareVideos": [
            {
              "assetId": advertising.customVideoAssetId,
              "assetVersion": "version_v1",
            }
          ]
        }
      }
      this.$axios.post('https://ad.yaelongtime.com/api/proxy/sd/creatives',
          [
            {
              "adGroupId": advertising.groupId,
              "properties": properties
            }
          ]
      ).then(res => {
        console.log(44, res)
        if (res.data[0].code === 207) {
          advertising.success = true
          Message({
            message: advertising.ad_activities + '投放成功',
            type: 'success',
          });
        } else {
          Message({
            message: advertising.ad_activities + '投放失败',
            type: 'success',
          });
        }
      })
    },
    //获取SD竞价
    handleSdBiddingClick(row) {
      let data = {}
      let $axios = this.$axios
      let products = []
      if (typeof row.asin === 'object' && Array.isArray(row.asin)) {
        row.asin.forEach(function (product) {
          let productAdsDict = {
            "asin": product.asin
          }
          products.push(productAdsDict)
        })
      } else {
        let productAds = {
          "asin": row.asin
        }
        products.push(productAds)
      }
      row.keyword.forEach(function (audience) {
        if (row.matching_method === '受众') {
          if (row.typeText === '亚马逊消费者') {
          } else if (row.typeText === '再营销浏览定向') {
            if (audience.audienceName === '您推广的商品') {
              data = {
                "products": products,
                "bidOptimization": row.bidOptimization,
                "costType": row.costType,
                "targetingClauses": [
                  {
                    "targetingClause": {
                      "expressionType": "manual",
                      "expression": [
                        {
                          "type": "views",
                          "value": [
                            {
                              "type": "exactProduct",
                            },
                            {
                              "type": "lookback",
                              "value": audience.backtrackingDaysValue
                            }
                          ]
                        }
                      ]
                    }
                  }
                ]
              }
            } else if (audience.audienceName === '与您推广商品类似的商品') {
              data = {
                "products": products,
                "bidOptimization": row.bidOptimization,
                "costType": row.costType,
                "targetingClauses": [
                  {
                    "targetingClause": {
                      "expressionType": "manual",
                      "expression": [
                        {
                          "type": "views",
                          "value": [
                            {
                              "type": "similarProduct",
                            },
                            {
                              "type": "lookback",
                              "value": audience.backtrackingDaysValue
                            }
                          ]
                        }
                      ]
                    }
                  }
                ]
              }
            } else {
              data = {
                "products": products,
                "bidOptimization": row.bidOptimization,
                "costType": row.costType,
                "targetingClauses": [
                  {
                    "targetingClause": {
                      "expressionType": "manual",
                      "expression": [
                        {
                          "type": "views",
                          "value": [
                            {
                              "type": "asinCategorySameAs",
                              value: audience.id
                            },
                            {
                              "type": "lookback",
                              "value": audience.backtrackingDaysValue
                            }
                          ]
                        }
                      ]
                    }
                  }
                ]
              }

            }
          } else if (row.typeText === '购买再营销') {
            if (audience.audienceName === '您推广的商品') {
              data = {
                "products": products,
                "bidOptimization": row.bidOptimization,
                "costType": row.costType,
                "targetingClauses": [
                  {
                    "targetingClause": {
                      "expressionType": "manual",
                      "expression": [
                        {
                          "type": "purchases",
                          "value": [
                            {
                              "type": "exactProduct",
                            },
                            {
                              "type": "lookback",
                              "value": audience.backtrackingDaysValue
                            }
                          ]
                        }
                      ]
                    }
                  }
                ]
              }
            } else if (audience.audienceName === '与您推广商品类似的商品') {
              data = {
                "products": products,
                "bidOptimization": row.bidOptimization,
                "costType": row.costType,
                "targetingClauses": [
                  {
                    "targetingClause": {
                      "expressionType": "manual",
                      "expression": [
                        {
                          "type": "purchases",
                          "value": [
                            {
                              "type": "similarProduct",
                            },
                            {
                              "type": "lookback",
                              "value": audience.backtrackingDaysValue
                            }
                          ]
                        }
                      ]
                    }
                  }
                ]
              }
            } else {
              data = {
                "products": products,
                "bidOptimization": row.bidOptimization,
                "costType": row.costType,
                "targetingClauses": [
                  {
                    "targetingClause": {
                      "expressionType": "manual",
                      "expression": [
                        {
                          "type": "purchases",
                          "value": [
                            {
                              "type": "asinCategorySameAs",
                              value: audience.id
                            },
                            {
                              "type": "lookback",
                              "value": audience.backtrackingDaysValue
                            }
                          ]
                        }
                      ]
                    }
                  }
                ]
              }
            }
          }
        } else {
          if (row.matching_method === '品类投放 — ASIN') {
            data = {
              "bidOptimization": row.bidOptimization,
              "costType": row.costType,
              "targetingClauses": [
                {
                  "targetingClause": {
                    "expressionType": row.matching_method_type,
                    "expression": [
                      {
                        "type": "asinSameAs",
                        "value": audience.recommendedAsin
                      }
                    ]
                  }
                }
              ]
            }
          } else if (row.matching_method === '品类投放 — 类目') {
            if (audience.na === '类似于推广的产品') {
              data = {
                "products": products,
                "bidOptimization": row.bidOptimization,
                "costType": row.costType,
                "targetingClauses": [
                  {
                    "targetingClause": {
                      "expressionType": row.matching_method_type,
                      "expression": [
                        {
                          "type": "similarProduct"
                        }
                      ]
                    }
                  }
                ]
              }
            } else {
              data = {
                "bidOptimization": row.bidOptimization,
                "costType": row.costType,
                "targetingClauses": [
                  {
                    "targetingClause": {
                      "expressionType": row.matching_method_type,
                      "expression": [
                        {
                          "type": "asinCategorySameAs",
                          "value": audience.id
                        }
                      ]
                    }
                  }
                ]
              }
            }
          }
        }
        $axios.post('https://ad.yaelongtime.com/api/proxy/sd/targets/bid/recommendations', data, {
              headers: {
                'Content-Type': 'application/vnd.sdtargetingrecommendations.v3.2+json'
              },
            }
        ).then(res => {
          if (res.data.bidRecommendations) {
            res.data.bidRecommendations.forEach(function (bidding) {
              if (bidding.code === '200') {
                audience.rangeStart = bidding.rangeLower
                audience.rangeMedian = bidding.recommended
                audience.rangeEnd = bidding.rangeUpper
                audience.bidding = (parseInt(row.selectedType) / 10 * bidding.rangeUpper).toFixed(2)
              } else {
                audience.rangeStart = '~'
                audience.rangeMedian = '~'
                audience.rangeEnd = '~'
                audience.bidding = row.defaultBidding
              }
            })
          } else {
            audience.rangeStart = '~'
            audience.rangeMedian = '~'
            audience.rangeEnd = '~'
            audience.bidding = row.defaultBidding
          }
        })
      })
    },
    //无关键词、类目、asin是否投放
    handleAdNextClick() {
      const allKeywordsHaveValue = this.advertisingStrategyData.every(item => item.keyword && item.keyword.length === 0);
      const allKeywordsValue = this.advertisingStrategyData.every(item => !item.keyword);
      const allVcpmHaveValue = this.advertisingStrategyData.every(item => item.costType === 'vcpm' && item.defaultBidding < 1);
      let $message = this.$message
      let shouldExecuteHandleNextClick = true;
      if (allKeywordsHaveValue === true || allKeywordsValue === true) {
        this.$confirm('无对应关键词、类目、ASIN', '确认信息', {
          distinguishCancelAndClose: true,
          confirmButtonText: '投放',
          cancelButtonText: '放弃投放'
        }).then(() => {
          this.handleNextClick()
        }).catch(action => {
          Message({
            type: 'warning',
            message: action === 'cancel'
                ? '放弃投放'
                : '停留在当前页面'
          })
        });
      } else if (allVcpmHaveValue === true) {
        $message.error('默认竞价小于1,无法投放')
      } else {
        this.advertisingStrategyData.forEach(function (ad) {
          if (ad.costType === 'vcpm') {
            ad.keyword.forEach(function (keyword) {
              if (keyword.bidding >= ad.budget / 2) {
                shouldExecuteHandleNextClick = false;
              }
            })
          }
        })
        if (shouldExecuteHandleNextClick) {
          this.handleNextClick();
        } else {
          this.$message.error('竞价大于预算的一半，无法投放')
        }
      }
    },
    handleNegativeRadioAsinChange() {
    },
    handleNegativeKeywordClick() {
    },
    //清空查询品牌
    refreshBrand() {
      this.filterBrandValue = ''
      this.tableBrandData = []
    },
    //获取SP否定投放品牌
    searchBrand() {
      let radioNegativeAsin = this.radioNegativeAsin
      let negativeHierarchy = this.negativeHierarchy
      if (negativeHierarchy) {
        if (this.filterBrandValue) {
          this.$axios.post('https://ad.yaelongtime.com/api/proxy/sp/negativeTargets/brands/search',
              {
                "keyword": this.filterBrandValue
              },
              {
                headers: {
                  'Content-Type': 'application/vnd.spproducttargeting.v3+json'
                },
              }
          ).then(res => {
            let brandList = []
            res.data.forEach(function (brand) {
              if (radioNegativeAsin === 2) {
                let brandDict = {
                  negativeText: brand.name,
                  id: brand.id,
                  disabled: false,
                  matchType: '否定品牌',
                  negativeHierarchy: negativeHierarchy,
                  type: '商品'
                }
                brandList.push(brandDict)
              }
            })
            this.tableBrandData = brandList
          })
        }
      } else {
        Message({
          message: '缺少层级',
          type: 'error',
        });
      }
    },
    //获取SB/SD否定投放品牌
    searchSbBrand() {
      let radioNegativeAsin = this.radioNegativeAsin
      let negativeHierarchy = this.negativeHierarchy
      if (negativeHierarchy) {
        if (this.filterBrandValue) {
          this.$axios.post('https://ad.yaelongtime.com/api/proxy/sp/negativeTargets/brands/search',
              {
                "keyword": this.filterBrandValue
              },
              {
                headers: {
                  'Content-Type': 'application/vnd.spproducttargeting.v3+json'
                },
              }
          ).then(res => {
            let brandList = []
            res.data.forEach(function (brand) {
              if (radioNegativeAsin === 2) {
                let brandDict = {
                  negativeText: brand.name,
                  id: brand.id,
                  disabled: false,
                  matchType: '否定品牌',
                  negativeHierarchy: negativeHierarchy,
                  type: '商品'
                }
                brandList.push(brandDict)
              }
            })
            this.tableBrandData = brandList
          })
        }
      } else {
        Message({
          message: '缺少层级',
          type: 'error',
        });
      }
    },
    //清除SB/SD否定品牌
    refreshSbBrand() {
      this.searchSbBrand()
    },
    //获取建议竞价
    handleRecommendedBiddingClick() {
      if (this.dialogData.ad_activities.substring(0, 2).includes('SP')) {
        if (this.dialogData.ad_activities.includes('KW') || this.dialogData.ad_activities.includes('LW') || this.dialogData.ad_activities.includes('SW')) {
          this.sendKeywordCampaignsData(this.dialogData)
        } else if (this.dialogData.ad_activities.includes('SPP')) {
          this.handleAsinCampaignsClick(this.dialogData)
        }
      }
      if (this.dialogData.ad_activities.substring(0, 2).includes('SB')) {
        if (this.dialogData.ad_activities.includes('KW') || this.dialogData.ad_activities.includes('LW') || this.dialogData.ad_activities.includes('SW')) {
          this.sendSbKeywordCampaignsData(this.dialogData)
        } else if (this.dialogData.ad_activities.substring(0, 7).includes('A')) {
          this.sendSbKeywordCampaignsData(this.dialogData)
        } else if (this.dialogData.advertising_strategy.substring(0, 7).includes('C')) {
          this.sendSbKeywordCampaignsData(this.dialogData)
        }
      } else if (this.dialogData.ad_activities.substring(0, 2).includes('SD')) {
        this.handleSdBiddingClick(this.dialogData)
      }
    },
    //关闭否定表单
    dialogNegativeVisible() {
      this.dialogSpNegativeFormVisible = false
      this.dialogSbNegativeFormVisible = false
      this.dialogSdNegativeFormVisible = false
      this.dialogNegativeData.negativeLength = this.dialogNegativeData.tableRecommendationNegativeData.length
    },
    //添加否定表单
    openNegativeDialog(row) {
      if (row.advertising_strategy.includes('SPA')) {
        this.isBrandRadioDisabled = true
      } else {
        this.isBrandRadioDisabled = false
      }
      if (row.advertising_strategy.includes('SP')) {
        this.dialogSpNegativeFormVisible = true
      } else if (row.advertising_strategy.includes('SB')) {
        this.dialogSbNegativeFormVisible = true
      } else if (row.advertising_strategy.includes('SD')) {
        this.dialogSdNegativeFormVisible = true
      }
      this.tableRecommendationNegativeData = row.tableRecommendationNegativeData
      this.dialogNegativeData = row
    },
    //添加否定品牌
    handleRecommendationBrandClick(row) {
      // 将选中行数据添加到目标表格
      this.tableRecommendationNegativeData.push(row);
      // 禁用按钮
      row.disabled = true;
    },
    //手动添加否定ASIN
    AddNegativeAsinManually() {
      let radioNegativeAsin = this.radioNegativeAsin
      let negativeHierarchy = this.negativeHierarchy
      let tableRecommendationNegativeData = this.tableRecommendationNegativeData
      const negativeAsins = this.negativeKeywordInput.trim(); // 去除首尾空白字符
      if (negativeHierarchy) {
        if (negativeAsins) {
          const negativeAsinArray = negativeAsins.split('\n'); // 将输入的关键词按行拆分成数组
          negativeAsinArray.forEach(function (negativeAsin) {
            if (radioNegativeAsin === 1) {
              let negativeAsinDict = {
                negativeText: negativeAsin,
                matchType: '否定Asin',
                negativeHierarchy: negativeHierarchy,
                type: '商品'
              }
              tableRecommendationNegativeData.push(negativeAsinDict)
            }
          })
          this.dialogNegativeData.tableRecommendationNegativeData = tableRecommendationNegativeData
          this.negativeKeywordInput = ''
        } else {
          Message({
            message: '输入不能为空',
            type: 'error'
          })
        }
      } else {
        Message({
          message: '缺少层级',
          type: 'error'
        })
      }
    },
    //添加SP/SD否定关键词
    handleRecommendationNegativeKeywordClick() {
      let radioNegative = this.radioNegative
      let negativeHierarchy = this.negativeHierarchy
      let tableRecommendationNegativeData = this.tableRecommendationNegativeData
      const negativeKeywords = this.negativeKeywordInput.trim(); // 去除首尾空白字符
      if (negativeKeywords) {
        const negativeKeywordArray = negativeKeywords.split('\n'); // 将输入的关键词按行拆分成数组
        negativeKeywordArray.forEach(function (negativeKeyword) {
          if (negativeHierarchy) {
            if (radioNegative === 1) {
              let negativeKeywordDict = {
                negativeText: negativeKeyword,
                matchType: '否定精准',
                negativeHierarchy: negativeHierarchy,
                typeInfo: 'NEGATIVE_EXACT',
                type: '关键词'
              }
              tableRecommendationNegativeData.push(negativeKeywordDict)

            } else if (radioNegative === 2) {
              let negativeKeywordDict = {
                negativeText: negativeKeyword,
                matchType: '否定词组',
                negativeHierarchy: negativeHierarchy,
                typeInfo: 'NEGATIVE_PHRASE',
                type: '关键词'
              }
              tableRecommendationNegativeData.push(negativeKeywordDict)
            }
          } else {
            Message({
              message: '输入为空',
              type: 'error'
            })
          }

        })
        this.dialogNegativeData.tableRecommendationNegativeData = tableRecommendationNegativeData
        this.negativeKeywordInput = ''
      } else {
        Message({
          message: "缺少层级",
          type: 'error'
        })
      }
    },
    //添加SP否定关键词
    handleSbRecommendationNegativeKeywordClick() {
      let radioNegative = this.radioNegative
      let negativeHierarchy = this.negativeHierarchy
      let tableRecommendationNegativeData = this.tableRecommendationNegativeData
      const negativeKeywords = this.negativeKeywordInput.trim(); // 去除首尾空白字符
      if (negativeKeywords) {
        const negativeKeywordArray = negativeKeywords.split('\n'); // 将输入的关键词按行拆分成数组
        negativeKeywordArray.forEach(function (negativeKeyword) {
          if (negativeHierarchy) {
            if (radioNegative === 1) {
              let negativeKeywordDict = {
                negativeText: negativeKeyword,
                matchType: '否定精准',
                negativeHierarchy: negativeHierarchy,
                typeInfo: 'negativeExact',
                type: '关键词'
              }
              tableRecommendationNegativeData.push(negativeKeywordDict)

            } else if (radioNegative === 2) {
              let negativeKeywordDict = {
                negativeText: negativeKeyword,
                matchType: '否定词组',
                negativeHierarchy: negativeHierarchy,
                typeInfo: 'negativePhrase',
                type: '关键词'
              }
              tableRecommendationNegativeData.push(negativeKeywordDict)
            }
          } else {
            Message({
              message: '输入为空',
              type: 'error'
            })
          }

        })
        this.dialogNegativeData.tableRecommendationNegativeData = tableRecommendationNegativeData
        this.negativeKeywordInput = ''
      } else {
        Message({
          message: "缺少层级",
          type: 'error'
        })
      }
    },
    //否定词组显示
    handleNegativeRadioChange() {
      let negativeKeywordData = []
      if (this.radio === '1') {
        negativeKeywordData = this.negativeKeywordAllData[0]
      } else if (this.radio === '2') {
        negativeKeywordData = this.negativeKeywordAllData[1]
      }
      this.negativeKeywordData = negativeKeywordData
    },
    //删除否定关键词
    deleteRecommendedNegativeRow(index) {
      const deletedRow = this.tableRecommendationNegativeData[index];
      this.tableRecommendationNegativeData.splice(index, 1);
      if (this.tableBrandData.find(item => item === deletedRow)) {
        const sourceRow = this.tableBrandData.find(item => item === deletedRow);
        if (sourceRow) {
          sourceRow.disabled = false;
        }
      }
    },
    //活动删除
    handleDeleteClick(index) {
      this.advertisingStrategyData.splice(index, 1);
    },
    //点击投放广告
    handleNextClick() {
      const handleCampaignsClick = (ad) => {
        this.handleCampaignsClick(ad)
      };
      const sendKeywordRequest = (ad) => {
        this.sendKeywordRequest(ad)
      };
      const sendProductPositioningRequest = (ad) => {
        this.sendProductPositioningRequest(ad)
      };
      const handleSbCampaignsClick = (ad) => {
        this.handleSbCampaignsClick(ad)
      };
      const sendSbKeywordRequest = (ad) => {
        this.sendSbKeywordRequest(ad)
      };
      const sendSbProductPositioningRequest = (ad) => {
        this.sendSbProductPositioningRequest(ad)
      };
      const handleSdCampaignsClick = (ad) => {
        this.handleSdCampaignsClick(ad)
      };
      let disableButton = ''
      this.advertisingStrategyData.forEach(function (ad) {
        if (ad.success === true) {
          disableButton = true
        } else {
          if (ad.advertising_strategy.substring(0, 2).includes('SP')) {
            if (ad.adGroupId && ad.campaignId) {
              if (ad.advertising_strategy.includes('SPP')) {
                sendProductPositioningRequest(ad)
              } else if (ad.advertising_strategy.includes('SPA')) {
                handleCampaignsClick(ad)
              } else if (ad.advertising_strategy.includes('SPW')) {
                sendKeywordRequest(ad)
              }
            } else {
              handleCampaignsClick(ad)
            }
          } else if (ad.advertising_strategy.substring(0, 2).includes('SB')) {
            if (ad.adGroupId && ad.campaignId) {
              if (ad.matching_method === '关键词') {
                sendSbKeywordRequest(ad);
              } else if (ad.matching_method === '商品投放 — ASIN定位') {
                sendSbProductPositioningRequest(ad)
              } else if (ad.matching_method === '商品投放 — 类目定位') {
                sendSbProductPositioningRequest(ad)
              }
            } else {
              handleSbCampaignsClick(ad)
            }
          }
          if (ad.advertising_strategy.substring(0, 2).includes('SD')) {
            handleSdCampaignsClick(ad)
          }
        }
      })
      this.disableButton = disableButton
    },
    //创建SB广告活动
    handleSbCampaignsClick(ad) {
      const sendAdSbGroupRequest = (advertising) => {
        this.sendAdSbGroupRequest(advertising)
      };
      if (ad.startDate !== '' && ad.endDate !== '') {
        let campaigns = []
        if (ad.brandEntityId && ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "DAILY",
            "brandEntityId": ad.brandEntityId,
            "bidding": {
              "bidOptimization": true
            },
            "portfolioId": ad.selectedPortfoliosValue,
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        } else if (!ad.brandEntityId && !ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "DAILY",
            "bidding": {
              "bidOptimization": true
            },
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        } else if (ad.brandEntityId && !ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "DAILY",
            "brandEntityId": ad.brandEntityId,
            "bidding": {
              "bidOptimization": true
            },
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        } else if (!ad.brandEntityId && ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "DAILY",
            "bidding": {
              "bidOptimization": true
            },
            "portfolioId": ad.selectedPortfoliosValue,
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        }
        axios.post('https://ad.yaelongtime.com/api/proxy/sb/v4/campaign',
            {
              "campaigns": campaigns
            }, {
              headers: {
                'Content-Type': 'application/vnd.sbcampaignresource.v4+json'
              }
            }
        ).then(res => {
          if (res.data.campaigns.success) {
            ad.campaignId = res.data.campaigns.success[0].campaignId
            sendAdSbGroupRequest(ad);
          } else {
            this.$message.success(ad.ad_activities + '已存在')
          }
        })
      } else if (ad.startDate !== '' && ad.endDate === '') {
        let campaigns = []
        if (ad.brandEntityId && ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "DAILY",
            "brandEntityId": ad.brandEntityId,
            "bidding": {
              "bidOptimization": true
            },
            "portfolioId": ad.selectedPortfoliosValue,
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        } else if (!ad.brandEntityId && !ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "DAILY",
            "bidding": {
              "bidOptimization": true
            },
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        } else if (ad.brandEntityId && !ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "DAILY",
            "brandEntityId": ad.brandEntityId,
            "bidding": {
              "bidOptimization": true
            },
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        } else if (!ad.brandEntityId && ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "DAILY",
            "bidding": {
              "bidOptimization": true
            },
            "portfolioId": ad.selectedPortfoliosValue,
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        }
        axios.post('https://ad.yaelongtime.com/api/proxy/sb/v4/campaigns',
            {
              "campaigns": campaigns
            }, {
              headers: {
                'Content-Type': 'application/vnd.sbcampaignresource.v4+json'
              }
            }
        ).then(res => {
          if (res.data.campaigns.success) {
            ad.campaignId = res.data.campaigns.success[0].campaignId
            sendAdSbGroupRequest(ad);
          } else {
            this.$message.success(ad.ad_activities + '已存在')
          }
        }).catch(error => {
          Message({
            message: error,
            type: 'error',
          });
        })
      }
    },
    //创建SB广告组
    sendAdSbGroupRequest(advertising) {
      const sendSbAdRequest = (advertising) => {
        this.sendSbAdRequest(advertising)
      };
      if (advertising.adGroupId) {
        sendSbAdRequest();
      } else {
        axios.post('https://ad.yaelongtime.com/api/proxy/sb/v4/adGroups', {
              "adGroups": [
                {
                  "campaignId": advertising.campaignId,
                  "name": advertising.selectedGroupsValue,
                  "state": "ENABLED",
                }
              ]
            },
            {
              headers: {
                'Content-Type': 'application/vnd.sbadgroupresource.v4+json'
              }
            }
        ).then(res => {
          if (res.data.adGroups.success) {
            advertising.adGroupId = res.data.adGroups.success[0].adGroupId
            sendSbAdRequest(advertising);
          } else if (res.data.adGroups.error) {
            this.$message.success(advertising.ad_activities + '广告组已存在')
          }
        }).catch(error => {
          Message({
            message: error,
            type: 'error',
          });
        })
      }
    },
    //创建SB广告(判断广告类型，执行不同的广告类型)
    sendSbAdRequest(advertising) {
      const sendSbKeywordRequest = (advertising) => {
        this.sendSbKeywordRequest(advertising)
      };
      //审核
      const sendSbProductPositioningRequest = (row) => {
        this.sendSbProductPositioningRequest(row)
      }
      if (advertising.advertising_strategy.includes('SBI-P') || advertising.advertising_strategy.includes('SBI-SP')) {
        let landingPageAsinList = []
        advertising.asin.forEach(function (product) {
          landingPageAsinList.push(product.asin)
        })
        let pageType = ''
        let adsList = []
        if (advertising.advertising_strategy.includes('SBI-P')) {
          pageType = "PRODUCT_LIST"
          if (advertising.customImageAssetId) {
            adsList = [
              {
                "landingPage": {
                  "asins": landingPageAsinList,
                  "pageType": pageType,
                },
                "name": advertising.ad_activities,
                "state": "ENABLED",
                "adGroupId": advertising.adGroupId,
                "creative": {
                  "brandLogoCrop": {
                    "top": 0,
                    "left": 0,
                    "width": 400,
                    "height": 400
                  },
                  "asins": landingPageAsinList,
                  "brandName": advertising.brandName,
                  "customImageAssetId": 'advertising.customImageAssetId',
                  "customImageCrop": {
                    "top": 0,
                    "left": 0,
                    "width": 1200,
                    "height": 630
                  },
                  "brandLogoAssetID": advertising.brandLogoAssetID,
                  "headline": advertising.headline
                }
              }]
          } else {
            adsList = [
              {
                "landingPage": {
                  "asins": landingPageAsinList,
                  "pageType": pageType,
                },
                "name": advertising.ad_activities,
                "state": "ENABLED",
                "adGroupId": advertising.adGroupId,
                "creative": {
                  "brandLogoCrop": {
                    "top": 0,
                    "left": 0,
                    "width": 400,
                    "height": 400
                  },
                  "asins": landingPageAsinList,
                  "brandName": 'Potlimepan',
                  "brandLogoAssetID": advertising.brandLogoAssetID,
                  "headline": advertising.headline
                }
              }]
          }
        } else if (advertising.advertising_strategy.includes('SBI-SP')) {
          let asinList = []
          advertising.ProductsList.forEach(function (product) {
            asinList.push(product.asin)
          })
          pageType = "STORE"
          adsList = [
            {
              "landingPage": {
                "pageType": pageType,
                'url': advertising.landingPageUrl
              },
              "name": advertising.ad_activities,
              "state": "ENABLED",
              "adGroupId": advertising.adGroupId,
              "creative": {
                "brandLogoCrop": {
                  "top": 0,
                  "left": 0,
                  "width": 400,
                  "height": 400
                },
                "asins": asinList,
                "brandName": advertising.brandName,
                "brandLogoAssetID": advertising.brandLogoAssetID,
                "headline": advertising.headline
              }
            }]
        }
        axios.post('https://ad.yaelongtime.com/api/proxy/sb/v4/ads/productCollection', {
              "ads": adsList
            },
            {
              headers: {
                'Content-Type': 'application/vnd.sbadresource.v4+json'
              }
            }
        ).then(res => {
          if (res.data.ads.success[0]) {
            if (!advertising.keyword || advertising.keyword.length === 0) {
              advertising.success = true
              Message({
                message: advertising.ad_activities + '投放成功',
                type: 'success',
              });
            } else {
              if (advertising.matching_method === '关键词') {
                sendSbKeywordRequest(advertising);
              } else if (advertising.matching_method === '商品投放 — ASIN定位') {
                sendSbProductPositioningRequest(advertising)
              } else if (advertising.matching_method === '商品投放 — 类目定位') {
                sendSbProductPositioningRequest(advertising)
              }
            }

          } else {
            Message({
              message: advertising.productAds.error[0].errors[0].errorValue.message,
              type: 'error',
            });
          }
        }).catch(error => {
          Message({
            message: error,
            type: 'error',
          });
        })
      } else if (advertising.advertising_strategy.includes('SBI-S')) {
        let adsList = []
        let homeStoreUrl = ''
        advertising.storePageList.forEach(function (store) {
          if (store.storePageName === 'Home') {
            homeStoreUrl = store.storePageUrl
          }
        })
        adsList = [
          {
            "landingPage": {
              "url": homeStoreUrl
            },
            "name": advertising.ad_activities,
            "state": "ENABLED",
            "adGroupId": advertising.adGroupId,
            "creative": {
              "brandName": advertising.brandName,
              "brandLogoCrop": {
                "top": 0,
                "left": 0,
                "width": 400,
                "height": 400
              },
              "subpages": [
                {
                  "pageTitle": advertising.firstProductsList[0].storePageName,
                  "asin": advertising.firstProductsList[0].asin,
                  "url": advertising.firstProductsList[0].storePageUrl,
                },
                {
                  "pageTitle": advertising.secondProductsList[0].storePageName,
                  "asin": advertising.secondProductsList[0].asin,
                  "url": advertising.secondProductsList[0].storePageUrl,
                },
                {
                  "pageTitle": advertising.thirdProductsList[0].storePageName,
                  "asin": advertising.thirdProductsList[0].asin,
                  "url": advertising.thirdProductsList[0].storePageUrl,
                }
              ],
              "brandLogoAssetID": advertising.brandLogoAssetID,
              "headline": advertising.headline
            }
          }]
        axios.post('https://ad.yaelongtime.com/api/proxy/sb/v4/ads/storeSpotlight', {
              "ads": adsList
            },
            {
              headers: {
                'Content-Type': 'application/vnd.sbadresource.v4+json'
              }
            }
        ).then(res => {
          if (res.data.ads.success[0]) {
            if (!advertising.keyword || advertising.keyword.length === 0) {
              advertising.success = true
              Message({
                message: advertising.ad_activities + '投放成功',
                type: 'success',
              });
            } else {
              if (advertising.matching_method === '关键词') {
                sendSbKeywordRequest(advertising);
              } else if (advertising.matching_method === '商品投放 — ASIN定位') {
                sendSbProductPositioningRequest(advertising)
              } else if (advertising.matching_method === '商品投放 — 类目定位') {
                sendSbProductPositioningRequest(advertising)
              }
            }

          } else {
            Message({
              message: advertising.productAds.error[0].errors[0].errorValue.message,
              type: 'error',
            });
          }
        }).catch(error => {
          Message({
            message: error,
            type: 'error',
          });
        })
      } else if (advertising.advertising_strategy.includes('SBV-P') || advertising.advertising_strategy.includes('SBV-SP')) {
        let adsList = []
        let landingPageAsinList = []
        advertising.asin.forEach(function (product) {
          landingPageAsinList.push(product.asin)
        })
        if (advertising.advertising_strategy.includes('SBV-P')) {
          adsList = [{
            "name": advertising.advertising_strategy,
            "state": "ENABLED",
            "adGroupId": advertising.adGroupId,
            "creative": {
              "asins": landingPageAsinList,
              "videoAssetIds":
                  [
                    advertising.customVideoAssetId
                  ]
            }
          }]
          axios.post('https://ad.yaelongtime.com/api/proxy/sb/v4/ads/video', {
                "ads": adsList
              },
              {
                headers: {
                  'Content-Type': 'application/vnd.sbadresource.v4+json'
                }
              }
          ).then(res => {
            if (res.data.ads.success[0]) {
              // sendSbPreModerateRequest(advertising)
              if (advertising.matching_method === '关键词') {
                sendSbKeywordRequest(advertising);
              } else if (advertising.matching_method === '商品投放 — ASIN定位') {
                sendSbProductPositioningRequest(advertising)
              } else if (advertising.matching_method === '商品投放 — 类目定位') {
                sendSbProductPositioningRequest(advertising)
              }
            } else {
              Message({
                message: advertising.productAds.error[0].errors[0].errorValue.message,
                type: 'error',
              });
            }
          }).catch(error => {
            Message({
              message: error,
              type: 'error',
            });
          })
        } else if (advertising.advertising_strategy.includes('SBV-SP')) {
          adsList = [{
            "landingPage": {
              "pageType": "STORE",
              "url": advertising.landingPageUrl
            },
            "name": advertising.ad_activities,
            "state": "ENABLED",
            "adGroupId": advertising.adGroupId,
            "creative": {
              "asins": [
                advertising.videoProductsList[0].asin
              ],
              "videoAssetIds": [
                advertising.customVideoAssetId
              ],
              "brandName": advertising.brandName,
              "brandLogoAssetID": advertising.brandLogoAssetID,
              "headline": advertising.headline
            }
          }]
          axios.post('https://ad.yaelongtime.com/api/proxy/sb/v4/ads/brandVideo', {
                "ads": adsList
              },
              {
                headers: {
                  'Content-Type': 'application/vnd.sbadresource.v4+json'
                }
              }
          ).then(res => {
            if (res.data.ads.success[0]) {
              // sendSbPreModerateRequest(advertising)
              if (advertising.matching_method === '关键词') {
                sendSbKeywordRequest(advertising);
              } else if (advertising.matching_method === '商品投放 — ASIN定位') {
                sendSbProductPositioningRequest(advertising)
              } else if (advertising.matching_method === '商品投放 — 类目定位') {
                sendSbProductPositioningRequest(advertising)
              }
            } else {
              Message({
                message: advertising.productAds.error[0].errors[0].errorValue.message,
                type: 'error',
              });
            }
          }).catch(error => {
            Message({
              message: error,
              type: 'error',
            });
          })
        }
      }
    },
    //SB关键词创建
    sendSbKeywordRequest(advertising) {
      const sendNegativeRequest = (row) => {
        this.sendNegativeRequest(row)
      };
      if (advertising.matching_method === '关键词') {
        advertising.keyword.forEach(function (keyword) {
          axios.post('https://ad.yaelongtime.com/api/proxy/sb/keywords',
              [
                {
                  "campaignId": advertising.campaignId,
                  "matchType": (keyword.matchType).toLowerCase(),
                  "bid": parseFloat(keyword.bidding),
                  "adGroupId": advertising.adGroupId,
                  "keywordText": keyword.keyword
                }
              ],
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
          ).then(res => {
            if (res.data[0].code === "SUCCESS") {
              if (advertising.tableRecommendationNegativeData.length > 0) {
                sendNegativeRequest(advertising)
              } else {
                advertising.success = true
                Message({
                  message: advertising.ad_activities + '投放成功',
                  type: 'success',
                });
              }
            } else {
              Message({
                message: advertising.ad_activities + '投放失败',
                type: 'error',
              });
            }
          }).catch(error => {
            Message({
              message: error,
              type: 'error',
            });
          })
        })
      }
    },
    //SB类目、ASIN投放data
    sendSbProductPositioningRequest(row) {
      const sendSbPositioningRequest = (adCategory) => {
        this.sendSbPositioningRequest(adCategory)
      };
      let adDataList = []
      let type = ''
      let value = ''
      let expression = []
      row.keyword.forEach(function (positioning) {
        if (row.matching_method === '商品投放 — ASIN定位') {
          value = positioning.recommendedAsin
          if (positioning.selectedAsinType === '精准') {
            type = "asinSameAs"
          } else if (positioning.selectedAsinType === '扩展') {
            type = "asinSameAs"
          }
        } else if (row.matching_method === '商品投放 — 类目定位') {
          if (positioning.positioning.includes('分类细化')) {
            let category = [{
              value: positioning.id,
              type: "asinCategorySameAs"
            },]
            if (positioning.brand !== '') {
              let refineCategory = {
                type: 'asinBrandSameAs',
                value: positioning.brand
              }
              category.push(refineCategory)
            } else if (positioning.startPrice !== '' && positioning.endPrice !== '') {
              let refineCategory = {
                type: 'asinPriceBetween',
                value: (parseInt(positioning.startPrice) - parseInt(positioning.endPrice)).toString()
              }
              category.push(refineCategory)
            } else if (positioning.startStar !== undefined && positioning.endStar !== undefined) {
              let refineCategory = {
                type: 'asinReviewRatingBetween',
                value: (parseInt(positioning.startStar) - parseInt(positioning.endStar)).toString()
              }
              category.push(refineCategory)
            } else {
              let refineCategory = {
                value: positioning.id,
                type: "asinCategorySameAs"
              }
              category.push(refineCategory)
            }
            expression.push(category)
          } else {
            value = positioning.id
            type = "asinCategorySameAs"
          }
        }
        if (positioning.positioning.includes("类目细化")) {
          let adData = {
            "expressions": expression,
            "campaignId": parseInt(row.campaignId),
            "bid": parseFloat(positioning.bidding),
            "adGroupId": parseInt(row.adGroupId)
          }
          adDataList.push(adData)
        } else {
          let adData = {
            "expressions": [
              {
                "type": type,
                "value": value
              }
            ],
            "campaignId": parseInt(row.campaignId),
            "bid": parseFloat(positioning.bidding),
            "adGroupId": parseInt(row.adGroupId)
          }
          adDataList.push(adData)
        }
      })
      row.adDataList = adDataList
      sendSbPositioningRequest(row)
    },
    //SB类目，asin投放
    sendSbPositioningRequest(row) {
      const sendNegativeRequest = (row) => {
        this.sendNegativeRequest(row)
      };
      axios.post('https://ad.yaelongtime.com/api/proxy/sb/targets', {
            "targets": row.adDataList
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
      ).then(res => {
        if (res.data.createTargetSuccessResults.length > 0) {
          if (row.tableRecommendationNegativeData.length > 0) {
            sendNegativeRequest(row)
          } else {
            row.success = true
            Message({
              message: row.ad_activities + '投放成功',
              type: 'success',
            });
          }
        } else if (res.data.targetingClauses.error) {
          Message({
            message: 'ASIN已存在',
            type: 'error',
          });
        }
      }).catch(error => {
        Message({
          message: error,
          type: 'error',
        });
      })
    },
    //创建SP广告活动
    handleCampaignsClick(ad) {
      const sendAdGroupRequest = (advertising) => {
        this.sendAdGroupRequest(advertising)
      };
      if (ad.biddingStrategy === '动态竞价-提高或降低') {
        ad.biddingStrategy = 'AUTO_FOR_SALES'
      } else if (ad.biddingStrategy === '固定竞价') {
        ad.biddingStrategy = 'MANUAL'
      } else if (ad.biddingStrategy === '动态竞价-只降低') {
        ad.biddingStrategy = 'LEGACY_FOR_SALES'
      }
      if (ad.selectedPortfoliosValue) {
        // if (!ad.advertising_strategy.includes('SPP')) {
        if (ad.startDate !== '' && ad.endDate !== '') {
          axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaigns',
              {
                "campaigns": [
                  {
                    "portfolioId": ad.selectedPortfoliosValue,
                    "endDate": ad.endDate,
                    "name": ad.ad_activities,
                    "targetingType": ad.matching_method_type,
                    "state": "ENABLED",
                    "dynamicBidding": {
                      "placementBidding": [
                        {
                          "percentage": parseInt(ad.adHomeSlotsValue),
                          "placement": "PLACEMENT_TOP"
                        },
                        {
                          "percentage": parseInt(ad.adSlotsValue),
                          "placement": "PLACEMENT_PRODUCT_PAGE"
                        },
                        {
                          "percentage": parseInt(ad.adOtherPagesValue),
                          "placement": "PLACEMENT_PRODUCT_PAGE"
                        }
                      ],
                      "strategy": ad.biddingStrategy
                    },
                    "startDate": ad.startDate,
                    "budget": {
                      "budgetType": "DAILY",
                      "budget": parseFloat(ad.budget)
                    }
                  }
                ]

              }, {
                headers: {
                  'Content-Type': 'application/vnd.spCampaign.v3+json'
                }
              }
          ).then(res => {
            if (res.data.campaigns.success[0].campaignId) {
              ad.campaignId = res.data.campaigns.success[0].campaignId
              sendAdGroupRequest(ad);
            }
          })
        } else if (ad.startDate !== '' && ad.endDate === '') {
          axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaigns',
              {
                "campaigns": [
                  {
                    "portfolioId": ad.selectedPortfoliosValue,
                    "name": ad.ad_activities,
                    "targetingType": ad.matching_method_type,
                    "state": "ENABLED",
                    "dynamicBidding": {
                      "placementBidding": [
                        {
                          "percentage": parseInt(ad.adHomeSlotsValue),
                          "placement": "PLACEMENT_TOP"
                        },
                        {
                          "percentage": parseInt(ad.adSlotsValue),
                          "placement": "PLACEMENT_PRODUCT_PAGE"
                        },
                        {
                          "percentage": parseInt(ad.adOtherPagesValue),
                          "placement": "PLACEMENT_PRODUCT_PAGE"
                        }
                      ],
                      "strategy": ad.biddingStrategy
                    },
                    "startDate": ad.startDate,
                    "budget": {
                      "budgetType": "DAILY",
                      "budget": parseFloat(ad.budget)
                    }
                  }
                ]

              }, {
                headers: {
                  'Content-Type': 'application/vnd.spCampaign.v3+json'
                }
              }
          ).then(res => {
            if (res.data.campaigns.success) {
              ad.campaignId = res.data.campaigns.success[0].campaignId
              sendAdGroupRequest(ad);
            } else {
              this.$message.error(ad.ad_activities + '已存在')
            }
          }).catch(error => {
            Message({
              message: error,
              type: 'error',
            });
          })
        }
        // }
      } else {
        // if (!ad.advertising_strategy.includes('SPP')) {
        if (ad.startDate !== '' && ad.endDate !== '') {
          axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaigns',
              {
                "campaigns": [
                  {
                    "endDate": ad.endDate,
                    "name": ad.ad_activities,
                    "targetingType": ad.matching_method_type,
                    "state": "ENABLED",
                    "dynamicBidding": {
                      "placementBidding": [
                        {
                          "percentage": parseInt(ad.adHomeSlotsValue),
                          "placement": "PLACEMENT_TOP"
                        },
                        {
                          "percentage": parseInt(ad.adSlotsValue),
                          "placement": "PLACEMENT_PRODUCT_PAGE"
                        },
                        {
                          "percentage": parseInt(ad.adOtherPagesValue),
                          "placement": "PLACEMENT_PRODUCT_PAGE"
                        }
                      ],
                      "strategy": ad.biddingStrategy
                    },
                    "startDate": ad.startDate,
                    "budget": {
                      "budgetType": "DAILY",
                      "budget": parseFloat(ad.budget)
                    }
                  }
                ]
              }, {
                headers: {
                  'Content-Type': 'application/vnd.spCampaign.v3+json'
                }
              }
          ).then(res => {
            if (res.data.campaigns.success) {
              ad.campaignId = res.data.campaigns.success[0].campaignId
              sendAdGroupRequest(ad);
            } else {
              this.$message.success(ad.ad_activities + '已存在')
            }
          })
        } else if (ad.startDate !== '' && ad.endDate === '') {
          axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaigns',
              {
                "campaigns": [
                  {
                    "name": ad.ad_activities,
                    "targetingType": ad.matching_method_type,
                    "state": "ENABLED",
                    "dynamicBidding": {
                      "placementBidding": [
                        {
                          "percentage": parseInt(ad.adHomeSlotsValue),
                          "placement": "PLACEMENT_TOP"
                        },
                        {
                          "percentage": parseInt(ad.adSlotsValue),
                          "placement": "PLACEMENT_PRODUCT_PAGE"
                        },
                        {
                          "percentage": parseInt(ad.adOtherPagesValue),
                          "placement": "PLACEMENT_PRODUCT_PAGE"
                        }
                      ],
                      "strategy": ad.biddingStrategy
                    },
                    "startDate": ad.startDate,
                    "budget": {
                      "budgetType": "DAILY",
                      "budget": parseFloat(ad.budget)
                    }
                  }
                ]

              }, {
                headers: {
                  'Content-Type': 'application/vnd.spCampaign.v3+json'
                }
              }
          ).then(res => {
            if (res.data.campaigns.success) {
              ad.campaignId = res.data.campaigns.success[0].campaignId
              sendAdGroupRequest(ad);
            } else {
              this.$message.success(ad.ad_activities + '已存在')
            }
          }).catch(error => {
            Message({
              message: error,
              type: 'error',
            });
          })
        }
        // }
      }
    },
    //创建SP广告组
    sendAdGroupRequest(advertising) {
      const sendProductRequest = (advertising) => {
        this.sendProductRequest(advertising)
      };
      if (advertising.adGroupId) {
        sendProductRequest();
      } else {
        axios.post('https://ad.yaelongtime.com/api/proxy/sp/adGroups', {
              "adGroups": [
                {
                  "campaignId": advertising.campaignId,
                  "name": advertising.selectedGroupsValue,
                  "state": "ENABLED",
                  "defaultBid": parseFloat(advertising.defaultBidding),
                }
              ]
            },
            {
              headers: {
                'Content-Type': 'application/vnd.spAdGroup.v3+json'
              }
            }
        ).then(res => {
          if (res.data.adGroups.success) {
            advertising.adGroupId = res.data.adGroups.success[0].adGroupId
            sendProductRequest(advertising);
          } else if (res.data.adGroups.error) {
            this.$message.success(advertising.ad_activities + '广告组已存在')
          }
        }).catch(error => {
          Message({
            message: error,
            type: 'error',
          });
        })
      }
    },
    //检查产品资格
    sendProductEligibility(advertising) {
      const sendProductRequest = (advertising) => {
        this.sendProductRequest(advertising)
      };
      axios.post('https://ad.yaelongtime.com/api/proxy/eligibility/product/list', {
            "adType": advertising.type,
            "productDetailsList": [
              {
                "asin": advertising.asin.asin
              }
            ]
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
      ).then(res => {
        if (res.data.productResponseList) {
          res.data.productResponseList.forEach(function (product) {
            if (product.eligibilityStatusList[0].severity === 'INELIGIBLE') {
              Message({
                message: product.eligibilityStatusList[0].productDetails.asin + '不符合广告投放',
                type: 'error',
              });
            } else if (product.eligibilityStatusList[0].severity === 'ELIGIBLE_WITH_WARNING') {
              sendProductRequest(advertising)
            }
          })
        }
      })
    },
    //创建SP产品广告
    sendProductRequest(advertising) {
      const sendKeywordRequest = (advertising) => {
        this.sendKeywordRequest(advertising)
      };
      const sendAutoId = (adAuto) => {
        this.sendAutoId(adAuto)
      }
      const sendProductPositioningRequest = (row) => {
        this.sendProductPositioningRequest(row)
      }
      let productAdsList = []
      if (typeof advertising.asin === 'object' && Array.isArray(advertising.asin)) {
        advertising.asin.forEach(function (product) {
          let productAdsDict = {
            "campaignId": advertising.campaignId,
            "state": "ENABLED",
            "sku": product.sku,
            "adGroupId": advertising.adGroupId
          }
          productAdsList.push(productAdsDict)
        })
      } else {
        let productAds = {
          "campaignId": advertising.campaignId,
          "state": "ENABLED",
          "sku": advertising.sku,
          "adGroupId": advertising.adGroupId
        }
        productAdsList.push(productAds)
      }
      axios.post('https://ad.yaelongtime.com/api/proxy/sp/productAds', {
            "productAds": productAdsList
          },
          {
            headers: {
              'Content-Type': 'application/vnd.spProductAd.v3+json'
            }
          }
      ).then(res => {
        if (res.data.productAds.success[0] && advertising.matching_method_type === 'MANUAL') {
          if (advertising.matching_method === '关键词') {
            sendKeywordRequest(advertising);
          } else if (advertising.matching_method === '商品投放 — ASIN定位') {
            sendProductPositioningRequest(advertising)
          } else if (advertising.matching_method === '商品投放 — 类目定位') {
            sendProductPositioningRequest(advertising)
          }
        } else if (res.data.productAds.success[0] && advertising.matching_method_type === 'AUTO') {
          sendAutoId(advertising)
        } else {
          Message({
            message: advertising.productAds.error[0].errors[0].errorValue.message,
            type: 'error',
          });
        }
      }).catch(error => {
        Message({
          message: error,
          type: 'error',
        });
      })
    },
    //SP/SB/SD否定投放
    sendNegativeRequest(row) {
      if (row.tableRecommendationNegativeData.length > 0) {
        row.tableRecommendationNegativeData.forEach(function (negativeKeyword) {
              if (row.advertising_strategy.includes('SP')) {
                if (negativeKeyword.type === '关键词') {
                  if (negativeKeyword.negativeHierarchy === '在广告活动层级') {
                    axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaignNegativeKeywords', {
                          "campaignNegativeKeywords": [
                            {
                              "campaignId": row.campaignId,
                              "matchType": negativeKeyword.typeInfo,
                              "state": "ENABLED",
                              "keywordText": negativeKeyword.negativeText
                            }
                          ]
                        },
                        {
                          headers: {
                            'Content-Type': 'application/vnd.spCampaignNegativeKeyword.v3+json'
                          }
                        }
                    ).then(res => {
                      if (res.data.campaignNegativeKeywords.success) {
                        row.success = true
                        Message({
                          message: row.ad_activities + '投放成功',
                          type: 'success',
                        });
                      } else {
                        this.$message.error(row.advertising_strategy + '活动否定投放失败')
                      }
                    })
                  } else if (negativeKeyword.negativeHierarchy === '在广告组层级') {
                    axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaignNegativeKeywords', {
                          "campaignNegativeKeywords": [
                            {
                              "campaignId": row.campaignId,
                              "adGroupId": row.adGroupId,
                              "matchType": negativeKeyword.typeInfo,
                              "state": "ENABLED",
                              "keywordText": negativeKeyword.negativeText
                            }
                          ]
                        },
                        {
                          headers: {
                            'Content-Type': 'application/vnd.spCampaignNegativeKeyword.v3+json'
                          }
                        }
                    ).then(res => {
                      if (res.data.campaignNegativeKeywords.success) {
                        row.success = true
                        Message({
                          message: row.ad_activities + '投放成功',
                          type: 'success',
                        });
                      } else {
                        this.$message.error(row.advertising_strategy + '活动否定投放失败')
                      }
                    })
                  }
                } else if (negativeKeyword.type === '商品') {
                  if (negativeKeyword.negativeHierarchy === '在广告活动层级') {
                    if (negativeKeyword.matchType === '否定Asin') {
                      axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaignNegativeTargets', {
                            "campaignNegativeTargetingClauses": [
                              {
                                "expression": [
                                  {
                                    "type": "ASIN_SAME_AS",
                                    "value": negativeKeyword.negativeText
                                  }
                                ],
                                "campaignId": row.campaignId,
                                "state": "ENABLED"
                              }
                            ]
                          },
                          {
                            headers: {
                              'Content-Type': 'application/vnd.spCampaignNegativeTargetingClause.v3+json'
                            }
                          }
                      ).then(res => {
                        if (res.data.campaignNegativeTargetingClauses.success) {
                          row.success = true
                          Message({
                            message: row.ad_activities + '投放成功',
                            type: 'success',
                          });
                        } else {
                          this.$message.error(row.advertising_strategy + '活动否定投放失败')
                        }
                      })
                    } else if (negativeKeyword.matchType === '否定品牌') {
                      axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaignNegativeTargets', {
                            "campaignNegativeTargetingClauses": [
                              {
                                "expression": [
                                  {
                                    "type": "ASIN_BRAND_SAME_AS",
                                    "value": negativeKeyword.id
                                  }
                                ],
                                "campaignId": row.campaignId,
                                "state": "ENABLED"
                              }
                            ]
                          },
                          {
                            headers: {
                              'Content-Type': 'application/vnd.spCampaignNegativeTargetingClause.v3+json'
                            }
                          }
                      ).then(res => {
                        if (res.data.campaignNegativeTargetingClauses.success) {
                          row.success = true
                          Message({
                            message: row.ad_activities + '投放成功',
                            type: 'success',
                          });
                        } else {
                          this.$message.error(row.advertising_strategy + '活动否定品牌投放失败')
                        }
                      })
                    }
                  } else if (negativeKeyword.negativeHierarchy === '在广告组层级') {
                    if (negativeKeyword.matchType === '否定Asin') {
                      axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaignNegativeTargets', {
                            "campaignNegativeTargetingClauses": [
                              {
                                "expression": [
                                  {
                                    "type": "ASIN_SAME_AS",
                                    "value": negativeKeyword.negativeText
                                  }
                                ],
                                "campaignId": row.campaignId,
                                "state": "ENABLED",
                                "adGroupId": row.adGroupId
                              }
                            ]
                          },
                          {
                            headers: {
                              'Content-Type': 'application/vnd.spCampaignNegativeTargetingClause.v3+json'
                            }
                          }
                      ).then(res => {
                        if (res.data.campaignNegativeTargetingClauses.success) {
                          row.success = true
                          Message({
                            message: row.ad_activities + '投放成功',
                            type: 'success',
                          });
                        } else {
                          this.$message.error(row.advertising_strategy + '活动否定投放失败')
                        }
                      })
                    } else if (negativeKeyword.matchType === '否定品牌') {
                      axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaignNegativeTargets', {
                            "campaignNegativeTargetingClauses": [
                              {
                                "expression": [
                                  {
                                    "type": "ASIN_BRAND_SAME_AS",
                                    "value": negativeKeyword.id
                                  }
                                ],
                                "campaignId": row.campaignId,
                                "state": "ENABLED",
                                "adGroupId": row.adGroupId
                              }
                            ]
                          },
                          {
                            headers: {
                              'Content-Type': 'application/vnd.spCampaignNegativeTargetingClause.v3+json'
                            }
                          }
                      ).then(res => {
                        if (res.data.campaignNegativeTargetingClauses.success) {
                          row.success = true
                          Message({
                            message: row.ad_activities + '投放成功',
                            type: 'success',
                          });
                        } else {
                          this.$message.error(row.advertising_strategy + '活动否定品牌投放失败')
                        }
                      })
                    }
                  }
                }
              } else if (row.advertising_strategy.includes('SB')) {
                if (negativeKeyword.type === '关键词') {
                  axios.post('https://ad.yaelongtime.com/api/proxy/sb/negativeKeywords', {
                        "campaignNegativeKeywords": [
                          {
                            "campaignId": row.campaignId,
                            "adGroupId": row.adGroupId,
                            "matchType": negativeKeyword.typeInfo,
                            "state": "ENABLED",
                            "keywordText": negativeKeyword.negativeText
                          }
                        ]
                      },
                      {
                        headers: {
                          'Content-Type': 'application/vnd.sbkeywordresponse.v3+json'
                        }
                      }
                  ).then(res => {
                    if (res.data.campaignNegativeKeywords.success) {
                      row.success = true
                      Message({
                        message: row.ad_activities + '投放成功',
                        type: 'success',
                      });
                    } else {
                      this.$message.error(row.advertising_strategy + '活动否定投放失败')
                    }
                  })
                } else if (negativeKeyword.type === '商品') {
                  if (negativeKeyword.matchType === '否定Asin') {
                    axios.post('https://ad.yaelongtime.com/api/proxy/sb/negativeTargets', {
                          "campaignNegativeTargetingClauses": [
                            {
                              "expression": [
                                {
                                  "type": "asinSameAs",
                                  "value": negativeKeyword.negativeText
                                }
                              ],
                              "campaignId": row.campaignId,
                              "state": "ENABLED",
                              "adGroupId": row.adGroupId
                            }
                          ]
                        },
                        {
                          headers: {
                            'Content-Type': 'application/vnd.sbcreatenegativetargetsrequest.v3+json'
                          }
                        }
                    ).then(res => {
                      if (res.data.createTargetSuccessResults.length > 0) {
                        row.success = true
                        Message({
                          message: row.ad_activities + '投放成功',
                          type: 'success',
                        });
                      } else {
                        this.$message.error(row.advertising_strategy + '活动否定投放失败')
                      }
                    })
                  } else if (negativeKeyword.matchType === '否定品牌') {
                    axios.post('https://ad.yaelongtime.com/api/proxy/sb/negativeTargets', {
                          "campaignNegativeTargetingClauses": [
                            {
                              "expression": [
                                {
                                  "type": "asinBrandSameAs",
                                  "value": negativeKeyword.id
                                }
                              ],
                              "campaignId": row.campaignId,
                              "state": "ENABLED",
                              "adGroupId": row.adGroupId
                            }
                          ]
                        },
                        {
                          headers: {
                            'Content-Type': 'application/vnd.sbcreatenegativetargetsrequest.v3+json'
                          }
                        }
                    ).then(res => {
                      if (res.data.createTargetSuccessResults.length > 0) {
                        row.success = true
                        Message({
                          message: row.ad_activities + '投放成功',
                          type: 'success',
                        });
                      } else {
                        this.$message.error(row.advertising_strategy + '活动否定品牌投放失败')
                      }
                    })
                  }
                }
              } else if (row.advertising_strategy.includes('SD')) {
                if (negativeKeyword.matchType === '否定Asin') {
                  axios.post('https://ad.yaelongtime.com/api/proxy/sd/negativeTargets', {
                        "campaignNegativeTargetingClauses": [
                          {
                            "expression": [
                              {
                                "type": "asinSameAs",
                                "value": negativeKeyword.negativeText
                              }
                            ],
                            "expressionType": "manual",
                            "state": "enabled",
                            "adGroupId": row.adGroupId
                          }
                        ]
                      },
                      {
                        headers: {
                          'Content-Type': 'application/json'
                        }
                      }
                  ).then(res => {
                    if (res.data.code === 207) {
                      row.success = true
                      Message({
                        message: row.ad_activities + '投放成功',
                        type: 'success',
                      });
                    } else {
                      this.$message.error(row.advertising_strategy + '活动否定投放失败')
                    }
                  })
                } else if (negativeKeyword.matchType === '否定品牌') {
                  axios.post('https://ad.yaelongtime.com/api/proxy/sd/negativeTargets', {
                        "campaignNegativeTargetingClauses": [
                          {
                            "expression": [
                              {
                                "type": "asinBrandSameAs",
                                "value": negativeKeyword.id
                              }
                            ],
                            "expressionType": "manual",
                            "state": "enabled",
                            "adGroupId": row.adGroupId
                          }
                        ]
                      },
                      {
                        headers: {
                          'Content-Type': 'application/json'
                        }
                      }
                  ).then(res => {
                    if (res.data.code === 207) {
                      row.success = true
                      Message({
                        message: row.ad_activities + '投放成功',
                        type: 'success',
                      });
                    } else {
                      this.$message.error(row.advertising_strategy + '活动否定品牌投放失败')
                    }
                  })
                }
              }
            }
        )
      }
    },
    //获取auto广告的自动表达式
    autoTargeting(row) {
      let asinList = []
      this.selectionProduct.forEach(item => {
        asinList.push(item.asin)
      });
      if (row.biddingStrategy === '动态竞价-提高或降低') {
        row.biddingStrategy = 'AUTO_FOR_SALES'
      } else if (row.biddingStrategy === '固定竞价') {
        row.biddingStrategy = 'MANUAL'
      } else if (row.biddingStrategy === '动态竞价-只降低') {
        row.biddingStrategy = 'LEGACY_FOR_SALES'
      }
      if (row.advertising_strategy === 'SPA1') {
        this.$axios.post('https://ad.yaelongtime.com/api/proxy/sp/targets/bid/recommendations',
            {
              "asins": asinList,
              "targetingExpressions": [
                {
                  "type": "CLOSE_MATCH"
                },
              ],
              "bidding": {
                "adjustments": [
                  {
                    "percentage": parseInt(row.adHomeSlotsValue),
                    "placement": "PLACEMENT_TOP"
                  },
                  {
                    "percentage": parseInt(row.adSlotsValue),
                    "placement": "PLACEMENT_PRODUCT_PAGE"
                  },
                  {
                    "percentage": parseInt(row.adOtherPagesValue),
                    "placement": "PLACEMENT_PRODUCT_PAGE"
                  }
                ],
                "strategy": row.biddingStrategy
              },
              "recommendationType": "BIDS_FOR_NEW_AD_GROUP"
            },
            {
              headers: {
                'Content-Type': 'application/vnd.spthemebasedbidrecommendation.v3+json'
              },
            }
        ).then(res => {
          row.autoData[0].type = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].targetingExpression.type
          row.autoData[0].suggestedBid1 = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[0].suggestedBid
          row.autoData[0].suggestedBid2 = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[1].suggestedBid
          row.autoData[0].suggestedBid3 = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[2].suggestedBid
          row.autoData[0].bidding = (row.selectedType / 10 * res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[2].suggestedBid).toFixed(2)
        }).catch(error => {
          row.autoData[0].type = '~'
          row.autoData[0].suggestedBid1 = '~'
          row.autoData[0].suggestedBid2 = '~'
          row.autoData[0].suggestedBid3 = '~'
          row.autoData[0].bidding = row.defaultBidding
        })
      } else if (row.advertising_strategy === 'SPA2') {
        this.$axios.post('https://ad.yaelongtime.com/api/proxy/sp/targets/bid/recommendations',
            {
              "asins": asinList,
              "targetingExpressions": [
                {
                  "type": "LOOSE_MATCH"
                }
              ],
              "bidding": {
                "adjustments": [
                  {
                    "percentage": parseInt(row.adHomeSlotsValue),
                    "placement": "PLACEMENT_TOP"
                  },
                  {
                    "percentage": parseInt(row.adSlotsValue),
                    "placement": "PLACEMENT_PRODUCT_PAGE"
                  },
                  {
                    "percentage": parseInt(row.adOtherPagesValue),
                    "placement": "PLACEMENT_PRODUCT_PAGE"
                  }
                ],
                "strategy": row.biddingStrategy
              },
              "recommendationType": "BIDS_FOR_NEW_AD_GROUP"
            },
            {
              headers: {
                'Content-Type': 'application/vnd.spthemebasedbidrecommendation.v3+json'
              },
            }
        ).then(res => {
          row.autoData[0].type = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].targetingExpression.type
          row.autoData[0].suggestedBid1 = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[0].suggestedBid
          row.autoData[0].suggestedBid2 = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[1].suggestedBid
          row.autoData[0].suggestedBid3 = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[2].suggestedBid
          row.autoData[0].bidding = (row.selectedType / 10 * res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[2].suggestedBid.suggestedBid3).toFixed(2)
        }).catch(error => {
          row.autoData[0].type = '~'
          row.autoData[0].suggestedBid1 = '~'
          row.autoData[0].suggestedBid2 = '~'
          row.autoData[0].suggestedBid3 = '~'
          row.autoData[0].bidding = row.defaultBidding
        })
      } else if (row.advertising_strategy === 'SPA3') {
        this.$axios.post('https://ad.yaelongtime.com/api/proxy/sp/targets/bid/recommendations',
            {
              "asins": asinList,
              "targetingExpressions": [
                {
                  "type": "COMPLEMENTS"
                }
              ],
              "bidding": {
                "adjustments": [
                  {
                    "percentage": parseInt(row.adHomeSlotsValue),
                    "placement": "PLACEMENT_TOP"
                  },
                  {
                    "percentage": parseInt(row.adSlotsValue),
                    "placement": "PLACEMENT_PRODUCT_PAGE"
                  },
                  {
                    "percentage": parseInt(row.adOtherPagesValue),
                    "placement": "PLACEMENT_PRODUCT_PAGE"
                  }
                ],
                "strategy": row.biddingStrategy
              },
              "recommendationType": "BIDS_FOR_NEW_AD_GROUP"
            },
            {
              headers: {
                'Content-Type': 'application/vnd.spthemebasedbidrecommendation.v3+json'
              },
            }
        ).then(res => {
          row.autoData[0].type = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].targetingExpression.type
          row.autoData[0].suggestedBid1 = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[0].suggestedBid
          row.autoData[0].suggestedBid2 = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[1].suggestedBid
          row.autoData[0].suggestedBid3 = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[2].suggestedBid
          row.autoData[0].bidding = (row.selectedType / 10 * res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[2].suggestedBid).toFixed(2)
        }).catch(error => {
          row.autoData[0].type = '~'
          row.autoData[0].suggestedBid1 = '~'
          row.autoData[0].suggestedBid2 = '~'
          row.autoData[0].suggestedBid3 = '~'
          row.autoData[0].bidding = row.defaultBidding
        })
      } else if (row.advertising_strategy === 'SPA4') {
        this.$axios.post('https://ad.yaelongtime.com/api/proxy/sp/targets/bid/recommendations',
            {
              "asins": asinList,
              "targetingExpressions": [
                {
                  "type": "SUBSTITUTES"
                }
              ],
              "bidding": {
                "adjustments": [
                  {
                    "percentage": parseInt(row.adHomeSlotsValue),
                    "placement": "PLACEMENT_TOP"
                  },
                  {
                    "percentage": parseInt(row.adSlotsValue),
                    "placement": "PLACEMENT_PRODUCT_PAGE"
                  },
                  {
                    "percentage": parseInt(row.adOtherPagesValue),
                    "placement": "PLACEMENT_PRODUCT_PAGE"
                  }
                ],
                "strategy": row.biddingStrategy
              },
              "recommendationType": "BIDS_FOR_NEW_AD_GROUP"
            },
            {
              headers: {
                'Content-Type': 'application/vnd.spthemebasedbidrecommendation.v3+json'
              },
            }
        ).then(res => {
          row.autoData[0].type = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].targetingExpression.type
          row.autoData[0].suggestedBid1 = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[0].suggestedBid
          row.autoData[0].suggestedBid2 = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[1].suggestedBid
          row.autoData[0].suggestedBid3 = res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[2].suggestedBid
          row.autoData[0].bidding = (row.selectedType / 10 * res.data.bidRecommendations[0].bidRecommendationsForTargetingExpressions[0].bidValues[2].suggestedBid).toFixed(2)
        }).catch(error => {
          row.autoData[0].type = '~'
          row.autoData[0].suggestedBid1 = '~'
          row.autoData[0].suggestedBid2 = '~'
          row.autoData[0].suggestedBid3 = '~'
          row.autoData[0].bidding = row.defaultBidding
        })
      }
    },
    // 获取自动目标ID（对应广告活动与广告组的目标id)
    sendAutoId(adAuto) {
      const sendAutoTargets = (adAuto) => {
        this.sendAutoTargets(adAuto)
      };
      let autoTargetIdList = []
      axios.post('https://ad.yaelongtime.com/api/proxy/sp/targets/list', {
            "campaignIdFilter": {
              "include": [
                adAuto.campaignId
              ]
            },
            "adGroupIdFilter": {
              "include": [
                adAuto.groupId
              ]
            },
            "stateFilter": {
              "include": [
                "ENABLED"
              ]
            },
            "expressionTypeFilter": {
              "include": [
                "AUTO"
              ]
            },
            "includeExtendedDataFields": true
          },
          {
            headers: {
              'Content-Type': 'application/vnd.spTargetingClause.v3+json'
            }
          }
      ).then(res => {
        res.data.targetingClauses.forEach(function (auto) {
          let autoTargetIdDict = {
            'expression': auto.expression[0].type,
            'targetId': auto.targetId
          }
          autoTargetIdList.push(autoTargetIdDict)
        })
        adAuto.autoTargetIdList = autoTargetIdList
        sendAutoTargets(adAuto)
      })
    },
    // 修改自动广告目标条款
    sendAutoTargets(adAuto) {
      const sendNegativeRequest = (row) => {
        this.sendNegativeRequest(row)
      };
      if (adAuto.matching_method === '自动') {
        if (adAuto.advertising_strategy === 'SPA1') {
          adAuto.autoTargetIdList.forEach(function (autoTarget) {
            if (autoTarget.expression === 'QUERY_HIGH_REL_MATCHES') {
              axios.put('https://ad.yaelongtime.com/api/proxy/sp/targets', {
                    "targetingClauses": [
                      {
                        "expression": [
                          {
                            "type": autoTarget.expression,
                          }
                        ],
                        "targetId": autoTarget.targetId,
                        "expressionType": adAuto.matching_method_type,
                        "state": "ENABLED",
                        "bid": parseFloat(adAuto.autoData[0].bidding)
                      }
                    ]
                  },
                  {
                    headers: {
                      'Content-Type': 'application/vnd.spTargetingClause.v3+json'
                    }
                  }
              ).then(res => {
                if (res.data.targetingClauses.success) {
                  if (adAuto.tableRecommendationNegativeData.length > 0) {
                    sendNegativeRequest(adAuto)
                  } else {
                    adAuto.success = true
                    Message({
                      message: adAuto.ad_activities + '投放成功',
                      type: 'success',
                    });
                  }
                } else {
                  Message({
                    message: adAuto.ad_activities + '投放失败',
                    type: 'success',
                  });
                }
              })
            } else {
              axios.put('https://ad.yaelongtime.com/api/proxy/sp/targets', {
                    "targetingClauses": [
                      {
                        "expression": [
                          {
                            "type": autoTarget.expression,
                          }
                        ],
                        "targetId": autoTarget.targetId,
                        "expressionType": adAuto.matching_method_type,
                        "state": "PAUSED",
                        "bid": parseFloat(adAuto.autoData[0].bidding)
                      }
                    ]
                  },
                  {
                    headers: {
                      'Content-Type': 'application/vnd.spTargetingClause.v3+json'
                    }
                  }
              ).then(res => {
                if (res.data.targetingClauses.success) {
                  console.log('其余自动表达式暂停')
                } else {
                  Message({
                    message: '自动表达式暂停失败',
                    type: 'success',
                  });
                }
              })
            }
          })
        } else if (adAuto.advertising_strategy === 'SPA2') {
          adAuto.autoTargetIdList.forEach(function (autoTarget) {
            if (autoTarget.expression === 'QUERY_BROAD_REL_MATCHES') {
              axios.put('https://ad.yaelongtime.com/api/proxy/sp/targets', {
                    "targetingClauses": [
                      {
                        "expression": [
                          {
                            "type": autoTarget.expression,
                            "value": "string"
                          }
                        ],
                        "targetId": autoTarget.targetId,
                        "expressionType": adAuto.matching_method_type,
                        "state": "ENABLED",
                        "bid": parseFloat(adAuto.autoData[0].bidding)
                      }
                    ]
                  },
                  {
                    headers: {
                      'Content-Type': 'application/vnd.spTargetingClause.v3+json'
                    }
                  }
              ).then(res => {
                if (res.data.targetingClauses.success) {
                  adAuto.success = true
                  Message({
                    message: adAuto.ad_activities + '投放成功',
                    type: 'success',
                  });
                } else {
                  Message({
                    message: adAuto.ad_activities + '投放失败',
                    type: 'success',
                  });
                }
              })
            } else {
              axios.put('https://ad.yaelongtime.com/api/proxy/sp/targets', {
                    "targetingClauses": [
                      {
                        "expression": [
                          {
                            "type": autoTarget.expression,
                          }
                        ],
                        "targetId": autoTarget.targetId,
                        "expressionType": adAuto.matching_method_type,
                        "state": "PAUSED",
                        "bid": parseFloat(adAuto.autoData[0].bidding)
                      }
                    ]
                  },
                  {
                    headers: {
                      'Content-Type': 'application/vnd.spTargetingClause.v3+json'
                    }
                  }
              ).then(res => {
                if (res.data.targetingClauses.success) {
                  console.log('其余自动表达式暂停')
                } else {
                  Message({
                    message: '自动表达式暂停失败',
                    type: 'success',
                  });
                }
              })
            }
          })
        } else if (adAuto.advertising_strategy === 'SPA3') {
          adAuto.autoTargetIdList.forEach(function (autoTarget) {
            if (autoTarget.expression === 'ASIN_SUBSTITUTE_RELATED') {
              axios.put('https://ad.yaelongtime.com/api/proxy/sp/targets', {
                    "targetingClauses": [
                      {
                        "expression": [
                          {
                            "type": autoTarget.expression,
                            "value": "string"
                          }
                        ],
                        "targetId": autoTarget.targetId,
                        "expressionType": adAuto.matching_method_type,
                        "state": "ENABLED",
                        "bid": parseFloat(adAuto.autoData[0].bidding)
                      }
                    ]
                  },
                  {
                    headers: {
                      'Content-Type': 'application/vnd.spTargetingClause.v3+json'
                    }
                  }
              ).then(res => {
                if (res.data.targetingClauses.success) {
                  adAuto.success = true
                  Message({
                    message: adAuto.ad_activities + '投放成功',
                    type: 'success',
                  });
                } else {
                  Message({
                    message: adAuto.ad_activities + '投放失败',
                    type: 'success',
                  });
                }
              })
            } else {
              axios.put('https://ad.yaelongtime.com/api/proxy/sp/targets', {
                    "targetingClauses": [
                      {
                        "expression": [
                          {
                            "type": autoTarget.expression,
                          }
                        ],
                        "targetId": autoTarget.targetId,
                        "expressionType": adAuto.matching_method_type,
                        "state": "PAUSED",
                        "bid": parseFloat(adAuto.autoData[0].bidding)
                      }
                    ]
                  },
                  {
                    headers: {
                      'Content-Type': 'application/vnd.spTargetingClause.v3+json'
                    }
                  }
              ).then(res => {
                if (res.data.targetingClauses.success) {
                  console.log('其余自动表达式暂停')
                } else {
                  Message({
                    message: '自动表达式暂停失败',
                    type: 'success',
                  });
                }
              })
            }
          })
        } else if (adAuto.advertising_strategy === 'SPA4') {
          adAuto.autoTargetIdList.forEach(function (autoTarget) {
            if (autoTarget.expression === 'ASIN_ACCESSORY_RELATED') {
              axios.put('https://ad.yaelongtime.com/api/proxy/sp/targets', {
                    "targetingClauses": [
                      {
                        "expression": [
                          {
                            "type": autoTarget.expression,
                            "value": "string"
                          }
                        ],
                        "targetId": autoTarget.targetId,
                        "expressionType": adAuto.matching_method_type,
                        "state": "ENABLED",
                        "bid": parseFloat(adAuto.autoData[0].bidding)
                      }
                    ]
                  },
                  {
                    headers: {
                      'Content-Type': 'application/vnd.spTargetingClause.v3+json'
                    }
                  }
              ).then(res => {
                if (res.data.targetingClauses.success) {
                  adAuto.success = true
                  Message({
                    message: adAuto.ad_activities + '投放成功',
                    type: 'success',
                  });
                } else {
                  Message({
                    message: adAuto.ad_activities + '投放失败',
                    type: 'success',
                  });
                }
              })
            } else {
              axios.put('https://ad.yaelongtime.com/api/proxy/sp/targets', {
                    "targetingClauses": [
                      {
                        "expression": [
                          {
                            "type": autoTarget.expression,
                          }
                        ],
                        "targetId": autoTarget.targetId,
                        "expressionType": adAuto.matching_method_type,
                        "state": "PAUSED",
                        "bid": parseFloat(adAuto.autoData[0].bidding)
                      }
                    ]
                  },
                  {
                    headers: {
                      'Content-Type': 'application/vnd.spTargetingClause.v3+json'
                    }
                  }
              ).then(res => {
                if (res.data.targetingClauses.success) {
                  console.log('其余自动表达式暂停')
                } else {
                  Message({
                    message: '自动表达式暂停失败',
                    type: 'success',
                  });
                }
              })
            }
          })
        }
      }
    },
    //存在新增关键词创建广告活动
    sendKeywordCampaignsData(row) {
      const sendKeywordAdGroupRequest = (advertising) => {
        this.sendKeywordAdGroupRequest(advertising)
      };
      if (row.biddingStrategy === '动态竞价-提高或降低') {
        row.biddingStrategy = 'AUTO_FOR_SALES'
      } else if (row.biddingStrategy === '固定竞价') {
        row.biddingStrategy = 'MANUAL'
      } else if (row.biddingStrategy === '动态竞价-只降低') {
        row.biddingStrategy = 'LEGACY_FOR_SALES'
      }
      if (row.selectedPortfoliosValue) {
        if (!row.advertising_strategy.includes('SPP')) {
          if (row.startDate !== '' && row.endDate !== '') {
            axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaigns',
                {
                  "campaigns": [
                    {
                      "portfolioId": row.selectedPortfoliosValue,
                      "endDate": row.endDate,
                      "name": row.ad_activities,
                      "targetingType": row.matching_method_type,
                      "state": "ENABLED",
                      "dynamicBidding": {
                        "placementBidding": [
                          {
                            "percentage": parseInt(row.adHomeSlotsValue),
                            "placement": "PLACEMENT_TOP"
                          },
                          {
                            "percentage": parseInt(row.adSlotsValue),
                            "placement": "PLACEMENT_PRODUCT_PAGE"
                          },
                          {
                            "percentage": parseInt(row.adOtherPagesValue),
                            "placement": "PLACEMENT_PRODUCT_PAGE"
                          }
                        ],
                        "strategy": row.biddingStrategy
                      },
                      "startDate": row.startDate,
                      "budget": {
                        "budgetType": "DAILY",
                        "budget": parseFloat(row.budget)
                      }
                    }
                  ]

                }, {
                  headers: {
                    'Content-Type': 'application/vnd.spCampaign.v3+json'
                  }
                }
            ).then(res => {
              if (res.data.campaigns.success) {
                row.campaignId = res.data.campaigns.success[0].campaignId
                sendKeywordAdGroupRequest(row);
              } else {
                console.log(row.ad_activities + '广告活动已存在')
              }
            })
          } else if (row.startDate !== '' && row.endDate === '') {
            axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaigns',
                {
                  "campaigns": [
                    {
                      "portfolioId": row.selectedPortfoliosValue,
                      "name": row.ad_activities,
                      "targetingType": row.matching_method_type,
                      "state": "ENABLED",
                      "dynamicBidding": {
                        "placementBidding": [
                          {
                            "percentage": parseInt(row.adHomeSlotsValue),
                            "placement": "PLACEMENT_TOP"
                          },
                          {
                            "percentage": parseInt(row.adSlotsValue),
                            "placement": "PLACEMENT_PRODUCT_PAGE"
                          },
                          {
                            "percentage": parseInt(row.adOtherPagesValue),
                            "placement": "PLACEMENT_PRODUCT_PAGE"
                          }
                        ],
                        "strategy": row.biddingStrategy
                      },
                      "startDate": row.startDate,
                      "budget": {
                        "budgetType": "DAILY",
                        "budget": parseFloat(row.budget)
                      }
                    }
                  ]

                }, {
                  headers: {
                    'Content-Type': 'application/vnd.spCampaign.v3+json'
                  }
                }
            ).then(res => {
              if (res.data.campaigns.success) {
                row.campaignId = res.data.campaigns.success[0].campaignId
                sendKeywordAdGroupRequest(row);
              } else {
                this.$message.error(row.ad_activities + '广告活动已存在')
              }
            }).catch(error => {
              Message({
                message: error,
                type: 'error',
              });
            })
          }
        }
      } else {
        if (!row.advertising_strategy.includes('SPP')) {
          if (row.startDate !== '' && row.endDate !== '') {
            axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaigns',
                {
                  "campaigns": [
                    {
                      "endDate": row.endDate,
                      "name": row.ad_activities,
                      "targetingType": row.matching_method_type,
                      "state": "ENABLED",
                      "dynamicBidding": {
                        "placementBidding": [
                          {
                            "percentage": parseInt(row.adHomeSlotsValue),
                            "placement": "PLACEMENT_TOP"
                          },
                          {
                            "percentage": parseInt(row.adSlotsValue),
                            "placement": "PLACEMENT_PRODUCT_PAGE"
                          },
                          {
                            "percentage": parseInt(row.adOtherPagesValue),
                            "placement": "PLACEMENT_PRODUCT_PAGE"
                          }
                        ],
                        "strategy": row.biddingStrategy
                      },
                      "startDate": row.startDate,
                      "budget": {
                        "budgetType": "DAILY",
                        "budget": parseFloat(row.budget)
                      }
                    }
                  ]
                }, {
                  headers: {
                    'Content-Type': 'application/vnd.spCampaign.v3+json'
                  }
                }
            ).then(res => {
              if (res.data.campaigns.success) {
                row.campaignId = res.data.campaigns.success[0].campaignId
                sendKeywordAdGroupRequest(row);
              } else {
                this.$message.error(row.ad_activities + '广告活动已存在')
              }
            })
          } else if (row.startDate !== '' && row.endDate === '') {
            axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaigns',
                {
                  "campaigns": [
                    {
                      "name": row.ad_activities,
                      "targetingType": row.matching_method_type,
                      "state": "ENABLED",
                      "dynamicBidding": {
                        "placementBidding": [
                          {
                            "percentage": parseInt(row.adHomeSlotsValue),
                            "placement": "PLACEMENT_TOP"
                          },
                          {
                            "percentage": parseInt(row.adSlotsValue),
                            "placement": "PLACEMENT_PRODUCT_PAGE"
                          },
                          {
                            "percentage": parseInt(row.adOtherPagesValue),
                            "placement": "PLACEMENT_PRODUCT_PAGE"
                          }
                        ],
                        "strategy": row.biddingStrategy
                      },
                      "startDate": row.startDate,
                      "budget": {
                        "budgetType": "DAILY",
                        "budget": parseFloat(row.budget)
                      }
                    }
                  ]

                }, {
                  headers: {
                    'Content-Type': 'application/vnd.spCampaign.v3+json'
                  }
                }
            ).then(res => {
              if (res.data.campaigns.success) {
                row.campaignId = res.data.campaigns.success[0].campaignId
                sendKeywordAdGroupRequest(row);
              } else {
                this.$message.error(row.ad_activities + '广告活动已存在')
              }

            }).catch(error => {
              Message({
                message: error,
                type: 'error',
              });
            })
          }
        }
      }
    },
    //存在新增关键词创建广告组
    sendKeywordAdGroupRequest(row) {
      const sendKeywordAdProductRequest = (row) => {
        this.sendKeywordAdProductRequest(row)
      };
      if (!row.advertising_strategy.includes('SPP')) {
        if (row.adGroupId) {
          sendKeywordAdProductRequest();
        } else {
          axios.post('https://ad.yaelongtime.com/api/proxy/sp/adGroups', {
                "adGroups": [
                  {
                    "campaignId": row.campaignId,
                    "name": row.selectedGroupsValue,
                    "state": "ENABLED",
                    "defaultBid": parseFloat(row.defaultBidding),
                  }
                ]
              },
              {
                headers: {
                  'Content-Type': 'application/vnd.spAdGroup.v3+json'
                }
              }
          ).then(res => {
            if (res.data.adGroups.success) {
              row.adGroupId = res.data.adGroups.success[0].adGroupId
              sendKeywordAdProductRequest(row);
            } else if (res.data.adGroups.error) {
              console.log(row.ad_activities + '关键字广告组已存在')
            }
          }).catch(error => {
            Message({
              message: error,
              type: 'error',
            });
          })
        }
      }
    },
    //存在新关键词创建产品广告---获取推荐竞价
    sendKeywordAdProductRequest(row) {
      const sendNewKeywordBidRequest = (row) => {
        this.sendNewKeywordBidRequest(row)
      };
      let productAdsList = []
      if (typeof row.asin === 'object' && Array.isArray(row.asin)) {
        row.asin.forEach(function (product) {
          let productAdsDict = {
            "campaignId": row.campaignId,
            "state": "ENABLED",
            "sku": product.sku,
            "adGroupId": row.adGroupId
          }
          productAdsList.push(productAdsDict)
        })
      } else {
        let productAds = {
          "campaignId": row.campaignId,
          "state": "ENABLED",
          "sku": row.sku,
          "adGroupId": row.adGroupId
        }
        productAdsList.push(productAds)
      }
      axios.post('https://ad.yaelongtime.com/api/proxy/sp/productAds', {
            "productAds": productAdsList
          },
          {
            headers: {
              'Content-Type': 'application/vnd.spProductAd.v3+json'
            }
          }
      ).then(res => {
        if (res.data.productAds.success[0] && row.matching_method_type === 'MANUAL') {
          if (row.matching_method === '关键词') {
            sendNewKeywordBidRequest(row);
          }
        } else {
          this.$message.error(row.ad_activities + '关键字产品已存在')
        }
      }).catch(error => {
        Message({
          message: error,
          type: 'error',
        });
      })
    },
    //获取新关键字的推荐竞价
    sendNewKeywordBidRequest(row) {
      row.keyword.forEach(function (keyword) {
        if (keyword.matchType === "BROAD") {
          axios.post('https://ad.yaelongtime.com/api/proxy/v2/sp/targets/bidRecommendations', {
                "adGroupId": row.adGroupId,
                "expressions": [
                  [
                    {
                      "value": keyword.keyword,
                      "type": "queryBroadMatches"
                    }
                  ]
                ]
              },
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
          ).then(res => {
            if (res.data.recommendations.length > 0) {
              res.data.recommendations.forEach(function (bid) {
                if (bid.code !== 'NOT_FOUND') {
                  keyword.rangeStart = bid.suggestedBid.rangeStart
                  keyword.rangeMedian = bid.suggestedBid.suggested
                  keyword.rangeEnd = bid.suggestedBid.rangeEnd
                  keyword.bidding = (parseInt(row.selectedType) / 10 * bid.suggestedBid.rangeEnd).toFixed(2)
                } else {
                  keyword.rangeStart = '~'
                  keyword.rangeMedian = '~'
                  keyword.rangeEnd = '~'
                  keyword.bidding = row.defaultBidding
                }
              })
            } else {
              keyword.rangeStart = '~'
              keyword.rangeMedian = '~'
              keyword.rangeEnd = '~'
              keyword.bidding = row.defaultBidding
            }
          })
        } else if (keyword.matchType === 'PHRASE') {
          axios.post('https://ad.yaelongtime.com/api/proxy/v2/sp/targets/bidRecommendations', {
                "adGroupId": row.adGroupId,
                "expressions": [
                  [
                    {
                      "value": keyword.keyword,
                      "type": "queryPhraseMatches"
                    }
                  ]
                ]
              },
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
          ).then(res => {
            if (res.data.recommendations.length > 0) {
              res.data.recommendations.forEach(function (bid) {
                if (bid.code !== 'NOT_FOUND') {
                  keyword.rangeStart = bid.suggestedBid.rangeStart
                  keyword.rangeMedian = bid.suggestedBid.suggested
                  keyword.rangeEnd = bid.suggestedBid.rangeEnd
                  keyword.bidding = (row.selectedType / 10 * bid.suggestedBid.rangeEnd).toFixed(2)
                } else {
                  keyword.rangeStart = '~'
                  keyword.rangeMedian = '~'
                  keyword.rangeEnd = '~'
                  keyword.bidding = row.defaultBidding
                }
              })
            } else {
              keyword.rangeStart = '~'
              keyword.rangeMedian = '~'
              keyword.rangeEnd = '~'
              keyword.bidding = row.defaultBidding
            }
          })
        } else if (keyword.matchType === 'EXACT') {
          axios.post('https://ad.yaelongtime.com/api/proxy/v2/sp/targets/bidRecommendations', {
                "adGroupId": row.adGroupId,
                "expressions": [
                  [
                    {
                      "value": keyword.keyword,
                      "type": "queryExactMatches"
                    }
                  ]
                ]
              },
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
          ).then(res => {
            if (res.data.recommendations.length > 0) {
              res.data.recommendations.forEach(function (bid) {
                if (bid.code !== 'NOT_FOUND') {
                  keyword.rangeStart = bid.suggestedBid.rangeStart
                  keyword.rangeMedian = bid.suggestedBid.suggested
                  keyword.rangeEnd = bid.suggestedBid.rangeEnd
                  keyword.bidding = (row.selectedType / 10 * bid.suggestedBid.rangeEnd).toFixed(2)
                } else {
                  keyword.rangeStart = '~'
                  keyword.rangeMedian = '~'
                  keyword.rangeEnd = '~'
                  keyword.bidding = row.defaultBidding
                }
              })
            } else {
              keyword.rangeStart = '~'
              keyword.rangeMedian = '~'
              keyword.rangeEnd = '~'
              keyword.bidding = row.defaultBidding
            }
          })
        }
      })
    },
    //SB新增关键词、类目、asin创建广告活动
    sendSbKeywordCampaignsData(ad) {
      const sendSbKeywordAdSbGroupRequest = (advertising) => {
        this.sendSbKeywordAdSbGroupRequest(advertising)
      };
      if (ad.startDate !== '' && ad.endDate !== '') {
        let campaigns = []
        if (ad.brandEntityId && ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "DAILY",
            "brandEntityId": ad.brandEntityId,
            "bidding": {
              "bidOptimization": true
            },
            "portfolioId": ad.selectedPortfoliosValue,
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        } else if (!ad.brandEntityId && !ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "DAILY",
            "bidding": {
              "bidOptimization": true
            },
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        } else if (ad.brandEntityId && !ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "DAILY",
            "brandEntityId": ad.brandEntityId,
            "bidding": {
              "bidOptimization": true
            },
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        } else if (!ad.brandEntityId && ad.selectedPortfoliosValue) {
          let campaignsDict = {
            "budgetType": "DAILY",
            "bidding": {
              "bidOptimization": true
            },
            "portfolioId": ad.selectedPortfoliosValue,
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        }
        axios.post('https://ad.yaelongtime.com/api/proxy/sb/v4/campaign',
            {
              "campaigns": campaigns
            }, {
              headers: {
                'Content-Type': 'application/vnd.sbcampaignresource.v4+json'
              }
            }
        ).then(res => {
          if (res.data.campaigns.success) {
            ad.campaignId = res.data.campaigns.success[0].campaignId
            sendSbKeywordAdSbGroupRequest(ad);
          } else {
            console.log(ad.ad_activities + '已存在')
          }
        })
      } else if (ad.startDate !== '' && ad.endDate === '') {
        let campaigns = []
        if (ad.brandEntityId !== '' && ad.selectedPortfoliosValue !== '') {
          let campaignsDict = {
            "budgetType": "DAILY",
            "brandEntityId": ad.brandEntityId,
            "bidding": {
              "bidOptimization": true
            },
            "portfolioId": ad.selectedPortfoliosValue,
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        } else if (ad.brandEntityId === '' && ad.selectedPortfoliosValue === '') {
          let campaignsDict = {
            "budgetType": "DAILY",
            "bidding": {
              "bidOptimization": true
            },
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        } else if (ad.brandEntityId !== '' && ad.selectedPortfoliosValue === '') {
          let campaignsDict = {
            "budgetType": "DAILY",
            "brandEntityId": ad.brandEntityId,
            "bidding": {
              "bidOptimization": true
            },
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        } else if (ad.brandEntityId === '' && ad.selectedPortfoliosValue !== '') {
          let campaignsDict = {
            "budgetType": "DAILY",
            "bidding": {
              "bidOptimization": true
            },
            "portfolioId": ad.selectedPortfoliosValue,
            "name": ad.ad_activities,
            "state": "ENABLED",
            "startDate": ad.startDate,
            "budget": parseFloat(ad.budget)
          }
          campaigns.push(campaignsDict)
        }
        axios.post('https://ad.yaelongtime.com/api/proxy/sb/v4/campaigns',
            {
              "campaigns": campaigns
            }, {
              headers: {
                'Content-Type': 'application/vnd.sbcampaignresource.v4+json'
              }
            }
        ).then(res => {
          if (res.data.campaigns.success) {
            ad.campaignId = res.data.campaigns.success[0].campaignId
            sendSbKeywordAdSbGroupRequest(ad);
          } else {
            this.$message.error(ad.ad_activities + '已存在')
          }
        }).catch(error => {
          Message({
            message: error,
            type: 'error',
          });
        })
      }
    },
    //SB新增关键词创建广告组
    sendSbKeywordAdSbGroupRequest(advertising) {
      const sendKeywordSbAdRequest = (advertising) => {
        this.sendKeywordSbAdRequest(advertising)
      };
      if (advertising.adGroupId) {
        sendKeywordSbAdRequest();
      } else {
        axios.post('https://ad.yaelongtime.com/api/proxy/sb/v4/adGroups', {
              "adGroups": [
                {
                  "campaignId": advertising.campaignId,
                  "name": advertising.selectedGroupsValue,
                  "state": "ENABLED",
                }
              ]
            },
            {
              headers: {
                'Content-Type': 'application/vnd.sbadgroupresource.v4+json'
              }
            }
        ).then(res => {
          if (res.data.adGroups.success) {
            advertising.adGroupId = res.data.adGroups.success[0].adGroupId
            sendKeywordSbAdRequest(advertising);
          } else if (res.data.adGroups.error) {
            this.$message.error(advertising.ad_activities + '广告组已存在')
          }
        }).catch(error => {
          Message({
            message: error,
            type: 'error',
          });
        })
      }
    },
    //SB策略绑定
    sendKeywordSbAdRequest(advertising) {
      const sendSbNewKeywordBidRequest = (advertising) => {
        this.sendSbNewKeywordBidRequest(advertising)
      };
      if (advertising.advertising_strategy.includes('SBI-P') || advertising.advertising_strategy.includes('SBI-SP')) {
        let landingPageAsinList = []
        advertising.asin.forEach(function (product) {
          landingPageAsinList.push(product.asin)
        })
        let pageType = ''
        let adsList = []
        if (advertising.advertising_strategy.includes('SBI-P')) {
          pageType = "PRODUCT_LIST"
          if (advertising.customImageAssetId) {
            adsList = [
              {
                "landingPage": {
                  "asins": landingPageAsinList,
                  "pageType": pageType,
                },
                "name": advertising.ad_activities,
                "state": "ENABLED",
                "adGroupId": advertising.adGroupId,
                "creative": {
                  "brandLogoCrop": {
                    "top": 0,
                    "left": 0,
                    "width": 400,
                    "height": 400
                  },
                  "asins": landingPageAsinList,
                  "brandName": advertising.brandName,
                  "customImageAssetId": advertising.customImageAssetId,
                  "customImageCrop": {
                    "top": 0,
                    "left": 0,
                    "width": 1200,
                    "height": 630
                  },
                  "brandLogoAssetID": advertising.brandLogoAssetID,
                  "headline": advertising.headline
                }
              }]
          } else {
            adsList = [
              {
                "landingPage": {
                  "asins": landingPageAsinList,
                  "pageType": pageType,
                },
                "name": advertising.ad_activities,
                "state": "ENABLED",
                "adGroupId": advertising.adGroupId,
                "creative": {
                  "brandLogoCrop": {
                    "top": 0,
                    "left": 0,
                    "width": 400,
                    "height": 400
                  },
                  "asins": landingPageAsinList,
                  "brandName": advertising.brandName,
                  "brandLogoAssetID": advertising.brandLogoAssetID,
                  "headline": advertising.headline
                }
              }]
          }
        } else if (advertising.advertising_strategy.includes('SBI-SP')) {
          let asinList = []
          advertising.ProductsList.forEach(function (product) {
            asinList.push(product.asin)
          })
          pageType = "STORE"
          adsList = [
            {
              "landingPage": {
                "pageType": pageType,
                'url': advertising.landingPageUrl
              },
              "name": advertising.ad_activities,
              "state": "ENABLED",
              "adGroupId": advertising.adGroupId,
              "creative": {
                "brandLogoCrop": {
                  "top": 0,
                  "left": 0,
                  "width": 400,
                  "height": 400
                },
                "asins": asinList,
                "brandName": advertising.brandName,
                "brandLogoAssetID": advertising.brandLogoAssetID,
                "headline": advertising.headline
              }
            }]
        }
        axios.post('https://ad.yaelongtime.com/api/proxy/sb/v4/ads/productCollection', {
              "ads": adsList
            },
            {
              headers: {
                'Content-Type': 'application/vnd.sbadresource.v4+json'
              }
            }
        ).then(res => {
          if (res.data.ads.success[0]) {
            sendSbNewKeywordBidRequest(advertising)
          } else {
            Message({
              message: advertising.productAds.error[0].errors[0].errorValue.message,
              type: 'error',
            });
          }
        }).catch(error => {
          Message({
            message: error,
            type: 'error',
          });
        })
      } else if (advertising.advertising_strategy.includes('SBI-S')) {
        let adsList = []
        let homeStoreUrl = ''
        advertising.storePageList.forEach(function (store) {
          if (store.storePageName === 'Home') {
            homeStoreUrl = store.storePageUrl
          }
        })
        adsList = [
          {
            "landingPage": {
              "url": homeStoreUrl
            },
            "name": advertising.ad_activities,
            "state": "ENABLED",
            "adGroupId": advertising.adGroupId,
            "creative": {
              "brandName": advertising.brandName,
              "brandLogoCrop": {
                "top": 0,
                "left": 0,
                "width": 400,
                "height": 400
              },
              "subpages": [
                {
                  "pageTitle": advertising.firstProductsList[0].storePageName,
                  "asin": advertising.firstProductsList[0].asin,
                  "url": advertising.firstProductsList[0].storePageUrl,
                },
                {
                  "pageTitle": advertising.secondProductsList[0].storePageName,
                  "asin": advertising.secondProductsList[0].asin,
                  "url": advertising.secondProductsList[0].storePageUrl,
                },
                {
                  "pageTitle": advertising.thirdProductsList[0].storePageName,
                  "asin": advertising.thirdProductsList[0].asin,
                  "url": advertising.thirdProductsList[0].storePageUrl,
                }
              ],
              "brandLogoAssetID": advertising.brandLogoAssetID,
              "headline": advertising.headline
            }
          }]
      } else if (advertising.advertising_strategy.includes('SBV-P') || advertising.advertising_strategy.includes('SBV-SP')) {
        let adsList = []
        let landingPageAsinList = []
        advertising.asin.forEach(function (product) {
          landingPageAsinList.push(product.asin)
        })
        if (advertising.advertising_strategy.includes('SBV-P')) {
          adsList = [{
            "name": advertising.advertising_strategy,
            "state": "ENABLED",
            "adGroupId": advertising.adGroupId,
            "creative": {
              "asins": landingPageAsinList,
              "videoAssetIds":
                  [
                    advertising.customVideoAssetId
                  ]
            }
          }]
          axios.post('https://ad.yaelongtime.com/api/proxy/sb/v4/ads/video', {
                "ads": adsList
              },
              {
                headers: {
                  'Content-Type': 'application/vnd.sbadresource.v4+json'
                }
              }
          ).then(res => {
            if (res.data.ads.success[0]) {
              sendSbNewKeywordBidRequest(advertising)
            } else {
              Message({
                message: advertising.productAds.error[0].errors[0].errorValue.message,
                type: 'error',
              });
            }
          }).catch(error => {
            Message({
              message: error,
              type: 'error',
            });
          })
        } else if (advertising.advertising_strategy.includes('SBV-SP')) {
          adsList = [{
            "landingPage": {
              "pageType": "STORE",
              "url": advertising.landingPageUrl
            },
            "name": advertising.ad_activities,
            "state": "ENABLED",
            "adGroupId": advertising.adGroupId,
            "creative": {
              "asins": [
                advertising.videoProductsList[0].asin
              ],
              "videoAssetIds": [
                advertising.customVideoAssetId
              ],
              "brandName": advertising.brandName,
              "brandLogoAssetID": advertising.brandLogoAssetID,
              "headline": advertising.headline
            }
          }]
          axios.post('https://ad.yaelongtime.com/api/proxy/sb/v4/ads/brandVideo', {
                "ads": adsList
              },
              {
                headers: {
                  'Content-Type': 'application/vnd.sbadresource.v4+json'
                }
              }
          ).then(res => {
            if (res.data.ads.success[0]) {
              sendSbNewKeywordBidRequest(advertising)
            } else {
              Message({
                message: advertising.productAds.error[0].errors[0].errorValue.message,
                type: 'error',
              });
            }
          }).catch(error => {
            Message({
              message: error,
              type: 'error',
            });
          })
        }
      }
    },
    //获取sb新增关键词/类目/ASIN进价
    sendSbNewKeywordBidRequest(row) {
      let keywords = []
      let targets = []
      row.keyword.forEach(function (keyword) {
        if (row.matching_method === '关键词') {
          keywords = [
            {
              "matchType": (keyword.matchType).toLowerCase(),
              "keywordText": keyword.keyword
            }
          ]
        } else if (row.matching_method === '商品投放 — ASIN定位') {
          targets = [
            [
              {
                "type": "asinSameAs",
                "value": keyword.recommendedAsin
              }
            ]
          ]
        } else if (row.matching_method === '商品投放 — 类目定位') {
          targets = [
            [
              {
                "type": "asinCategorySameAs",
                "value": keyword.na
              }
            ]
          ]
        }
        let data = {
          "campaignId": parseInt(row.campaignId),
          "keywords": keywords,
          "targets": targets
        }
        axios.post('https://ad.yaelongtime.com/api/proxy/sb/recommendations/bids', data,
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
        ).then(res => {
          if (res.data.keywordsBidsRecommendationSuccessResults.length > 0) {
            res.data.keywordsBidsRecommendationSuccessResults.forEach(function (bid) {
              if (bid.code !== 'NOT_FOUND') {
                keyword.rangeStart = bid.recommendedBid.rangeStart
                keyword.rangeMedian = bid.recommendedBid.recommended
                keyword.rangeEnd = bid.recommendedBid.rangeEnd
                keyword.bidding = (parseInt(row.selectedType) / 10 * bid.recommendedBid.rangeEnd).toFixed(2)
              } else {
                keyword.rangeStart = '~'
                keyword.rangeMedian = '~'
                keyword.rangeEnd = '~'
                keyword.bidding = row.defaultBidding
              }
            })
          } else if (res.data.targetsBidsRecommendationSuccessResults.length > 0) {
            res.data.targetsBidsRecommendationSuccessResults.forEach(function (bid) {
              if (bid.code !== 'NOT_FOUND') {
                keyword.rangeStart = bid.recommendedBid.rangeStart
                keyword.rangeMedian = bid.recommendedBid.recommended
                keyword.rangeEnd = bid.recommendedBid.rangeEnd
                keyword.bidding = (parseInt(row.selectedType) / 10 * bid.recommendedBid.rangeEnd).toFixed(2)
              } else {
                keyword.rangeStart = '~'
                keyword.rangeMedian = '~'
                keyword.rangeEnd = '~'
                keyword.bidding = row.defaultBidding
              }
            })
          } else {
            keyword.rangeStart = '~'
            keyword.rangeMedian = '~'
            keyword.rangeEnd = '~'
            keyword.bidding = row.defaultBidding
          }
        })
      })
    },
    // asin/类目投放创建广告组sb
    sendAbAsinGroupRequest(row) {
      const sendAbProductRequest = (row) => {
        this.sendAbProductRequest(row)
      };
      axios.post('https://ad.yaelongtime.com/api/proxy/sp/adGroups', {
            "adGroups": [
              {
                "campaignId": row.campaignId,
                "name": row.selectedGroupsValue,
                "state": "ENABLED",
                "defaultBid": parseFloat(row.defaultBidding),
              }
            ]
          },
          {
            headers: {
              'Content-Type': 'application/vnd.spAdGroup.v3+json'
            }
          }
      ).then(res => {
        if (res.data.adGroups.success) {
          row.adGroupId = res.data.adGroups.success[0].adGroupId
          sendAbProductRequest(row)
        } else if (res.data.adGroups.error) {
          this.$message.error(row.ad_activities + '类目/asin广告组已存在')
        }
      }).catch(error => {
        Message({
          message: error,
          type: 'error',
        });
      })
    },
    //存在类目/ASIN创建产品广告---获取推荐竞价sb
    sendAbProductRequest(row) {
      const sendProductBiddingRequest = (row) => {
        this.sendProductBiddingRequest(row)
      };
      let productAdsList = []
      if (typeof row.asin === 'object' && Array.isArray(row.asin)) {
        row.asin.forEach(function (product) {
          let productAdsDict = {
            "campaignId": row.campaignId,
            "state": "ENABLED",
            "sku": product.sku,
            "adGroupId": row.adGroupId
          }
          productAdsList.push(productAdsDict)
        })
      } else {
        let productAds = {
          "campaignId": row.campaignId,
          "state": "ENABLED",
          "sku": row.sku,
          "adGroupId": row.adGroupId
        }
        productAdsList.push(productAds)
      }
      axios.post('https://ad.yaelongtime.com/api/proxy/sp/productAds', {
            "productAds": productAdsList
          },
          {
            headers: {
              'Content-Type': 'application/vnd.spProductAd.v3+json'
            }
          }
      ).then(res => {
        if (res.data.productAds.success[0] && row.matching_method_type === 'MANUAL') {
          sendProductBiddingRequest(row)
        } else {
          this.$message.error(row.ad_activities + '类目/asin产品已存在')
        }
      }).catch(error => {
        Message({
          message: error,
          type: 'error',
        });
      })
    },
    //获取类目/ASIN竞价
    sendKeywordRequest(advertising) {
      const sendNegativeRequest = (row) => {
        this.sendNegativeRequest(row)
      };
      if (advertising.matching_method === '关键词') {
        advertising.keyword.forEach(function (keyword) {
          axios.post('https://ad.yaelongtime.com/api/proxy/sp/keywords', {
                "keywords": [
                  {
                    "campaignId": advertising.campaignId,
                    "matchType": keyword.matchType,
                    "state": "ENABLED",
                    "bid": parseFloat(keyword.bidding),
                    "adGroupId": advertising.adGroupId,
                    "keywordText": keyword.keyword
                  }
                ]
              },
              {
                headers: {
                  'Content-Type': 'application/vnd.spKeyword.v3+json'
                }
              }
          ).then(res => {
            if (res.data.keywords.success) {
              if (advertising.tableRecommendationNegativeData.length > 0) {
                sendNegativeRequest(advertising)
              } else {
                advertising.success = true
                Message({
                  message: advertising.ad_activities + '投放成功',
                  type: 'success',
                });
              }
            } else {
              Message({
                message: advertising.ad_activities + '投放失败',
                type: 'error',
              });
            }
          }).catch(error => {
            Message({
              message: error,
              type: 'error',
            });
          })
        })
      }
    },
    // asin/类目投放创建广告活动
    handleAsinCampaignsClick(row) {
      const sendAbAsinGroupRequest = (row) => {
        this.sendAbAsinGroupRequest(row)
      };
      if (row.biddingStrategy === '动态竞价-提高或降低') {
        row.biddingStrategy = 'AUTO_FOR_SALES'
      } else if (row.biddingStrategy === '固定竞价') {
        row.biddingStrategy = 'MANUAL'
      } else if (row.biddingStrategy === '动态竞价-只降低') {
        row.biddingStrategy = 'LEGACY_FOR_SALES'
      }
      if (row.selectedPortfoliosValue) {
        if (row.startDate !== '' && row.endDate !== '') {
          axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaigns',
              {
                "campaigns": [
                  {
                    "portfolioId": row.selectedPortfoliosValue,
                    "endDate": row.endDate,
                    "name": row.ad_activities,
                    "targetingType": row.matching_method_type,
                    "state": "ENABLED",
                    "dynamicBidding": {
                      "placementBidding": [
                        {
                          "percentage": parseInt(row.adHomeSlotsValue),
                          "placement": "PLACEMENT_TOP"
                        },
                        {
                          "percentage": parseInt(row.adSlotsValue),
                          "placement": "PLACEMENT_PRODUCT_PAGE"
                        }
                      ],
                      "strategy": row.biddingStrategy
                    },
                    "startDate": row.startDate,
                    "budget": {
                      "budgetType": "DAILY",
                      "budget": parseFloat(row.budget)
                    }
                  }
                ]

              }, {
                headers: {
                  'Content-Type': 'application/vnd.spCampaign.v3+json'
                }
              }
          ).then(res => {
            if (res.data.campaigns.success) {
              row.campaignId = res.data.campaigns.success[0].campaignId
              sendAbAsinGroupRequest(row);
            } else {
              console.log(row.ad_activities + '类目/asin活动已存在')
            }

          }).catch(error => {
            Message({
              message: error,
              type: 'error',
            });
          })
        } else if (row.startDate !== '' && row.endDate === '') {
          axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaigns',
              {
                "campaigns": [
                  {
                    "portfolioId": row.selectedPortfoliosValue,
                    "name": row.ad_activities,
                    "targetingType": row.matching_method_type,
                    "state": "ENABLED",
                    "dynamicBidding": {
                      "placementBidding": [
                        {
                          "percentage": parseInt(row.adHomeSlotsValue),
                          "placement": "PLACEMENT_TOP"
                        },
                        {
                          "percentage": parseInt(row.adSlotsValue),
                          "placement": "PLACEMENT_PRODUCT_PAGE"
                        }
                      ],
                      "strategy": row.types
                    },
                    "startDate": row.startDate,
                    "budget": {
                      "budgetType": "DAILY",
                      "budget": parseFloat(row.budget)
                    }
                  }
                ]

              }, {
                headers: {
                  'Content-Type': 'application/vnd.spCampaign.v3+json'
                }
              }
          ).then(res => {
            if (res.data.campaigns.success) {
              row.campaignId = res.data.campaigns.success[0].campaignId
              sendAbAsinGroupRequest(row);
            } else if (res.data.campaigns.error) {
              this.$message.error(row.ad_activities + '类目/asin活动已存在')
            }
          }).catch(error => {
            Message({
              message: error,
              type: 'error',
            });
          })
        }
      } else {
        if (row.startDate !== '' && row.endDate !== '') {
          axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaigns',
              {
                "campaigns": [
                  {
                    "endDate": row.endDate,
                    "name": row.ad_activities,
                    "targetingType": row.matching_method_type,
                    "state": "ENABLED",
                    "dynamicBidding": {
                      "placementBidding": [
                        {
                          "percentage": parseInt(row.adHomeSlotsValue),
                          "placement": "PLACEMENT_TOP"
                        },
                        {
                          "percentage": parseInt(row.adSlotsValue),
                          "placement": "PLACEMENT_PRODUCT_PAGE"
                        }
                      ],
                      "strategy": row.biddingStrategy
                    },
                    "startDate": row.startDate,
                    "budget": {
                      "budgetType": "DAILY",
                      "budget": parseFloat(row.budget)
                    }
                  }
                ]

              }, {
                headers: {
                  'Content-Type': 'application/vnd.spCampaign.v3+json'
                }
              }
          ).then(res => {
            if (res.data.campaigns.success) {
              row.campaignId = res.data.campaigns.success[0].campaignId
              sendAbAsinGroupRequest(row);
            } else {
              this.$message.error(row.ad_activities + '类目/asin广告活动已存在')
            }

          }).catch(error => {
            Message({
              message: '已存在广告活动',
              type: 'error',
            });
          })
        } else if (row.startDate !== '' && row.endDate === '') {
          axios.post('https://ad.yaelongtime.com/api/proxy/sp/campaigns',
              {
                "campaigns": [
                  {
                    "name": row.ad_activities,
                    "targetingType": row.matching_method_type,
                    "state": "ENABLED",
                    "dynamicBidding": {
                      "placementBidding": [
                        {
                          "percentage": parseInt(row.adHomeSlotsValue),
                          "placement": "PLACEMENT_TOP"
                        },
                        {
                          "percentage": parseInt(row.adSlotsValue),
                          "placement": "PLACEMENT_PRODUCT_PAGE"
                        }
                      ],
                      "strategy": row.types
                    },
                    "startDate": row.startDate,
                    "budget": {
                      "budgetType": "DAILY",
                      "budget": parseFloat(row.budget)
                    }
                  }
                ]

              }, {
                headers: {
                  'Content-Type': 'application/vnd.spCampaign.v3+json'
                }
              }
          ).then(res => {
            if (res.data.campaigns.success) {
              row.campaignId = res.data.campaigns.success[0].campaignId
              sendAbAsinGroupRequest(row);
            } else if (res.data.campaigns.error) {
              this.$message.error(row.ad_activities + '类目/asin广告活动已存在')
            }
          }).catch(error => {
            Message({
              message: error,
              type: 'error',
            });
          })
        }
      }

    },
    sendProductBiddingRequest(row) {
      let value = ''
      let type = ''
      row.keyword.forEach(function (positioning) {
        if (row.matching_method === '商品投放 — ASIN定位') {
          value = positioning.recommendedAsin
          if (positioning.selectedAsinType === '精准') {
            type = "asinSameAs"
          } else if (positioning.selectedAsinType === '扩展') {
            type = "asinExpandedFrom"
          }
        } else if (row.matching_method === '商品投放 — 类目定位') {
          value = positioning.id
          type = "asinCategorySameAs"
        }
        axios.post('https://ad.yaelongtime.com/api/proxy/v2/sp/targets/bidRecommendations', {
              "adGroupId": row.adGroupId,
              "expressions": [
                [
                  {
                    "value": value,
                    "type": type
                  }
                ]
              ]
            },
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
        ).then(res => {
          if (res.data.recommendations) {
            positioning.rangeStart = res.data.recommendations[0].suggestedBid.rangeStart
            positioning.rangeMedian = res.data.recommendations[0].suggestedBid.suggested
            positioning.rangeEnd = res.data.recommendations[0].suggestedBid.rangeEnd
            positioning.bidding = (res.data.recommendations[0].suggestedBid.rangeEnd * row.selectedType / 10).toFixed(2)
          }
        }).catch(error => {
          positioning.rangeStart = "~"
          positioning.rangeMedian = "~"
          positioning.rangeEnd = "~"
          positioning.bidding = row.defaultBidding
        })
      })
    },
    //ASIN/类目投放data
    sendProductPositioningRequest(row) {
      const sendPositioningRequest = (adCategory) => {
        this.sendPositioningRequest(adCategory)
      };
      let adDataList = []
      let type = ''
      let value = ''
      let expression = []
      row.keyword.forEach(function (positioning) {
        if (row.matching_method === '商品投放 — ASIN定位') {
          value = positioning.recommendedAsin
          if (positioning.selectedAsinType === '精准') {
            type = "ASIN_SAME_AS"
          } else if (positioning.selectedAsinType === '扩展') {
            type = "ASIN_EXPANDED_FROM"
          }
        } else if (row.matching_method === '商品投放 — 类目定位') {
          if (positioning.positioning.includes('分类细化')) {
            let category = [{
              value: positioning.id,
              type: "ASIN_CATEGORY_SAME_AS"
            },]
            if (positioning.brand !== '') {
              let refineCategory = {
                type: 'ASIN_BRAND_SAME_AS',
                value: positioning.brand
              }
              category.push(refineCategory)
            } else if (positioning.startPrice !== '' && positioning.endPrice !== '') {
              let refineCategory = {
                type: 'ASIN_PRICE_BETWEEN',
                value: (parseInt(positioning.startPrice) - parseInt(positioning.endPrice)).toString()
              }
              category.push(refineCategory)
            } else if (positioning.startStar !== undefined && positioning.endStar !== undefined) {
              let refineCategory = {
                type: 'ASIN_REVIEW_RATING_BETWEEN',
                value: (parseInt(positioning.startStar) - parseInt(positioning.endStar)).toString()
              }
              category.push(refineCategory)
            } else if (positioning.prime !== '') {
              let refineCategory = {
                type: 'ASIN_IS_PRIME_SHIPPING_ELIGIBLE',
                value: positioning.prime
              }
              category.push(refineCategory)
            } else {
              let refineCategory = {
                value: positioning.id,
                type: "ASIN_CATEGORY_SAME_AS"
              }
              category.push(refineCategory)
            }
            expression.push(category)
          } else {
            value = positioning.id
            type = "ASIN_CATEGORY_SAME_AS"
          }
        }
        if (positioning.positioning.includes("类目细化")) {
          let adData = {
            "expression": expression,
            "campaignId": row.campaignId,
            "expressionType": row.matching_method_type,
            "state": "ENABLED",
            "bid": parseFloat(positioning.bidding),
            "adGroupId": row.adGroupId
          }
          adDataList.push(adData)
        } else {
          let adData = {
            "expression": [
              {
                "type": type,
                "value": value
              }
            ],
            "campaignId": row.campaignId,
            "expressionType": row.matching_method_type,
            "state": "ENABLED",
            "bid": parseFloat(positioning.bidding),
            "adGroupId": row.adGroupId
          }
          adDataList.push(adData)
        }
      })
      row.adDataList = adDataList
      sendPositioningRequest(row)
    },
    // asin/类目投放
    sendPositioningRequest(row) {
      const sendNegativeRequest = (row) => {
        this.sendNegativeRequest(row)
      };
      axios.post('https://ad.yaelongtime.com/api/proxy/sp/targets', {
            "targetingClauses": row.adDataList
          },
          {
            headers: {
              'Content-Type': 'application/vnd.spTargetingClause.v3+json'
            }
          }
      ).then(res => {
        if (res.data.targetingClauses.success) {
          if (row.tableRecommendationNegativeData.length > 0) {
            sendNegativeRequest(row)
          } else {
            row.success = true
            Message({
              message: row.ad_activities + '投放成功',
              type: 'success',
            });
          }
        } else if (res.data.targetingClauses.error) {
          Message({
            message: 'ASIN已存在',
            type: 'error',
          });
        }
      }).catch(error => {
        Message({
          message: error,
          type: 'error',
        });
      })
    },
    handleSelectionBiddingStrategy() {
    },
    //自动广告表达式页签关闭
    dialogAutoVisible() {
      this.dialogAutoFormVisible = false
    },
    //手动广告关键字页签关闭
    dialogKeywordForm() {
      this.dialogKeywordFormVisible = false
    },
    //手动广告类目页签关闭
    dialogCategoriesForm() {
      this.dialogCategoriesFormVisible = false
    },
    //手动广告ASIN页签关闭
    dialogAsinForm() {
      this.dialogAsinFormVisible = false
    },
    //手动关闭受众页签
    dialogAudienceForm() {
      this.dialogAudienceFormVisible = false
    },
    //上一步
    handleUpClick() {
      this.$router.push({
        path: '/ad-composition', query: {
          selectionProductStrategyData: this.selectionProductStrategyData,
          selectionProductData: this.selectionProduct,
          tableInfoData: this.tableInfoData,
          selectionInfo: this.selectionInfo,
          upPath: this.$route.path
        }
      });
    },
    //嵌套页签
    openDialog(row) {
      if (row.keywordLength) {
        if (row.advertising_strategy.substring(0, 2).includes('SP')) {
          if (row.advertising_strategy === 'SPA1' || row.advertising_strategy === 'SPA2' || row.advertising_strategy === 'SPA3' || row.advertising_strategy === 'SPA4') {
            this.autoTargeting(row)
            setTimeout(() => {
              // 在延迟后执行打开页签的操作
              this.dialogAutoFormVisible = true
            }, 3000); // 延迟时间为 3000秒 毫（即 3 秒）
            this.tableAutoData = row.autoData
          } else if (row.advertising_strategy.includes("KW") || row.advertising_strategy.includes("LW") || row.advertising_strategy.includes("SW")) {
            let newKeyword = ''
            row.keyword.forEach(function (keyword) {
              if (keyword.newKeyword === '新关键字') {
                newKeyword = keyword.newKeyword
              }
            })
            if (newKeyword === '新关键字') {
              // 在延迟后执行打开页签的操作
              this.dialogKeywordFormVisible = true
              this.tableKeywordData = row.keyword
            } else {
              this.dialogKeywordFormVisible = true
              this.tableKeywordData = row.keyword
            }
          } else if (row.advertising_strategy.includes("SPP")) {
            // this.handleAsinCampaignsClick(row)
            if (row.matching_method === '商品投放 — 类目定位') {
              // 在延迟后执行打开页签的操作
              this.dialogCategoriesFormVisible = true
              this.tableCategoriesData = row.keyword;
            } else if (row.matching_method === '商品投放 — ASIN定位') {
              this.dialogAsinFormVisible = true
              this.tableRecommendationAsinData = row.keyword;
            }
          }
          this.dialogData = row
        } else if (row.advertising_strategy.substring(0, 2).includes('SB')) {
          if (row.advertising_strategy.includes("KW") || row.advertising_strategy.includes("LW") || row.advertising_strategy.includes("SW")) {
            let newKeyword = ''
            row.keyword.forEach(function (keyword) {
              if (keyword.newKeyword === '新关键字') {
                newKeyword = keyword.newKeyword
              }
            })
            if (newKeyword === '新关键字') {
              // 在延迟后执行打开页签的操作
              this.dialogKeywordFormVisible = true
              this.tableKeywordData = row.keyword
            } else {
              this.dialogKeywordFormVisible = true
              this.tableKeywordData = row.keyword
            }
          } else if (row.advertising_strategy.includes("C") || row.advertising_strategy.includes("A")) {
            if (row.matching_method === '商品投放 — 类目定位') {
              // 在延迟后执行打开页签的操作
              this.dialogCategoriesFormVisible = true
              this.tableCategoriesData = row.keyword;
            } else if (row.matching_method === '商品投放 — ASIN定位') {
              this.dialogAsinFormVisible = true
              this.tableRecommendationAsinData = row.keyword;
            }
          }
          this.dialogData = row
        } else if (row.advertising_strategy.substring(0, 2).includes('SD')) {
          if (row.advertising_strategy.includes('SDI-P-') || row.advertising_strategy.includes('SDI-C') || row.advertising_strategy.includes('SDV-P-')
              || row.advertising_strategy.includes('SDV-C')) {
            if (row.matching_method === '品类投放 — ASIN') {
              this.dialogAsinFormVisible = true
              this.tableRecommendationAsinData = row.keyword;
            } else if (row.matching_method === '品类投放 — 类目') {
              this.dialogCategoriesFormVisible = true
              this.tableCategoriesData = row.keyword;
            }
          } else if (row.advertising_strategy.includes('SDI-AA') || row.advertising_strategy.includes('SDV-AA')) {
            this.dialogAudienceFormVisible = true
            this.tableRecommendationAudienceData = row.keyword
          } else if (row.advertising_strategy.includes('SDI-VR') || row.advertising_strategy.includes('SDV-VR')) {
            this.dialogAudienceFormVisible = true
            this.tableRecommendationAudienceData = row.keyword
          } else if (row.advertising_strategy.includes('SDI-PR') || row.advertising_strategy.includes('SDV-PR')) {
            this.dialogAudienceFormVisible = true
            this.tableRecommendationAudienceData = row.keyword
          }
          this.dialogData = row
        }
      }
    },
    showButton(row) {
      row.showButton = true;
    },
    hideButton(row) {
      row.showButton = false;
    },
    handleChange() {
      this.$axios.get('https://ad.yaelongtime.com/api/employee/me',
          {}
      ).then(res => {
        console.log(777, res.data)
        this.name = res.data.alias
        let advertisingStrategyData = []
        let selectionProduct = this.selectionProduct
        let selectionProductStrategyData = this.selectionProductStrategyData
        let name = this.name
         console.log(77887, this.selectionProductStrategyData)
        this.selectionProductStrategyData.forEach(function (ad) {
          if (ad.strategy.length > 1) {
            if (ad.product_state === 1) {
              selectionProduct.forEach(function (product) {
                if (ad.keyword) {
                  let activities = {
                    type: product.strategy[0].advertising_strategy.slice(0, 2),
                    ad_activities: product.strategy[0].advertising_strategy + ('-') + product.sku + ('-') + product.strategy[0].selectedType + ('-') + selectionProductStrategyData[0].startDate + ('-') + name,
                    advertising_strategy: product.strategy[0].advertising_strategy,
                    selectedType: product.strategy[0].selectedType,
                    keyword: product.strategy[0].keyword,
                    defaultBidding: ad.defaultBidding,
                    selectedPortfoliosValue: (ad.selectedPortfoliosValue).toString(),
                    asin: product.asin,
                    sku: product.sku,
                    adPlacementPositionName: selectionProductStrategyData[0].adPlacementPositionName,
                    types: ad.types,
                    matching_method_type: selectionProductStrategyData[0].matching_method_type,
                    startDate: selectionProductStrategyData[0].startDate,
                    endDate: selectionProductStrategyData[0].endDate,
                    keywordLength: product.strategy[0].keywordLength,
                    autoData: product.strategy[0].autoData,
                    budget: selectionProductStrategyData[0].budget,
                    adSlotsValue: selectionProductStrategyData[0].adSlotsValue,
                    adHomeSlotsValue: selectionProductStrategyData[0].adHomeSlotsValue,
                    adPlacementPosition: selectionProductStrategyData[0].adPlacementPosition,
                    selectedGroupsValue: ad.selectedGroupsValue,
                    matching_method: selectionProductStrategyData[0].matching_method,
                    selectedBiddingStrategy: '',
                    biddingStrategy: selectionProductStrategyData[0].biddingStrategy,
                    state: true,
                    showButton: false,
                    showDeleteButton: false,
                    negativeLength: 0,
                    typeText: product.strategy[0].typeText,
                    tableRecommendationNegativeData: [],
                    headline: ad.headline,
                    customImageAssetId: ad.customImageAssetId,
                    customVideoAssetId: ad.customVideoAssetId,
                    landingPageUrl: ad.landingPageUrl,
                    storePageList: ad.storePageList,
                    firstProductsList: ad.firstProductsList,
                    secondProductsList: ad.secondProductsList,
                    thirdProductsList: ad.thirdProductsList,
                    videoProductsList: ad.videoProductsList,
                    brandEntityId: ad.brandEntityId,
                    brandLogoAssetID: ad.brandLogoAssetID,
                    brandName: ad.brandName,
                    bidOptimization: ad.bidOptimization,
                    costType: ad.costType,
                    tactic: ad.tactic,
                    adOtherPagesValue: ad.adOtherPagesValue
                  }
                  advertisingStrategyData.push(activities)
                } else if (ad.autoData) {
                  let activities = {
                    type: product.strategy[0].advertising_strategy.slice(0, 2),
                    ad_activities: product.strategy[0].advertising_strategy + ('-') + product.sku + ('-') + product.strategy[0].selectedType + ('-') + selectionProductStrategyData[0].startDate + ('-') + name,
                    advertising_strategy: product.strategy[0].advertising_strategy,
                    selectedType: product.strategy[0].selectedType,
                    keyword: ad.keyword,
                    defaultBidding: ad.defaultBidding,
                    selectedPortfoliosValue: (ad.selectedPortfoliosValue).toString(),
                    asin: product.asin,
                    sku: product.sku,
                    adPlacementPositionName: selectionProductStrategyData[0].adPlacementPositionName,
                    types: ad.types,
                    matching_method_type: selectionProductStrategyData[0].matching_method_type,
                    startDate: selectionProductStrategyData[0].startDate,
                    endDate: selectionProductStrategyData[0].endDate,
                    keywordLength: product.strategy[0].keywordLength,
                    autoData: ad.autoData,
                    budget: selectionProductStrategyData[0].budget,
                    adSlotsValue: selectionProductStrategyData[0].adSlotsValue,
                    adHomeSlotsValue: selectionProductStrategyData[0].adHomeSlotsValue,
                    adPlacementPosition: selectionProductStrategyData[0].adPlacementPosition,
                    selectedGroupsValue: ad.selectedGroupsValue,
                    matching_method: selectionProductStrategyData[0].matching_method,
                    selectedBiddingStrategy: '',
                    biddingStrategy: selectionProductStrategyData[0].biddingStrategy,
                    state: true,
                    showButton: false,
                    showDeleteButton: false,
                    negativeLength: 0,
                    typeText: product.strategy[0].typeText,
                    tableRecommendationNegativeData: [],
                    headline: ad.headline,
                    customImageAssetId: ad.customImageAssetId,
                    customVideoAssetId: ad.customVideoAssetId,
                    landingPageUrl: ad.landingPageUrl,
                    storePageList: ad.storePageList,
                    firstProductsList: ad.firstProductsList,
                    secondProductsList: ad.secondProductsList,
                    thirdProductsList: ad.thirdProductsList,
                    videoProductsList: ad.videoProductsList,
                    brandEntityId: ad.brandEntityId,
                    brandLogoAssetID: ad.brandLogoAssetID,
                    brandName: ad.brandName,
                    bidOptimization: ad.bidOptimization,
                    costType: ad.costType,
                    tactic: ad.tactic,
                    adOtherPagesValue: ad.adOtherPagesValue
                  }
                  advertisingStrategyData.push(activities)
                }
              })
            } else {
              let asin = []
              selectionProduct.forEach(function (product) {
                let products = {
                  'asin': product.asin,
                  'sku': product.sku
                }
                asin.push(products)
              })
              let activities = {
                type: ad.advertising_strategy.slice(0, 2),
                ad_activities: ad.advertising_strategy + ('-') + ad.strategy[0].name + ('-') + ad.selectedType + ('-') + ad.startDate + ('-') + name,
                advertising_strategy: ad.advertising_strategy,
                matching_method_type: ad.matching_method_type,
                selectedType: ad.selectedType,
                keyword: ad.keyword,
                asin: asin,
                keywordLength: ad.keywordLength,
                selectedPortfoliosValue: (ad.selectedPortfoliosValue).toString(),
                defaultBidding: ad.defaultBidding,
                autoData: ad.autoData,
                types: ad.types,
                startDate: ad.startDate,
                endDate: ad.endDate,
                budget: ad.budget,
                adSlotsValue: ad.adSlotsValue,
                adHomeSlotsValue: ad.adHomeSlotsValue,
                adPlacementPosition: ad.adPlacementPosition,
                adPlacementPositionName: ad.adPlacementPositionName,
                selectedGroupsValue: ad.selectedGroupsValue,
                matching_method: ad.matching_method,
                selectedBiddingStrategy: '',
                biddingStrategy: ad.biddingStrategy,
                state: true,
                showButton: false,
                showDeleteButton: false,
                negativeLength: 0,
                typeText: ad.typeText,
                tableRecommendationNegativeData: [],
                headline: ad.headline,
                customImageAssetId: ad.customImageAssetId,
                customVideoAssetId: ad.customVideoAssetId,
                landingPageUrl: ad.landingPageUrl,
                storePageList: ad.storePageList,
                firstProductsList: ad.firstProductsList,
                secondProductsList: ad.secondProductsList,
                thirdProductsList: ad.thirdProductsList,
                videoProductsList: ad.videoProductsList,
                brandEntityId: ad.brandEntityId,
                brandLogoAssetID: ad.brandLogoAssetID,
                brandName: ad.brandName,
                bidOptimization: ad.bidOptimization,
                costType: ad.costType,
                tactic: ad.tactic,
                adOtherPagesValue: ad.adOtherPagesValue
              }
              advertisingStrategyData.push(activities)
            }
          } else if (ad.strategy.length === 1) {
            let activities = {
              type: ad.advertising_strategy.slice(0, 2),
              ad_activities: ad.advertising_strategy + ('-') + ad.strategy[0].sku + ('-') + ad.selectedType + ('-') + ad.startDate + ('-') + name,
              advertising_strategy: ad.advertising_strategy,
              selectedType: ad.selectedType,
              keyword: ad.keyword,
              keywordLength: ad.keywordLength,
              selectedPortfoliosValue: (ad.selectedPortfoliosValue).toString(),
              defaultBidding: ad.defaultBidding,
              budget: ad.budget,
              asin: ad.strategy[0].asin,
              sku: ad.strategy[0].sku,
              startDate: ad.startDate,
              endDate: ad.endDate,
              matching_method_type: ad.matching_method_type,
              autoData: ad.autoData,
              types: ad.types,
              adSlotsValue: ad.adSlotsValue,
              adHomeSlotsValue: ad.adHomeSlotsValue,
              adPlacementPosition: ad.adPlacementPosition,
              selectedGroupsValue: ad.selectedGroupsValue,
              adPlacementPositionName: ad.adPlacementPositionName,
              matching_method: ad.matching_method,
              selectedBiddingStrategy: '',
              biddingStrategy: ad.biddingStrategy,
              state: true,
              showButton: false,
              showDeleteButton: false,
              negativeLength: 0,
              typeText: ad.typeText,
              tableRecommendationNegativeData: [],
              headline: ad.headline,
              customImageAssetId: ad.customImageAssetId,
              customVideoAssetId: ad.customVideoAssetId,
              landingPageUrl: ad.landingPageUrl,
              storePageList: ad.storePageList,
              firstProductsList: ad.firstProductsList,
              secondProductsList: ad.secondProductsList,
              thirdProductsList: ad.thirdProductsList,
              videoProductsList: ad.videoProductsList,
              brandEntityId: ad.brandEntityId,
              brandLogoAssetID: ad.brandLogoAssetID,
              brandName: ad.brandName,
              bidOptimization: ad.bidOptimization,
              costType: ad.costType,
              tactic: ad.tactic,
              adOtherPagesValue: ad.adOtherPagesValue
            }
            advertisingStrategyData.push(activities)
          }
        })
        this.advertisingStrategyData = advertisingStrategyData
      }).catch(error => {
        this.$message.error(error)
      })

    },
    handleStateChange(row) {
      console.log(999999, row)
    },
  },
  computed: {
    styles() {
      // 根据基准字号和行高计算字体大小和行距
      const fontSize = `${this.baseFontSize}px`;
      const lineHeight = `${this.baseLineHeight * this.baseFontSize}px`;

      // 响应式布局
      return {
        'font-size': fontSize,
        'line-height': lineHeight,
        'padding-top': '-10px',
        'padding-left': '-10px',
        'padding-right': '1px',
      };
    }
    ,
  },
  created() {
    if (this.$route.query.upPath === '/ad-composition') {
      this.selectionProduct = this.$route.query.selectionProductData;
      this.selectionProductStrategyData = this.$route.query.selectionProductStrategyData;
      this.tableInfoData = this.$route.query.tableInfoData
      this.selectionInfo = this.$route.query.selectionInfo
    }
    this.handleChange()
  },
}
</script>

<style scoped>
.product-container {
  display: flex;
  flex-wrap: wrap;
}

.product-item {
  display: flex;
  align-items: center;
  margin: 20px;
}

.product-item img {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}

.sku,
.asin {
  margin: 20px 10px 1px 10px;
}
</style>