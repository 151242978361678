<template>
  <div :style="styles">
    <el-col :span="24">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">广告</el-breadcrumb-item>
        <el-breadcrumb-item>历史广告</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider></el-divider>
    </el-col>
    <el-row :gutter="24">
      <el-col :span="3" style="padding-left: 30px">
        <el-select
            v-model="amazon_shop_selected"
            collapse-tags
            filterable
            @change="handleChange"
            :default-first-option="true"
            style="background-color:#409eff "
            placeholder="请选择店铺"
        >
          <el-option
              v-for="item in amazon_shop_options"
              :key="item.id"
              :label="item.amazon_name"
              :value="item.amazon_name">
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="4">
        <el-button type="primary" icon="el-icon-circle-plus" plain @click="handleCreateAdClick">广告创建</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="4" style="padding-left: 30px">
        <el-input v-model="filterValue" placeholder="请输入SKU"
                  @keydown.enter="search" @blur="search" @clear="refresh" clearable></el-input>
      </el-col>
      <el-col :span="4">
        <el-input v-model="filterTypeValue" placeholder="请输入ASIN"
                  @keydown.enter="searchType" @blur="searchType" @clear="refreshType" clearable></el-input>
      </el-col>
      <el-col :span="4">
        <el-input v-model="filterAdCampaignValue" placeholder="请输入广告活动"
                  @keydown.enter="searchAdCampaign" @blur="searchAdCampaign" @clear="refreshAdCampaign"
                  clearable></el-input>
      </el-col>
      <el-col :span="4">
        <div class="block">
          <el-date-picker
              v-model="valueTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @keydown.enter="searchAdTime"
              @blur="searchAdTime"
              @clear="refreshAdTime"
          >
          </el-date-picker>
        </div>
      </el-col>
    </el-row>
    <el-row :gutter="24" style="margin: 10px 20px;">
      <div class="my-table-container">
        <div class="empty-div"></div>
        <el-table
            v-loading="loading"
            class="my-table"
            border
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%;"
            :max-height="getMaxHeight()"
            :header-row-style="{height: '50px'}"
        >
          <el-table-column
              sortable
              type="index"
              label="序号"
              fixed
              width="80">
          </el-table-column>
          <el-table-column
              prop="state"
              label="有效"
              width="80">
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.state"
                  @change="handleStateChange(scope.row)"
                  validate-event
                  inactive-color="＃808080"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
              prop="type"
              label="类型"
              width="80"
          >
          </el-table-column>
          <el-table-column
              prop="campaignName"
              label="广告活动"
              width="360"
          >
          </el-table-column>
          <el-table-column
              prop="campaignName"
              label="广告组合"
              width="360"
          >
          </el-table-column>
          <el-table-column
              sortable
              prop="campaignBudgetAmount"
              label="预算"
              width="100"
          >
          </el-table-column>
          <el-table-column
              prop="impressions"
              label="曝光量"
              width="100"
          >
          </el-table-column>
          <el-table-column
              prop="clicks"
              label="点击"
              width="100"
          >
          </el-table-column>
          <el-table-column
              prop="spend"
              label="花费"
              width="100"
          >
          </el-table-column>
          <el-table-column
              prop="sales30d"
              label="销售额/月"
              width="100"
          >
          </el-table-column>
          <el-table-column
              prop="purchasesSameSku30d"
              label="销售量/月"
              width="100"
          >
          </el-table-column>
          <el-table-column
              prop="acos"
              label="ACos"
              width="100"
          >
          </el-table-column>
          <el-table-column
              prop="clickThroughRate"
              label="CTR"
              width="100"
          >
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
          class="pagination-wrapper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="parseInt(pagination.page)"
          :page-sizes="pagination.pageSizeOption"
          :page-size="parseInt(pagination.page_size)"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total_records"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import {Message} from 'element-ui'
import axios from 'axios'

export default {
  name: "HistoricalAdvertising",
  data() {
    return {
      maxHeight: 500, // 默认最大高度
      loading: true,
      value: true,
      filterValue: '',
      filterTypeValue: '',
      valueTime: '',
      filterAdCampaignValue: '',
      pagination: {
        page: 0,
        page_size: 20,
        total_records: 1,
        total_pages: 1,
        pageSizeOption: [20, 50, 100, 200]
      },
      amazon_shop_selected: '',
      options: ['US', 'MX', 'CA', 'DE', 'EX'],
      amazon_shop_options: [],
      tableData: [],
      baseFontSize: 18,
      baseLineHeight: 1.8,
      profile: '',
      storeInfo: '',
    }
  },
  computed: {
    styles() {
      // 根据基准字号和行高计算字体大小和行距
      const fontSize = `${this.baseFontSize}px`;
      const lineHeight = `${this.baseLineHeight * this.baseFontSize}px`;

      // 响应式布局
      return {
        'font-size': fontSize,
        'line-height': lineHeight,
        'padding-top': '-10px',
        'padding-left': '-10px',
        'padding-right': '1px',
      };
    },
  },
  methods: {
    getMaxHeight() {
      // 在这里根据屏幕大小或其他条件计算最大高度
      // 这里简单地根据窗口高度调整最大高度，你可以根据需要进行更复杂的计算
      return Math.max(window.innerHeight - 100, this.maxHeight);
    },
    searchAdTime() {
      const created = this.valueTime[0].toISOString().split('T')[0]; // 获取开始日期
      const updated = this.valueTime[1].toISOString().split('T')[0]; // 获取结束日期
      if (this.valueTime) {
        this.$axios.get('https://ad.yaelongtime.com/api/sp/report',
            {
              params: {
                'campaign.created': created,
              }
            }
        ).then(res => {
          this.loading = false
          let tableData = []
          res.data.content.forEach(function (ad) {
            if (ad.campaign.state === 'ENABLED') {
              ad.campaign.state = true
            } else {
              ad.campaign.state = false
            }
            let inventoryData = {
              campaignName: ad.campaign.name,
              state: ad.campaign.state,
              type: 'SP',
              impressions: ad.impressions,
              sales30d: ad.sales30d,
              clicks: ad.clicks,
              campaignBudgetAmount: ad.campaignBudgetAmount,
              clickThroughRate: (ad.clickThroughRate * 1).toFixed(2) + '%',
              targetingType: ad.campaign.targetingType,
              sku: ad.sku,
              asin: ad.asin,
              spend: ad.spend,
              roas: ad.roasClicks14d,
              purchasesSameSku30d: ad.purchasesSameSku30d,
              acos: (ad.acosClicks14d).toFixed(2)
            }
            tableData.push(inventoryData)
          })
          this.tableData = tableData
          this.pagination.total_pages = res.data.totalPages
          this.pagination.total_records = res.data.totalElements
        }).catch(error => {
          this.$message.error(error)
        })

      } else {
        this.handleCurrentChange()
      }
    },
    refreshAdTime() {
      this.handleCurrentChange()
    },
    handleCreateAdClick() {
      if (this.storeInfo !== 0) {
        this.$router.push('/ad-actions')
      } else {
        this.$message.error('无绑定店铺信息无法创建广告,请联系管理员')

      }
    },
    handleChange() {
      let profile = ''
      let area = ''
      const handleCurrentChange = () => {
        this.handleCurrentChange(0)
      };
      let amazon_shop_selected = this.amazon_shop_selected
      this.amazon_shop_options.forEach(function (amazon) {
        if (amazon.amazon_name === amazon_shop_selected) {
          profile = amazon.profile
          area = amazon.area
        }
      })
      axios.post(`https://ad.yaelongtime.com/api/employee/account/${area}/profile/${profile}`,
          {}
      ).then(res => {
        handleCurrentChange()
        location.reload()
      })
    },
    searchType() {
      if (this.filterTypeValue) {
        this.$axios.get('https://ad.yaelongtime.com/api/sp/report',
            {
              params: {
                asin: this.filterTypeValue,
              }
            }
        ).then(res => {
          console.log(8787, res.data)
          this.loading = false
          let tableData = []
          res.data.content.forEach(function (ad) {
            if (ad.campaign.state === 'ENABLED') {
              ad.campaign.state = true
            } else {
              ad.campaign.state = false
            }
            let inventoryData = {
              campaignName: ad.campaign.name,
              state: ad.campaign.state,
              type: 'SP',
              impressions: ad.impressions,
              sales30d: ad.sales30d,
              clicks: ad.clicks,
              campaignBudgetAmount: ad.campaignBudgetAmount,
              clickThroughRate: (ad.clickThroughRate * 1).toFixed(2) + '%',
              targetingType: ad.campaign.targetingType,
              sku: ad.sku,
              asin: ad.asin,
              spend: ad.spend,
              roas: ad.roasClicks14d,
              purchasesSameSku30d: ad.purchasesSameSku30d,
              acos: (ad.acosClicks14d).toFixed(2)
            }
            tableData.push(inventoryData)
          })
          this.tableData = tableData
          this.pagination.total_pages = res.data.totalPages
          this.pagination.total_records = res.data.totalElements
        }).catch(error => {
          this.$message.error(error)
        })

      } else {
        this.handleCurrentChange()
      }
    },
    search() {
      if (this.filterValue) {
        this.$axios.get('https://ad.yaelongtime.com/api/sp/report',
            {
              params: {
                sku: this.filterValue,
              }
            }
        ).then(res => {
          this.loading = false
          let tableData = []
          res.data.content.forEach(function (ad) {
            if (ad.campaign.state === 'ENABLED') {
              ad.campaign.state = true
            } else {
              ad.campaign.state = false
            }
            let inventoryData = {
              campaignName: ad.campaign.name,
              state: ad.campaign.state,
              type: 'SP',
              impressions: ad.impressions,
              sales30d: ad.sales30d,
              clicks: ad.clicks,
              campaignBudgetAmount: ad.campaignBudgetAmount,
              clickThroughRate: (ad.clickThroughRate * 100).toFixed(2) + '%',
              targetingType: ad.campaign.targetingType,
              sku: ad.sku,
              asin: ad.asin,
              spend: ad.spend,
              roas: ad.roasClicks14d,
              purchasesSameSku30d: ad.purchasesSameSku30d,
              acos: ad.acosClicks14d
            }
            tableData.push(inventoryData)
          })
          this.tableData = tableData
          this.pagination.total_pages = res.data.totalPages
          this.pagination.total_records = res.data.totalElements
        }).catch(error => {
          this.$message.error(error)
        })
      } else {
        this.handleCurrentChange()
      }
    },
    refreshType() {
      this.handleCurrentChange()
    },
    refresh() {
      this.handleCurrentChange()
    },
    handleStateChange(row) {

    },
    searchAdCampaign() {
      if (this.filterAdCampaignValue) {
        this.$axios.get('https://ad.yaelongtime.com/api/sp/report',
            {
              params: {
                'campaign.name': this.filterAdCampaignValue,
              }
            }
        ).then(res => {
          console.log(7878, res.data)
          this.loading = false
          let tableData = []
          res.data.content.forEach(function (ad) {
            if (ad.campaign.state === 'ENABLED') {
              ad.campaign.state = true
            } else {
              ad.campaign.state = false
            }
            let inventoryData = {
              campaignName: ad.campaign.name,
              state: ad.campaign.state,
              type: 'SP',
              impressions: ad.impressions,
              sales30d: ad.sales30d,
              clicks: ad.clicks,
              campaignBudgetAmount: ad.campaignBudgetAmount,
              clickThroughRate: (ad.clickThroughRate * 100).toFixed(2) + '%',
              targetingType: ad.campaign.targetingType,
              sku: ad.sku,
              asin: ad.asin,
              spend: ad.spend,
              roas: ad.roasClicks14d,
              purchasesSameSku30d: ad.purchasesSameSku30d,
              acos: ad.acosClicks14d
            }
            tableData.push(inventoryData)
          })
          this.tableData = tableData
          this.pagination.total_pages = res.data.totalPages
          this.pagination.total_records = res.data.totalElements
        }).catch(error => {
          this.$message.error(error)
        })

      } else {
        this.handleCurrentChange()
      }
    },
    refreshAdCampaign() {
      this.filterAdCampaignValue = '';
      this.searchAdCampaign();
    },
    handleSizeChange(val) {
      this.pagination.page_size = val
      this.handleCurrentChange(0)
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(currentPage) {
      if (currentPage === 0) {
        currentPage = this.pagination.page
      }
      const createdTime = new Date(); // 获取开始日期
      let created = createdTime.toISOString().split('T')[0]
      console.log(`当前页: ${currentPage}`);
      console.log(`当前每页大小: ${this.pagination.page_size}`);
      this.$axios.get('https://ad.yaelongtime.com/api/sp/report', {
            params: {
              page: currentPage,
              size: this.pagination.page_size,
            }
          }
      ).then(res => {
        this.loading = false
        let tableData = []
        if(res.data.content){
          res.data.content.forEach(function (ad) {
          if (ad.campaign.state === 'ENABLED') {
            ad.campaign.state = true
          } else {
            ad.campaign.state = false
          }
          let inventoryData = {
            campaignName: ad.campaign.name,
            state: ad.campaign.state,
            type: 'SP',
            impressions: ad.impressions,
            sales30d: ad.sales30d,
            clicks: ad.clicks,
            campaignBudgetAmount: ad.campaignBudgetAmount,
            clickThroughRate: (ad.clickThroughRate * 1).toFixed(2) + '%',
            targetingType: ad.campaign.targetingType,
            sku: ad.sku,
            asin: ad.asin,
            spend: ad.spend,
            roas: ad.roasClicks14d,
            purchasesSameSku30d: ad.purchasesSameSku30d,
            acos: (ad.acosClicks14d).toFixed(2)
          }

          tableData.push(inventoryData)
        })
        this.tableData = tableData
        }else{
          this.tableData = []
        }
        this.pagination.total_pages = res.data.totalPages
        this.pagination.total_records = res.data.totalElements
      }).catch(error => {
            this.$message.error('无广告数据')
          }
      )
    },
    //获取中文店铺选择
    handleNameDate() {
      this.$axios.get('https://ad.yaelongtime.com/api/me',
          {}
      ).then(res => {
        console.log(888, res.data)
        res.data.profiles.forEach(function (profiles) {
          if (res.data.logined === profiles.logined) {
            amazon_shop_selected = res.data.nickname + '-' + profiles.country
          }
        })
        this.amazon_shop_selected = amazon_shop_selected
      })
      const amazon_shop_options = []
      let amazon_shop_selected = ''
      this.$axios.get('https://ad.yaelongtime.com/api/employee/me',
          {}
      ).then(res => {
        this.name = res.data.name
        return this.$axios.get(`https://ad.yaelongtime.com/api/employee/${res.data.id}`)
      }).then(res => {
        if (res.data.accounts && res.data.profiles) {
          if (res.data.accounts.length > 0 && res.data.profiles.length > 0) {
            res.data.accounts.forEach(function (area) {
              res.data.profiles.forEach(function (store) {
                if (area.id === store.accountId) {
                  let storeDict = {
                    area: area.id,
                    profile: store.id,
                    amazon_name: area.nickname + '-' + store.country
                  }
                  amazon_shop_options.push(storeDict)
                }
              })
            })
            this.amazon_shop_options = amazon_shop_options
          }
        } else {
          this.$message.error('无绑定店铺信息')
          this.storeInfo = 0
          this.tableData = []
          this.amazon_shop_selected = ''
        }

      })
    },
  },
  created() {
    this.handleNameDate()
    this.handleCurrentChange(0)
  },
  mounted() {
    // 监听窗口大小变化，实时更新表格最大高度
    window.addEventListener('resize', () => {
      this.maxHeight = this.getMaxHeight();
    });
  },

}
</script>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.search {
  margin: 0;
  padding: 0;
  background-color: #f9fafc;
  padding-right: 100%;
  font-size: 1px;
}

.pagination-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 6px;
}

.my-table-container {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.my-table {
  border-collapse: collapse;
}

@media screen and (max-width: 600px) {
  .my-table {
    font-size: 12px;
    line-height: 1.5;
  }
}

.my-table-container {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.my-table {
  border-collapse: collapse;
}

@media screen and (max-width: 600px) {
  .my-table {
    font-size: 12px;
    line-height: 1.5;
  }
}
</style>